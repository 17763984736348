// ReadMore.js
import React, { useState } from 'react';
import style from './ReadMore.module.css';
import FAQ from '../assets/FAQ_1_-removebg.png'

const Newreadmorebutton = ({ text, QuesAns }) => {
    const [showFullText, setShowFullText] = useState(false);
    const [selection, setSelection] = useState(null)
    const toggle = (i) => {
        if (selection === i) {
            return setSelection(null)
        }
        setSelection(i)
    }

    const toggleReadMore = () => {
        setShowFullText(!showFullText);
    };

    return (
        <div >
            <div className={style.container}>
                <div dangerouslySetInnerHTML={{ __html: showFullText ? text : `${text.slice(0, 200)}...` }} />
            </div>
            {showFullText && <div className={style.FAQS}>
                <div style={{ position: 'absolute', width: '378px' }}>
                    <h2 className={style.accordion_h1}>Frequently Asked Questions</h2>
                </div>
                <div className={style.faqdiv2}>
                    <div className={style.faqimg}>
                        <img className={style.faq} src={FAQ} alt="FAQ" />
                    </div>
                    <div className={style.wrapper}>

                        <div className={style.accordion}>
                            {QuesAns.map((element, i) => (
                                <div key={element.id}>
                                    <div className={style.title_1} style={{ height: '90px' }} onClick={() => { toggle(i) }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', gridGap: '8px' }}>{element.icon}<h3 className={style.accodion_h2}>{element.statement}</h3></div>
                                    </div>
                                    <div className={selection === i ? `${style.contain} ${style.show}` : `${style.contain}`}>
                                        <p className={style.container_accordion_ptag}>  {element.paragraph}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>}
            <button className={style.button} onClick={toggleReadMore}>
                {showFullText ? 'Read Less' : 'Read More'}
            </button>
        </div>
    );
};

export default Newreadmorebutton;
