import React, { useEffect, useRef } from 'react'
import emailjs from '@emailjs/browser';
import styles from './IndustryServices.module.css'
import TextSphare from '../BlogPages/BlogSubPages/TextSphare'
import { Link, useNavigate } from 'react-router-dom'

import Images1 from '../assets/Industry Services/SEO Services/Now_20240415_210326_0000.png'
import Images2 from '../assets/Industry Services/SEO Services/Now_20240415_223918_0000.png'
import Images3 from '../assets/Industry Services/SEO Services/Now_20240415_223918_0001.png'
import Images4 from '../assets/Industry Services/SEO Services/Now_20240415_223918_0002.png'
import Images5 from '../assets/Industry Services/SEO Services/WhatsApp Image 2024-04-14 at 00.08.46_270cae72.jpg'
import Images6 from '../assets/Industry Services/SEO Services/WhatsApp Image 2024-04-15 at 22.21.04_abb02654.jpg'
import Images7 from '../assets/Industry Services/SEO Services/wordcloudSEO.png'
import Images8 from '../assets/Industry Services/SEO Services/wordcloudSEO1.png'
import Images9 from '../assets/Industry Services/SEO Services/Classic Word Cloud.jpeg.jpg'
// import Images10 from '../assets/Industry Services/SEO Services/Now_20240415_210326_0000.png'
// import Images11 from '../assets/Industry Services/SEO Services//Now_20240415_223918_0001.png'
// import Images12 from '../assets/Industry Services/SEO Services/Now_20240415_223918_0002.png'
// import Images13 from '../assets/Industry Services/SEO Services/WhatsApp Image 2024-04-14 at 00.08.46_270cae72.jpg'

import Video1 from '../assets/Industry Services/Video/Digital.mp4'
import Video2 from '../assets/Industry Services/Video/whatsapp2.mp4'
import Video3 from '../assets/Industry Services/Video/whatsapp.mp4'

import bannerImage from '../assets/Digital Marketing/IMG-20231116-WA0001.jpg'
import Icons1 from '../assets/Home Two/IMG-20231207-WA0009.jpg'
import Icons2 from '../assets/Home Two/IMG-20231207-WA0010.jpg'
import Icons3 from '../assets/Home Two/IMG-20231207-WA0011.jpg'
import Icons4 from '../assets/Home Two/IMG-20231207-WA0012.jpg'
import Toolsnames from '../Pages/Toolsnames'
import Carousal from '../Carousal'
import { Helmet } from 'react-helmet';

import SEO from '../assets/Industry Services/Services Images/seo services.png'
import PPC from '../assets/Industry Services/Services Images/shopify.png'
import websiteDevelopment from '../assets/Industry Services/Services Images/web development.png'
import dataAnalytics from '../assets/Industry Services/Services Images/report.png'
import SocialMarketing from '../assets/Industry Services/Services Images/social media marketing.png'
import emailMarketing from '../assets/Industry Services/Services Images/email marketing.png'
import contentMarketing from '../assets/Industry Services/Services Images/seo content.png'
import reputationManagement from '../assets/Industry Services/Services Images/reputation.png'
import convertionRate from '../assets/Industry Services/Services Images/conversion rate.png'


function DigitalMarketing() {
    const texts = ["Tags", "Meta", "SEO", "Audit", "Local", "Maps", "Links", "Schema", "Content", "Keywords"];

    const navigate = useNavigate()
    const form = useRef();
    // document.getElementById('videos').play()

    // let vid = document.getElementById("videos");

    // function playVid() {
    //     vid.classList.add("active")
    // }
    // useEffect(() => {
    //     playVid()
    // }, [])

    const videoRef = useRef(null);
    const videoRef1 = useRef(null);
    const videoRef2 = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.play();
        }
        if (videoRef1.current) {
            videoRef1.current.play();
        }
        if (videoRef2.current) {
            videoRef2.current.play();
        }
    }, []);

    const sendEmail = (e) => {
        e.preventDefault();
        navigate("/thank-you")
        emailjs
            .sendForm('service_xeikd6k', 'template_184dkpm', form.current, {
                publicKey: 'n--h-ZLHb25m5t0A1',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    };
    return (
        <>
            <Helmet>
                <title>Grand Rapids Digital Marketing Company | Best Online Marketing Services</title>
                <meta name="description" content="NSP is a Digital Marketing Company. We build ROI-driven strategies to help achieve your business goals. Contact us today!" />
                <link rel="canonical" href="https://www.nspglobalservices.com/digital-marketing-for-grand-rapids" />
            </Helmet>
            <div className={styles.container}>
                <div className={styles.content} >
                    <h1 className={styles.title} >Grand Rapids Digital Marketing Services</h1>
                    <p className={styles.subtitle}>Let’s talk about how we can turn your business into an online success story!</p>
                    <div className={styles.buttons} >
                        {/* <Link className={styles.btns} to='/contact-us'>Call us now</Link> */}
                        <Link className={styles.btns} to='/contact-us'>Contact us now</Link>
                    </div>
                </div>
                <div className={styles.bannerImage}>
                    <TextSphare texts={["Consultation",
                        "Keyword Research",
                        "On-Page",
                        "Off-Page",
                        "Reputation Management",
                        "Google Ads",
                        "Facebook Ads",
                        "Lead Generation",
                        "Shopping Ads",
                        "Maximize Conversions",]} />
                </div>
            </div>
            <div className={styles.contents} >
                <h2 className={styles.heading}>Grow Your Business with our Expert Digital Marketing Strategies</h2>
                <div className={styles.alternativeContent} >
                    <div className={styles.contentImages} >
                        <img style={{ height: '100%', width: '70%' }} src={SEO} alt="" />
                    </div>
                    <div className={styles.contentTexts}>
                        <h2 className={styles.heading}>Enterprise Search Engine Optimization Services</h2>
                        <p className={styles.paragraph}>Our <Link to={'/seo-services'}>SEO services</Link> enhance your website's online visibility, making it easy for potential customers to find you. We employ a comprehensive strategy, including keyword research, <Link to={'/seo-services/on-page-seo'}>on-page optimization</Link>, and robust backlink building to establish authority.</p>
                    </div>
                </div>
                <div className={styles.alternativeContent}>
                    <div className={styles.contentTexts}>
                        <h2 className={styles.heading}>Pay-Per-Click Advertising Services</h2>
                        <p className={styles.paragraph}>Our <Link to={'/ppc-services'}>Our Pay-Per-Click (PPC) Advertising service</Link> offers immediate brand visibility by placing ads where your target audience is searching. Utilizing Google Ads, Bing Ads, and social media, we develop campaigns that maximize your investment.</p>
                    </div>
                    <div className={styles.contentImages}>
                        <img style={{ width: '70%' }} src={PPC} alt="" />
                    </div>
                </div>
                <div className={styles.alternativeContent}>
                    <div className={styles.contentImages}>
                        <img style={{ width: '70%', mixBlendMode: 'darken' }} src={SocialMarketing} alt="" />
                    </div>
                    <div className={styles.contentTexts}>
                        <h2 className={styles.heading}>Social Media Marketing Services</h2>
                        <p className={styles.paragraph}>Our affordable social media marketing services focus on enhancing your brand's online presence by developing strategic content that appeals to your audience, aiming to grow your following and deepen connections for real conversions. We handle content creation, scheduling, and engagement, keeping your profiles vibrant.</p>
                    </div>
                </div>
                <div className={styles.alternativeContent}>
                    <div className={styles.contentTexts}>
                        <h2 className={styles.heading}>Content Marketing Services</h2>
                        <p className={styles.paragraph}>Our affordable <Link to={'/content-marketing-services'}>Content Marketing services</Link> attract and engage your target audience with quality content, from insightful blog posts to captivating videos, aligning with your brand values to meet audience needs. We focus on creating, distributing, and analyzing content to drive conversions and build loyalty.</p>
                    </div>
                    <div className={styles.contentImages}>
                        <img style={{ width: '70%', mixBlendMode: 'darken' }} src={contentMarketing} alt="" />
                    </div>
                </div>
                <div className={styles.alternativeContent}>
                    <div className={styles.contentImages}>
                        <img style={{ width: '70%', mixBlendMode: 'darken' }} src={emailMarketing} alt="" />
                    </div>
                    <div className={styles.contentTexts}>
                        <h2 className={styles.heading}>Outsource Email Marketing Services</h2>
                        <p className={styles.paragraph}>Our cold Email Marketing service utilizes personalized communication to engage and inform your audience, enhancing their connection to your brand. It includes custom newsletters, targeted campaigns, segmentation strategies, and detailed analytics to ensure effective messaging and track performance.</p>
                    </div>
                </div>
                <div className={styles.alternativeContent}>
                    <div className={styles.contentTexts}>
                        <h2 className={styles.heading}>Online Reputation Management Services</h2>
                        <p className={styles.paragraph}>Our Online Reputation Management services are designed to protect and improve your brand's online presence, essential in today's digital landscape for influencing consumer trust. We monitor your brand's online mentions, address negatives, and highlight positives across platforms like Google and Yelp.</p>
                    </div>
                    <div className={styles.contentImages}>
                        <img style={{ width: '70%', mixBlendMode: 'darken' }} src={reputationManagement} alt="" />
                    </div>
                </div>
                <div className={styles.alternativeContent}>
                    <div className={styles.contentImages}>
                        <img style={{ width: '70%', mixBlendMode: 'darken' }} src={websiteDevelopment} alt="" />
                    </div>
                    <div className={styles.contentTexts}>
                        <h2 className={styles.heading}>Website Design and Development Services</h2>
                        <p className={styles.paragraph}>Our <Link to={'/design-development'}>Website Design and Development services</Link> focus on crafting stunning, functional websites tailored to your business needs, ensuring a smooth, mobile-responsive user experience optimized for search engines. We offer end-to-end solutions from custom design to CMS integration, e-commerce, and maintenance, aiming to develop visually appealing., traffic-driving websites.</p>
                    </div>
                </div>
                <div className={styles.alternativeContent}>
                    <div className={styles.contentTexts}>
                        <h2 className={styles.heading}>Data Analytics and Reporting</h2>
                        <p className={styles.paragraph}>Our <Link to={'/industries/technology/data-analytics'}>Data Analytics</Link> and Reporting services provide essential insights for informed decision-making and strategic planning for business growth. By analyzing data from various marketing channels, including PPC, Content Marketing, Email Marketing, and more, we identify valuable insights into campaign performance.</p>
                    </div>
                    <div className={styles.contentImages}>
                        <img style={{ width: '70%', mixBlendMode: 'darken' }} src={dataAnalytics} alt="" />
                    </div>
                </div>
                <div className={styles.alternativeContent}>
                    <div className={styles.contentImages}>
                        <img style={{ width: '70%', mixBlendMode: 'darken' }} src={convertionRate} alt="" />
                    </div>
                    <div className={styles.contentTexts}>
                        <h2 className={styles.heading}>Conversion Rate Optimization</h2>
                        <p className={styles.paragraph}>Our Conversion Rate Optimization (CRO) services transform your website visitors into customers by deeply understanding user behavior and optimizing every part of their online journey. We refine landing pages for compelling usability, use A/B testing to fine-tune your site, and enhance user experience to attract and keep customers.</p>
                    </div>
                </div>
            </div>
            {/* container 3 */}
            <div className={styles.container_3}>
                <div className={styles.container_3_div1}>
                    <h3 className={styles.container_3_H2}>Why Choose Us?</h3>
                    <p className={styles.container_3_para}>Increase your online presence and drive success with NSP's comprehensive digital marketing services. Benefit from increased visibility, lead generation, and revenue growth.</p>
                </div>
                <div className={styles.cardContainer}>
                    <div className={styles.card} >
                        {/* <img loading="lazy" src={Icons1} alt='Icons1' className={styles.cardimg} /> */}
                        <h3 className={styles.cardTitle}>Expertise</h3>
                        <p className={styles.cardPara}> Our team of experienced digital marketers has a deep understanding of the ever-evolving landscape of online marketing and can help you navigate it with ease.</p>
                    </div>
                    <div className={styles.card} >
                        {/* <img loading="lazy" src={Icons2} alt='Icons2' className={styles.cardimg} /> */}
                        <h3 className={styles.cardTitle}>Customized Solutions</h3>
                        <p className={styles.cardPara}>We believe in tailor-made strategies that are specific to your business objectives, target audience, and industry.</p>
                    </div>
                    <div className={styles.card} >
                        {/* <img loading="lazy" src={Icons3} alt='Icons3' className={styles.cardimg} /> */}
                        <h3 className={styles.cardTitle}>Results-driven</h3>
                        <p className={styles.cardPara}> Our ultimate goal is to help your business achieve tangible results and a healthy return on investment.</p>
                    </div>
                    <div className={styles.card} >
                        {/* <img loading="lazy" src={Icons4} alt='Icons4' className={styles.cardimg} /> */}
                        <h3 className={styles.cardTitle}>SEO Services Near Me</h3>
                        <p className={styles.cardPara}>NSP Global Services We are just a Click Away for all your SEO needs.</p>
                    </div>
                </div>
            </div>
            {/* toolsname */}
            <div className={styles.tools}>
                <h2 className={styles.toolweuse} >Tools that we use</h2>
                <div className={styles.toolsname}> <Toolsnames /></div>
            </div>
            {/* testimonials  */}
            <div className={styles.container5}>
                <div className={styles.container5_div}>
                    <p className={styles.container4_para1}>Testimonials</p>
                    <h2 className={styles.container4_hone}>What People Say</h2>
                    {/* <p className={styles.container4_para2}>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. when the musics over turnoff the light</p> */}
                </div>
            </div>
            <div className={styles.carousel}>
                <Carousal num={3} />
            </div>
            {/*  */}
            <div className={styles.container4}>
                <div >
                    <img loading="lazy" src={bannerImage} alt='bannerImage' className={styles.bannerimage} />
                </div>
                <div className={styles.bannerForm}>
                    <h2>Get Your Free Quote</h2>
                    <form className={styles.Form} ref={form} onSubmit={sendEmail} >
                        <input className={styles.inputs} placeholder='Your Name' type="text" name="user_name" />
                        <input className={styles.inputs} placeholder='Your email' type="email" name="user_email" />
                        <input className={styles.inputs} name="message" placeholder='Enter your message' />
                        {/* <input type="hidden" name="_template" value="table"></input> */}
                        <input type="submit" className={styles.FormButton} value="Send" />
                        {/* <button className={style.FormButton} type='submit'>Submit</button> */}
                    </form>
                </div>
            </div>
        </>
    )
}

export default DigitalMarketing