import React from 'react'
import style from './pricing.module.css'
import { Link } from 'react-router-dom'
import check from '../assets/check.png'

function OnlineMarketPlacePricing() {
    return (
        <div >
            <div className={style.pricing_card}>
                <div className={style.pricing}>
                    <div className={style.pricing_rate}>
                        <h5 className={style.container4_hfive}>Custom Marketplace</h5>
                        <div className={style.pricing_value}>
                            <span className={style.value}>$9999</span>
                        </div>
                        <p className={style.container4_para3}>Lite includes:</p>
                    </div>
                    <ul>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Unique Pages and UI Design</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Complete Custom Development</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Expert Technical Support</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Responsive Design</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Newsfeed Integration</span>
                        </li>
                    </ul>
                    <Link to='/contact-us' className={style.signup_btn}>Signup Now</Link>
                </div>
            </div>
        </div>
    )
}

export default OnlineMarketPlacePricing