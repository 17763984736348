import React from 'react'
import style from './pricing.module.css'
import { Link } from 'react-router-dom'
import check from '../assets/check.png'

function WebdesignPricing() {
    return (
        <div >
            <div className={style.pricing_card}>
                <div className={style.pricing}>
                    <div className={style.pricing_rate}>
                        <h5 className={style.container4_hfive}>LITE</h5>
                        <div className={style.pricing_value}>
                            <span className={style.value}>$350</span>
                            <span className={style.month}>/month</span>
                        </div>
                        <p className={style.container4_para3}>Get started with a beautifully designed website of 2-4 pages, perfect for showcasing your business or personal brand.</p>
                    </div>
                    <ul>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Website Design (2-4 Pages)</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Design Revisions - 3 Times</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Expert Technical Support</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Responsive Design</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}> CMS Platform - WordPress</span>
                        </li>
                    </ul>
                    <Link to='/contact-us' className={style.signup_btn}>Signup Now</Link>
                </div>
                <div className={style.pricing}>
                    <div className={style.pricing_rate}>
                        <h5 className={style.container4_hfive}>STANDARD</h5>
                        <div className={style.pricing_value}>
                            <span className={style.value}>$550</span>
                            <span className={style.month}>/month</span>
                        </div>
                        <p className={style.container4_para3}>Take your online presence to the next level with a professionally designed website of 6-8 pages, tailored to meet your specific needs.</p>
                    </div>
                    <ul>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}> Website Design (5 - 8 Pages)</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Design Revisions - Unlimited</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Professional Blog Design</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>CMS Platform - WordPress</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Responsive Design</span>
                        </li>
                    </ul>
                    <Link to='/contact-us' className={style.signup_btn}>Signup Now</Link>
                </div>
                <div className={style.pricing}>
                    <div className={style.pricing_rate}>
                        <h5 className={style.container4_hfive}>PROFESSIONAL</h5>
                        <div className={style.pricing_value}>
                            <span className={style.value}>$650</span>
                            <span className={style.month}>/month</span>
                        </div>
                        <p className={style.container4_para3}>Unlock the full potential of your online presence with an expansive website of 10-12 pages, designed to accommodate the unique requirements of your growing business.</p>
                    </div>
                    <ul>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Website Design (10 - 12 Pages)</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>60 pages optimized</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Design Revisions - Unlimited</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>CMS Platform - WordPress</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>24/7 priority support</span>
                        </li>
                    </ul>
                    <Link to='/contact-us' className={style.signup_btn}>Signup Now</Link>
                </div>

            </div>
           
        </div>
    )
}

export default WebdesignPricing