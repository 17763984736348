import React from 'react'
import style from './pricing.module.css'
import { Link } from 'react-router-dom'
import check from '../assets/check.png'

function WebApplicationPricing() {
    return (
        <div >
            <div className={style.pricing_card}>
                <div className={style.pricing}>
                    <div className={style.pricing_rate}>
                        <h5 className={style.container4_hfive}>Automated Conferencing Portal</h5>
                        <div className={style.pricing_value}>
                            <span className={style.value}>$4999</span>
                        </div>
                        <p className={style.container4_para3}>Lite includes:</p>
                    </div>
                    <ul>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Unlimited Page Website</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Unique Pages and UI Design</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Complete Custom Development</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Responsive Design</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>10 Unique Banner Designs</span>
                        </li>
                    </ul>
                    <Link to='/contact-us' className={style.signup_btn}>Signup Now</Link>
                </div>
                <div className={style.pricing}>
                    <div className={style.pricing_rate}>
                        <h5 className={style.container4_hfive}>Custom CRM Portal</h5>
                        <div className={style.pricing_value}>
                            <span className={style.value}>$6999</span>
                        </div>
                        <p className={style.container4_para3}>Lite includes:</p>
                    </div>
                    <ul>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Unlimited Page Website</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Unique Pages and UI Design</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Complete Custom Development</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Performance and analytics</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Responsive Design</span>
                        </li>
                    </ul>
                    <Link to='/contact-us' className={style.signup_btn}>Signup Now</Link>
                </div>
                <div className={style.pricing}>
                    <div className={style.pricing_rate}>
                        <h5 className={style.container4_hfive}>Automated E-Commerce</h5>
                        <div className={style.pricing_value}>
                            <span className={style.value}>$9999</span>
                        </div>
                        <p className={style.container4_para3}>Lite includes:</p>
                    </div>
                    <ul>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}> Unique Pages and UI Design</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Complete Custom Development</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Complete W3C Certified HTML</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Complete Deployment</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>24/7 priority support</span>
                        </li>
                    </ul>
                    <Link to='/contact-us' className={style.signup_btn}>Signup Now</Link>
                </div>

            </div>

        </div>
    )
}

export default WebApplicationPricing