import React from 'react'
import style from './pricing.module.css'
import { Link } from 'react-router-dom'
import check from '../assets/check.png'

function EcommercePricing() {
    return (
        <div >
            <div className={style.pricing_card}>
                <div className={style.pricing}>
                    <div className={style.pricing_rate}>
                        <h5 className={style.container4_hfive}>Startup E-Commerce</h5>
                        <div className={style.pricing_value}>
                            <span className={style.value}>$999</span>
                        </div>
                        <p className={style.container4_para3}>Lite includes:</p>
                    </div>
                    <ul>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>10 to 20 Pages Website</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Mobile Friendly Responsive</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Custom, Interactive & Dynamic Web Design</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Theme Development</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Interactive Sliding Banners</span>
                        </li>
                    </ul>
                    <Link to='/contact-us' className={style.signup_btn}>Signup Now</Link>
                </div>
                <div className={style.pricing}>
                    <div className={style.pricing_rate}>
                        <h5 className={style.container4_hfive}>Essential E-Commerce</h5>
                        <div className={style.pricing_value}>
                            <span className={style.value}>$1799</span>
                        </div>
                        <p className={style.container4_para3}>Lite includes:</p>
                    </div>
                    <ul>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Custom Ecommerce Website Design</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Fully Mobile Responsive</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Interactive Sliding Banners</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Complete W3C Certified HTML</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Complete Deployment</span>
                        </li>
                    </ul>
                    <Link to='/contact-us' className={style.signup_btn}>Signup Now</Link>
                </div>
                <div className={style.pricing}>
                    <div className={style.pricing_rate}>
                        <h5 className={style.container4_hfive}>Business E-Commerce</h5>
                        <div className={style.pricing_value}>
                            <span className={style.value}>$3699</span>
                        </div>
                        <p className={style.container4_para3}>Lite includes:</p>
                    </div>
                    <ul>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Unlimited Web Pages</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Custom Made, Interactive, Dynamic & High End Design</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Interactive Sliding Banners</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Mobile Responsive</span>
                        </li>
                        <li className={style.list_items}>
                            <img src={check} alt='check' className={style.check_img} />
                            <span className={style.list_contents}>Complete W3C Certified HTML</span>
                        </li>
                    </ul>
                    <Link to='/contact-us' className={style.signup_btn}>Signup Now</Link>
                </div>

            </div>

        </div>
    )
}

export default EcommercePricing