// TextSphere.js
import React, { useRef, useEffect } from 'react';
import './Textsphare.css';


const TextSphere = ({ texts }) => {
  const containerRef = useRef(null);
  let angleX = 0;
  let angleY = 0;

  useEffect(() => {
    const container = containerRef.current;

    const updateSpherePosition = () => {
      const radius = 100;
      const textElements = container.getElementsByClassName('text');
      Array.from(textElements).forEach((text, index) => {
        const angle = (Math.PI * 1.8 / (texts.length - 1)) * index;
        const x = radius * Math.sin(angleX + angle) * 1.5;
        const y = radius * Math.cos(angleY + angle) * 1.5;
        const z = radius * Math.cos(angleX + angle) * Math.sin(angleY + angle) * 1.5;

        text.style.transform = `translate3d(${x}px, ${y}px, ${z}px)`;
      });
    };

    const animate = () => {
      angleX += 0.01;
      angleY += 0.01;

      updateSpherePosition();

      requestAnimationFrame(animate);
    };

    animate();

    return () => cancelAnimationFrame(animate);
  }, [texts]);

  return (
    <div className="sphere-container" ref={containerRef}>
      {texts.map((text, index) => (
        <div className="text" key={index}>
          {text}
        </div>
      ))}
    </div>
  );
};

export default TextSphere;

