import React, { useRef } from 'react'
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom'
import styles from './PopUps.module.css'
// import { createBrowserHistory } from 'history';

function PopUps(props) {
//  const location = useLocation()
//  const history = createBrowserHistory();
//  const history = useHistory();
// const Thankyou = ()=>{
//   history.push('/thank-you');
//  }

const navigate = useNavigate()
const form = useRef();



const sendEmail = (e) => {
    e.preventDefault();
    navigate("/thank-you")
    emailjs
        .sendForm('service_xeikd6k', 'template_184dkpm', form.current, {
            publicKey: 'n--h-ZLHb25m5t0A1',
        })
        .then(
            () => {
                console.log('SUCCESS!');
            },
            (error) => {
                console.log('FAILED...', error.text);
            },
        );
};
  return (
    <>
    <div className={styles.container}>
      <div className={styles.containerdiv} >
        <div className={styles.title} >
          <h1 className={styles.text}>Claim Your Free SEO Audit Now!</h1>
          <button onClick={props.CancelBtn} className={styles.titlebtn} >X</button>
        </div>
        <div className={styles.formdiv} >
          <form className={styles.form} ref={form} onSubmit={sendEmail}>
            <input className={styles.inputs} placeholder='Your Name' type="text" name="user_name" /><br />
            <input className={styles.inputs} placeholder='Your email' type="email" name="user_email" /><br />
            <input className={styles.inputs}  placeholder='Your phone' name="message"  /><br />
            <input type="submit" className={styles.container_1_ptag} value="Send" />
            {/* <input type="hidden" name="_template" value="table"></input>
            <button type='submit' onClick={props.ThanksYou} className={styles.container_1_ptag} >Submit</button> */}
          </form>
        </div>
      </div>
      </div>
    </>
  )
}

export default PopUps;