import React from 'react';
import style from '../Pages/LocationCard.module.css';

const LocationCard = (props) => {
    const handleViewOffice = () => {
        // Handle View Office click
    };

    const handleGetDirections = () => {
        // Handle Get Directions click
    };

    const handleViewServices = () => {
        // Handle View Services click
    };

    function convertString(input) {
        // Split the string by the hyphen
        const words = input.split('-');

        // Capitalize the first letter of each word and join them with a space
        // const converted = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        const converted = words
            .filter(word => word.toLowerCase() !== 'seo' && word.toLowerCase() !== 'agency')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

        return converted;
    }

    const outputString = convertString(props.paths);

    return (
        <div className={style.locationCard}>
            {/* <h2 className={style.heading} ></h2>
            <p>318 North George Street</p>
            <p>York, PA 17401</p>
            <p className={style.phoneNumber}>📞 345 678 987</p>
            <div className={style.Buttons}>
                <button onClick={handleViewOffice}>View Office</button>
                <button onClick={handleGetDirections}>Get Directions</button>
                <button onClick={handleViewServices}>View Services</button>
            </div> */}
            {outputString}
        </div>
    );
};

export default LocationCard;
