import React from 'react'
import style from '../BlogPages/BlogSubPages/Alteration.module.css'
import { GoPlus } from 'react-icons/go'
import FAQ from '../assets/FAQ_1_-removebg.png'
import { useState } from 'react'



export default function InteriorDesign() {
    return (
        <>
            <div className={style.header}>
                <div className={style.container1_div}>
                    <h1 className={style.container1_div_hone}>100 Interior Design Quotes by Famous Designers</h1>
                    {/* <p className={style.container1_div_ptag}>Grow Your Accountancy Firm With Organic SEO Services</p> */}
                </div>
                <div className={style.container2_div}>
                    {/* <TextSphare texts={[
              "Consultation",
              "Keyword Research",
              "On-Page",
              "Off-Page",
              "SEO",
              "Reputation Management",
              "Google Ads",
              "Facebook Ads",
              "Lead Generation",
              "Shopping Ads",
              "Maximize Conversions",
              "Accountancy Firm SEO"
            ]} /> */}
                </div>
            </div>
            <div className={style.container2}>
                <div className={style.container2_div1}>
                    <h2>Here are over 100 interior design quotes by famous designers:</h2>
                    <p>“An interior is the natural projection of the soul.” – Coco Chanel</p>
                    <p>“The best rooms have something to say about the people who live in them.” – David Hicks</p>
                    <p>“Design is not just what it looks like and feels like. Design is how it works.” – Steve Jobs</p>
                    <p>“Design is a plan for arranging elements in such a way as best to accomplish a particular purpose.” – Charles Eames</p>
                    <p>“Creativity is allowing yourself to make mistakes. Design is knowing which ones to keep.” – Scott Adams</p>
                    <p>“An essential aspect of creativity is not being afraid to fail.” – Edwin Land</p>
                    <p>“Have nothing in your house that you do not know to be useful or believe to be beautiful.” – William Morris</p>
                    <p>“Design is thinking made visual.” – Saul Bass</p>
                    <p>“Good design is obvious. Great design is transparent.” – Joe Sparano</p>
                    <p>“Design is where science and art break even.” – Robin Mathew</p>
                    <p>“Design is intelligence made visible.” – Alina Wheeler</p>
                    <p>“A room should never allow the eye to settle in one place. It should smile at you and create fantasy.” – Juan Montoya</p>
                    <p>“To create an interior, the designer must develop an overall concept and stick to it.” – Albert Hadley</p>
                    <p>“Simplicity is the ultimate sophistication.” – Leonardo da Vinci</p>
                    <p>“The details are not the details. They make the design.” – Charles Eames</p>
                    <p>“Architecture should speak of its time and place, but yearn for timelessness.” – Frank Gehry</p>
                    <p>“Good buildings come from good people, and all problems are solved by good design.” – Stephen Gardiner</p>
                    <p>“Design adds value faster than it adds costs.” – Joel Spolsky</p>
                    <p>“Design is not for philosophy—it’s for life.” – Issey Miyake</p>
                    <p>“Design must reflect the practical and aesthetic in business but above all… good design must primarily serve people.” – Thomas J. Watson</p>
                    <p>“Form follows function – that has been misunderstood. Form and function should be one, joined in a spiritual union.” – Frank Lloyd Wright</p>
                    <p>“Luxury is in each detail.” – Hubert de Givenchy</p>
                    <p>“Your home should tell the story of who you are, and be a collection of what you love.” – Nate Berkus</p>
                    <p>“Recognizing the need is the primary condition for design.” – Charles Eames</p>
                    <p>“Design is a constant challenge to balance comfort with luxe, the practical with the desirable.” – Donna Karan</p>
                    <p>“Design is a response to a specific problem. You are given a problem to solve, and then you let the problem itself tell you what your solution is.” – Chip Kidd</p>
                    <p>“Good design is all about making other designers feel like idiots because that idea wasn’t theirs.” – Frank Chimero</p>
                    <p>“If you love something, it will work. That’s the only real rule.” – Bunny Williams</p>
                    <p>“Design is not about products. Design is about relationships.” – Humantific</p>
                    <p>“Be faithful to your own taste, because nothing you really like is ever out of style.” – Billy Baldwin</p>
                    <p>“Innovation is the ability to see change as an opportunity—not a threat.” – Steve Jobs</p>
                    <p>“Design should never say, ‘Look at me.’ It should always say, ‘Look at this.'” – David Craib</p>
                    <p>“The ultimate inspiration is the deadline.” – Nolan Bushnell</p>
                    <p>“Designers may be the true intellectuals of the future.” – Paola Antonelli</p>
                    <p>“Designers don’t actually solve problems. They work through them.” – Marty Neumeier</p>
                    <p>“The essence of interior design will always be about people and how they live. It is about the realities of what makes for an attractive, civilized, meaningful environment, not about fashion or what’s in or what’s out.” – Albert Hadley</p>
                    <p>“All rooms ought to look as if they were lived in, and to have so to say, a friendly welcome ready for the incomer.” – William Morris</p>
                    <p>“Good design is making something intelligible and memorable. Great design is making something memorable and meaningful.” – Dieter Rams</p>
                    <p>“Design can be art. Design can be aesthetics. Design is so simple, that’s why it is so complicated.” – Paul Rand</p>
                    <p>“Design is the intermediary between information and understanding.” – Hans Hoffman</p>
                    <p>“Design creates culture. Culture shapes values. Values determine the future.” – Robert L. Peters</p>
                    <p>“Design is a journey of discovery.” – Unknown</p>
                    <p>“Trendy is the last stage before tacky.” – Karl Lagerfeld</p>
                    <p>“The best way to design is to be connected with your intuition.” – Bijoy Jain</p>
                    <p>“Fashion changes, but style endures.” – Coco Chanel</p>
                    <p>“A designer knows he has achieved perfection not when there is nothing left to add, but when there is nothing left to take away.” – Antoine de Saint-Exupery</p>
                    <p>“Design is an opportunity to continue telling the story, not just to sum everything up.” – Tate Linden</p>
                    <p>“Design is like a mom, nobody notices when she’s around, but everybody misses her when she’s not.” – Santiago Borray</p>
                    <p>“A designer is a planner with an aesthetic sense.” – Bruno Munari</p>
                    <p>“Design is the silent ambassador of your brand.” – Paul Rand</p>
                    <p>“Design is the application of intent – the opposite of happenstance, and an antidote to accident.” – Robert L. Peters</p>
                    <p>“The real beauty of a room is not just in how it looks, but in how it feels.” – Unknown</p>
                    <p>“You have to be interested in culture to design for it.” – Hiroshi Fujiwara</p>
                    <p>“A room is not a room without natural light.” – Louis Kahn</p>
                    <p>“Design is the fundamental soul of a man-made creation that ends up expressing itself in successive outer layers of the product or service.” – Steve Jobs</p>
                    <p>“I don’t design clothes, I design dreams.” – Ralph Lauren</p>
                    <p>“Good design is good business.” – Thomas J. Watson</p>
                    <p>“One cannot think well, love well, sleep well if one has not dined well.” – Virginia Woolf</p>
                    <p>“A room should never be all one thing; you need the mix of the old and new, the rough and soft, to keep it interesting.” – Nate Berkus</p>
                    <p>“Design is not just what it looks like and feels like. Design is how it works.” – Steve Jobs</p>
                    <p>“Design is the conscious effort to impose a meaningful order.” – Victor Papanek</p>
                    <p>“Good design is as little design as possible.” – Dieter Rams</p>
                    <p>“Design must seduce, shape, and perhaps more importantly, evoke an emotional response.” – April Greiman</p>
                    <p>“The home should be the treasure chest of living.” – Le Corbusier</p>
                    <p>“Inspiration comes of working every day.” – Charles Baudelaire</p>
                    <p>“When you’re building a room, you’re building character, and character is the strength and wisdom of a home.” – Rose Tarlow</p>
                    <p>“A house is much more than a mere shelter—it should lift us emotionally and spiritually.” – John Saladino</p>
                    <p>“Design is not a single object or dimension. Design is messy and complex.” – Natasha Jen</p>
                    <p>“Make your home as comfortable and attractive as possible and then get on with living. There’s more to life than decorating.” – Albert Hadley</p>
                    <p>“Design is as much an act of spacing as an act of marking.” – Ellen Lupton</p>
                    <p>“To design is to communicate clearly by whatever means you can control or master.” – Milton Glaser</p>
                    <p>“Design is a balance between form and function – it takes two.” – Seesaw</p>
                    <p>“Design should be user-centric.” – Donald A. Norman</p>
                    <p>“Design is the art of gradually applying constraints until only one solution remains.” – Unknown</p>
                    <p>“The best design is a good plan.” – Isaac Mizrahi</p>
                    <p>“The goal of design is to raise the expectation of what design can be.” – Paula Scher</p>
                    <p>“Good design is clear thinking made visual.” – Edward Tufte</p>
                    <p>“Design is a tool to help people.” – Bill Moggridge</p>
                    <p>“Good design begins with honesty, asks tough questions, comes from collaboration and from trusting your intuition.” – Freeman Thomas</p>
                    <p>“Every great design begins with an even better story.” – Lorinda Mamo</p>
                    <p>“Design is a response to a specific problem. You are given a problem to solve, and then you let the problem itself tell you what your solution is.” – Chip Kidd</p>
                    <p>“Design is an opportunity to continue telling the story, not just to sum everything up.” – Tate Linden</p>
                    <p>“Design is a way of life, a point of view. It involves the whole complex of visual communications: talent, creative ability, manual skill, and technical knowledge. Aesthetics and economics, technology and psychology are intrinsically related to the process.” – Paul Rand</p>
                    <p>“Design is in everything we make, but it’s also between those things. It’s a mix of craft, science, storytelling, propaganda, and philosophy.” – Erik Adigard</p>
                    <p>“Design is more than just a few tricks to the eye. It’s a few tricks to the brain.” – Neville Brody</p>
                    <p>“Design is about making things good (and then better) and right (and fantastic) for the people who use and encounter them.” – Matt Beale</p>
                    <p>“Design can be art. Design can be aesthetics. Design is so simple, that’s why it is so complicated.” – Paul Rand</p>
                    <p>“Good design is all about making other designers feel like idiots because that idea wasn’t theirs.” – Frank Chimero</p>
                    <p>“Designing a product is designing a relationship.” – Steve Rogers</p>
                    <p>“Designers are meant to be loved, not to be understood.” – Fabien Barral</p>
                    <p>“Design is the method of putting form and content together. Design, just as art, has multiple definitions; there is no single definition. Design can be art. Design can be aesthetics. Design is so simple, that’s why it is so complicated.” – Paul Rand</p>
                    <p>“Good design is honest.” – Dieter Rams</p>
                    <p>“Design is the search for a magical balance between business and art; art and craft; intuition and reason; concept and detail; playfulness and formality; client and designer; designer and printer; and printer and public.” – Valerie Pettis</p>
                    <p>“Design is a formal response to a strategic question.” – Mariona Lopez</p>
                    <p>“The client may be the king, but he’s not the art director.” – Von R. Glitschka</p>
                    <p>“The function of design is letting design function.” – Micha Commeren</p>
                    <p>“Design is the application of intent – the opposite of happenstance, and an antidote to accident.” – Robert L. Peters</p>
                    <p>“Everything is designed. Few things are designed well.” – Brian Reed</p>
                    <p>“People ignore design that ignores people.” – Frank Chimero</p>
                    <p>“Good design is all about making other designers feel like idiots because that idea wasn’t theirs.” – Frank Chimero</p>
                </div>

                {/* FAQ */}
            </div>
        </>
    )
}
