import React, { Suspense, useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, useNavigate, useHistory, Navigate } from 'react-router-dom'
import { createBrowserHistory } from 'history';
import Whatsappchat from './components/LandingPages/Whatsappchat';
import ErrorPage from './components/LandingPages/ErrorPage';
import PopUps from './components/Pages/PopUps';
import SEOPages from './components/IndustryServices/SEOPages';
import DigitalMarketingIndustry from './components/IndustryServices/DigitalMarketing';
import DesignAndDevelopment from './components/IndustryServices/DesignAndDevelopment';
import SEOCaseStudy from './components/CaseStudy/SEOCaseStudy';
import Location from './components/Locations/Location';
import Locations from './components/Locations/Locations';
import DigitalMarketingCaseStudy from './components/CaseStudy/DigitalMarketingCaseStudy';
import DesignAndDevelopmentCaseStudy from './components/CaseStudy/DesignAndDevelopmentCaseStudy';
import PaidMediaCaseStudy from './components/CaseStudy/PaidMediaCaseStudy';
import BirthDaywishes from './components/HomepageBlog/BirthDaywishes';
import GoodMorning from './components/HomepageBlog/GoodMorning';
import InteriorDesign from './components/HomepageBlog/InteriorDesign';
import PowerVisual from './components/HomepageBlog/PowerVisual';
import WebDevelopmentCompany from './components/HomepageBlog/WebDevelopmentCompany';
const ThanksYou = React.lazy(() => import('./components/LandingPages/ThanksYou'));
const Loader = React.lazy(() => import('./components/Pages/Loader'));
const MedicalEquipmentComponent = React.lazy(() => import('./components/BlogPages/BlogSubPages/MedicalEquipmentComponent'));
const FoodDeliveryServices = React.lazy(() => import('./components/BlogPages/FoodDeliveryServices'));
const GarageDoorRepair = React.lazy(() => import('./components/BlogPages/BlogSubPages/GarageDoorRepair'));
const Customs = React.lazy(() => import('./components/BlogPages/BlogSubPages/Customs'));
const ServiceMainPage = React.lazy(() => import('./components/Servicepages/ServiceMainPage'));
const About = React.lazy(() => import('./components/LandingPages/About'));
const Service = React.lazy(() => import('./components/Servicepages/Service'));
const Contact = React.lazy(() => import('./components/LandingPages/Contact'));
const Home = React.lazy(() => import('./components/LandingPages/Home'));
const PrivacyandPolicy = React.lazy(() => import('./components/LandingPages/PrivacyandPolicy'));
const TermsandCondition = React.lazy(() => import('./components/LandingPages/TermsandCondition'));
const Blog = React.lazy(() => import('./components/LandingPages/Blog'));
const Porfolio = React.lazy(() => import('./components/LandingPages/Porfolio'));
// const ServiceDetails = React.lazy(() => import('./components/ServiceDetailspages/ServiceDetails'));
const Footer = React.lazy(() => import('./components/LandingPages/Footer'));
const Finance = React.lazy(() => import('./components/BlogPages/Finance'));
const Education = React.lazy(() => import('./components/BlogPages/Education'));
const FoodAndBeverage = React.lazy(() => import('./components/BlogPages/FoodAndBeverage'));
const Franchises = React.lazy(() => import('./components/BlogPages/Franchises'));
const HeavyEquipment = React.lazy(() => import('./components/BlogPages/HeavyEquipment'));
const AutoManufacturing = React.lazy(() => import('./components/BlogPages/BlogSubPages/AutoManufacturing'));
const AutoRepairs = React.lazy(() => import('./components/BlogPages/BlogSubPages/AutoRepairs'));
const Autoservice = React.lazy(() => import('./components/BlogPages/BlogSubPages/Autoservice'));
const Motorcycle = React.lazy(() => import('./components/BlogPages/BlogSubPages/Motorcycle'));
const TruckandTrailer = React.lazy(() => import('./components/BlogPages/BlogSubPages/TruckandTrailer'));
const CarDealer = React.lazy(() => import('./components/BlogPages/BlogSubPages/CarDealer'));
const Collages = React.lazy(() => import('./components/BlogPages/BlogSubPages/Collages'));
const HigherEducation = React.lazy(() => import('./components/BlogPages/BlogSubPages/HigherEducation'));
const DayCares = React.lazy(() => import('./components/BlogPages/BlogSubPages/DayCares'));
const TechnicalSchools = React.lazy(() => import('./components/BlogPages/BlogSubPages/TechnicalSchools'));
const OnlineEducation = React.lazy(() => import('./components/BlogPages/BlogSubPages/OnlineEducation'));
const Accounting = React.lazy(() => import('./components/BlogPages/BlogSubPages/Accounting'));
const Banks = React.lazy(() => import('./components/BlogPages/BlogSubPages/Banks'));
const CPAFirms = React.lazy(() => import('./components/BlogPages/BlogSubPages/CPAFirms'));
const CreditServices = React.lazy(() => import('./components/BlogPages/BlogSubPages/CreditServices'));
const CreditUnions = React.lazy(() => import('./components/BlogPages/BlogSubPages/CreditUnions'));
const LoanOfficer = React.lazy(() => import('./components/BlogPages/BlogSubPages/LoanOfficer'));
const WealthManagement = React.lazy(() => import('./components/BlogPages/BlogSubPages/WealthManagement'));
const FinancialAdvisors = React.lazy(() => import('./components/BlogPages/BlogSubPages/FinancialAdvisors'));
const Bakeries = React.lazy(() => import('./components/BlogPages/BlogSubPages/Bakeries'));
const Breweries = React.lazy(() => import('./components/BlogPages/BlogSubPages/Breweries'));
const Cafes = React.lazy(() => import('./components/BlogPages/BlogSubPages/Cafes'));
const FoodProducts = React.lazy(() => import('./components/BlogPages/BlogSubPages/FoodProducts'));
const Speciality = React.lazy(() => import('./components/BlogPages/BlogSubPages/Speciality'));
const Bars = React.lazy(() => import('./components/BlogPages/BlogSubPages/Bars'));
const Resturents = React.lazy(() => import('./components/BlogPages/BlogSubPages/Resturents'));
const OnlineFood = React.lazy(() => import('./components/BlogPages/BlogSubPages/OnlineFood'));
const SearchEngine = React.lazy(() => import('./components/BlogPages/BlogSubPages/SearchEngine'));
const WebDesign = React.lazy(() => import('./components/BlogPages/BlogSubPages/WebDesign'));
const SocialMedia = React.lazy(() => import('./components/BlogPages/BlogSubPages/SocialMedia'));
const Resturentfrenchise = React.lazy(() => import('./components/BlogPages/BlogSubPages/Resturentfrenchise'));
const RetailFranchise = React.lazy(() => import('./components/BlogPages/BlogSubPages/RetailFranchise'));
const AutoMotiveFranchise = React.lazy(() => import('./components/BlogPages/BlogSubPages/AutoMotiveFranchise'));
const HealthWellness = React.lazy(() => import('./components/BlogPages/BlogSubPages/HealthWellness'));
const EducationFranchise = React.lazy(() => import('./components/BlogPages/BlogSubPages/EducationFranchise'));
const Cleaning = React.lazy(() => import('./components/BlogPages/BlogSubPages/Cleaning'));
const HomeFranchise = React.lazy(() => import('./components/BlogPages/BlogSubPages/HomeFranchise'));
const TrevelFranchise = React.lazy(() => import('./components/BlogPages/BlogSubPages/TrevelFranchise'));
const SeniorFranchise = React.lazy(() => import('./components/BlogPages/BlogSubPages/SeniorFranchise'));
const Forlift = React.lazy(() => import('./components/BlogPages/BlogSubPages/Forlift'));
const Excavation = React.lazy(() => import('./components/BlogPages/BlogSubPages/Excavation'));
const Paving = React.lazy(() => import('./components/BlogPages/BlogSubPages/Paving'));
const Equipment = React.lazy(() => import('./components/BlogPages/BlogSubPages/Equipment'));
const LogisticsAndTransportation = React.lazy(() => import('./components/BlogPages/LogisticsAndTransportation'));
const Industrial = React.lazy(() => import('./components/BlogPages/Industrial'));
const Insurance = React.lazy(() => import('./components/BlogPages/Insurance'));
const HomeandRepire = React.lazy(() => import('./components/BlogPages/HomeandRepire'));
const Legal = React.lazy(() => import('./components/BlogPages/Legal'));
const LocalConsumer = React.lazy(() => import('./components/BlogPages/LocalConsumer'));
const Fitness = React.lazy(() => import('./components/BlogPages/Fitness'));
const MedicalandHealth = React.lazy(() => import('./components/BlogPages/MedicalandHealth'));
const ProfessionalServices = React.lazy(() => import('./components/BlogPages/ProfessionalServices'));
const RealEstate = React.lazy(() => import('./components/BlogPages/RealEstate'));
const Recreation = React.lazy(() => import('./components/BlogPages/Recreation'));
const Retail = React.lazy(() => import('./components/BlogPages/Retail'));
const Tech = React.lazy(() => import('./components/BlogPages/Tech'));
const TourismAndHospitality = React.lazy(() => import('./components/BlogPages/TourismAndHospitality'));
const Manufacturing = React.lazy(() => import('./components/BlogPages/Manufacturing'));
const Fashion = React.lazy(() => import('./components/BlogPages/Fashion'));
const CarandTexiServices = React.lazy(() => import('./components/BlogPages/BlogSubPages/CarandTexiServices'));
const Freight = React.lazy(() => import('./components/BlogPages/BlogSubPages/Freight'));
const Trucking = React.lazy(() => import('./components/BlogPages/BlogSubPages/Trucking'));
const AirCargo = React.lazy(() => import('./components/BlogPages/BlogSubPages/AirCargo'));
const Shipping = React.lazy(() => import('./components/BlogPages/BlogSubPages/Shipping'));
const Courier = React.lazy(() => import('./components/BlogPages/BlogSubPages/Courier'));
const WareHousing = React.lazy(() => import('./components/BlogPages/BlogSubPages/WareHousing'));
const Solar = React.lazy(() => import('./components/BlogPages/BlogSubPages/Solar'));
const Renewable = React.lazy(() => import('./components/BlogPages/BlogSubPages/Renewable'));
const Refrigeration = React.lazy(() => import('./components/BlogPages/BlogSubPages/Refrigeration'));
const Limber = React.lazy(() => import('./components/BlogPages/BlogSubPages/Limber'));
const Mining = React.lazy(() => import('./components/BlogPages/BlogSubPages/Mining'));
const OilandGas = React.lazy(() => import('./components/BlogPages/BlogSubPages/OilandGas'));
const Planting = React.lazy(() => import('./components/BlogPages/BlogSubPages/Planting'));
const WellDriling = React.lazy(() => import('./components/BlogPages/BlogSubPages/WellDriling'));
const Cabinet = React.lazy(() => import('./components/BlogPages/BlogSubPages/Cabinet'));
const IndusrtialEquipment = React.lazy(() => import('./components/BlogPages/BlogSubPages/IndusrtialEquipment'));
const Machinery = React.lazy(() => import('./components/BlogPages/BlogSubPages/Machinery'));
const IndustrialAutomation = React.lazy(() => import('./components/BlogPages/BlogSubPages/IndustrialAutomation'));
const IndustrialSupplies = React.lazy(() => import('./components/BlogPages/BlogSubPages/IndustrialSupplies'));
const MaintanceRepair = React.lazy(() => import('./components/BlogPages/BlogSubPages/MaintanceRepair'));
const SafetyProduct = React.lazy(() => import('./components/BlogPages/BlogSubPages/SafetyProduct'));
const WasteProduct = React.lazy(() => import('./components/BlogPages/BlogSubPages/WasteProduct'));
const EngineeringServices = React.lazy(() => import('./components/BlogPages/BlogSubPages/EngineeringServices'));
const CleaningServices = React.lazy(() => import('./components/BlogPages/BlogSubPages/CleaningServices'));
const TraningEducation = React.lazy(() => import('./components/BlogPages/BlogSubPages/TraningEducation'));
const HomeImprovement = React.lazy(() => import('./components/BlogPages/BlogSubPages/HomeImprovement'));
const HomeEnergy = React.lazy(() => import('./components/BlogPages/BlogSubPages/HomeEnergy'));
const HomeBuilder = React.lazy(() => import('./components/BlogPages/BlogSubPages/HomeBuilder'));
const HomeSecurity = React.lazy(() => import('./components/BlogPages/BlogSubPages/HomeSecurity'));
const ChimneyAndMasonry = React.lazy(() => import('./components/BlogPages/BlogSubPages/ChimneyAndMasonry'));
const BathroomRemodel = React.lazy(() => import('./components/BlogPages/BlogSubPages/BathroomRemodel'));
const TreeCare = React.lazy(() => import('./components/BlogPages/BlogSubPages/TreeCare'));
const LandScaping = React.lazy(() => import('./components/BlogPages/BlogSubPages/LandScaping'));
const MovingCompanies = React.lazy(() => import('./components/BlogPages/BlogSubPages/MovingCompanies'));
const PestControl = React.lazy(() => import('./components/BlogPages/BlogSubPages/PestControl'));
const DeckBuilder = React.lazy(() => import('./components/BlogPages/BlogSubPages/DeckBuilder'));
const Electrician = React.lazy(() => import('./components/BlogPages/BlogSubPages/Electrician'));
const Flooring = React.lazy(() => import('./components/BlogPages/BlogSubPages/Flooring'));
const GarageDoors = React.lazy(() => import('./components/BlogPages/BlogSubPages/GarageDoors'));
const Plumber = React.lazy(() => import('./components/BlogPages/BlogSubPages/Plumber'));
const Roofing = React.lazy(() => import('./components/BlogPages/BlogSubPages/Roofing'));
const Restoration = React.lazy(() => import('./components/BlogPages/BlogSubPages/Restoration'));
const HVAC = React.lazy(() => import('./components/BlogPages/BlogSubPages/HVAC'));
const CellPhoneRepair = React.lazy(() => import('./components/BlogPages/BlogSubPages/CellPhoneRepair'));
const ComputerRetail = React.lazy(() => import('./components/BlogPages/BlogSubPages/ComputerRetail'));
const MovingServices = React.lazy(() => import('./components/BlogPages/BlogSubPages/MovingServices'));
const LockSmith = React.lazy(() => import('./components/BlogPages/BlogSubPages/LockSmith'));
const FenceInstaller = React.lazy(() => import('./components/BlogPages/BlogSubPages/FenceInstaller'));
const GroceryDeliveryServices = React.lazy(() => import('./components/BlogPages/BlogSubPages/GroceryDeliveryServices'));
const WindowAndDoor = React.lazy(() => import('./components/BlogPages/BlogSubPages/WindowAndDoor'));
const InteriorDesignServices = React.lazy(() => import('./components/BlogPages/BlogSubPages/InteriorDesignServices'));
const HandyManServices = React.lazy(() => import('./components/BlogPages/BlogSubPages/HandyManServices'));
const Financial = React.lazy(() => import('./components/BlogPages/BlogSubPages/Financial'));
const Health = React.lazy(() => import('./components/BlogPages/BlogSubPages/Health'));
const Business = React.lazy(() => import('./components/BlogPages/BlogSubPages/Business'));
const AutoInsurance = React.lazy(() => import('./components/BlogPages/BlogSubPages/AutoInsurance'));
const TravelInsurance = React.lazy(() => import('./components/BlogPages/BlogSubPages/TravelInsurance'));
const HomeInsurance = React.lazy(() => import('./components/BlogPages/BlogSubPages/HomeInsurance'));
const DisabilityInsurance = React.lazy(() => import('./components/BlogPages/BlogSubPages/DisabilityInsurance'));
const PersonalInjuryLawyers = React.lazy(() => import('./components/BlogPages/BlogSubPages/PersonalInjuryLawyers'));
const Criminal = React.lazy(() => import('./components/BlogPages/BlogSubPages/Criminal'));
const FamiliyLaw = React.lazy(() => import('./components/BlogPages/BlogSubPages/FamiliyLaw'));
const Bankruptcy = React.lazy(() => import('./components/BlogPages/BlogSubPages/Bankruptcy'));
const Immigration = React.lazy(() => import('./components/BlogPages/BlogSubPages/Immigration'));
const EstatePlanning = React.lazy(() => import('./components/BlogPages/BlogSubPages/EstatePlanning'));
const Assisted = React.lazy(() => import('./components/BlogPages/BlogSubPages/Assisted'));
const Auctioneers = React.lazy(() => import('./components/BlogPages/BlogSubPages/Auctioneers'));
const Catering = React.lazy(() => import('./components/BlogPages/BlogSubPages/Catering'));
const EventPlanner = React.lazy(() => import('./components/BlogPages/BlogSubPages/EventPlanner'));
const Florist = React.lazy(() => import('./components/BlogPages/BlogSubPages/Florist'));
const Funeral = React.lazy(() => import('./components/BlogPages/BlogSubPages/Funeral'));
const Vaterinarians = React.lazy(() => import('./components/BlogPages/BlogSubPages/Vaterinarians'));
const Janitors = React.lazy(() => import('./components/BlogPages/BlogSubPages/Janitors'));
const PrivateInvestigator = React.lazy(() => import('./components/BlogPages/BlogSubPages/PrivateInvestigator'));
const StorageFacilities = React.lazy(() => import('./components/BlogPages/BlogSubPages/StorageFacilities'));
const AlternativeMedicine = React.lazy(() => import('./components/BlogPages/BlogSubPages/AlternativeMedicine'));
const Audioligy = React.lazy(() => import('./components/BlogPages/BlogSubPages/Audioligy'));
const BioTechnology = React.lazy(() => import('./components/BlogPages/BlogSubPages/BioTechnology'));
const Cardiology = React.lazy(() => import('./components/BlogPages/BlogSubPages/Cardiology'));
const Fertility = React.lazy(() => import('./components/BlogPages/BlogSubPages/Fertility'));
const Dentists = React.lazy(() => import('./components/BlogPages/BlogSubPages/Dentists'));
const Dermatology = React.lazy(() => import('./components/BlogPages/BlogSubPages/Dermatology'));
const DoctorsOffices = React.lazy(() => import('./components/BlogPages/BlogSubPages/DoctorsOffices'));
const EarNoseThroat = React.lazy(() => import('./components/BlogPages/BlogSubPages/EarNoseThroat'));
const EHRCompanies = React.lazy(() => import('./components/BlogPages/BlogSubPages/EHRCompanies'));
const MedicalImagining = React.lazy(() => import('./components/BlogPages/BlogSubPages/MedicalImagining'));
const MentalAndBehavioral = React.lazy(() => import('./components/BlogPages/BlogSubPages/MentalAndBehavioral'));
const NutritionistAndDieticians = React.lazy(() => import('./components/BlogPages/BlogSubPages/NutritionistAndDieticians'));
const OB = React.lazy(() => import('./components/BlogPages/BlogSubPages/OB'));
const OccupationalTharepy = React.lazy(() => import('./components/BlogPages/BlogSubPages/OccupationalTharepy'));
const OnCologist = React.lazy(() => import('./components/BlogPages/BlogSubPages/OnCologist'));
const Optometrists = React.lazy(() => import('./components/BlogPages/BlogSubPages/Optometrists'));
const Orthodontists = React.lazy(() => import('./components/BlogPages/BlogSubPages/Orthodontists'));
const Othopedic = React.lazy(() => import('./components/BlogPages/BlogSubPages/Othopedic'));
const OutPatient = React.lazy(() => import('./components/BlogPages/BlogSubPages/OutPatient'));
const PersonalProtective = React.lazy(() => import('./components/BlogPages/BlogSubPages/PersonalProtective'));
const Pharmaceutical = React.lazy(() => import('./components/BlogPages/BlogSubPages/Pharmaceutical'));
const PhysicalTharepy = React.lazy(() => import('./components/BlogPages/BlogSubPages/PhysicalTharepy'));
const Physicians = React.lazy(() => import('./components/BlogPages/BlogSubPages/Physicians'));
const PlasticSergery = React.lazy(() => import('./components/BlogPages/BlogSubPages/PlasticSergery'));
const Padiatry = React.lazy(() => import('./components/BlogPages/BlogSubPages/Padiatry'));
const Psychiatrists = React.lazy(() => import('./components/BlogPages/BlogSubPages/Psychiatrists'));
const SpeechTharepists = React.lazy(() => import('./components/BlogPages/BlogSubPages/SpeechTharepists'));
const Urologists = React.lazy(() => import('./components/BlogPages/BlogSubPages/Urologists'));
const Counselor = React.lazy(() => import('./components/BlogPages/BlogSubPages/Counselor'));
const NursingHomes = React.lazy(() => import('./components/BlogPages/BlogSubPages/NursingHomes'));
const DoctorsOnDemand = React.lazy(() => import('./components/BlogPages/BlogSubPages/DoctorsOnDemand'));
const Dance = React.lazy(() => import('./components/BlogPages/BlogSubPages/Dance'));
const ExcesiseEquipmentCompanies = React.lazy(() => import('./components/BlogPages/BlogSubPages/ExcesiseEquipmentCompanies'));
const PersonalTrainer = React.lazy(() => import('./components/BlogPages/BlogSubPages/PersonalTrainer'));
const YogaStudios = React.lazy(() => import('./components/BlogPages/BlogSubPages/YogaStudios'));
const MedicalSPA = React.lazy(() => import('./components/BlogPages/BlogSubPages/MedicalSPA'));
const FitnessCenter = React.lazy(() => import('./components/BlogPages/BlogSubPages/FitnessCenter'));
const MartialArtSchools = React.lazy(() => import('./components/BlogPages/BlogSubPages/MartialArtSchools'));
const BalletsSchools = React.lazy(() => import('./components/BlogPages/BlogSubPages/BalletsSchools'));
const GymnasticsSchool = React.lazy(() => import('./components/BlogPages/BlogSubPages/GymnasticsSchool'));
const SwimmingSchools = React.lazy(() => import('./components/BlogPages/BlogSubPages/SwimmingSchools'));
const Nutritinists = React.lazy(() => import('./components/BlogPages/BlogSubPages/Nutritinists'));
const PilatesStudios = React.lazy(() => import('./components/BlogPages/BlogSubPages/PilatesStudios'));
const Acupuncturists = React.lazy(() => import('./components/BlogPages/BlogSubPages/Acupuncturists'));
const Architecture = React.lazy(() => import('./components/BlogPages/BlogSubPages/Architecture'));
const Consultants = React.lazy(() => import('./components/BlogPages/BlogSubPages/Consultants'));
const Engineering = React.lazy(() => import('./components/BlogPages/BlogSubPages/Engineering'));
const MergersAndAcquisitions = React.lazy(() => import('./components/BlogPages/BlogSubPages/MergersAndAcquisitions'));
const Patroll = React.lazy(() => import('./components/BlogPages/BlogSubPages/Patroll'));
const Publishing = React.lazy(() => import('./components/BlogPages/BlogSubPages/Publishing'));
const Recuiters = React.lazy(() => import('./components/BlogPages/BlogSubPages/Recuiters'));
const Translation = React.lazy(() => import('./components/BlogPages/BlogSubPages/Translation'));
const Appartment = React.lazy(() => import('./components/BlogPages/BlogSubPages/Appartment'));
const PropertyManegement = React.lazy(() => import('./components/BlogPages/BlogSubPages/PropertyManegement'));
const Realtors = React.lazy(() => import('./components/BlogPages/BlogSubPages/Realtors'));
const HomeStaginingServices = React.lazy(() => import('./components/BlogPages/BlogSubPages/HomeStaginingServices'));
const RealEstateAppreaisers = React.lazy(() => import('./components/BlogPages/BlogSubPages/RealEstateAppreaisers'));
const RealEstatePhotograph = React.lazy(() => import('./components/BlogPages/BlogSubPages/RealEstatePhotograph'));
const ConstructionCompany = React.lazy(() => import('./components/BlogPages/BlogSubPages/ConstructionCompany'));
const RealEstateInvestement = React.lazy(() => import('./components/BlogPages/BlogSubPages/RealEstateInvestement'));
const CommercialRealEstate = React.lazy(() => import('./components/BlogPages/BlogSubPages/CommercialRealEstate'));
const Boat = React.lazy(() => import('./components/BlogPages/BlogSubPages/Boat'));
const Campgrounds = React.lazy(() => import('./components/BlogPages/BlogSubPages/Campgrounds'));
const GolfCourse = React.lazy(() => import('./components/BlogPages/BlogSubPages/GolfCourse'));
const OutDoors = React.lazy(() => import('./components/BlogPages/BlogSubPages/OutDoors'));
const Radio = React.lazy(() => import('./components/BlogPages/BlogSubPages/Radio'));
const Recration = React.lazy(() => import('./components/BlogPages/BlogSubPages/Recration'));
const RVDealer = React.lazy(() => import('./components/BlogPages/BlogSubPages/RVDealer'));
const SwimmingPoolCompanies = React.lazy(() => import('./components/BlogPages/BlogSubPages/SwimmingPoolCompanies'));
const Departement = React.lazy(() => import('./components/BlogPages/BlogSubPages/Departement'));
const ToysAndGames = React.lazy(() => import('./components/BlogPages/BlogSubPages/ToysAndGames'));
const Ecommerce = React.lazy(() => import('./components/BlogPages/BlogSubPages/Ecommerce'));
const HomeDecore = React.lazy(() => import('./components/BlogPages/BlogSubPages/HomeDecore'));
const Jewelry = React.lazy(() => import('./components/BlogPages/BlogSubPages/Jewelry'));
const Luxury = React.lazy(() => import('./components/BlogPages/BlogSubPages/Luxury'));
const PetSupplies = React.lazy(() => import('./components/BlogPages/BlogSubPages/PetSupplies'));
const OnlineMarketPlace = React.lazy(() => import('./components/BlogPages/BlogSubPages/OnlineMarketPlace'));
const ClothingAndApparel = React.lazy(() => import('./components/BlogPages/BlogSubPages/ClothingAndApparel'));
const BeautyAndSkinCare = React.lazy(() => import('./components/BlogPages/BlogSubPages/BeautyAndSkinCare'));
const HeathAndWellness = React.lazy(() => import('./components/BlogPages/BlogSubPages/HeathAndWellness'));
const ElectronicStore = React.lazy(() => import('./components/BlogPages/BlogSubPages/ElectronicStore'));
const SportsAndOutdoor = React.lazy(() => import('./components/BlogPages/BlogSubPages/SportsAndOutdoor'));
const Cloud = React.lazy(() => import('./components/BlogPages/BlogSubPages/Cloud'));
const Software = React.lazy(() => import('./components/BlogPages/BlogSubPages/Software'));
const TechCompanies = React.lazy(() => import('./components/BlogPages/BlogSubPages/TechCompanies'));
const TeleCommunication = React.lazy(() => import('./components/BlogPages/BlogSubPages/TeleCommunication'));
const Robotics = React.lazy(() => import('./components/BlogPages/BlogSubPages/Robotics'));
const ComputerRepair = React.lazy(() => import('./components/BlogPages/BlogSubPages/ComputerRepair'));
const CyberSecurity = React.lazy(() => import('./components/BlogPages/BlogSubPages/CyberSecurity'));
const ArtificialIntelligence = React.lazy(() => import('./components/BlogPages/BlogSubPages/ArtificialIntelligence'));
const DataAnalytics = React.lazy(() => import('./components/BlogPages/BlogSubPages/DataAnalytics'));
const VideoGameCompanies = React.lazy(() => import('./components/BlogPages/BlogSubPages/VideoGameCompanies'));
const MobileAppDeveloper = React.lazy(() => import('./components/BlogPages/BlogSubPages/MobileAppDeveloper'));
const DigitalMarketing = React.lazy(() => import('./components/BlogPages/BlogSubPages/DigitalMarketing'));
const CloudStorage = React.lazy(() => import('./components/BlogPages/BlogSubPages/CloudStorage'));
const ItConsultingFirms = React.lazy(() => import('./components/BlogPages/BlogSubPages/ItConsultingFirms'));
const HotelAndResort = React.lazy(() => import('./components/BlogPages/BlogSubPages/HotelAndResort'));
const VacationRentals = React.lazy(() => import('./components/BlogPages/BlogSubPages/VacationRentals'));
const Musemums = React.lazy(() => import('./components/BlogPages/BlogSubPages/Musemums'));
const TourOperator = React.lazy(() => import('./components/BlogPages/BlogSubPages/TourOperator'));
const TravelAgencies = React.lazy(() => import('./components/BlogPages/BlogSubPages/TravelAgencies'));
const ResturentAgencies = React.lazy(() => import('./components/BlogPages/BlogSubPages/ResturentAgencies'));
const AmusementPark = React.lazy(() => import('./components/BlogPages/BlogSubPages/AmusementPark'));
const MusemsAndArtGallerie = React.lazy(() => import('./components/BlogPages/BlogSubPages/MusemsAndArtGallerie'));
const Adventure = React.lazy(() => import('./components/BlogPages/BlogSubPages/Adventure'));
const Casinos = React.lazy(() => import('./components/BlogPages/BlogSubPages/Casinos'));
const CountryClubs = React.lazy(() => import('./components/BlogPages/BlogSubPages/CountryClubs'));
const LimoCompanies = React.lazy(() => import('./components/BlogPages/BlogSubPages/LimoCompanies'));
const AirCraft = React.lazy(() => import('./components/BlogPages/BlogSubPages/AirCraft'));
const CounterTop = React.lazy(() => import('./components/BlogPages/BlogSubPages/CounterTop'));
const FoodAndBeverages = React.lazy(() => import('./components/BlogPages/BlogSubPages/FoodAndBeverages'));
const SolarPanel = React.lazy(() => import('./components/BlogPages/BlogSubPages/SolarPanel'));
const PharmaceuticalManufacturing = React.lazy(() => import('./components/BlogPages/BlogSubPages/PharmaceuticalManufacturing'));
const Vintage = React.lazy(() => import('./components/BlogPages/BlogSubPages/Vintage'));
const SecondHand = React.lazy(() => import('./components/BlogPages/BlogSubPages/SecondHand'));
const Consignment = React.lazy(() => import('./components/BlogPages/BlogSubPages/Consignment'));
const Alteration = React.lazy(() => import('./components/BlogPages/BlogSubPages/Alteration'));
const Clothing = React.lazy(() => import('./components/BlogPages/BlogSubPages/Clothing'));
const SheoStore = React.lazy(() => import('./components/BlogPages/BlogSubPages/SheoStore'));
const BeautyStores = React.lazy(() => import('./components/BlogPages/BlogSubPages/BeautyStores'));
const JewelryStores = React.lazy(() => import('./components/BlogPages/BlogSubPages/JewelryStores'));
const OrganicFoodStores = React.lazy(() => import('./components/BlogPages/BlogSubPages/OrganicFoodStores'));
const SmallBussinesSEO = React.lazy(() => import('./components/ServiceDetailspages/SmallBussinesSEO'));
const LocalSEO = React.lazy(() => import('./components/ServiceDetailspages/LocalSEO'));
const NationwideSEO = React.lazy(() => import('./components/ServiceDetailspages/NationwideSEO'));
const InternationSEO = React.lazy(() => import('./components/ServiceDetailspages/InternationSEO'));
const YouTubeSEO = React.lazy(() => import('./components/ServiceDetailspages/YouTubeSEO'));
const MobileSEO = React.lazy(() => import('./components/ServiceDetailspages/MobileSEO'));
const ConsultancySEO = React.lazy(() => import('./components/ServiceDetailspages/ConsultancySEO'));
const ShopifySEO = React.lazy(() => import('./components/ServiceDetailspages/ShopifySEO'));
const WordpressSEO = React.lazy(() => import('./components/ServiceDetailspages/WordpressSEO'));
const OnPageSEO = React.lazy(() => import('./components/ServiceDetailspages/OnPageSEO'));
const TechnicalSEO = React.lazy(() => import('./components/ServiceDetailspages/TechnicalSEO'));
const KeywordSEO = React.lazy(() => import('./components/ServiceDetailspages/KeywordSEO'));
const Backlink = React.lazy(() => import('./components/ServiceDetailspages/Backlink'));
const LinkBuilding = React.lazy(() => import('./components/ServiceDetailspages/LinkBuilding'));
const AuditSEO = React.lazy(() => import('./components/ServiceDetailspages/AuditSEO'));
const GoogleAnalytics = React.lazy(() => import('./components/ServiceDetailspages/GoogleAnalytics'));
const GA4Analytics = React.lazy(() => import('./components/ServiceDetailspages/GA4Analytics'));
const FranchiseSEO = React.lazy(() => import('./components/ServiceDetailspages/FranchiseSEO'));
const GoogleAdMarketing = React.lazy(() => import('./components/ServiceDetailspages/GoogleAdMarketing'));
const PPCMAnagementService = React.lazy(() => import('./components/ServiceDetailspages/PPCMAnagementService'));
const FacebookAdvertising = React.lazy(() => import('./components/ServiceDetailspages/FacebookAdvertising'));
const InstagramAdvertising = React.lazy(() => import('./components/ServiceDetailspages/InstagramAdvertising'));
const YouTubeAdvertising = React.lazy(() => import('./components/ServiceDetailspages/YouTubeAdvertising'));
const TwitterAdvertising = React.lazy(() => import('./components/ServiceDetailspages/TwitterAdvertising'));
const LinkedInMarketing = React.lazy(() => import('./components/ServiceDetailspages/LinkedInMarketing'));
const PinterestMarketing = React.lazy(() => import('./components/ServiceDetailspages/PinterestMarketing'));
const QuaraMarketing = React.lazy(() => import('./components/ServiceDetailspages/QuaraMarketing'));
const WhatsappMarketing = React.lazy(() => import('./components/ServiceDetailspages/WhatsappMarketing'));
const SEOContentWritting = React.lazy(() => import('./components/ServiceDetailspages/SEOContentWritting'));
const ProductDescription = React.lazy(() => import('./components/ServiceDetailspages/ProductDescription'));
const GuestBlogging = React.lazy(() => import('./components/ServiceDetailspages/GuestBlogging'));
const WebCopyingWritting = React.lazy(() => import('./components/ServiceDetailspages/WebCopyingWritting'));
const BlogWritting = React.lazy(() => import('./components/ServiceDetailspages/BlogWritting'));
const B2BWebDesign = React.lazy(() => import('./components/ServiceDetailspages/B2BWebDesign'));
const B2CWebDesign = React.lazy(() => import('./components/ServiceDetailspages/B2CWebDesign'));
const EcommerWebDesign = React.lazy(() => import('./components/ServiceDetailspages/EcommerWebDesign'));
const WordpressWebDesign = React.lazy(() => import('./components/ServiceDetailspages/WordpressWebDesign'));
const ShopifyWebDesign = React.lazy(() => import('./components/ServiceDetailspages/ShopifyWebDesign'));
const ResponsiveWebDesign = React.lazy(() => import('./components/ServiceDetailspages/ResponsiveWebDesign'));
const LandingPagesDesign = React.lazy(() => import('./components/ServiceDetailspages/LandingPagesDesign'));
const CustomLogoDesign = React.lazy(() => import('./components/ServiceDetailspages/CustomLogoDesign'));
const WebsiteMaintance = React.lazy(() => import('./components/ServiceDetailspages/WebsiteMaintance'));
const WebsiteHosting = React.lazy(() => import('./components/ServiceDetailspages/WebsiteHosting'));
const WebsiteoptimizationService = React.lazy(() => import('./components/ServiceDetailspages/WebsiteoptimizationService'));
const WebsiteRedesign = React.lazy(() => import('./components/ServiceDetailspages/WebsiteRedesign'));
const CelebritySEO = React.lazy(() => import('./components/ServiceDetailspages/CelebritySEO'));
const LuxarySEO = React.lazy(() => import('./components/ServiceDetailspages/LuxarySEO'));
const CreativeDesign = React.lazy(() => import('./components/ServiceDetailspages/CreativeDesign'));
const SEOService = React.lazy(() => import('./components/Servicepages/SEOService'));
//const Navbar = React.lazy(() => import('./components/LandingPages/Navbar'));
const PPCService = React.lazy(() => import('./components/Servicepages/PPCService'));
const GovernmentAgencies = React.lazy(() => import('./components/BlogPages/GovernmentAgencies'));
const TourismAgency = React.lazy(() => import('./components/BlogPages/BlogSubPages/TourismAgency'));
const CulturalAgency = React.lazy(() => import('./components/BlogPages/BlogSubPages/CulturalAgency'));
const EconomicDevelopment = React.lazy(() => import('./components/BlogPages/BlogSubPages/EconomicDevelopment'));
const PublicHealth = React.lazy(() => import('./components/BlogPages/BlogSubPages/PublicHealth'));
const EnvironmentAgency = React.lazy(() => import('./components/BlogPages/BlogSubPages/EnvironmentAgency'));
const GovernmentEducation = React.lazy(() => import('./components/BlogPages/BlogSubPages/GovernmentEducation'));
const PublicSafety = React.lazy(() => import('./components/BlogPages/BlogSubPages/PublicSafety'));
const SocialService = React.lazy(() => import('./components/BlogPages/BlogSubPages/SocialService'));
const EmploymentService = React.lazy(() => import('./components/BlogPages/BlogSubPages/EmploymentService'));
const Automotive = React.lazy(() => import('./components/BlogPages/Automotive'));
const AutomotiveDealership = React.lazy(() => import('./components/BlogPages/BlogSubPages/AutomotiveDealership'));
const CookingSchools = React.lazy(() => import('./components/BlogPages/BlogSubPages/CookingSchools'));
const Chiropractors = React.lazy(() => import('./components/BlogPages/BlogSubPages/Chiropractors'));
const ArtsSchools = React.lazy(() => import('./components/BlogPages/BlogSubPages/ArtsSchools'));
const MiningEquipment = React.lazy(() => import('./components/BlogPages/BlogSubPages/MiningEquipment'));
const AgricuturalEquipment = React.lazy(() => import('./components/BlogPages/BlogSubPages/AgricuturalEquipment'));
const IndustrialMachinery = React.lazy(() => import('./components/BlogPages/BlogSubPages/IndustrialMachinery'));
const MaterialHandingEquipment = React.lazy(() => import('./components/BlogPages/BlogSubPages/MaterialHandingEquipment'));
const PowerGenerationEquipment = React.lazy(() => import('./components/BlogPages/BlogSubPages/PowerGenerationEquipment'));
const HeavyTruckAndTrailerSales = React.lazy(() => import('./components/BlogPages/BlogSubPages/HeavyTruckAndTrailerSales'));
const TikTokAdvertising = React.lazy(() => import('./components/ServiceDetailspages/TikTokAdvertising'));
const ContentMarketingServices = React.lazy(() => import('./components/Servicepages/ContentMarketingServices'));
const EcommerceSEO = React.lazy(() => import('./components/ServiceDetailspages/EcommerceSEO'));
const Navbar1 = React.lazy(() => import('./components/LandingPages/Navbar1'));
const BackToTop = React.lazy(() => import('./components/LandingPages/BackToTop'));
const BrandingAgency = React.lazy(() => import('./components/HomepageBlog/BrandingAgency'));
const AdvertisingAgency = React.lazy(() => import('./components/HomepageBlog/AdvertisingAgency'));
const HomeTwo = React.lazy(() => import('./components/LandingPages/HomeTwo'))

function App() {
  const [popup, setPopup] = useState(false);
  const history = createBrowserHistory();
  // const navigate = useNavigate();

  // const ThankYou = () => {
  //   setPopup(false);
  //   navigate('/thank-you')
  // }

  useEffect(() => {
    window.addEventListener('load', () => {
      setTimeout(() => {
        setPopup(true);
        // document.body.style.overflow = 'hidden';
      }, "15000");
    })
  }, [])

  const cancelbtn = () => {
    setPopup(false);
    document.body.style.overflowY = 'initial';
  }
  return (
    <>
      <div className='App' >
        <BrowserRouter >
          <Suspense fallback={<h2><Loader /></h2>}>
            <Navbar1 />
            {/* {popup && <PopUps CancelBtn={() => { cancelbtn() }}  />} */}
            <Routes>
              {/* Landing Pages */}
              <Route path='/' element={<Home />} />
              <Route path='/home' element={<HomeTwo />} />
              <Route path='/about-us' element={<About />} />
              {/* <Route path='/servicedetails' element={<ServiceDetails />} />  */}
              <Route path='/portfolio' element={<Porfolio />} />
              <Route path='/contact-us' element={<Contact />} />
              <Route path='/privacy-policy' element={<PrivacyandPolicy />} />
              <Route path='/terms-condition' element={<TermsandCondition />} />
              {/* Blog page */}
              <Route path='/industries' element={<Blog />} />
              {/* services */}
              <Route path='/services' element={<ServiceMainPage />} />
              {/* Industry Section  */}
              <Route path='/industries/fashion' element={<Fashion />} />
              <Route path='/industries/automotive' element={<Automotive />} />
              <Route path='/industries/education' element={<Education />} />
              <Route path='/industries/finance' element={<Finance />} />
              <Route path='/industries/food-beverage' element={<FoodAndBeverage />} />
              <Route path='/industries/franchises' element={<Franchises />} />
              <Route path='/industries/heavy-equipment' element={<HeavyEquipment />} />
              <Route path='/industries/logistics' element={<LogisticsAndTransportation />} />
              <Route path='/industries/industrial' element={<Industrial />} />
              <Route path='/industries/home-repair' element={<HomeandRepire />} />
              <Route path='/industries/legal' element={<Legal />} />
              <Route path='/industries/local-consumer' element={<LocalConsumer />} />
              <Route path='/industries/medical-health' element={<MedicalandHealth />} />
              <Route path='/industries/fitness' element={<Fitness />} />
              <Route path='/industries/professional-services' element={<ProfessionalServices />} />
              <Route path='/industries/real-estate' element={<RealEstate />} />
              <Route path='/industries/recreation' element={<Recreation />} />
              <Route path='/industries/retail-ecommerce' element={<Retail />} />
              <Route path='/industries/technology' element={<Tech />} />
              <Route path='/industries/insurance' element={<Insurance />} />
              <Route path='/industries/tourism-hospitality' element={<TourismAndHospitality />} />
              <Route path='/industries/manufacturing' element={<Manufacturing />} />
              <Route path='/industries/government-agency' element={<GovernmentAgencies />} />
              {/* Fashion */}
              <Route path='/industries/fashion/vintage-stores/seo' element={<Vintage />} />
              <Route path='/industries/fashion/secondhand-stores/seo' element={<SecondHand />} />
              <Route path='/industries/fashion/consignment-shops' element={<Consignment />} />
              <Route path='/industries/fashion/alteration-services/seo' element={<Alteration />} />
              <Route path='/industries/fashion/clothing-rental/seo' element={<Clothing />} />
              <Route path='/industries/fashion/shoe-stores/seo' element={<SheoStore />} />
              <Route path='/industries/fashion/beauty-salons/seo' element={<BeautyStores />} />
              <Route path='/industries/fashion/jewellry-stores/seo' element={< Jewelry />} />
              {/* Autom Motive Section changes happen */}
              <Route path='/industries/automotive/auto-manufacturing' element={<AutoManufacturing />} />
              <Route path='/industries/automotive/auto-repair-services/seo' element={<AutoRepairs />} />
              <Route path='/industries/automotive/auto-parts-retailers/seo' element={<Autoservice />} />
              <Route path='/industries/automotive/motorcycle-dealers/seo' element={<Motorcycle />} />
              <Route path='/industries/automotive/truck-companies/seo' element={<TruckandTrailer />} />
              <Route path='/industries/automotive/automotive-dealers/seo' element={<AutomotiveDealership />} />
              <Route path='/industries/automotive/car-dealers/seo' element={<CarDealer />} />
              {/* Education Section changes happen */}
              <Route path='/industries/education/colleges-universities/seo' element={<Collages />} />
              <Route path='/industries/education/language-schools/seo' element={<HigherEducation />} />
              <Route path='/industries/education/day-care/seo' element={<DayCares />} />
              <Route path='/industries/education/tutoring/seo' element={<TechnicalSchools />} />
              <Route path='/industries/education/cooking-schools/seo' element={<CookingSchools />} />
              <Route path='/industries/education/arts-schools/seo' element={<ArtsSchools />} />
              <Route path='/industries/education/online-education/seo' element={<OnlineEducation />} />
              {/* Finance Section changes happen */}
              <Route path='/industries/finance/accountants/seo' element={<Accounting />} />
              <Route path='/industries/finance/banks' element={<Banks />} />
              <Route path='/industries/finance/cpa-firms/seo' element={<CPAFirms />} />
              <Route path='/industries/finance/credit-services/seo' element={<CreditServices />} />
              <Route path='/industries/finance/credit-unions/seo' element={<CreditUnions />} />
              <Route path='/industries/finance/loan-officer/seo' element={<LoanOfficer />} />
              <Route path='/industries/finance/wealth-management/seo' element={<WealthManagement />} />
              <Route path='/industries/finance/financial-advisors/seo' element={<FinancialAdvisors />} />
              {/* Food & Braveges changes happen */}
              <Route path='/industries/food-beverage/bakeries/seo' element={<Bakeries />} />
              <Route path='/industries/food-beverage/wineries' element={<Breweries />} />
              <Route path='/industries/food-beverage/cafes/seo' element={<Cafes />} />
              <Route path='/industries/food-beverage/food-products/seo' element={<FoodProducts />} />
              <Route path='/industries/food-beverage/speciality-food-stores/seo' element={<Speciality />} />
              <Route path='/industries/food-beverage/bars/seo' element={<Bars />} />
              <Route path='/industries/food-beverage/restaurants/seo' element={<Resturents />} />
              <Route path='/industries/food-beverage/online-food-delivery/seo' element={<OnlineFood />} />
              <Route path='/industries/food-beverage/industrial' element={<Industrial />} />
              <Route path='/industries/food-beverage/organic-food-stores/seo' element={<OrganicFoodStores />} />
              {/* Franchises */}
              <Route path='/industries/franchises/child-entertainment-and-recreation-franchise' element={<SearchEngine />} />
              <Route path='/industries/franchises/fitness-franchise' element={<WebDesign />} />
              <Route path='/industries/franchises/social-media' element={<SocialMedia />} />
              <Route path='/industries/franchises/restaurant-franchise/seo' element={<Resturentfrenchise />} />
              <Route path='/industries/franchises/retail-franchise/seo' element={<RetailFranchise />} />
              <Route path='/industries/franchises/automotive-franchise/seo' element={<AutoMotiveFranchise />} />
              <Route path='/industries/franchises/health-wellness-franchise/seo' element={<HealthWellness />} />
              <Route path='/industries/franchises/education-franchise/seo' element={<EducationFranchise />} />
              <Route path='/industries/franchises/cleaning-franchise/seo' element={<Cleaning />} />
              <Route path='/industries/franchises/home-improvement-franchise/seo' element={<HomeFranchise />} />
              <Route path='/industries/franchises/hospitality-franchise/seo' element={<TrevelFranchise />} />
              <Route path='/industries/franchises/senior-care-franchise/seo' element={<SeniorFranchise />} />
              {/* Heavy Equipment changes happen */}
              <Route path='/industries/heavy-equipment/forklift-dealers/seo' element={<Forlift />} />
              <Route path='/industries/heavy-equipment/construction-equipment-companies/seo' element={<Excavation />} />
              <Route path='/industries/heavy-equipment/paving-companies/seo' element={<Paving />} />
              <Route path='/industries/heavy-equipment/equipment-rental-companies/seo' element={<Equipment />} />
              <Route path='/industries/heavy-equipment/mining-equipment-companies/seo' element={<MiningEquipment />} />
              <Route path='/industries/heavy-equipment/agricultural-equipment-companies/seo' element={<AgricuturalEquipment />} />
              <Route path='/industries/heavy-equipment/industrial-machinery/seo' element={<IndustrialMachinery />} />
              <Route path='/industries/heavy-equipment/material-handling-equipment/seo' element={<MaterialHandingEquipment />} />
              <Route path='/industries/heavy-equipment/power-generation-equipment/seo' element={<PowerGenerationEquipment />} />
              <Route path='/industries/heavy-equipment/heavy-truck-trailer/seo' element={<HeavyTruckAndTrailerSales />} />
              {/* Logistics and Transportation */}
              <Route path='/industries/logistics/taxi-rental-companies/seo' element={<CarandTexiServices />} />
              <Route path='/industries/logistics/freight' element={<Freight />} />
              <Route path='/industries/logistics/trucking-companies/seo' element={<Trucking />} />
              <Route path='/industries/logistics/air-cargo' element={<AirCargo />} />
              <Route path='/industries/logistics/shipping' element={<Shipping />} />
              <Route path='/industries/logistics/courier-services/seo' element={<Courier />} />
              <Route path='/industries/logistics/warehousing' element={<WareHousing />} />
              <Route path='/industries/logistics/customs' element={<Customs />} />
              <Route path='/industries/logistics/logistics-consulting-firm' element={<Equipment />} />
              {/* Industrial changes happen */}
              <Route path='/industries/industrial/solar-energy' element={<Solar />} />
              <Route path='/industries/industrial/renewable-energy' element={<Renewable />} />
              <Route path='/industries/industrial/refrigeration' element={<Refrigeration />} />
              <Route path='/industries/industrial/lumber-companies/seo' element={<Limber />} />
              <Route path='/industries/industrial/mining-companies' element={<Mining />} />
              <Route path='/industries/industrial/oil-gas-companies' element={<OilandGas />} />
              <Route path='/industries/industrial/plating' element={<Planting />} />
              <Route path='/industries/industrial/well-drilling-companies' element={<WellDriling />} />
              <Route path='/industries/industrial/cabinet-manufacturing-companies/seo' element={<Cabinet />} />
              <Route path='/industries/industrial/industrial-equipment-manufacturers' element={<IndusrtialEquipment />} />
              <Route path='/industries/industrial/machinery-manufacturers' element={<Machinery />} />
              <Route path='/industries/industrial/industrial-automation-companies' element={<IndustrialAutomation />} />
              <Route path='/industries/industrial/industrial-supplies' element={<IndustrialSupplies />} />
              <Route path='/industries/industrial-companies' element={<MaintanceRepair />} />
              <Route path='/industries/industrial/safety-products-manufacturers' element={<SafetyProduct />} />
              <Route path='/industries/industrial/waste-management-companies' element={<WasteProduct />} />
              <Route path='/industries/industrial/environmental-engineering' element={<EngineeringServices />} />
              <Route path='/industries/industrial/cleaning-services' element={<CleaningServices />} />
              <Route path='/industries/industrial/sustainable-agriculture' element={<TraningEducation />} />
              {/* Home And Repair changes happen */}
              <Route path='/industries/home-repair/home-improvement' element={<HomeImprovement />} />
              <Route path='/industries/home-repair/home-energy' element={<HomeEnergy />} />
              <Route path='/industries/home-repair/home-builder/seo' element={<HomeBuilder />} />
              <Route path='/industries/home-repair/home-security' element={<HomeSecurity />} />
              <Route path='/industries/home-repair/chimney-masonry' element={<ChimneyAndMasonry />} />
              <Route path='/industries/home-repair/bathroom-remodel' element={<BathroomRemodel />} />
              <Route path='/industries/home-repair/tree-care' element={<TreeCare />} />
              <Route path='/industries/home-repair/landscaping-companies' element={<LandScaping />} />
              <Route path='/industries/home-repair/moving-companies' element={<MovingCompanies />} />
              <Route path='/industries/home-repair/pest-control' element={<PestControl />} />
              <Route path='/industries/home-repair/deck-builders' element={<DeckBuilder />} />
              <Route path='/industries/home-repair/electricians' element={<Electrician />} />
              <Route path='/industries/home-repair/flooring' element={<Flooring />} />
              <Route path='/industries/home-repair/garage-doors' element={<GarageDoors />} />
              <Route path='/industries/home-repair/plumbers' element={<Plumber />} />
              <Route path='/industries/home-repair/roofing' element={<Roofing />} />
              <Route path='/industries/home-repair/restoration-companies' element={<Restoration />} />
              <Route path='/industries/home-repair/hvac' element={<HVAC />} />
              <Route path='/industries/home-repair/garage-door-repair' element={<GarageDoorRepair />} />
              <Route path='/industries/home-repair/carpet-cleaners' element={<CellPhoneRepair />} />
              <Route path='/industries/home-repair/computer-retail-shop' element={<ComputerRetail />} />
              <Route path='/industries/home-repair/moving-services' element={<MovingServices />} />
              <Route path='/industries/home-repair/locksmiths' element={<LockSmith />} />
              <Route path='/industries/home-repair/fence-installer' element={<FenceInstaller />} />
              <Route path='/industries/home-repair/food-delivery-services' element={<FoodDeliveryServices />} />
              <Route path='/industries/home-repair/grocery-delivery-services' element={<GroceryDeliveryServices />} />
              <Route path='/industries/home-repair/window-door-companies' element={<WindowAndDoor />} />
              <Route path='/industries/home-repair/interior-design-services' element={<InteriorDesignServices />} />
              <Route path='/industries/home-repair/handyman-services' element={<HandyManServices />} />
              {/* Insurance changes happen */}
              <Route path='/industries/insurance/financial-insurance/seo' element={<Financial />} />
              <Route path='/industries/insurance/health-insurance/seo' element={<Health />} />
              <Route path='/industries/insurance/business-insurance/seo' element={<Business />} />
              <Route path='/industries/insurance/automobile-insurance/seo' element={<AutoInsurance />} />
              <Route path='/industries/insurance/travel-insurance/seo' element={<TravelInsurance />} />
              <Route path='/industries/insurance/home-insurance/seo' element={<HomeInsurance />} />
              <Route path='/industries/insurance/disability-insurance/seo' element={<DisabilityInsurance />} />
              {/* Legal changes happen */}
              <Route path='/industries/legal/personal-injury-lawyers/seo' element={<PersonalInjuryLawyers />} />
              <Route path='/industries/legal/criminal-defense-lawyers/seo' element={<Criminal />} />
              <Route path='/industries/legal/family-lawyers/seo' element={<FamiliyLaw />} />
              <Route path='/industries/legal/bankruptcy-lawyers/seo' element={<Bankruptcy />} />
              <Route path='/industries/legal/immigration-lawyers/seo' element={<Immigration />} />
              <Route path='/industries/legal/estate-planning-lawyers/seo' element={<EstatePlanning />} />
              {/* Local Consumer change happen */}
              <Route path='/industries/local-consumer/assisted-senior-living' element={<Assisted />} />
              <Route path='/industries/local-consumer/auctioneers/seo' element={<Auctioneers />} />
              <Route path='/industries/local-consumer/catering/seo' element={<Catering />} />
              <Route path='/industries/local-consumer/event-planner' element={<EventPlanner />} />
              <Route path='/industries/local-consumer/florist/seo' element={<Florist />} />
              <Route path='/industries/local-consumer/funeral-homes/seo' element={<Funeral />} />
              <Route path='/industries/local-consumer/veterinarians/seo' element={<Vaterinarians />} />
              <Route path='/industries/local-consumer/janitors/seo' element={<Janitors />} />
              <Route path='/industries/local-consumer/driving-school' element={<RVDealer />} />
              <Route path='/industries/local-consumer/private-investigators/seo' element={<PrivateInvestigator />} />
              <Route path='/industries/local-consumer/storage-facilities/seo' element={<StorageFacilities />} />
              {/* Medical Health */}
              <Route path='/industries/medical-health/alternative-medicine' element={<AlternativeMedicine />} />
              <Route path='/industries/medical-health/audiology' element={<Audioligy />} />
              <Route path='/industries/medical-health/bio-technology' element={<BioTechnology />} />
              <Route path='/industries/medical-health/cardiology' element={<Cardiology />} />
              <Route path='/industries/medical-health/fertility-clinics' element={<Fertility />} />
              <Route path='/industries/medical-health/dentists' element={<Dentists />} />
              <Route path='/industries/medical-health/dermatology' element={<Dermatology />} />
              <Route path='/industries/medical-health/doctors-offices' element={<DoctorsOffices />} />
              <Route path='/industries/medical-health/ear-nose-throat-doctors' element={<EarNoseThroat />} />
              <Route path='/industries/medical-health/ehr-companies' element={<EHRCompanies />} />
              <Route path='/industries/medical-health/chiropractors' element={<Chiropractors />} />
              <Route path='/industries/medical-health/medical-imaging' element={<MedicalImagining />} />
              <Route path='/industries/medical-health/mental-behavioral-health' element={<MentalAndBehavioral />} />
              <Route path='/industries/medical-health/nutritionists-dieticians' element={<NutritionistAndDieticians />} />
              <Route path='/industries/medical-health/ob-gyn' element={<OB />} />
              <Route path='/industries/medical-health/occupational-therapy' element={<OccupationalTharepy />} />
              <Route path='/industries/medical-health/oncologists' element={<OnCologist />} />
              <Route path='/industries/medical-health/optometrists' element={<Optometrists />} />
              <Route path='/industries/medical-health/orthodontists' element={<Orthodontists />} />
              <Route path='/industries/medical-health/orthopedic' element={<Othopedic />} />
              <Route path='/industries/medical-health/rehab-addiction-treatment' element={<OutPatient />} />
              <Route path='/industries/medical-health/personal-protective' element={<PersonalProtective />} />
              <Route path='/industries/medical-health/pharmaceutical-companies' element={<Pharmaceutical />} />
              <Route path='/industries/medical-health/telehealth' element={<PhysicalTharepy />} />
              <Route path='/industries/medical-health/hospice' element={<Physicians />} />
              <Route path='/industries/medical-health/plastic-surgery' element={<PlasticSergery />} />
              <Route path='/industries/medical-health/podiatry' element={<Padiatry />} />
              <Route path='/industries/medical-health/psychiatrists' element={<Psychiatrists />} />
              <Route path='/industries/medical-health/speech-therapists' element={<SpeechTharepists />} />
              <Route path='/industries/medical-health/urologists' element={<Urologists />} />
              <Route path='/industries/medical-health/counselor' element={<Counselor />} />
              <Route path='/industries/medical-health/nursing-homes' element={<NursingHomes />} />
              <Route path='/industries/medical-health/hospitals-healthcare' element={<DoctorsOnDemand />} />
              {/* Fitness */}
              <Route path='/industries/fitness/dance-studios' element={<Dance />} />
              <Route path='/industries/fitness/exercise-equipment-companies' element={<ExcesiseEquipmentCompanies />} />
              <Route path='/industries/fitness/personal-trainer' element={<PersonalTrainer />} />
              <Route path='/industries/fitness/yoga-studios' element={<YogaStudios />} />
              <Route path='/industries/fitness/medical-spa' element={<MedicalSPA />} />
              <Route path='/industries/fitness/fitness-centers' element={<FitnessCenter />} />
              <Route path='/industries/fitness/martial-arts-schools' element={<MartialArtSchools />} />
              <Route path='/industries/fitness/ballet-schools' element={<BalletsSchools />} />
              <Route path='/industries/fitness/gymnastics-schools' element={<GymnasticsSchool />} />
              <Route path='/industries/fitness/swimming-schools' element={<SwimmingSchools />} />
              <Route path='/industries/fitness/nutritionists' element={<Nutritinists />} />
              <Route path='/industries/fitness/pilates-studios' element={<PilatesStudios />} />
              <Route path='/industries/fitness/acupuncturists' element={<Acupuncturists />} />
              {/* Personal Services */}
              <Route path='/industries/professional-services/architectural-firms' element={<Architecture />} />
              <Route path='/industries/professional-services/consultants' element={<Consultants />} />
              <Route path='/industries/professional-services/engineering-firms' element={<Engineering />} />
              <Route path='/industries/professional-services/mergers-acquisitions' element={<MergersAndAcquisitions />} />
              <Route path='/industries/professional-services/payroll-services' element={<Patroll />} />
              <Route path='/industries/professional-services/publishing-companies' element={<Publishing />} />
              <Route path='/industries/professional-services/recruiters' element={<Recuiters />} />
              <Route path='/industries/professional-services/translation-and-interpretations-services' element={<Translation />} />
              {/* Real Estate */}
              <Route path='/industries/real-estate/apartments' element={<Appartment />} />
              <Route path='/industries/real-estate/property-management-services/seo' element={<PropertyManegement />} />
              <Route path='/industries/real-estate/realtors' element={<Realtors />} />
              <Route path='/industries/real-estate/home-staging-services/seo' element={<HomeStaginingServices />} />
              <Route path='/industries/real-estate/real-estate-appraisers/seo' element={<RealEstateAppreaisers />} />
              <Route path='/industries/real-estate/real-estate-photographers/seo' element={<RealEstatePhotograph />} />
              <Route path='/industries/real-estate/construction-companies/seo' element={<ConstructionCompany />} />
              <Route path='/industries/real-estate/real-estate-investement-companies/seo' element={<RealEstateInvestement />} />
              <Route path='/industries/real-estate/commercial-real-estate' element={<CommercialRealEstate />} />
              {/* Recration */}
              <Route path='/industries/recreation/boat-dealers/seo' element={<Boat />} />
              <Route path='/industries/recreation/rv-parks-campgrounds/seo' element={<Campgrounds />} />
              <Route path='/industries/recreation/golf-course-companies/seo' element={<GolfCourse />} />
              <Route path='/industries/recreation/outdoor-products/seo' element={<OutDoors />} />
              <Route path='/industries/recreation/radio-stations/seo' element={<Radio />} />
              <Route path='/industries/recreation/travel-recreation-websites' element={<Recration />} />
              <Route path='/industries/recreation/swimming-pool-companies/seo' element={<SwimmingPoolCompanies />} />
              {/* Retail & Ecommerce */}
              <Route path='/industries/retail-ecommerce/department-stores' element={<Departement />} />
              <Route path='/industries/retail-ecommerce/toys-stores' element={<ToysAndGames />} />
              <Route path='/industries/retail-ecommerce/ecommerce' element={<Ecommerce />} />
              <Route path='/industries/retail-ecommerce/home-decor-stores' element={<HomeDecore />} />
              <Route path='/industries/retail-ecommerce/jewelry' element={<JewelryStores />} />
              <Route path='/industries/retail-ecommerce/luxury-brands' element={<Luxury />} />
              <Route path='/industries/retail-ecommerce/pet-supplies-store' element={<PetSupplies />} />
              <Route path='/industries/retail-ecommerce/online-market-places' element={<OnlineMarketPlace />} />
              <Route path='/industries/retail-ecommerce/apparel-stores' element={<ClothingAndApparel />} />
              <Route path='/industries/retail-ecommerce/beauty-skincare' element={<BeautyAndSkinCare />} />
              <Route path='/industries/retail-ecommerce/health-wellness-stores' element={<HeathAndWellness />} />
              <Route path='/industries/retail-ecommerce/electronics-stores' element={<ElectronicStore />} />
              <Route path='/industries/retail-ecommerce/sports-stores' element={<SportsAndOutdoor />} />
              {/* Tech */}
              <Route path='/industries/technology/cloud-computing' element={<Cloud />} />
              <Route path='/industries/technology/software' element={<Software />} />
              <Route path='/industries/technology/technology-companies' element={<TechCompanies />} />
              <Route path='/industries/technology/tele-communication' element={<TeleCommunication />} />
              <Route path='/industries/technology/robotics' element={<Robotics />} />
              <Route path='/industries/technology/computer-repair' element={<ComputerRepair />} />
              <Route path='/industries/technology/cyber-security' element={<CyberSecurity />} />
              <Route path='/industries/technology/artificial-intelligence' element={<ArtificialIntelligence />} />
              <Route path='/industries/technology/data-analytics' element={<DataAnalytics />} />
              <Route path='/industries/technology/video-game-companies' element={<VideoGameCompanies />} />
              <Route path='/industries/technology/mobile-app-development-company' element={<MobileAppDeveloper />} />
              <Route path='/industries/technology/digital-marketing' element={<DigitalMarketing />} />
              <Route path='/industries/technology/cloud-storage' element={<CloudStorage />} />
              <Route path='/industries/technology/it-consulting-firms' element={<ItConsultingFirms />} />
              {/* Tourism And Hospitality changes happen */}
              <Route path='/industries/tourism-hospitality/hotels-resorts' element={<HotelAndResort />} />
              <Route path='/industries/tourism-hospitality/vacation-rentals' element={<VacationRentals />} />
              <Route path='/industries/tourism-hospitality/museums' element={<Musemums />} />
              <Route path='/industries/tourism-hospitality/tour-operators' element={<TourOperator />} />
              <Route path='/industries/tourism-hospitality/travel-agencies' element={<TravelAgencies />} />
              <Route path='/industries/tourism-hospitality/movie-theaters' element={<ResturentAgencies />} />
              <Route path='/industries/tourism-hospitality/amusement-parks' element={<AmusementPark />} />
              <Route path='/industries/tourism-hospitality/art-galleries' element={<MusemsAndArtGallerie />} />
              <Route path='/industries/tourism-hospitality/adventure' element={<Adventure />} />
              <Route path='/industries/tourism-hospitality/casinos' element={<Casinos />} />
              <Route path='/industries/tourism-hospitality/country-clubs' element={<CountryClubs />} />
              <Route path='/industries/tourism-hospitality/limo-companies' element={<LimoCompanies />} />
              {/* Manufaruring changes happen */}
              <Route path='/industries/manufacturing/aviation/seo' element={<AirCraft />} />
              <Route path='/industries/manufacturing/countertop-manufacturers/seo' element={<CounterTop />} />
              <Route path='/industries/manufacturing/food-beverages-manufacturers/seo' element={<FoodAndBeverages />} />
              <Route path='/industries/manufacturing/solar-panel-manufacturers/seo' element={<SolarPanel />} />
              <Route path='/industries/manufacturing/phamaceutical-manufacturers/seo' element={<PharmaceuticalManufacturing />} />
              {/* Government Agency changes happen */}
              <Route path='/industries/government-agency/tourism/seo' element={<TourismAgency />} />
              <Route path='/industries/government-agency/arts-cultural/seo' element={<CulturalAgency />} />
              <Route path='/industries/government-agency/economic-development/seo' element={<EconomicDevelopment />} />
              <Route path='/industries/nonprofits' element={<PublicHealth />} />
              <Route path='/industries/government-agency/environment/seo' element={<EnvironmentAgency />} />
              <Route path='/industries/government-agency/government-education' element={<GovernmentEducation />} />
              <Route path='/industries/government-agency/public-safety-seo' element={<PublicSafety />} />
              <Route path='/industries/government-agency/social-service' element={<SocialService />} />
              <Route path='/industries/government-agency/employment-service' element={<EmploymentService />} />
              {/* Service Pages */}
              <Route path='/seo-services' element={<SEOService />} />
              <Route path='/ppc-services' element={<PPCService />} />
              <Route path='/content-marketing-services' element={<ContentMarketingServices />} />
              <Route path='/design-development' element={<Service />} />
              {/* Service Details */}
              {/* SEO Services */}
              <Route path='/seo-services/ecommerce-seo' element={<EcommerceSEO />} />
              <Route path='/seo-services/small-business-seo' element={<SmallBussinesSEO />} />
              <Route path='/seo-services/local-seo' element={<LocalSEO />} />
              <Route path='/seo-services/nationwide-seo' element={<NationwideSEO />} />
              <Route path='/seo-services/international-seo' element={<InternationSEO />} />
              <Route path='/seo-services/youtube-seo' element={<YouTubeSEO />} />
              <Route path='/seo-services/mobile-seo' element={<MobileSEO />} />
              <Route path='/seo-services/seo-consultancy' element={<ConsultancySEO />} />
              <Route path='/seo-services/shopify-seo' element={<ShopifySEO />} />
              <Route path='/seo-services/wordpress-seo' element={<WordpressSEO />} />
              <Route path='/seo-services/on-page-seo' element={<OnPageSEO />} />
              <Route path='/seo-services/technical-seo' element={<TechnicalSEO />} />
              <Route path='/seo-services/seo-keywords-research' element={<KeywordSEO />} />
              <Route path='/seo-services/backlink-audit' element={<Backlink />} />
              <Route path='/seo-services/link-building-services' element={<LinkBuilding />} />
              <Route path='/seo-services/seo-audits' element={<AuditSEO />} />
              <Route path='/seo-services/google-analytics-services' element={<GoogleAnalytics />} />
              <Route path='/seo-services/ga4-migration' element={<GA4Analytics />} />
              <Route path='/seo-services/franchise-seo' element={<FranchiseSEO />} />
              <Route path='/seo-services/celebrity-seo' element={<CelebritySEO />} />
              <Route path='/seo-services/luxury-seo' element={<LuxarySEO />} />
              {/* PPC Services */}
              <Route path='/ppc-services/google-ads-marketing' element={<GoogleAdMarketing />} />
              <Route path='/ppc-services/ppc-management-services' element={<PPCMAnagementService />} />
              <Route path='/ppc-services/facebook-advertising' element={<FacebookAdvertising />} />
              <Route path='/ppc-services/instagram-advertising' element={<InstagramAdvertising />} />
              <Route path='/ppc-services/youtube-advertising' element={<YouTubeAdvertising />} />
              <Route path='/ppc-services/twitter-marketing' element={<TwitterAdvertising />} />
              <Route path='/ppc-services/linkedin-marketing' element={<LinkedInMarketing />} />
              <Route path='/ppc-services/pinterest-marketing' element={<PinterestMarketing />} />
              <Route path='/ppc-services/quora-marketing' element={<QuaraMarketing />} />
              <Route path='/ppc-services/whatsapp-marketing' element={<WhatsappMarketing />} />
              <Route path='/ppc-services/tiktok-advertising' element={<TikTokAdvertising />} />
              {/* Website And Design */}
              <Route path='/design-development/b2b-web-design' element={<B2BWebDesign />} />
              <Route path='/design-development/b2c-web-design' element={<B2CWebDesign />} />
              <Route path='/design-development/ecommerce-web-design' element={<EcommerWebDesign />} />
              <Route path='/design-development/wordpress-web-design' element={<WordpressWebDesign />} />
              <Route path='/design-development/shopify-web-design' element={<ShopifyWebDesign />} />
              <Route path='/design-development/responsive-web-design' element={<ResponsiveWebDesign />} />
              <Route path='/design-development/landing-pages-design' element={<LandingPagesDesign />} />
              <Route path='/design-development/custom-logo-design' element={<CustomLogoDesign />} />
              <Route path='/design-development/website-maintenance' element={<WebsiteMaintance />} />
              <Route path='/design-development/website-hosting' element={<WebsiteHosting />} />
              <Route path='/design-development/website-optimization-services' element={<WebsiteoptimizationService />} />
              <Route path='/design-development/website-redesign' element={<WebsiteRedesign />} />
              <Route path='/creative-design' element={<CreativeDesign />} />
              {/* Content Marketing Services */}
              <Route path='/content-marketing-services/seo-content-writing' element={<SEOContentWritting />} />
              <Route path='/content-marketing-services/product-descriptions' element={<ProductDescription />} />
              <Route path='/content-marketing-services/guest-blogging' element={<GuestBlogging />} />
              <Route path='/content-marketing-services/web-copy-writing' element={<WebCopyingWritting />} />
              <Route path='/content-marketing-services/blog-writing' element={<BlogWritting />} />
              {/* Homepage Blog */}
              <Route path='/blogs/5-reasons-why-your-business-needs-a-branding-agency/' element={<BrandingAgency />} />
              <Route path='/blogs/how-to-choose-the-right-advertising-agency-for-your-business-needs/' element={<AdvertisingAgency />} />
              <Route path='/blogs/100-birthday-wishes-for-your-loved-ones/' element={<BirthDaywishes />} />
              <Route path='/blogs/129-romantic-good-morning-messages-to-your-wife-to-make-her-day/' element={<GoodMorning />} />
              <Route path='/blogs/100-interior-design-quotes-by-famous-designers/' element={<InteriorDesign />} />
              <Route path='/blogs/the-power-of-visual-communication-best-practices-for-creative-agencies/' element={<PowerVisual />} />
              <Route path='/blogs/the-benefits-of-hiring-a-professional-website-development-company-for-your-business/' element={<WebDevelopmentCompany />} />
              <Route path='*' element={<ErrorPage />} />
              <Route path='/thank-you' element={<ThanksYou />} />
              {/* Industry Services */}
              <Route path='/seo-for/window-cleaning-services' element={<SEOPages />} />
              <Route path='/digital-marketing-for-grand-rapids' element={<DigitalMarketingIndustry />} />
              <Route path='/cannabis-advertising-agency/' element={<DesignAndDevelopment />} />
              {/* Case           Studies          */}
              {/* For SEO */}
              <Route path='/seo-for/travel-services' element={<SEOCaseStudy heading="Travel SEO Services" link="travel-services" />} />
              <Route path='/seo-for/hotel-services' element={<SEOCaseStudy heading="Hotel SEO Services" link="hotel-services" />} />
              <Route path='/seo-for/fintech-services' element={<SEOCaseStudy heading="SEO for Fintech Service Providers" link="fintech-services" />} />
              <Route path='/seo-for/appliance-repair-services' element={<SEOCaseStudy heading="Appliance Repair SEO Services" link="appliance-repair-services" />} />
              <Route path='/seo-for/locksmith-services' element={<SEOCaseStudy heading="Locksmith SEO Services" link="locksmith-services" />} />
              <Route path='/seo-for/cbd-services' element={<SEOCaseStudy heading="CBD SEO Services" link="cbd-services" />} />
              <Route path='/seo-for/grand-rapids-services' element={<SEOCaseStudy heading="SEO Service Grand Rapids" link="grand-rapids-services" />} />
              <Route path='/seo-for/wedding-photographers-services' element={<SEOCaseStudy heading="SEO Services for Wedding Photographers" link="wedding-photographers-services" />} />
              <Route path='/seo-for/cosmetics-services' element={<SEOCaseStudy heading="Cosmetics SEO Services" link="cosmetics-services" />} />
              <Route path='/seo-for/home-services' element={<SEOCaseStudy heading="Home Service Company SEO" link="home-services" />} />
              <Route path='/seo-for/professional-firm-services' element={<SEOCaseStudy heading="SEO for Professional Services Firms" link="professional-firm-services" />} />
              <Route path='/seo-for/spanish-services' element={<SEOCaseStudy heading="Spanish SEO Services" link="spanish-services" />} />
              <Route path='/seo-for/virtual-assistant-services' element={<SEOCaseStudy heading="Virtual Assistant SEO Services" link="virtual-assistant-services" />} />
              <Route path='/seo-for/architects-services' element={<SEOCaseStudy heading="Architects SEO Services" link="architects-services" />} />
              <Route path='/seo-for/electricians-services' element={<SEOCaseStudy heading="SEO Services for Electricians" link="electricians-services" />} />
              <Route path='/seo-for/security-guard-services' element={<SEOCaseStudy heading="SEO Services for Security Guard Companies" link="security-guard-services" />} />
              <Route path='/seo-for/translation-services' element={<SEOCaseStudy heading="SEO Translation Services" link="translation-services" />} />
              <Route path='/seo-for/french-services' element={<SEOCaseStudy heading="French SEO Services" link="french-services" />} />
              <Route path='/seo-for/chiropractor-services' element={<SEOCaseStudy heading="Chiropractor SEO Services" link="chiropractor-services" />} />
              <Route path='/seo-for/concierge-services' element={<SEOCaseStudy heading="Concierge SEO Services" link="concierge-services" />} />
              <Route path='/seo-for/crypto-services' element={<SEOCaseStudy heading="Crypto SEO Services" link="crypto-services" />} />
              <Route path='/seo-for/dispensary-services' element={<SEOCaseStudy heading="Dispensary SEO Service" link="dispensary-services" />} />
              <Route path='/seo-for/german-services' element={<SEOCaseStudy heading="German SEO Services" link="german-services" />} />
              <Route path='/seo-for/3d-printing-services' element={<SEOCaseStudy heading="3D printing service" link="3d-printing-services" />} />
              <Route path='/seo-for/abortion-clinic' element={<SEOCaseStudy heading="Abortion clinic" link="abortion-clinic" />} />
              <Route path='/seo-for/abrasives-supplier' element={<SEOCaseStudy heading="Abrasives supplier" link="abrasives-supplier" />} />
              <Route path='/seo-for/academic-department' element={<SEOCaseStudy heading="Academic department" link="academic-department" />} />
              <Route path='/seo-for/adult-entertainment-club' element={<SEOCaseStudy heading="Adult entertainment club" link="adult-entertainment-club" />} />
              <Route path='/seo-for/adult-entertainment-store' element={<SEOCaseStudy heading="Adult entertainment store" link="adult-entertainment-store" />} />
              <Route path='/seo-for/adult-foster-care-service' element={<SEOCaseStudy heading="Adult foster care service" link="adult-foster-care-service" />} />
              <Route path='/seo-for/adventure-sports' element={<SEOCaseStudy heading="Adventure sports" link="adventure-sports" />} />
              <Route path='/seo-for/advertising-agency' element={<SEOCaseStudy heading="Advertising agency" link="advertising-agency" />} />
              <Route path='/seo-for/aerial-photographer' element={<SEOCaseStudy heading="Aerial photographer" link="aerial-photographer" />} />
              <Route path='/seo-for/aero-dance-class' element={<SEOCaseStudy heading="Aero dance class" link="aero-dance-class" />} />
              <Route path='/seo-for/aerobics-instructor' element={<SEOCaseStudy heading="Aerobics instructor" link="aerobics-instructor" />} />
              <Route path='/seo-for/aerospace-company' element={<SEOCaseStudy heading="Aerospace company" link="aerospace-company" />} />
              <Route path='/seo-for/african-goods-store' element={<SEOCaseStudy heading="African goods store" link="african-goods-store" />} />
              <Route path='/seo-for/aged-care' element={<SEOCaseStudy heading="Aged care" link="aged-care" />} />
              <Route path='/seo-for/agistment-service' element={<SEOCaseStudy heading="Agistment service" link="agistment-service" />} />
              <Route path='/seo-for/agricultural-association' element={<SEOCaseStudy heading="Agricultural association" link="agricultural-association" />} />
              <Route path='/seo-for/agricultural-cooperative' element={<SEOCaseStudy heading="Agricultural cooperative" link="agricultural-cooperative" />} />
              <Route path='/seo-for/agricultural-machinery-manufacturer' element={<SEOCaseStudy heading="Agricultural machinery manufacturer" link="agricultural-machinery-manufacturer" />} />
              <Route path='/seo-for/agricultural-organization' element={<SEOCaseStudy heading="Agricultural organization" link="agricultural-organization" />} />
              <Route path='/seo-for/agricultural-production' element={<SEOCaseStudy heading="Agricultural production" link="agricultural-production" />} />
              <Route path='/seo-for/agricultural-service' element={<SEOCaseStudy heading="Agricultural service" link="agricultural-service" />} />
              <Route path='/seo-for/agrochemicals-supplier' element={<SEOCaseStudy heading="Agrochemicals supplier" link="agrochemicals-supplier" />} />
              <Route path='/seo-for/aikido-school' element={<SEOCaseStudy heading="Aikido school" link="aikido-school" />} />
              <Route path='/seo-for/air-compressor-supplier' element={<SEOCaseStudy heading="Air compressor supplier" link="air-compressor-supplier" />} />
              <Route path='/seo-for/air-conditioning-contractor' element={<SEOCaseStudy heading="Air conditioning contractor" link="air-conditioning-contractor" />} />
              <Route path='/seo-for/air-conditioning-store' element={<SEOCaseStudy heading="Air conditioning store" link="ai-conditioning-store" />} />
              <Route path='/seo-for/air-conditioning-system-supplier' element={<SEOCaseStudy heading="Air conditioning system supplier" link="air-conditioning-system-supplier" />} />
              <Route path='/seo-for/air-duct-cleaning-service' element={<SEOCaseStudy heading="Air duct cleaning service" link="air-duct-cleaning-service" />} />
              <Route path='/seo-for/air-filter-supplier' element={<SEOCaseStudy heading="Air filter supplier" link="air-filter-supplier" />} />
              <Route path='/seo-for/air-force-base' element={<SEOCaseStudy heading="Air force base" link="air-force-base" />} />
              <Route path='/seo-for/air-taxi' element={<SEOCaseStudy heading="Air taxi" link="air-taxi" />} />
              <Route path='/seo-for/aircraft-maintenance-company' element={<SEOCaseStudy heading="Aircraft maintenance company" link="aircraft-maintenance-company" />} />
              <Route path='/seo-for/aircraft-rental-service' element={<SEOCaseStudy heading="Aircraft rental service" link="aircraft-rental-service" />} />
              <Route path='/seo-for/aircraft-supply-store' element={<SEOCaseStudy heading="Aircraft supply store" link="aircraft-supply-store" />} />
              <Route path='/seo-for/airline-ticket-agency' element={<SEOCaseStudy heading="Airline ticket agency" link="airline-ticket-agency" />} />
              <Route path='/seo-for/airline-ticket-agency' element={<SEOCaseStudy heading="Airline ticket agency" link="airline-ticket-agency" />} />
              <Route path='/seo-for/airplane' element={<SEOCaseStudy heading="Airplane" link="airplane" />} />
              <Route path='/seo-for/airport' element={<SEOCaseStudy heading="Airport" link="airport" />} />
              <Route path='/seo-for/airport-shuttle-service' element={<SEOCaseStudy heading="Airport shuttle service" link="airport-shuttle-service" />} />
              <Route path='/seo-for/alcohol-manufacturer' element={<SEOCaseStudy heading="Alcohol manufacturer" link="alcohol-manufacturer" />} />
              <Route path='/seo-for/alcohol-retail-monopoly' element={<SEOCaseStudy heading="Alcohol retail monopoly" link="alcohol-retail-monopoly" />} />
              <Route path='/seo-for/alcoholic-beverage-wholesaler' element={<SEOCaseStudy heading="Alcoholic beverage wholesaler" link="alcoholic-beverage-wholesaler" />} />
              <Route path='/seo-for/aluminum-frames-supplier' element={<SEOCaseStudy heading="Aluminum frames supplier" link="aluminum-frames-supplier" />} />
              <Route path='/seo-for/aluminum-supplier' element={<SEOCaseStudy heading="Aluminum supplier" link="aluminum-supplier" />} />
              <Route path='/seo-for/aluminum-welder' element={<SEOCaseStudy heading="Aluminum welder" link="aluminum-welder" />} />
              <Route path='/seo-for/aluminum-window' element={<SEOCaseStudy heading="Aluminum window" link="aluminum-window" />} />
              <Route path='/seo-for/american-football-field' element={<SEOCaseStudy heading="American football field" link="american-football-field" />} />
              <Route path='/seo-for/amusement-center' element={<SEOCaseStudy heading="Amusement center" link="amusement-center" />} />
              <Route path='/seo-for/amusement-park' element={<SEOCaseStudy heading="Amusement park" link="amusement-park" />} />
              <Route path='/seo-for/anesthesiologist' element={<SEOCaseStudy heading="Anesthesiologist" link="anesthesiologist" />} />
              <Route path='/seo-for/anganwadi-center' element={<SEOCaseStudy heading="Anganwadi center" link="anganwadi-center" />} />
              <Route path='/seo-for/animal-feed-store' element={<SEOCaseStudy heading="Animal feed store" link="animal-feed-store" />} />
              <Route path='/seo-for/animal-hospital' element={<SEOCaseStudy heading="Animal hospital" link="animal-hospital" />} />
              <Route path='/seo-for/animal-rescue-service' element={<SEOCaseStudy heading="Animal rescue service" link="animal-rescue-service" />} />
              <Route path='/seo-for/antique-furniture-store' element={<SEOCaseStudy heading="Antique furniture store" link="antique-furniture-store" />} />
              <Route path='/seo-for/apartment-building' element={<SEOCaseStudy heading="Apartment building" link="apartment-building" />} />
              <Route path='/seo-for/apartment-rental-agency' element={<SEOCaseStudy heading="Apartment rental agency" link="apartment-rental-agency" />} />
              <Route path='/seo-for/apostolic-church' element={<SEOCaseStudy heading="Apostolic church" link="apostolic-church" />} />
              <Route path='/seo-for/appliance-rental-service' element={<SEOCaseStudy heading="Appliance rental service" link="appliance-rental-service" />} />
              <Route path='/seo-for/appliance-repair-service' element={<SEOCaseStudy heading="Appliance repair service" link="appliance-repair-service" />} />
              <Route path='/seo-for/appliance-store' element={<SEOCaseStudy heading="Appliance store" link="appliance-store" />} />
              <Route path='/seo-for/appliances-customer-service' element={<SEOCaseStudy heading="Appliances customer service" link="appliances-customer-service" />} />
              <Route path='/seo-for/appraiser' element={<SEOCaseStudy heading="Appraiser" link="appraiser" />} />
              <Route path='/seo-for/apprenticeship-center' element={<SEOCaseStudy heading="Apprenticeship center" link="apprenticeship-center" />} />
              <Route path='/seo-for/aquaculture-farm' element={<SEOCaseStudy heading="Aquaculture farm" link="aquaculture-farm" />} />
              <Route path='/seo-for/aquatic-centre' element={<SEOCaseStudy heading="Aquatic centre" link="aquatic-centre" />} />
              <Route path='/seo-for/arboretum' element={<SEOCaseStudy heading="Arboretum" link="arboretum" />} />
              <Route path='/seo-for/arborist-&-tree-surgeon' element={<SEOCaseStudy heading="Arborist and tree surgeon" link="arborist-&-tree-surgeon" />} />
              <Route path='/seo-for/archaeological-museum' element={<SEOCaseStudy heading="Archaeological museum" link="archaeological-museum" />} />
              <Route path='/seo-for/archery-club' element={<SEOCaseStudy heading="Archery club" link="archery-club" />} />
              <Route path='/seo-for/archery-range' element={<SEOCaseStudy heading="Archery range" link="archery -ange" />} />
              <Route path='/seo-for/archery-store' element={<SEOCaseStudy heading="Archery store" link="archery-store" />} />
              <Route path='/seo-for/architects-association' element={<SEOCaseStudy heading="Architects association" link="architects-association" />} />
              <Route path='/seo-for/architectural-designer' element={<SEOCaseStudy heading="Architectural designer" link="architectural-designer" />} />
              <Route path='/seo-for/architectural-salvage-store' element={<SEOCaseStudy heading="Architectural salvage store" link="architectural-salvage-store" />} />
              <Route path='/seo-for/architecture-firm' element={<SEOCaseStudy heading="Architecture firm" link="architecture-firm" />} />
              <Route path='/seo-for/architecture-school' element={<SEOCaseStudy heading="Architecture school" link="architecture-school" />} />
              <Route path='/seo-for/archive' element={<SEOCaseStudy heading="Archive" link="archive" />} />
              <Route path='/seo-for/arena' element={<SEOCaseStudy heading="Arena" link="arena" />} />
              <Route path='/seo-for/armed-forces-association' element={<SEOCaseStudy heading="Armed forces association" link="armed-forces-association" />} />
              <Route path='/seo-for/armenian-church' element={<SEOCaseStudy heading="Armenian church" link="armenian-church" />} />
              <Route path='/seo-for/army-barracks' element={<SEOCaseStudy heading="Army barracks" link="army-barracks" />} />
              <Route path='/seo-for/army-&-navy-surplus-shop' element={<SEOCaseStudy heading="Army & navy surplus shop" link="army-&-navy-surplus-shop" />} />
              <Route path='/seo-for/army-facility' element={<SEOCaseStudy heading="Army facility" link="army-facility" />} />
              <Route path='/seo-for/army-museum' element={<SEOCaseStudy heading="Army museum" link="army-museum" />} />
              <Route path='/seo-for/aromatherapy-class' element={<SEOCaseStudy heading="Aromatherapy class" link="aromatherapy-class" />} />
              <Route path='/seo-for/aromatherapy-service' element={<SEOCaseStudy heading="Aromatherapy service" link="aromatherapy-service" />} />
              <Route path='/seo-for/aromatherapy-supply-store' element={<SEOCaseStudy heading="Aromatherapy supply store" link="aromatherapy-supply-store" />} />
              <Route path='/seo-for/art-cafe' element={<SEOCaseStudy heading="Art cafe" link="art-cafe" />} />
              <Route path='/seo-for/art-center' element={<SEOCaseStudy heading="Art center" link="art-center" />} />
              <Route path='/seo-for/art-dealer' element={<SEOCaseStudy heading="Art dealer" link="art-dealer" />} />
              <Route path='/seo-for/art-gallery' element={<SEOCaseStudy heading="Art gallery" link="art-gallery" />} />
              <Route path='/seo-for/art-museum' element={<SEOCaseStudy heading="Art museum" link="art-museum" />} />
              <Route path='/seo-for/art-restoration-service' element={<SEOCaseStudy heading="Art restoration service" link="art-restoration-service" />} />
              <Route path='/seo-for/art-school' element={<SEOCaseStudy heading="Art school" link="art-school" />} />
              <Route path='/seo-for/art-studio' element={<SEOCaseStudy heading="Art studio" link="art-studio" />} />
              <Route path='/seo-for/art-supply-store' element={<SEOCaseStudy heading="Art supply store" link="art-supply-store" />} />
              <Route path='/seo-for/artificial-plant-supplier' element={<SEOCaseStudy heading="Artificial plant supplier" link="artificial-plant-supplier" />} />
              <Route path='/seo-for/artist' element={<SEOCaseStudy heading="Artist" link="artist" />} />
              <Route path='/seo-for/arts-organization' element={<SEOCaseStudy heading="Arts organization" link="arts-organization" />} />
              <Route path='/seo-for/asbestos-testing-service' element={<SEOCaseStudy heading="Asbestos testing service" link="asbestos-testing-service" />} />
              <Route path='/seo-for/ashram' element={<SEOCaseStudy heading="Ashram" link="ashram" />} />
              <Route path='/seo-for/asian-grocery-store' element={<SEOCaseStudy heading="Asian grocery store" link="asian-grocery-store" />} />
              <Route path='/seo-for/asian-household-goods-store' element={<SEOCaseStudy heading="Asian household goods store" link="asian-household-goods-store" />} />
              <Route path='/seo-for/asphalt-contractor' element={<SEOCaseStudy heading="Asphalt contractor" link="asphalt-contractor" />} />
              <Route path='/seo-for/asphalt-mixing-plant' element={<SEOCaseStudy heading="Asphalt mixing plant" link="asphalt-mixing-plant" />} />
              <Route path='/seo-for/assemblies-of-god-church' element={<SEOCaseStudy heading="Assemblies of God church" link="assemblies-of-god-church" />} />
              <Route path='/seo-for/assistante-maternelle' element={<SEOCaseStudy heading="Assistante maternelle" link="assistante-maternelle" />} />
              <Route path='/seo-for/assisted-living-facility' element={<SEOCaseStudy heading="Assisted living facility" link="assisted-living-facility" />} />
              <Route path='/seo-for/association-or-organization' element={<SEOCaseStudy heading="Association or organization" link="association-or-organization" />} />
              <Route path='/seo-for/aston-martin-dealer' element={<SEOCaseStudy heading="Aston Martin dealer" link="aston-martin-dealer" />} />
              <Route path='/seo-for/astrologer' element={<SEOCaseStudy heading="Astrologer" link="astrologer" />} />
              <Route path='/seo-for/athletic-club' element={<SEOCaseStudy heading="Athletic club" link="athletic-club" />} />
              <Route path='/seo-for/athletic-field' element={<SEOCaseStudy heading="Athletic field" link="athletic-field" />} />
              <Route path='/seo-for/athletic-park' element={<SEOCaseStudy heading="Athletic park" link="athletic-park" />} />
              <Route path='/seo-for/athletic-track' element={<SEOCaseStudy heading="Athletic track" link="athletic-track" />} />
              <Route path='/seo-for/atm' element={<SEOCaseStudy heading="ATM" link="atm" />} />
              <Route path='/seo-for/attorney-referral-service' element={<SEOCaseStudy heading="Attorney referral service" link="attorney-referral-service" />} />
              <Route path='/seo-for/atv-dealer' element={<SEOCaseStudy heading="ATV dealer" link="atv-dealer" />} />
              <Route path='/seo-for/atv-rental-service' element={<SEOCaseStudy heading="ATV rental service" link="atv-rental-service" />} />
              <Route path='/seo-for/atv-repair-shop' element={<SEOCaseStudy heading="ATV repair shop" link="atv-repair-shop" />} />
              <Route path='/seo-for/auction-house' element={<SEOCaseStudy heading="Auction house" link="auction-house" />} />
              <Route path='/seo-for/audio-visual-equipment-supplier' element={<SEOCaseStudy heading="Audio visual equipment supplier" link="audio-visual-equipment-supplier" />} />
              <Route path='/seo-for/audio-visual-equipment-repair-service' element={<SEOCaseStudy heading="Audio visual equipment repair service" link="audio-visual-equipment-repair-service" />} />
              <Route path='/seo-for/audiologist' element={<SEOCaseStudy heading="Audiologist" link="audiologist" />} />
              <Route path='/seo-for/audiovisual-equipment-rental-service' element={<SEOCaseStudy heading="Audiovisual equipment rental service" link="audiovisual-equipment-rental-service" />} />
              <Route path='/seo-for/auditorium' element={<SEOCaseStudy heading="Auditorium" link="auditorium" />} />
              <Route path='/seo-for/auto-air-conditioning-service' element={<SEOCaseStudy heading="Auto air conditioning service" link="auto-air-conditioning-service" />} />
              <Route path='/seo-for/auto-body-parts-supplier' element={<SEOCaseStudy heading="Auto body parts supplier" link="auto-body-parts-supplier" />} />
              <Route path='/seo-for/auto-body-shop' element={<SEOCaseStudy heading="Auto body shop" link="auto-body-shop" />} />
              <Route path='/seo-for/auto-broker' element={<SEOCaseStudy heading="Auto broker" link="auto-broker" />} />
              <Route path='/seo-for/auto-electrical-service' element={<SEOCaseStudy heading="Auto electrical service" link="auto-electrical-service" />} />
              <Route path='/seo-for/auto-glass-repair-service' element={<SEOCaseStudy heading="Auto glass repair service" link="auto-glass-repair-service" />} />
              <Route path='/seo-for/auto-glass-shop' element={<SEOCaseStudy heading="Auto glass shop" link="auto-glass-shop" />} />
              <Route path='/seo-for/auto-insurance-agency' element={<SEOCaseStudy heading="Auto insurance agency" link="auto-insurance-agency" />} />
              <Route path='/seo-for/auto-parts-manufacturer' element={<SEOCaseStudy heading="Auto parts manufacturer" link="auto-parts-manufacturer" />} />
              <Route path='/seo-for/auto-parts-market' element={<SEOCaseStudy heading="Auto parts market" link="auto-parts-market" />} />
              <Route path='/seo-for/auto-parts-store' element={<SEOCaseStudy heading="Auto parts store" link="auto-parts-store" />} />
              <Route path='/seo-for/auto-repair-shop' element={<SEOCaseStudy heading="Auto repair shop" link="auto-repair-shop" />} />
              <Route path='/seo-for/auto-restoration-service' element={<SEOCaseStudy heading="Auto restoration service" link="auto-restoration-service" />} />
              <Route path='/seo-for/auto-spring-shop' element={<SEOCaseStudy heading="Auto spring shop" link="auto-spring-shop" />} />
              <Route path='/seo-for/auto-tag-agency' element={<SEOCaseStudy heading="Auto tag agency" link="auto-tag-agency" />} />
              <Route path='/seo-for/auto-tune-up-service' element={<SEOCaseStudy heading="Auto tune up service" link="auto-tune-up-service" />} />
              <Route path='/seo-for/auto-window-tinting-service' element={<SEOCaseStudy heading="Auto window tinting service" link="auto-window-tinting-service" />} />
              <Route path='/seo-for/automation-company' element={<SEOCaseStudy heading="Automation company" link="automation-company" />} />
              <Route path='/seo-for/automobile-storage-facility' element={<SEOCaseStudy heading="Automobile storage facility" link="automobile-storage-facility" />} />
              <Route path='/seo-for/aviation-consultant' element={<SEOCaseStudy heading="Aviation consultant" link="aviation-consultant" />} />
              <Route path='/seo-for/baby-store' element={<SEOCaseStudy heading="Baby store" link="baby-store" />} />
              <Route path='/seo-for/bag-shop' element={<SEOCaseStudy heading="Bag shop" link="bag-shop" />} />
              <Route path='/seo-for/bagel-shop' element={<SEOCaseStudy heading="Bagel shop" link="bagel-shop" />} />
              <Route path='/seo-for/bail-bonds-service' element={<SEOCaseStudy heading="Bail bonds service" link="bail-bonds-service" />} />
              <Route path='/seo-for/bait-shop' element={<SEOCaseStudy heading="Bait shop" link="bait-shop" />} />
              <Route path='/seo-for/bakery' element={<SEOCaseStudy heading="Bakery" link="bakery" />} />
              <Route path='/seo-for/bakery-equipment' element={<SEOCaseStudy heading="Bakery equipment" link="bakery-equipment" />} />
              <Route path='/seo-for/baking-supply-store' element={<SEOCaseStudy heading="Baking supply store" link="baking-supply-store" />} />
              <Route path='/seo-for/ballet-school' element={<SEOCaseStudy heading="Ballet school" link="ballet-school" />} />
              <Route path='/seo-for/balloon-ride-tour-agency' element={<SEOCaseStudy heading="Balloon ride tour agency" link="balloon-ride-tour-agency" />} />
              <Route path='/seo-for/ballroom-dance-instructor' element={<SEOCaseStudy heading="Ballroom dance instructor" link="ballroom-dance-instructor" />} />
              <Route path='/seo-for/bangle-shop' element={<SEOCaseStudy heading="Bangle Shop" link="bangle-shop" />} />
              <Route path='/seo-for/bank' element={<SEOCaseStudy heading="Bank" link="bank" />} />
              <Route path='/seo-for/bankruptcy-attorney' element={<SEOCaseStudy heading="Bankruptcy attorney" link="bankruptcy-attorney" />} />
              <Route path='/seo-for/bankruptcy-service' element={<SEOCaseStudy heading="Bankruptcy service" link="bankruptcy-service" />} />
              <Route path='/seo-for/banquet-hall' element={<SEOCaseStudy heading="Banquet hall" link="banquet-hall" />} />
              <Route path='/seo-for/baptist-church' element={<SEOCaseStudy heading="Baptist church" link="baptist-church" />} />
              <Route path='/seo-for/bar-stool-supplier' element={<SEOCaseStudy heading="Bar stool supplier" link="bar-stool-supplier" />} />
              <Route path='/seo-for/bar-tabac' element={<SEOCaseStudy heading="Bar tabac" link="bar-tabac" />} />
              <Route path='/seo-for/barbecue-area' element={<SEOCaseStudy heading="Barbecue area" link="barbecue-area" />} />
              <Route path='/seo-for/barber-shop' element={<SEOCaseStudy heading="Barber shop" link="barber-shop" />} />
              <Route path='/seo-for/barber-supply-store' element={<SEOCaseStudy heading="Barber supply store" link="barber-supply-store" />} />
              <Route path='/seo-for/bariatric-surgeon' element={<SEOCaseStudy heading="Bariatric surgeon" link="bariatric-surgeon" />} />
              <Route path='/seo-for/barrel-supplier' element={<SEOCaseStudy heading="Barrel supplier" link="barrel-supplier" />} />
              <Route path='/seo-for/barrister' element={<SEOCaseStudy heading="Barrister" link="barrister" />} />
              <Route path='/seo-for/baseball-club' element={<SEOCaseStudy heading="Baseball club" link="baseball-club" />} />
              <Route path='/seo-for/baseball-field' element={<SEOCaseStudy heading="Baseball field" link="baseball-field" />} />
              <Route path='/seo-for/bathroom-remodeler' element={<SEOCaseStudy heading="Bathroom remodeler" link="bathroom-remodeler" />} />
              <Route path='/seo-for/batik-clothing-store' element={<SEOCaseStudy heading="Batik clothing store" link="batik-clothing-store" />} />
              <Route path='/seo-for/battery-manufacturer' element={<SEOCaseStudy heading="Battery manufacturer" link="battery-manufacturer" />} />
              <Route path='/seo-for/battery-wholesaler' element={<SEOCaseStudy heading="Battery wholesaler" link="battery-wholesaler" />} />
              <Route path='/seo-for/batting-cage-center' element={<SEOCaseStudy heading="Batting cage center" link="batting-cage-center" />} />
              <Route path='/seo-for/bbq-area' element={<SEOCaseStudy heading="BBQ area" link="bbq-area" />} />
              <Route path='/seo-for/beach-cleaning-service' element={<SEOCaseStudy heading="Beach cleaning service" link="beach-cleaning-service" />} />
              <Route path='/seo-for/beach-entertainment-shop' element={<SEOCaseStudy heading="Beach entertainment shop" link="beach-entertainment-shop" />} />
              <Route path='/seo-for/beach-volleyball-club' element={<SEOCaseStudy heading="Beach volleyball club" link="beach-volleyball-club" />} />
              <Route path='/seo-for/beauty-product-supplier' element={<SEOCaseStudy heading="Beauty product supplier" link="beauty-product-supplier" />} />
              <Route path='/seo-for/beauty-products-wholesaler' element={<SEOCaseStudy heading="Beauty products wholesaler" link="beauty-products-wholesaler" />} />
              <Route path='/seo-for/beauty-salon' element={<SEOCaseStudy heading="Beauty salon" link="beauty-salon" />} />
              <Route path='/seo-for/beauty-school' element={<SEOCaseStudy heading="Beauty school" link="beauty-school" />} />
              <Route path='/seo-for/beauty-supply-store' element={<SEOCaseStudy heading="Beauty supply store" link="beauty-supply-store" />} />
              <Route path='/seo-for/bed-&-breakfast' element={<SEOCaseStudy heading="Bed & breakfast" link="bed-&-breakfast" />} />
              <Route path='/seo-for/bed-shop' element={<SEOCaseStudy heading="Bed shop" link="bed-shop" />} />
              <Route path='/seo-for/bedding-store' element={<SEOCaseStudy heading="Bedding store" link="bedding-store" />} />
              <Route path='/seo-for/bedroom-furniture-store' element={<SEOCaseStudy heading="Bedroom furniture store" link="bedroom-furniture-store" />} />
              <Route path='/seo-for/bee-relocation-service' element={<SEOCaseStudy heading="Bee relocation service" link="bee-relocation-service" />} />
              <Route path='/seo-for/beer-distributor' element={<SEOCaseStudy heading="Beer distributor" link="beer-distributor" />} />
              <Route path='/seo-for/beer-garden' element={<SEOCaseStudy heading="Beer garden" link="beer-garden" />} />
              <Route path='/seo-for/beer-hall' element={<SEOCaseStudy heading="Beer hall" link="beer-hall" />} />
              <Route path='/seo-for/beer-store' element={<SEOCaseStudy heading="Beer store" link="beer-store" />} />
              <Route path='/seo-for/belt-shop' element={<SEOCaseStudy heading="GBelt shop" link="belt-shop" />} />
              <Route path='/seo-for/betting-agency' element={<SEOCaseStudy heading="Betting agency" link="betting-agency" />} />
              <Route path='/seo-for/beverage-distributor' element={<SEOCaseStudy heading="Beverage distributor" link="beverage-distributor" />} />
              <Route path='/seo-for/bicycle-club' element={<SEOCaseStudy heading="Bicycle club" link="bicycle-club" />} />
              <Route path='/seo-for/biotechnology-company' element={<SEOCaseStudy heading="Biotechnology company" link="biotechnology-company" />} />
              <Route path='/seo-for/biotechnology-engineer' element={<SEOCaseStudy heading="Biotechnology engineer" link="biotechnology-engineer" />} />
              <Route path='/seo-for/bird-control-service' element={<SEOCaseStudy heading="Bird control service" link="bird-control-service" />} />
              <Route path='/seo-for/bird-shop' element={<SEOCaseStudy heading="Bird shop" link="bird-shop" />} />
              <Route path='/seo-for/birth-center' element={<SEOCaseStudy heading="Birth center" link="birth-center" />} />
              <Route path='/seo-for/blast-cleaning-service' element={<SEOCaseStudy heading="Blast cleaning service" link="blast-cleaning-service" />} />
              <Route path='/seo-for/blinds-shop' element={<SEOCaseStudy heading="Blinds shop" link="blinds-shop" />} />
              <Route path='/seo-for/blood-bank' element={<SEOCaseStudy heading="Blood bank" link="blood-bank" />} />
              <Route path='/seo-for/blood-donation-center' element={<SEOCaseStudy heading="Blood donation center" link="blood-donation-center" />} />
              <Route path='/seo-for/blueprint-service' element={<SEOCaseStudy heading="Blueprint service" link="blueprint-service" />} />
              <Route path='/seo-for/board-game-club' element={<SEOCaseStudy heading="Board game club" link="board-game-club" />} />
              <Route path='/seo-for/boarding-school' element={<SEOCaseStudy heading="Boarding school" link="boarding-school" />} />
              <Route path='/seo-for/boat-dealer' element={<SEOCaseStudy heading="Boat dealer" link="boat-dealer" />} />
              <Route path='/seo-for/boat-ramp' element={<SEOCaseStudy heading="Boat ramp" link="boat-ramp" />} />
              <Route path='/seo-for/body-shaping-class' element={<SEOCaseStudy heading="Body shaping class" link="body-shaping-class" />} />
              <Route path='/seo-for/boiler-manufacturer' element={<SEOCaseStudy heading="Boiler manufacturer" link="boiler-manufacturer" />} />
              <Route path='/seo-for/boiler-supplier' element={<SEOCaseStudy heading="Boiler supplier" link="boiler-supplier" />} />
              <Route path='/seo-for/bonsai-plant-supplier' element={<SEOCaseStudy heading="Bonsai plant supplier" link="bonsai-plant-supplier" />} />
              <Route path='/seo-for/book-publisher' element={<SEOCaseStudy heading="Book publisher" link="book-publisher" />} />
              <Route path='/seo-for/book-store' element={<SEOCaseStudy heading="Book store" link="book-store" />} />
              <Route path='/seo-for/bookkeeping-service' element={<SEOCaseStudy heading="Bookkeeping service" link="bookkeeping-service" />} />
              <Route path='/seo-for/bookmaker' element={<SEOCaseStudy heading="Bookmaker" link="bookmaker" />} />
              <Route path='/seo-for/books-wholesaler' element={<SEOCaseStudy heading="Books wholesaler" link="books-wholesaler" />} />
              <Route path='/seo-for/boot-camp' element={<SEOCaseStudy heading="Boot camp" link="boot-camp" />} />
              <Route path='/seo-for/border-crossing-station' element={<SEOCaseStudy heading="Border crossing station" link="border-crossing-station" />} />
              <Route path='/seo-for/botanical-garden' element={<SEOCaseStudy heading="Botanical garden" link="botanical-garden" />} />
              <Route path='/seo-for/bowling-alley' element={<SEOCaseStudy heading="Bowling alley" link="bowling-alley" />} />
              <Route path='/seo-for/bowling-club' element={<SEOCaseStudy heading="Bowling club" link="bowling-club" />} />
              <Route path='/seo-for/bowling-supply-shop' element={<SEOCaseStudy heading="Bowling supply shop" link="bowling-supply-shop" />} />
              <Route path='/seo-for/boys-high-school' element={<SEOCaseStudy heading="Boys’ high school" link="boys-high-school" />} />
              <Route path='/seo-for/bpo-company' element={<SEOCaseStudy heading="BPO company" link="bpo-company" />} />
              <Route path='/seo-for/bpo-placement-agency' element={<SEOCaseStudy heading="BPO placement agency" link="bpo-placement-agency" />} />
              <Route path='/seo-for/brake-shop' element={<SEOCaseStudy heading="Brake shop" link="brake-shop" />} />
              <Route path='/seo-for/brazilian-pastelaria' element={<SEOCaseStudy heading="Brazilian pastelaria" link="brazilian-pastelaria" />} />
              <Route path='/seo-for/brewery' element={<SEOCaseStudy heading="Brewery" link="brewery" />} />
              <Route path='/seo-for/brewing-supply-store' element={<SEOCaseStudy heading="Brewing supply store" link="brewing-supply-store" />} />
              <Route path='/seo-for/brewpub' element={<SEOCaseStudy heading="Brewpub" link="brewpub" />} />
              <Route path='/seo-for/brick-manufacturer' element={<SEOCaseStudy heading="Brick manufacturer" link="brick-manufacturer" />} />
              <Route path='/seo-for/bridal-shop' element={<SEOCaseStudy heading="Bridal shop" link="bridal-shop" />} />
              <Route path='/seo-for/bridge' element={<SEOCaseStudy heading="Bridge" link="bridge" />} />
              <Route path='/seo-for/bridge-club' element={<SEOCaseStudy heading="Bridge club" link="bridge-club" />} />
              <Route path='/seo-for/building-designer' element={<SEOCaseStudy heading="Building designer" link="building-designer" />} />
              <Route path='/seo-for/building-restoration-service' element={<SEOCaseStudy heading="Building restoration service" link="building-restoration-service" />} />
              <Route path='/seo-for/building-society' element={<SEOCaseStudy heading="Building society" link="building-society" />} />
              <Route path='/seo-for/bus-and-coach-company' element={<SEOCaseStudy heading="Bus and coach company" link="bus-and-coach-company" />} />
              <Route path='/seo-for/bus-company' element={<SEOCaseStudy heading="Bus company" link="bus-company" />} />
              <Route path='/seo-for/bus-charter' element={<SEOCaseStudy heading="Bus charter" link="bus-charter" />} />
              <Route path='/seo-for/bus-depot' element={<SEOCaseStudy heading="Bus depot" link="bus-depot" />} />
              <Route path='/seo-for/bus-ticket-agency' element={<SEOCaseStudy heading="Bus ticket agency" link="bus-ticket-agency" />} />
              <Route path='/seo-for/bus-tour-agency' element={<SEOCaseStudy heading="Bus tour agency" link="bus-tour-agency" />} />
              <Route path='/seo-for/business-administration-service' element={<SEOCaseStudy heading="Business administration service" link="business-administration-service" />} />
              <Route path='/seo-for/business-attorney' element={<SEOCaseStudy heading="Business attorney" link="business-attorney" />} />
              <Route path='/seo-for/business-banking-service' element={<SEOCaseStudy heading="Business banking service" link="business-banking-service" />} />
              <Route path='/seo-for/business-broker' element={<SEOCaseStudy heading="Business broker" link="business-broker" />} />
              <Route path='/seo-for/business-center' element={<SEOCaseStudy heading="Business center" link="business-center" />} />
              <Route path='/seo-for/business-park' element={<SEOCaseStudy heading="Business park" link="business-park" />} />
              <Route path='/seo-for/business-school' element={<SEOCaseStudy heading="Business school" link="business-school" />} />
              <Route path='/seo-for/cabinet-maker' element={<SEOCaseStudy heading="Cabinet maker" link="cabinet-maker" />} />
              <Route path='/seo-for/cabinet-store' element={<SEOCaseStudy heading="Cabinet store" link="cabinet-store" />} />
              <Route path='/seo-for/call-center' element={<SEOCaseStudy heading="Call center" link="call-center" />} />
              <Route path='/seo-for/call-shop' element={<SEOCaseStudy heading="Call shop" link="call-shop" />} />
              <Route path='/seo-for/camera-repair-shop' element={<SEOCaseStudy heading="Camera repair shop" link="camera-repair-shop" />} />
              <Route path='/seo-for/camera-store' element={<SEOCaseStudy heading="Camera store" link="camera-store" />} />
              <Route path='/seo-for/camper-shell-supplier' element={<SEOCaseStudy heading="Camper shell supplier" link="camper-shell-supplier" />} />
              <Route path='/seo-for/campground' element={<SEOCaseStudy heading="Campground" link="campground" />} />
              <Route path='/seo-for/camping-cabin' element={<SEOCaseStudy heading="Camping cabin" link="camping-cabin" />} />
              <Route path='/seo-for/camping-farm' element={<SEOCaseStudy heading="Camping farm" link="camping-farm" />} />
              <Route path='/seo-for/camping-store' element={<SEOCaseStudy heading="Camping store" link="camping-store" />} />
              <Route path='/seo-for/cancer-treatment-center' element={<SEOCaseStudy heading="Cancer treatment center" link="cancer-treatment-center" />} />
              <Route path='/seo-for/candle-store' element={<SEOCaseStudy heading="Candle store" link="candle-store" />} />
              <Route path='/seo-for/candy-store' element={<SEOCaseStudy heading="Candy store" link="candy-store" />} />
              <Route path='/seo-for/cane-furniture-store' element={<SEOCaseStudy heading="Cane furniture store" link="cane-furniture-store" />} />
              <Route path='/seo-for/cannery' element={<SEOCaseStudy heading="cannery" link="cannery" />} />
              <Route path='/seo-for/castle' element={<SEOCaseStudy heading="Castle" link="castle" />} />
              <Route path='/seo-for/car-accessories-store' element={<SEOCaseStudy heading="Car accessories store" link="car-accessories-store" />} />
              <Route path='/seo-for/car-alarm-supplier' element={<SEOCaseStudy heading="Car alarm supplier" link="car-alarm-supplier" />} />
              <Route path='/seo-for/car-battery-store' element={<SEOCaseStudy heading="Car battery store" link="car-battery-store" />} />
              <Route path='/seo-for/car-dealer' element={<SEOCaseStudy heading="Car dealer" link="car-dealer" />} />
              <Route path='/seo-for/car-detailing-service' element={<SEOCaseStudy heading="Car detailing service" link="car-detailing-service" />} />
              <Route path='/seo-for/car-factory' element={<SEOCaseStudy heading="Car factory" link="car-factory" />} />
              <Route path='/seo-for/car-finance-&-loan-company' element={<SEOCaseStudy heading="Car finance and loan company" link="car-finance-&-loan-company" />} />
              <Route path='/seo-for/car-inspection-station' element={<SEOCaseStudy heading="Car inspection station" link="car-inspection-station" />} />
              <Route path='/seo-for/car-leasing-service' element={<SEOCaseStudy heading="Car leasing service" link="car-leasing-service" />} />
              <Route path='/seo-for/car-manufacturer' element={<SEOCaseStudy heading="Car manufacturer" link="car-manufacturer" />} />
              <Route path='/seo-for/car-racing-track' element={<SEOCaseStudy heading="Car racing track" link="car-racing-track" />} />
              <Route path='/seo-for/car-rental-agency' element={<SEOCaseStudy heading="Car rental agency" link="car-rental-agency" />} />
              <Route path='/seo-for/car-repair-&-maintenance-service' element={<SEOCaseStudy heading="Car repair and maintenance service" link="car-repair-&-maintenance-service" />} />
              <Route path='/seo-for/car-security-system-installer' element={<SEOCaseStudy heading="Car security system installer" link="car-security-system-installer" />} />
              <Route path='/seo-for/car-sharing-location' element={<SEOCaseStudy heading="Car sharing location" link="car-sharing-location" />} />
              <Route path='/seo-for/car-stereo-store' element={<SEOCaseStudy heading="Car stereo store" link="car-stereo-store" />} />
              <Route path='/seo-for/car-wash' element={<SEOCaseStudy heading="Car wash" link="car-wash" />} />
              <Route path='/seo-for/carpenter' element={<SEOCaseStudy heading="Carpenter" link="carpenter" />} />
              <Route path='/seo-for/carpet-cleaning-service' element={<SEOCaseStudy heading="Carpet cleaning service" link="carpet-cleaning-service" />} />
              <Route path='/seo-for/carpet-installer' element={<SEOCaseStudy heading="Carpet installer" link="carpet-installer" />} />
              <Route path='/seo-for/carpet-manufacturer' element={<SEOCaseStudy heading="Carpet manufacturer" link="carpet-manufacturer" />} />
              <Route path='/seo-for/carpet-store' element={<SEOCaseStudy heading="Carpet store" link="carpet-store" />} />
              <Route path='/seo-for/caterer' element={<SEOCaseStudy heading="Caterer" link="caterer" />} />
              <Route path='/seo-for/catering-food-&-drink-supplier' element={<SEOCaseStudy heading="Catering food and drink supplier" link="catering-food-&-drink-supplier" />} />
              <Route path='/seo-for/cathedral' element={<SEOCaseStudy heading="Cathedral" link="cathedral" />} />
              <Route path='/seo-for/catholic-cathedral' element={<SEOCaseStudy heading="Catholic cathedral" link="catholic-cathedral" />} />
              <Route path='/seo-for/catholic-church' element={<SEOCaseStudy heading="Catholic church" link="catholic-church" />} />
              <Route path='/seo-for/catholic-school' element={<SEOCaseStudy heading="Catholic school" link="catholic-school" />} />
              <Route path='/seo-for/cbse-school' element={<SEOCaseStudy heading="CBSE school" link="cbse-school" />} />
              <Route path='/seo-for/cd-store' element={<SEOCaseStudy heading="CD store" link="cd-store" />} />
              <Route path='/seo-for/ceiling-supplier' element={<SEOCaseStudy heading="Ceiling supplier" link="ceiling-supplier" />} />
              <Route path='/seo-for/cell-phone-accessory-store' element={<SEOCaseStudy heading="Cell phone accessory store" link="cell-phone-accessory-store" />} />
              <Route path='/seo-for/cell-phone-charging-station' element={<SEOCaseStudy heading="Cell phone charging station" link="cell-phone-charging-station" />} />
              <Route path='/seo-for/cell-phone-store' element={<SEOCaseStudy heading="Cell phone store" link="cell-phone-store" />} />
              <Route path='/seo-for/central-bank' element={<SEOCaseStudy heading="Central bank" link="central-bank" />} />
              <Route path='/seo-for/chapel' element={<SEOCaseStudy heading="Chapel" link="chapel" />} />
              <Route path='/seo-for/chartered-accountant' element={<SEOCaseStudy heading="Chartered accountant" link="chartered-accountant" />} />
              <Route path='/seo-for/chartered-surveyor' element={<SEOCaseStudy heading="Chartered surveyor" link="chartered-surveyor" />} />
              <Route path='/seo-for/chemical-engineering-service' element={<SEOCaseStudy heading="Chemical engineering service" link="chemical-engineering-service" />} />
              <Route path='/seo-for/chemical-exporter' element={<SEOCaseStudy heading="Chemical exporter" link="chemical-exporter" />} />
              <Route path='/seo-for/chemical-manufacturer' element={<SEOCaseStudy heading="Chemical manufacturer" link="chemical-manufacturer" />} />
              <Route path='/seo-for/chemical-plant' element={<SEOCaseStudy heading="Chemical plant" link="chemical-plant" />} />
              <Route path='/seo-for/chemical-wholesaler' element={<SEOCaseStudy heading="Chemical wholesaler" link="chemical-wholesaler" />} />
              <Route path='/seo-for/chemistry-lab' element={<SEOCaseStudy heading="Chemistry lab" link="chemistry-lab" />} />
              <Route path='/seo-for/chess-&-card-club' element={<SEOCaseStudy heading="Chess and card club" link="chess-&-card-club" />} />
              <Route path='/seo-for/chess-club' element={<SEOCaseStudy heading="Chess club" link="chess-club" />} />
              <Route path='/seo-for/chess-instructor' element={<SEOCaseStudy heading="Chess instructor" link="chess-instructor" />} />
              <Route path='/seo-for/childrens-camp' element={<SEOCaseStudy heading="Children’s camp" link="childrens-camp" />} />
              <Route path='/seo-for/childrens-clothing-store' element={<SEOCaseStudy heading="Children’s clothing store" link="childrens-clothing-store" />} />
              <Route path='/seo-for/childrens-farm' element={<SEOCaseStudy heading="Children’s farm" link="childrens-farm" />} />
              <Route path='/seo-for/childrens-cafe' element={<SEOCaseStudy heading="Childrens cafe" link="childrens-cafe" />} />
              <Route path='/seo-for/childrens-club' element={<SEOCaseStudy heading="Childrens club" link="childrens-club" />} />
              <Route path='/seo-for/childrens-library' element={<SEOCaseStudy heading="Childrens library" link="childrens-library" />} />
              <Route path='/seo-for/childrens-theater' element={<SEOCaseStudy heading="Childrens theater" link="childrens-theater" />} />
              <Route path='/seo-for/chimney-services' element={<SEOCaseStudy heading="Chimney services" link="chimney-services" />} />
              <Route path='/seo-for/chocolate-shop' element={<SEOCaseStudy heading="Chocolate shop" link="chocolate-shop" />} />
              <Route path='/seo-for/chimney-sweep' element={<SEOCaseStudy heading="Chimney sweep" link="chimney-sweep" />} />
              <Route path='/seo-for/chinaware-store' element={<SEOCaseStudy heading="Chinaware store" link="chinaware-store" />} />
              <Route path='/seo-for/chinese-language-instructor' element={<SEOCaseStudy heading="Chinese language instructor" link="chinese-language-instructor" />} />
              <Route path='/seo-for/chinese-language-school' element={<SEOCaseStudy heading="Chinese language school" link="chinese-language-school" />} />
              <Route path='/seo-for/chinese-medicine-clinic' element={<SEOCaseStudy heading="Chinese medicine clinic" link="chinese-medicine-clinic" />} />
              <Route path='/seo-for/chinese-medicine-store' element={<SEOCaseStudy heading="Chinese medicine store" link="chinese-medicine-store" />} />
              <Route path='/seo-for/chinese-supermarket' element={<SEOCaseStudy heading="Chinese supermarket" link="chinese-supermarket" />} />
              <Route path='/seo-for/chinese-takeaway' element={<SEOCaseStudy heading="Chinese takeaway" link="chinese-takeaway" />} />
              <Route path='/seo-for/chinese-tea-house' element={<SEOCaseStudy heading="Chinese tea house" link="chinese-tea-house" />} />
              <Route path='/seo-for/chocolate-artisan' element={<SEOCaseStudy heading="Chocolate artisan" link="chocolate-artisan" />} />
              <Route path='/seo-for/chocolate-cafe' element={<SEOCaseStudy heading="Chocolate cafe" link="chocolate-cafe" />} />
              <Route path='/seo-for/chocolate-factory' element={<SEOCaseStudy heading="Chocolate factory" link="chocolate-factory" />} />
              <Route path='/seo-for/cricket-club' element={<SEOCaseStudy heading="Cricket club" link="cricket-club" />} />
              <Route path='/seo-for/cricket-ground' element={<SEOCaseStudy heading="Cricket ground" link="cricket-ground" />} />
              <Route path='/seo-for/cricket-shop' element={<SEOCaseStudy heading="Cricket shop" link="cricket-shop" />} />
              <Route path='/seo-for/Crypto-atm' element={<SEOCaseStudy heading="Crypto ATM" link="Crypto-atm" />} />
              <Route path='/seo-for/cultural-association' element={<SEOCaseStudy heading="Cultural association" link="cultural-association" />} />
              <Route path='/seo-for/cultural-center' element={<SEOCaseStudy heading="Cultural center" link="cultural-center" />} />
              <Route path='/seo-for/cultural-landmark' element={<SEOCaseStudy heading="Cultural landmark" link="cultural-landmark" />} />
              <Route path='/seo-for/curling-club' element={<SEOCaseStudy heading="Curling club" link="curling-club" />} />
              <Route path='/seo-for/curling-hall' element={<SEOCaseStudy heading="Curling hall" link="curling-hall" />} />
              <Route path='/seo-for/custom-confiscated-goods-store' element={<SEOCaseStudy heading="Custom confiscated goods store" link="custom-confiscated-goods-store" />} />
              <Route path='/seo-for/custom-home-builder' element={<SEOCaseStudy heading="Custom home builder" link="custom-home-builder" />} />
              <Route path='/seo-for/custom-label-printer' element={<SEOCaseStudy heading="Custom label printer" link="custom-label-printer" />} />
              <Route path='/seo-for/custom-t-shirt-store' element={<SEOCaseStudy heading="Custom t-shirt store" link="custom-t-shirt-store" />} />
              <Route path='/seo-for/custom-tailor' element={<SEOCaseStudy heading="Custom tailor" link="custom-tailor" />} />
              <Route path='/seo-for/customs-broker' element={<SEOCaseStudy heading="Customs broker" link="customs-broker" />} />
              <Route path='/seo-for/customs-consultant' element={<SEOCaseStudy heading="Customs consultant" link="customs-consultant" />} />
              <Route path='/seo-for/customs-department' element={<SEOCaseStudy heading="Customs department" link="customs-department" />} />
              <Route path='/seo-for/customs-office' element={<SEOCaseStudy heading="Customs office" link="customs-office" />} />
              <Route path='/seo-for/customs-warehouse' element={<SEOCaseStudy heading="Customs warehouse" link="customs-warehouse" />} />
              <Route path='/seo-for/cycle-rickshaw-stand' element={<SEOCaseStudy heading="Cycle rickshaw stand" link="cycle-rickshaw-stand" />} />
              <Route path='/seo-for/cycling-park' element={<SEOCaseStudy heading="Cycling park" link="cycling-park" />} />
              <Route path='/seo-for/dairy-farm-equipment-supplier' element={<SEOCaseStudy heading="Dairy farm equipment supplier" link="dairy-farm-equipment-supplier" />} />
              <Route path='/seo-for/dairy-store' element={<SEOCaseStudy heading="Dairy store" link="dairy-store" />} />
              <Route path='/seo-for/dairy-supplier' element={<SEOCaseStudy heading="Dairy supplier" link="dairy-supplier" />} />
              <Route path='/seo-for/dance-club' element={<SEOCaseStudy heading="Dance club" link="dance-club" />} />
              <Route path='/seo-for/dance-company' element={<SEOCaseStudy heading="Dance company" link="dance-company" />} />
              <Route path='/seo-for/dance-conservatory' element={<SEOCaseStudy heading="Dance conservatory" link="dance-conservatory" />} />
              <Route path='/seo-for/dance-hall' element={<SEOCaseStudy heading="Dance hall" link="dance-hall" />} />
              <Route path='/seo-for/dance-pavillion' element={<SEOCaseStudy heading="Dance pavillion" link="dance-pavillion" />} />
              <Route path='/seo-for/dance-school' element={<SEOCaseStudy heading="Dance school" link="dance-school" />} />
              <Route path='/seo-for/dance-store' element={<SEOCaseStudy heading="Dance store" link="dance-store" />} />
              <Route path='/seo-for/dart-supply-store' element={<SEOCaseStudy heading="Dart supply store" link="dart-supply-store" />} />
              <Route path='/seo-for/data-entry-service' element={<SEOCaseStudy heading="Data entry service" link="data-entry-service" />} />
              <Route path='/seo-for/data-recovery-service' element={<SEOCaseStudy heading="Data recovery service" link="data-recovery-service" />} />
              <Route path='/seo-for/dating-service' element={<SEOCaseStudy heading="Dating service" link="dating-service" />} />
              <Route path='/seo-for/day-care-center' element={<SEOCaseStudy heading="Day care center" link="day-care-center" />} />
              <Route path='/seo-for/day-spa' element={<SEOCaseStudy heading="Day spa" link="day-spa" />} />
              <Route path='/seo-for/deaf-church' element={<SEOCaseStudy heading="Deaf church" link="deaf-church" />} />
              <Route path='/seo-for/deaf-service' element={<SEOCaseStudy heading="Deaf service" link="deaf-service" />} />
              <Route path='/seo-for/debris-removal-service' element={<SEOCaseStudy heading="Debris removal service" link="debris-removal-service" />} />
              <Route path='/seo-for/debt-collecting' element={<SEOCaseStudy heading="Debt collecting" link="debt-collecting" />} />
              <Route path='/seo-for/debt-collection-agency' element={<SEOCaseStudy heading="Debt collection agency" link="debt-collection-agency" />} />
              <Route path='/seo-for/deli' element={<SEOCaseStudy heading="Deli" link="deli" />} />
              <Route path='/seo-for/delivery-service' element={<SEOCaseStudy heading="Delivery service" link="delivery-service" />} />
              <Route path='/seo-for/dental-clinic' element={<SEOCaseStudy heading="Dental clinic" link="dental-clinic" />} />
              <Route path='/seo-for/dental-hygienist' element={<SEOCaseStudy heading="Dental hygienist" link="dental-hygienist" />} />
              <Route path='/seo-for/dental-implants-provider' element={<SEOCaseStudy heading="Dental implants provider" link="dental-implants-provider" />} />
              <Route path='/seo-for/dental-insurance-agency' element={<SEOCaseStudy heading="Dental insurance agency" link="dental-insurance-agency" />} />
              <Route path='/seo-for/dental-laboratory' element={<SEOCaseStudy heading="Dental laboratory" link="dental-laboratory" />} />
              <Route path='/seo-for/dental-radiology' element={<SEOCaseStudy heading="Dental radiology" link="dental-radiology" />} />
              <Route path='/seo-for/dental-school' element={<SEOCaseStudy heading="Dental school" link="dental-school" />} />
              <Route path='/seo-for/dental-supply-store' element={<SEOCaseStudy heading="Dental supply store" link="dental-supply-store" />} />
              <Route path='/seo-for/dentist' element={<SEOCaseStudy heading="Dentist" link="dentist" />} />
              <Route path='/seo-for/denture-care-center' element={<SEOCaseStudy heading="Denture care center" link="denture-care-center" />} />
              <Route path='/seo-for/department-for-regional-development' element={<SEOCaseStudy heading="Department for Regional Development" link="department-for-regional-development" />} />
              <Route path='/seo-for/department-of-education' element={<SEOCaseStudy heading="Department of education" link="department-of-education" />} />
              <Route path='/seo-for/department-of-finance' element={<SEOCaseStudy heading="Department of finance" link="department-of-finance" />} />
              <Route path='/seo-for/department-of-housing' element={<SEOCaseStudy heading="Department of housing" link="department-of-housing" />} />
              <Route path='/seo-for/department-of-social-services' element={<SEOCaseStudy heading="Department of Social Services" link="department-of-social-services" />} />
              <Route path='/seo-for/department-of-state-treasure' element={<SEOCaseStudy heading="Department of State Treasure" link="department-of-state-treasure" />} />
              <Route path='/seo-for/department-of-transportation' element={<SEOCaseStudy heading="Department of Transportation" link="department-of-transportation" />} />
              <Route path='/seo-for/department-store' element={<SEOCaseStudy heading="Department store" link="department-store" />} />
              <Route path='/seo-for/dept-of-city-treasure' element={<SEOCaseStudy heading="Dept of City Treasure" link="dept-of-city-treasure" />} />
              <Route path='/seo-for/dermatologist' element={<SEOCaseStudy heading="Dermatologist" link="dermatologist" />} />
              <Route path='/seo-for/desalination-plant' element={<SEOCaseStudy heading="Desalination plant" link="desalination-plant" />} />
              <Route path='/seo-for/design-agency' element={<SEOCaseStudy heading="Design agency" link="design-agency" />} />
              <Route path='/seo-for/design-engineer' element={<SEOCaseStudy heading="Design engineer" link="design-engineer" />} />
              <Route path='/seo-for/design-institute' element={<SEOCaseStudy heading="Design institute" link="design-institute" />} />
              <Route path='/seo-for/dessert-shop' element={<SEOCaseStudy heading="Dessert shop" link="dessert-shop" />} />
              <Route path='/seo-for/detention-center' element={<SEOCaseStudy heading="Detention center" link="detention-center" />} />
              <Route path='/seo-for/dhaba' element={<SEOCaseStudy heading="Dhaba" link="dhaba" />} />
              <Route path='/seo-for/diabetes-equipment-supplier' element={<SEOCaseStudy heading="Diabetes equipment supplier" link="diabetes-equipment-supplier" />} />
              <Route path='/seo-for/diamond-dealer' element={<SEOCaseStudy heading="Diamond dealer" link="diamond-dealer" />} />
              <Route path='/seo-for/diesel-engine-dealer' element={<SEOCaseStudy heading="Diesel engine dealer" link="diesel-engine-dealer" />} />
              <Route path='/seo-for/diesel-engine-repair-service' element={<SEOCaseStudy heading="Diesel engine repair service" link="diesel-engine-repair-service" />} />
              <Route path='/seo-for/diesel-fuel-supplier' element={<SEOCaseStudy heading="Diesel fuel supplier" link="diesel-fuel-supplier" />} />
              <Route path='/seo-for/dietitian' element={<SEOCaseStudy heading="Dietitian" link="dietitian" />} />
              <Route path='/seo-for/digital-printer' element={<SEOCaseStudy heading="Digital printer" link="digital-printer" />} />
              <Route path='/seo-for/digital-printing-service' element={<SEOCaseStudy heading="Digital printing service" link="digital-printing-service" />} />
              <Route path='/seo-for/diner' element={<SEOCaseStudy heading="Diner" link="diner" />} />
              <Route path='/seo-for/dinner-theater' element={<SEOCaseStudy heading="Dinner theater" link="dinner-theater" />} />
              <Route path='/seo-for/distance-learning-center' element={<SEOCaseStudy heading="Distance learning center" link="distance-learning-center" />} />
              <Route path='/seo-for/distribution-service' element={<SEOCaseStudy heading="Distribution service" link="distribution-service" />} />
              <Route path='/seo-for/district-attorney' element={<SEOCaseStudy heading="District attorney" link="district-attorney" />} />
              <Route path='/seo-for/district-council' element={<SEOCaseStudy heading="District council" link="district-council" />} />
              <Route path='/seo-for/district-government-office' element={<SEOCaseStudy heading="District government office" link="district-government-office" />} />
              <Route path='/seo-for/district-office' element={<SEOCaseStudy heading="District office" link="district-office" />} />
              <Route path='/seo-for/dive-club' element={<SEOCaseStudy heading="Dive club" link="dive-club" />} />
              <Route path='/seo-for/dive-shop' element={<SEOCaseStudy heading="Dive shop" link="dive-shop" />} />
              <Route path='/seo-for/diving-contractor' element={<SEOCaseStudy heading="Diving contractor" link="diving-contractor" />} />
              <Route path='/seo-for/divorce-lawyer' element={<SEOCaseStudy heading="Divorce lawyer" link="divorce-lawyer" />} />
              <Route path='/seo-for/divorce-service' element={<SEOCaseStudy heading="Divorce service" link="divorce-service" />} />
              <Route path='/seo-for/dj-supply store' element={<SEOCaseStudy heading="DJ supply store" link="dj-supply store" />} />
              <Route path='/seo-for/do-it-yourself-shop' element={<SEOCaseStudy heading="Do-it-yourself shop" link="do-it-yourself-shop" />} />
              <Route path='/seo-for/dock-builder' element={<SEOCaseStudy heading="Dock builder" link="dock-builder" />} />
              <Route path='/seo-for/doctor' element={<SEOCaseStudy heading="Doctor" link="doctor" />} />
              <Route path='/seo-for/dodge-dealer' element={<SEOCaseStudy heading="Dodge dealer" link="dodge-dealer" />} />
              <Route path='/seo-for/dog-breeder' element={<SEOCaseStudy heading="Dog breeder" link="dog-breeder" />} />
              <Route path='/seo-for/dog-day-care-center' element={<SEOCaseStudy heading="Dog day care center" link="dog-day-care-center" />} />
              <Route path='/seo-for/dog-park' element={<SEOCaseStudy heading="Dog park" link="dog-park" />} />
              <Route path='/seo-for/dog-trainer' element={<SEOCaseStudy heading="Dog trainer" link="dog-trainer" />} />
              <Route path='/seo-for/dog-walker' element={<SEOCaseStudy heading="Dog walker" link="dog-walker" />} />
              <Route path='/seo-for/dollar-store' element={<SEOCaseStudy heading="Dollar store" link="dollar-store" />} />
              <Route path='/seo-for/domestic-abuse-treatment-center' element={<SEOCaseStudy heading="Domestic abuse treatment center" link="domestic-abuse-treatment-center" />} />
              <Route path='/seo-for/domestic-airport' element={<SEOCaseStudy heading="Domestic airport" link="domestic-airport" />} />
              <Route path='/seo-for/door-manufacturer' element={<SEOCaseStudy heading="Door manufacturer" link="door-manufacturer" />} />
              <Route path='/seo-for/door-shop' element={<SEOCaseStudy heading="Door shop" link="door-shop" />} />
              <Route path='/seo-for/door-supplier' element={<SEOCaseStudy heading="Door supplier" link="door-supplier" />} />
              <Route path='/seo-for/door-warehouse' element={<SEOCaseStudy heading="Door warehouse" link="door-warehouse" />} />
              <Route path='/seo-for/doula' element={<SEOCaseStudy heading="Doula" link="doula" />} />
              <Route path='/seo-for/drafting-equipment-supplier' element={<SEOCaseStudy heading="Drafting equipment supplier" link="drafting-equipment-supplier" />} />
              <Route path='/seo-for/drafting-service' element={<SEOCaseStudy heading="Drafting service" link="drafting-service" />} />
              <Route path='/seo-for/drama-school' element={<SEOCaseStudy heading="Drama school" link="drama-school" />} />
              <Route path='/seo-for/drama-theater' element={<SEOCaseStudy heading="Drama theater" link="drama-theater" />} />
              <Route path='/seo-for/drawing-lessons' element={<SEOCaseStudy heading="Drawing lessons" link="drawing-lessons" />} />
              <Route path='/seo-for/dress-store' element={<SEOCaseStudy heading="Dress store" link="dress-stores" />} />
              <Route path='/seo-for/dressmaker' element={<SEOCaseStudy heading="Dressmaker" link="dressmaker" />} />
              <Route path='/seo-for/dried-flower-shop' element={<SEOCaseStudy heading="Dried flower shop" link="dried-flower-shop" />} />
              <Route path='/seo-for/drilling-contractor' element={<SEOCaseStudy heading="Drilling contractor" link="drilling-contractor" />} />
              <Route path='/seo-for/drilling-equipment-supplier' element={<SEOCaseStudy heading="Drilling equipment supplier" link="drilling-equipment-supplier" />} />
              <Route path='/seo-for/drivers-license-training-school' element={<SEOCaseStudy heading="Drivers license training school" link="drivers-license-training-school" />} />
              <Route path='/seo-for/driving-school' element={<SEOCaseStudy heading="Driving school" link="driving-school" />} />
              <Route path='/seo-for/driving-test-center' element={<SEOCaseStudy heading="Driving test center" link="driving-test-center" />} />
              <Route path='/seo-for/drug-store' element={<SEOCaseStudy heading="Drug store" link="drug-store" />} />
              <Route path='/seo-for/drug-testing-service' element={<SEOCaseStudy heading="Drug testing service" link="drug-testing-service" />} />
              <Route path='/seo-for/dry-cleaner' element={<SEOCaseStudy heading="Dry cleaner" link="dry-cleaner" />} />
              <Route path='/seo-for/dryer-vent-cleaning-service' element={<SEOCaseStudy heading="Dryer vent cleaning service" link="dryer-vent-cleaning-service" />} />
              <Route path='/seo-for/dump-truck-dealer' element={<SEOCaseStudy heading="Dump truck dealer" link="dump-truck-dealer" />} />
              <Route path='/seo-for/dvd-store' element={<SEOCaseStudy heading="DVD store" link="dvd-store" />} />
              <Route path='/seo-for/dye-store' element={<SEOCaseStudy heading="Dye store" link="dye-store" />} />
              <Route path='/seo-for/e-commerce-agency' element={<SEOCaseStudy heading="E commerce agency" link="e-commerce-agency" />} />
              <Route path='/seo-for/e-commerce-service' element={<SEOCaseStudy heading="E-commerce service" link="e-commerce-service" />} />
              <Route path='/seo-for/ear-piercing-service' element={<SEOCaseStudy heading="Ear piercing service" link="ear-piercing-service" />} />
              <Route path='/seo-for/earth-works-company' element={<SEOCaseStudy heading="Earth works company" link="earth-works-company" />} />
              <Route path='/seo-for/eastern-orthodox-church' element={<SEOCaseStudy heading="Eastern Orthodox Church" link="eastern-orthodox-church" />} />
              <Route path='/seo-for/ecological-park' element={<SEOCaseStudy heading="Ecological park" link="ecological-park" />} />
              <Route path='/seo-for/ecologists-association' element={<SEOCaseStudy heading="Ecologists association" link="ecologists-association" />} />
              <Route path='/seo-for/economic-consultant' element={<SEOCaseStudy heading="Economic consultant" link="economic-consultant" />} />
              <Route path='/seo-for/economic-development-agency' element={<SEOCaseStudy heading="Economic development agency" link="economic-development-agency" />} />
              <Route path='/seo-for/education-center' element={<SEOCaseStudy heading="Education center" link="education-center" />} />
              <Route path='/seo-for/educational-consultant' element={<SEOCaseStudy heading="Educational consultant" link="educational-consultant" />} />
              <Route path='/seo-for/educational-institution' element={<SEOCaseStudy heading="Educational institution" link="educational-institution" />} />
              <Route path='/seo-for/educational-supply-store' element={<SEOCaseStudy heading="Educational supply store" link="educational-supply-store" />} />
              <Route path='/seo-for/educational-testing-service' element={<SEOCaseStudy heading="Educational testing service" link="educational-testing-service" />} />
              <Route path='/seo-for/elder-law-attorney' element={<SEOCaseStudy heading="Elder law attorney" link="elder-law-attorney" />} />
              <Route path='/seo-for/electric-bicycle-store' element={<SEOCaseStudy heading="Electric bicycle store" link="electric-bicycle-store" />} />
              <Route path='/seo-for/electric-generator-shop' element={<SEOCaseStudy heading="Electric generator shop" link="electric-generator-shop" />} />
              <Route path='/seo-for/electric-motor-repair-shop' element={<SEOCaseStudy heading="Electric motor repair shop" link="electric-motor-repair-shop" />} />
              <Route path='/seo-for/electric-motor-scooter-dealer' element={<SEOCaseStudy heading="Electric motor scooter dealer" link="electric-motor-scooter-dealer" />} />
              <Route path='/seo-for/electric-motor-vehicle-dealer' element={<SEOCaseStudy heading="Electric motor vehicle dealer" link="electric-motor-vehicle-dealer" />} />
              <Route path='/seo-for/electric-motorcycle-dealer' element={<SEOCaseStudy heading="Electric motorcycle dealer" link="electric-motorcycle-dealer" />} />
              <Route path='/seo-for/electric-utility-company' element={<SEOCaseStudy heading="Electric utility company" link="electric-utility-company" />} />
              <Route path='/seo-for/electric-vehicle-charging-station' element={<SEOCaseStudy heading="Electric vehicle charging station" link="electric-vehicle-charging-station" />} />
              <Route path='/seo-for/electric-vehicle-charging-station-contractor' element={<SEOCaseStudy heading="Electric vehicle charging station contractor" link="electric-vehicle-charging-station-contractor" />} />
              <Route path='/seo-for/electrical-engineer' element={<SEOCaseStudy heading="Electrical engineer" link="electrical-engineer" />} />
              <Route path='/seo-for/electrical-equipment-manufacturer' element={<SEOCaseStudy heading="Electrical equipment manufacturer" link="electrical-equipment-manufacturer" />} />
              <Route path='/seo-for/electrical-equipment-supplier' element={<SEOCaseStudy heading="Electrical equipment supplier" link="electrical-equipment-supplier" />} />
              <Route path='/seo-for/electrical-installation-service' element={<SEOCaseStudy heading="Electrical installation service" link="electrical-installation-service" />} />
              <Route path='/seo-for/electrical-products-wholesaler' element={<SEOCaseStudy heading="Electrical products wholesaler" link="electrical-products-wholesaler" />} />
              <Route path='/seo-for/electrical-repair-shop' element={<SEOCaseStudy heading="Electrical repair shop" link="electrical-repair-shop" />} />
              <Route path='/seo-for/electrical-supply-store' element={<SEOCaseStudy heading="Electrical supply store" link="electrical-supply-store" />} />
              <Route path='/seo-for/electrician' element={<SEOCaseStudy heading="Electrician" link="electrician" />} />
              <Route path='/seo-for/electronic-engineering-service' element={<SEOCaseStudy heading="Electronic engineering service" link="electronic-engineering-service" />} />
              <Route path='/seo-for/electronic-parts-supplier' element={<SEOCaseStudy heading="Electronic parts supplier" link="electronic-parts-supplier" />} />
              <Route path='/seo-for/electronics-accessories-wholesaler' element={<SEOCaseStudy heading="Electronics accessories wholesaler" link="electronics-accessories-wholesaler" />} />
              <Route path='/seo-for/electronics-exporter' element={<SEOCaseStudy heading="Electronics exporter" link="electronics-exporter" />} />
              <Route path='/seo-for/electronics-hire-shop' element={<SEOCaseStudy heading="Electronics hire shop" link="electronics-hire-shop" />} />
              <Route path='/seo-for/electronics-manufacturer' element={<SEOCaseStudy heading="Electronics manufacturer" link="electronics-manufacturer" />} />
              <Route path='/seo-for/electronics-store' element={<SEOCaseStudy heading="Electronics store" link="electronics-store" />} />
              <Route path='/seo-for/electronics-vending-machine' element={<SEOCaseStudy heading="Electronics vending machine" link="electronics-vending-machine" />} />
              <Route path='/seo-for/electronics-wholesaler' element={<SEOCaseStudy heading="Electronics wholesaler" link="electronics-wholesaler" />} />
              <Route path='/seo-for/elementary-school' element={<SEOCaseStudy heading="Elementary school" link="elementary-school" />} />
              <Route path='/seo-for/elevator-manufacturer' element={<SEOCaseStudy heading="Elevator manufacturer" link="elevator-manufacturer" />} />
              <Route path='/seo-for/elevator-service' element={<SEOCaseStudy heading="Elevator service" link="elevator-service" />} />
              <Route path='/seo-for/emergency-care-physician' element={<SEOCaseStudy heading="Emergency care physician" link="emergency-care-physician" />} />
              <Route path='/seo-for/emergency-care-service' element={<SEOCaseStudy heading="Emergency care service" link="emergency-care-service" />} />
              <Route path='/seo-for/emergency-dental-service' element={<SEOCaseStudy heading="Emergency dental service" link="emergency-dental-service" />} />
              <Route path='/seo-for/emergency-locksmith-service' element={<SEOCaseStudy heading="Emergency locksmith service" link="emergency-locksmith-service" />} />
              <Route path='/seo-for/emergency-management-ministry' element={<SEOCaseStudy heading="Emergency management ministry" link="emergency-management-ministry" />} />
              <Route path='/seo-for/emergency-room' element={<SEOCaseStudy heading="Emergency room" link="emergency-room" />} />
              <Route path='/seo-for/emergency-training' element={<SEOCaseStudy heading="Emergency training school" link="emergency-training" />} />
              <Route path='/seo-for/emergency-training-school' element={<SEOCaseStudy heading="Emergency training school" link="emergency-training-school" />} />
              <Route path='/seo-for/emergency-veterinarian-service' element={<SEOCaseStudy heading="Emergency veterinarian service" link="emergency-veterinarian-service" />} />
              <Route path='/seo-for/employment-agency' element={<SEOCaseStudy heading="Employment agency" link="employment-agency" />} />
              <Route path='/seo-for/employment-attorney' element={<SEOCaseStudy heading="Employment attorney" link="employment-attorney" />} />
              <Route path='/seo-for/employment-center' element={<SEOCaseStudy heading="Employment center" link="employment-center" />} />
              <Route path='/seo-for/endodontist' element={<SEOCaseStudy heading="Endodontist" link="endodontist" />} />
              <Route path='/seo-for/endoscopist' element={<SEOCaseStudy heading="Endoscopist" link="endoscopist" />} />
              <Route path='/seo-for/energy-equipment-&-solutions' element={<SEOCaseStudy heading="Energy equipment and solutions" link="energy-equipment-&-solutions" />} />
              <Route path='/seo-for/engine-rebuilding-service' element={<SEOCaseStudy heading="Engine rebuilding service" link="engine-rebuilding-service" />} />
              <Route path='/seo-for/engineer' element={<SEOCaseStudy heading="Engineer" link="engineer" />} />
              <Route path='/seo-for/engineering-consultant' element={<SEOCaseStudy heading="Engineering consultant" link="engineering-consultant" />} />
              <Route path='/seo-for/engineering-school' element={<SEOCaseStudy heading="Engineering school" link="engineering-school" />} />
              <Route path='/seo-for/english-language-camp' element={<SEOCaseStudy heading="English language camp" link="english-language-camp" />} />
              <Route path='/seo-for/english-language-instructor' element={<SEOCaseStudy heading="English language instructor" link="english-language-instructor" />} />
              <Route path='/seo-for/english-language-school' element={<SEOCaseStudy heading="English language school" link="english-language-school" />} />
              <Route path='/seo-for/entertainer' element={<SEOCaseStudy heading="Entertainer" link="entertainer" />} />
              <Route path='/seo-for/entertainment-agency' element={<SEOCaseStudy heading="Entertainment agency" link="entertainment-agency" />} />
              <Route path='/seo-for/envelope-supplier' element={<SEOCaseStudy heading="Envelope supplier" link="envelope-supplier" />} />
              <Route path='/seo-for/environment-office' element={<SEOCaseStudy heading="Environment office" link="environment-office" />} />
              <Route path='/seo-for/environment-renewable-natural-resources' element={<SEOCaseStudy heading="Environment renewable natural resources" link="environment-renewable-natural-resources" />} />
              <Route path='/seo-for/environment-office' element={<SEOCaseStudy heading="Environment office" link="environment-office" />} />
              <Route path='/seo-for/environmental-consultant' element={<SEOCaseStudy heading="Environmental consultant" link="environmental-consultant" />} />
              <Route path='/seo-for/environmental-engineer' element={<SEOCaseStudy heading="Environmental engineer" link="environmental-engineer" />} />
              <Route path='/seo-for/environmental-organization' element={<SEOCaseStudy heading="Environmental organization" link="environmental-organization" />} />
              <Route path='/seo-for/environment-renewable-natural-resources' element={<SEOCaseStudy heading="Environment renewable natural resources" link="environment-renewable-natural-resources" />} />
              <Route path='/seo-for/episcopal-church' element={<SEOCaseStudy heading="Episcopal church" link="episcopal-church" />} />
              <Route path='/seo-for/equestrian-club' element={<SEOCaseStudy heading="Equestrian club" link="equestrian-club" />} />
              <Route path='/seo-for/equestrian-facility' element={<SEOCaseStudy heading="Equestrian facility" link="equestrian-facility" />} />
              <Route path='/seo-for/equestrian-store' element={<SEOCaseStudy heading="Equestrian store" link="equestrian-store" />} />
              <Route path='/seo-for/equipment-exporter' element={<SEOCaseStudy heading="Equipment exporter" link="equipment-exporter" />} />
              <Route path='/seo-for/equipment-importer' element={<SEOCaseStudy heading="Equipment importer" link="equipment-importer" />} />
              <Route path='/seo-for/equipment-rental-agency' element={<SEOCaseStudy heading="Equipment rental agency" link="equipment-rental-agency" />} />
              <Route path='/seo-for/equipment-supplier' element={<SEOCaseStudy heading="Equipment supplier" link="equipment-supplier" />} />
              <Route path='/seo-for/erotic-massage' element={<SEOCaseStudy heading="Erotic massage" link="erotic-massage" />} />
              <Route path='/seo-for/escape-room-center' element={<SEOCaseStudy heading="Escape room center" link="escape-room-center" />} />
              <Route path='/seo-for/escrow-service' element={<SEOCaseStudy heading="Escrow service" link="escrow-service" />} />
              <Route path='/seo-for/espresso-bar' element={<SEOCaseStudy heading="Espresso bar" link="espresso-bar" />} />
              <Route path='/seo-for/estate-appraiser' element={<SEOCaseStudy heading="Estate appraiser" link="estate-appraiser" />} />
              <Route path='/seo-for/estate-liquidator' element={<SEOCaseStudy heading="Estate liquidator" link="estate-liquidator" />} />
              <Route path='/seo-for/estate-litigation-attorney' element={<SEOCaseStudy heading="Estate litigation attorney" link="estate-litigation-attorney" />} />
              <Route path='/seo-for/estate-planning-attorney' element={<SEOCaseStudy heading="Estate planning attorney" link="estate-planning-attorney" />} />
              <Route path='/seo-for/ethnographic-museum' element={<SEOCaseStudy heading="Ethnographic museum" link="ethnographic-museum" />} />
              <Route path='/seo-for/evangelical-church' element={<SEOCaseStudy heading="Evangelical church" link="evangelical-church" />} />
              <Route path='/seo-for/evening-school' element={<SEOCaseStudy heading="Evening school" link="evening-school" />} />
              <Route path='/seo-for/event-management-company' element={<SEOCaseStudy heading="Event management company" link="event-management-company" />} />
              <Route path='/seo-for/event-planner' element={<SEOCaseStudy heading="Event planner" link="event-planner" />} />
              <Route path='/seo-for/event-technology-service' element={<SEOCaseStudy heading="Event technology service" link="event-technology-service" />} />
              <Route path='/seo-for/event-venue' element={<SEOCaseStudy heading="Event venue" link="event-venue" />} />
              <Route path='/seo-for/excavating-contractor' element={<SEOCaseStudy heading="Excavating contractor" link="excavating-contractor" />} />
              <Route path='/seo-for/executive-search-firm' element={<SEOCaseStudy heading="Executive search firm" link="executive-search-firm" />} />
              <Route path='/seo-for/executive-suite-rental-agency' element={<SEOCaseStudy heading="Executive suite rental agency" link="executive-suite-rental-agency" />} />
              <Route path='/seo-for/executor' element={<SEOCaseStudy heading="Executor" link="executor" />} />
              <Route path='/seo-for/exercise-equipment-store' element={<SEOCaseStudy heading="Exercise equipment store" link="exercise-equipment-store" />} />
              <Route path='/seo-for/exhibit' element={<SEOCaseStudy heading="Exhibit" link="exhibit" />} />
              <Route path='/seo-for/exhibition-&-trade-centre' element={<SEOCaseStudy heading="Exhibition and trade centre" link="exhibition-&-trade-centre" />} />
              <Route path='/seo-for/exhibition-planner' element={<SEOCaseStudy heading="exhibition-planner" link="exhibition-planner" />} />
              <Route path='/seo-for/exporter' element={<SEOCaseStudy heading="Exporter" link="exporter" />} />
              <Route path='/seo-for/extended-stay-hotel' element={<SEOCaseStudy heading="Extended stay hotel" link="extended-stay-hotel" />} />
              <Route path='/seo-for/eye-care-center' element={<SEOCaseStudy heading="Eye care center" link="eye-care-center" />} />
              <Route path='/seo-for/eyebrow-bar' element={<SEOCaseStudy heading="Eyebrow bar" link="eyebrow-bar" />} />
              <Route path='/seo-for/eyelash-salon' element={<SEOCaseStudy heading="Eyelash salon" link="eyelash-salon" />} />
              <Route path='/seo-for/fabric-product-manufacturer' element={<SEOCaseStudy heading="Fabric product manufacturer" link="eabric-product-manufacturer" />} />
              <Route path='/seo-for/fabric-wholesaler' element={<SEOCaseStudy heading="Fabric wholesaler" link="fabric-wholesaler" />} />
              <Route path='/seo-for/fabrication-engineer' element={<SEOCaseStudy heading="Fabrication engineer" link="fabrication-engineer" />} />
              <Route path='/seo-for/facial-spa' element={<SEOCaseStudy heading="Facial spa" link="facial-spa" />} />
              <Route path='/seo-for/factory-equipment-supplier' element={<SEOCaseStudy heading="Factory equipment supplier" link="factory-equipment-supplier" />} />
              <Route path='/seo-for/faculty-of-arts' element={<SEOCaseStudy heading="Faculty of arts" link="faculty-of-arts" />} />
              <Route path='/seo-for/faculty-of-chemistry' element={<SEOCaseStudy heading="Faculty of chemistry" link="faculty-of-chemistry" />} />
              <Route path='/seo-for/faculty-of-geography-&-history' element={<SEOCaseStudy heading="Faculty of geography and history" link="faculty-of-geography-&-history" />} />
              <Route path='/seo-for/faculty-of-law' element={<SEOCaseStudy heading="Faculty of law" link="faculty-of-law" />} />
              <Route path='/seo-for/faculty-of-pharmacy' element={<SEOCaseStudy heading="Faculty of pharmacy" link="faculty-of-pharmacy" />} />
              <Route path='/seo-for/faculty-of-psychology' element={<SEOCaseStudy heading="Faculty of psychology" link="faculty-of-psychology" />} />
              <Route path='/seo-for/faculty-of-science' element={<SEOCaseStudy heading="Faculty of science" link="Faculty-of-science" />} />
              <Route path='/seo-for/faculty-of-sports' element={<SEOCaseStudy heading="Faculty of sports" link="faculty-of-sports" />} />
              <Route path='/seo-for/fairground' element={<SEOCaseStudy heading="Fairground" link="fairground" />} />
              <Route path='/seo-for/family-counselor' element={<SEOCaseStudy heading="Family counselor" link="family-counselor" />} />
              <Route path='/seo-for/family-day-care-service' element={<SEOCaseStudy heading="Family day care service" link="family-day-care-service" />} />
              <Route path='/seo-for/family-law-attorney' element={<SEOCaseStudy heading="Family law attorney" link="family-law-attorney" />} />
              <Route path='/seo-for/family-planning-center' element={<SEOCaseStudy heading="Family planning center" link="family-planning-center" />} />
              <Route path='/seo-for/family-planning-counselor' element={<SEOCaseStudy heading="Family planning counselor" link="family-planning-counselor" />} />
              <Route path='/seo-for/family-practice-physician' element={<SEOCaseStudy heading="Family practice physician" link="family-practice-physician" />} />
              <Route path='/seo-for/family-service-center' element={<SEOCaseStudy heading="Family service center" link="family-service-center" />} />
              <Route path='/seo-for/farm' element={<SEOCaseStudy heading="Farm" link="farm" />} />
              <Route path='/seo-for/farm-bureau' element={<SEOCaseStudy heading="Farm bureau" link="farm-bureau" />} />
              <Route path='/seo-for/farm-equipment-repair-service' element={<SEOCaseStudy heading="Farm equipment repair service" link="farm-equipment-repair-service" />} />
              <Route path='/seo-for/farm-equipment-supplier' element={<SEOCaseStudy heading="Farm equipment supplier" link="farm-equipment-supplier" />} />
              <Route path='/seo-for/farm-household-tour' element={<SEOCaseStudy heading="Farm household tour" link="farm-household-tour" />} />
              <Route path='/seo-for/farm-shop' element={<SEOCaseStudy heading="Farm shop" link="farm-shop" />} />
              <Route path='/seo-for/farmers-market' element={<SEOCaseStudy heading="Farmers’ market" link="farmers-market" />} />
              <Route path='/seo-for/farmstay' element={<SEOCaseStudy heading="Farmstay" link="farmstay" />} />
              <Route path='/seo-for/fashion-accessories-store' element={<SEOCaseStudy heading="Fashion accessories store" link="fashion-accessories-store" />} />
              <Route path='/seo-for/fashion-design-school' element={<SEOCaseStudy heading="Fashion design school" link="fashion-design-school" />} />
              <Route path='/seo-for/fashion-designer' element={<SEOCaseStudy heading="Fashion designer" link="fashion-designer" />} />
              <Route path='/seo-for/favela' element={<SEOCaseStudy heading="Favela" link="favela" />} />
              <Route path='/seo-for/fax-service' element={<SEOCaseStudy heading="Fax service" link="fax-service" />} />
              <Route path='/seo-for/federal-agency-for-technical-relief' element={<SEOCaseStudy heading="Federal Agency for Technical Relief" link="federal-agency-for-technical-relief" />} />
              <Route path='/seo-for/federal-credit-union' element={<SEOCaseStudy heading="Federal credit union" link="federal-credit-union" />} />
              <Route path='/seo-for/federal-government-office' element={<SEOCaseStudy heading="Federal government office" link="federal-government-office" />} />
              <Route path='/seo-for/federal-police' element={<SEOCaseStudy heading="Federal police" link="federal-police" />} />
              <Route path='/seo-for/federal-reserve-bank' element={<SEOCaseStudy heading="Federal reserve bank" link="federal-reserve-bank" />} />
              <Route path='/seo-for/felt-boots-store' element={<SEOCaseStudy heading="Felt boots store" link="felt-boots-store" />} />
              <Route path='/seo-for/fence-contractor' element={<SEOCaseStudy heading="Fence contractor" link="fence-contractor" />} />
              <Route path='/seo-for/fence-supply-store' element={<SEOCaseStudy heading="Fence supply store" link="fence-supply-store" />} />
              <Route path='/seo-for/fencing-salon' element={<SEOCaseStudy heading="Fencing salon" link="fencing-salon" />} />
              <Route path='/seo-for/fencing-school' element={<SEOCaseStudy heading="Fencing school" link="fencing-school" />} />
              <Route path='/seo-for/feng-shui-shop' element={<SEOCaseStudy heading="Feng shui shop" link="feng-shui-shop" />} />
              <Route path='/seo-for/ferris-wheel' element={<SEOCaseStudy heading="Ferris wheel" link="ferris-wheel" />} />
              <Route path='/seo-for/ferry-service' element={<SEOCaseStudy heading="Ferry service" link="ferry-service" />} />
              <Route path='/seo-for/fertility-clinic' element={<SEOCaseStudy heading="Fertility clinic" link="fertility-clinic" />} />
              <Route path='/seo-for/fertility-physician' element={<SEOCaseStudy heading="Fertility physician" link="fertility-physician" />} />
              <Route path='/seo-for/festival' element={<SEOCaseStudy heading="Festival" link="festival" />} />
              <Route path='/seo-for/fiberglass-repair-service' element={<SEOCaseStudy heading="Fiberglass repair service" link="fiberglass-repair-service" />} />
              <Route path='/seo-for/fiberglass-supplier' element={<SEOCaseStudy heading="Fiberglass supplier" link="fiberglass-supplier" />} />
              <Route path='/seo-for/figurine-shop' element={<SEOCaseStudy heading="Figurine shop" link="figurine-shop" />} />
              <Route path='/seo-for/filipino-grocery-store' element={<SEOCaseStudy heading="Filipino grocery store" link="filipino-grocery-store" />} />
              <Route path='/seo-for/film-production-company' element={<SEOCaseStudy heading="Film production company" link="film-production-company" />} />
              <Route path='/seo-for/filtration-plant' element={<SEOCaseStudy heading="Filtration plant" link="filtration-plant" />} />
              <Route path='/seo-for/finance-broker' element={<SEOCaseStudy heading="Finance broker" link="finance-broker" />} />
              <Route path='/seo-for/financial' element={<SEOCaseStudy heading="Financial" link="financial" />} />
              <Route path='/seo-for/financial-audit' element={<SEOCaseStudy heading="Financial audit" link="financial-audit" />} />
              <Route path='/seo-for/financial-consultant' element={<SEOCaseStudy heading="Financial consultant" link="financial-consultant" />} />
              <Route path='/seo-for/financial-institution' element={<SEOCaseStudy heading="Financial institution" link="financial-institution" />} />
              <Route path='/seo-for/financial-planner' element={<SEOCaseStudy heading="Financial planner" link="financial-planner" />} />
              <Route path='/seo-for/fingerprinting-service' element={<SEOCaseStudy heading="Fingerprinting service" link="fingerprinting-service" />} />
              <Route path='/seo-for/finishing-materials-supplier' element={<SEOCaseStudy heading="Finishing materials supplier" link="finishing-materials-supplier" />} />
              <Route path='/seo-for/fire-damage-restoration-service' element={<SEOCaseStudy heading="Fire damage restoration service" link="fire-damage-restoration-service" />} />
              <Route path='/seo-for/fire-department-equipment-supplier' element={<SEOCaseStudy heading="Fire department equipment supplier" link="fire-department-equipment-supplier" />} />
              <Route path='/seo-for/fire-protection-consultant' element={<SEOCaseStudy heading="fire-protection-consultant" link="fire-protection-consultant" />} />
              <Route path='/seo-for/fire-protection-equipment-supplier' element={<SEOCaseStudy heading="Fire protection equipment supplier" link="fire-protection-equipment-supplier" />} />
              <Route path='/seo-for/fire-protection-service' element={<SEOCaseStudy heading="Fire protection service" link="fire-protection-service" />} />
              <Route path='/seo-for/fire-protection-system-supplier' element={<SEOCaseStudy heading="Fire protection system supplier" link="fire-protection-system-supplier" />} />
              <Route path='/seo-for/fire-station' element={<SEOCaseStudy heading="Fire station" link="fire-station" />} />
              <Route path='/seo-for/firearms-academy' element={<SEOCaseStudy heading="Firearms academy" link="firearms-academy" />} />
              <Route path='/seo-for/fireplace-manufacturer' element={<SEOCaseStudy heading="Fireplace manufacturer" link="fireplace-manufacturer" />} />
              <Route path='/seo-for/fireplace-store' element={<SEOCaseStudy heading="Fireplace store" link="fireplace-store" />} />
              <Route path='/seo-for/firewood-supplier' element={<SEOCaseStudy heading="Firewood supplier" link="firewood-supplier" />} />
              <Route path='/seo-for/fish-spa' element={<SEOCaseStudy heading="Fish spa" link="fish-spa" />} />
              <Route path='/seo-for/fish-store' element={<SEOCaseStudy heading="Fish store" link="fish-store" />} />
              <Route path='/seo-for/fishing-camp' element={<SEOCaseStudy heading="Fishing camp" link="fishing-camp" />} />
              <Route path='/seo-for/fishing-charter' element={<SEOCaseStudy heading="Fishing charter" link="fishing-charter" />} />
              <Route path='/seo-for/fishing-club' element={<SEOCaseStudy heading="Fishing club" link="fishing-club" />} />
              <Route path='/seo-for/fishing-store' element={<SEOCaseStudy heading="Fishing store" link="fishing-store" />} />
              <Route path='/seo-for/fitness-center' element={<SEOCaseStudy heading="Fitness center" link="fitness-center" />} />
              <Route path='/seo-for/fitness-equipment-wholesaler' element={<SEOCaseStudy heading="Fitness equipment wholesaler" link="fitness-equipment-wholesaler" />} />
              <Route path='/seo-for/fitted-furniture-supplier' element={<SEOCaseStudy heading="Fitted furniture supplier" link="fitted-furniture-supplier" />} />
              <Route path='/seo-for/flight-school' element={<SEOCaseStudy heading="Flight school" link="flight-school" />} />
              <Route path='/seo-for/floor-refinishing-service' element={<SEOCaseStudy heading="Floor refinishing service" link="floor-refinishing-service" />} />
              <Route path='/seo-for/floor-sanding-&-polishing-service' element={<SEOCaseStudy heading="Floor sanding and polishing service" link="floor-sanding-&-polishing-service" />} />
              <Route path='/seo-for/flooring-contractor' element={<SEOCaseStudy heading="Flooring contractor" link="flooring-contractor" />} />
              <Route path='/seo-for/flooring-store' element={<SEOCaseStudy heading="Flooring store" link="flooring-store" />} />
              <Route path='/seo-for/florist' element={<SEOCaseStudy heading="Florist" link="florist" />} />
              <Route path='/seo-for/flower-delivery' element={<SEOCaseStudy heading="Flower delivery" link="flower-delivery" />} />
              <Route path='/seo-for/flower-designer' element={<SEOCaseStudy heading="Flower designer" link="flower-designer" />} />
              <Route path='/seo-for/flower-market' element={<SEOCaseStudy heading="Flower market" link="flower-market" />} />
              <Route path='/seo-for/fmcg-goods-wholesaler' element={<SEOCaseStudy heading="FMCG goods wholesaler" link="fmcg-goods-wholesaler" />} />
              <Route path='/seo-for/fmcg-manufacturer' element={<SEOCaseStudy heading="FMCG manufacturer" link="fmcg-manufacturer" />} />
              <Route path='/seo-for/food-&-beverage-consultant' element={<SEOCaseStudy heading="Food and beverage consultant" link="food-&-beverage-consultant" />} />
              <Route path='/seo-for/food-&-beverage-exporter' element={<SEOCaseStudy heading="Food and beverage exporter" link="food-&-beverage-exporter" />} />
              <Route path='/seo-for/food-bank' element={<SEOCaseStudy heading="Food bank" link="food-bank" />} />
              <Route path='/seo-for/food-broker' element={<SEOCaseStudy heading="Food broker" link="food-roker" />} />
              <Route path='/seo-for/food-court' element={<SEOCaseStudy heading="Food court" link="food-court" />} />
              <Route path='/seo-for/food-manufacturing-supply' element={<SEOCaseStudy heading="Food manufacturing supply" link="food-manufacturing-supply" />} />
              <Route path='/seo-for/food-manufacturer' element={<SEOCaseStudy heading="Food manufacturer" link="food-manufacturer" />} />
              <Route path='/seo-for/food-manufacturing-supply' element={<SEOCaseStudy heading="Food manufacturing supply" link="food-manufacturing-supply" />} />
              <Route path='/seo-for/food-processing-company' element={<SEOCaseStudy heading="Food processing company" link="food-processing-company" />} />
              <Route path='/seo-for/food-producer' element={<SEOCaseStudy heading="Food producer" link="food-producer" />} />
              <Route path='/seo-for/food-products-supplier' element={<SEOCaseStudy heading="Food products supplier" link="food-products-supplier" />} />
              <Route path='/seo-for/food-seasoning-manufacturer' element={<SEOCaseStudy heading="Food seasoning manufacturer" link="food-seasoning-manufacturer" />} />
              <Route path='/seo-for/foot-bath' element={<SEOCaseStudy heading="Foot bath" link="foot-bath" />} />
              <Route path='/seo-for/foot-care' element={<SEOCaseStudy heading="Foot care" link="foot-care" />} />
              <Route path='/seo-for/foot-massage-parlor' element={<SEOCaseStudy heading="Foot massage parlor" link="foot-massage-parlor" />} />
              <Route path='/seo-for/foreign-consulate' element={<SEOCaseStudy heading="Foreign consulate" link="foreign-consulate" />} />
              <Route path='/seo-for/foreign-exchange-students-organization' element={<SEOCaseStudy heading="Foreign exchange students organization" link="foreign-exchange-students-organization" />} />
              <Route path='/seo-for/foreign-languages-program-school' element={<SEOCaseStudy heading="Foreign languages program school" link="foreign-languages-program-school" />} />
              <Route path='/seo-for/forensic-consultant' element={<SEOCaseStudy heading="Forensic consultant" link="forensic-consultant" />} />
              <Route path='/seo-for/forestry-service' element={<SEOCaseStudy heading="Forestry service" link="forestry-service" />} />
              <Route path='/seo-for/forklift-rental-service' element={<SEOCaseStudy heading="Forklift rental service" link="forklift-rental-service" />} />
              <Route path='/seo-for/formal-wear-store' element={<SEOCaseStudy heading="Formal wear store" link="formal-wear-store" />} />
              <Route path='/seo-for/fortress' element={<SEOCaseStudy heading="Fortress" link="fortress" />} />
              <Route path='/seo-for/fortune-telling-services' element={<SEOCaseStudy heading="Fortune telling services" link="fortune-telling-services" />} />
              <Route path='/seo-for/fraternal-organization' element={<SEOCaseStudy heading="Fraternal organization" link="fraternal-organization" />} />
              <Route path='/seo-for/free-clinic' element={<SEOCaseStudy heading="Free clinic" link="free-clinic" />} />
              <Route path='/seo-for/free-parking-lot' element={<SEOCaseStudy heading="Free parking lot" link="free-parking-lot" />} />
              <Route path='/seo-for/freight-forwarding-service' element={<SEOCaseStudy heading="Freight forwarding service" link="freight-forwarding-service" />} />
              <Route path='/seo-for/french-language-school' element={<SEOCaseStudy heading="French language school" link="french-language-school" />} />
              <Route path='/seo-for/fresh-food-market' element={<SEOCaseStudy heading="Fresh food market" link="fresh-food-market" />} />
              <Route path='/seo-for/friends-church' element={<SEOCaseStudy heading="Friends church" link="friends-church" />} />
              <Route path='/seo-for/frituur' element={<SEOCaseStudy heading="Frituur" link="frituur" />} />
              <Route path='/seo-for/frozen-dessert-supplier' element={<SEOCaseStudy heading="Frozen dessert supplier" link="frozen-dessert-supplier" />} />
              <Route path='/seo-for/frozen-food-manufacturer' element={<SEOCaseStudy heading="Frozen food manufacturer" link="frozen-food-manufacturer" />} />
              <Route path='/seo-for/frozen-food-store' element={<SEOCaseStudy heading="Frozen food store" link="frozen-food-store" />} />
              <Route path='/seo-for/frozen-yogurt-shop' element={<SEOCaseStudy heading="Frozen yogurt shop" link="frozen-yogurt-shop" />} />
              <Route path='/seo-for/fruit-&-vegetable-store' element={<SEOCaseStudy heading="Fruit and vegetable store" link="fruit-&-vegetable-store" />} />
              <Route path='/seo-for/full-dress-rental-service' element={<SEOCaseStudy heading="Full dress rental service" link="full-dress-rental-service" />} />
              <Route path='/seo-for/full-gospel-church' element={<SEOCaseStudy heading="Full Gospel church" link="full-gospel-church" />} />
              <Route path='/seo-for/funeral-director' element={<SEOCaseStudy heading="Funeral director" link="funeral-director" />} />
              <Route path='/seo-for/funeral-home' element={<SEOCaseStudy heading="Funeral home" link="funeral-home" />} />
              <Route path='/seo-for/fur-coat-shop' element={<SEOCaseStudy heading="Fur coat shop" link="fur-coat-shop" />} />
              <Route path='/seo-for/fur-manufacturer' element={<SEOCaseStudy heading="Fur manufacturer" link="fur-manufacturer" />} />
              <Route path='/seo-for/fur-service' element={<SEOCaseStudy heading="Fur service" link="fur-service" />} />
              <Route path='/seo-for/furnace-parts-supplier' element={<SEOCaseStudy heading="Furnace parts supplier" link="furnace-parts-supplier" />} />
              <Route path='/seo-for/furnace-repair-service' element={<SEOCaseStudy heading="Furnace repair service" link="furnace-repair-service" />} />
              <Route path='/seo-for/furnace-store' element={<SEOCaseStudy heading="Furnace store" link="furnace-store" />} />
              <Route path='/seo-for/furniture-accessories' element={<SEOCaseStudy heading="Furniture accessories" link="furniture-accessories" />} />
              <Route path='/seo-for/furniture-accessories-supplier' element={<SEOCaseStudy heading="Furniture accessories supplier" link="furniture-accessories-supplier" />} />
              <Route path='/seo-for/furniture-maker' element={<SEOCaseStudy heading="Furniture maker" link="furniture-maker" />} />
              <Route path='/seo-for/furniture-manufacturer' element={<SEOCaseStudy heading="Furniture manufacturer" link="furniture-manufacturer" />} />
              <Route path='/seo-for/furniture-rental-service' element={<SEOCaseStudy heading="Furniture rental service" link="furniture-rental-service" />} />
              <Route path='/seo-for/furniture-repair-shop' element={<SEOCaseStudy heading="Furniture repair shop" link="furniture-repair-shop" />} />
              <Route path='/seo-for/furniture-store' element={<SEOCaseStudy heading="Furniture store" link="furniture-store" />} />
              <Route path='/seo-for/futon-store' element={<SEOCaseStudy heading="Futon store" link="futon-store" />} />
              <Route path='/seo-for/gamblin-house' element={<SEOCaseStudy heading="Gambling house" link="gamblin-house" />} />
              <Route path='/seo-for/game-store' element={<SEOCaseStudy heading="Game store" link="game-store" />} />
              <Route path='/seo-for/garage-builder' element={<SEOCaseStudy heading="Garage builder" link="garage-builder" />} />
              <Route path='/seo-for/garage-door-supplier' element={<SEOCaseStudy heading="Garage door supplier" link="garage-door-supplier" />} />
              <Route path='/seo-for/garbage-collection-service' element={<SEOCaseStudy heading="Garbage collection service" link="garbage-collection-service" />} />
              <Route path='/seo-for/garbage-dump' element={<SEOCaseStudy heading="Garbage dump" link="garbage-dump" />} />
              <Route path='/seo-for/garbage-dump-service' element={<SEOCaseStudy heading="Garbage dump service" link="garbage-dump-service" />} />
              <Route path='/seo-for/garden' element={<SEOCaseStudy heading="Garden" link="garden" />} />
              <Route path='/seo-for/garden-building-supplier' element={<SEOCaseStudy heading="Garden building supplier" link="garden-building-supplier" />} />
              <Route path='/seo-for/garden-center' element={<SEOCaseStudy heading="Garden center" link="garden-center" />} />
              <Route path='/seo-for/garden-furniture-shop' element={<SEOCaseStudy heading="Garden furniture shop" link="garden-furniture-shop" />} />
              <Route path='/seo-for/garden-machinery-supplier' element={<SEOCaseStudy heading="Garden machinery supplier" link="garden-machinery-supplier" />} />
              <Route path='/seo-for/gardener' element={<SEOCaseStudy heading="Gardener" link="gardener" />} />
              <Route path='/seo-for/garment-exporter' element={<SEOCaseStudy heading="Garment exporter" link="garment-exporter" />} />
              <Route path='/seo-for/gas-company' element={<SEOCaseStudy heading="Gas company" link="gas-company" />} />
              <Route path='/seo-for/gas-cylinders-supplier' element={<SEOCaseStudy heading="Gas cylinders supplier" link="gas-cylinders-supplier" />} />
              <Route path='/seo-for/gas-engineer' element={<SEOCaseStudy heading="Gas engineer" link="gas-engineer" />} />
              <Route path='/seo-for/gas-logs-supplier' element={<SEOCaseStudy heading="Gas logs supplier" link="gas-logs-supplier" />} />
              <Route path='/seo-for/gas-station' element={<SEOCaseStudy heading="Gas station" link="gas-station" />} />
              <Route path='/seo-for/gasfitter' element={<SEOCaseStudy heading="Gasfitter" link="gasfitter" />} />
              <Route path='/seo-for/gastroenterologist' element={<SEOCaseStudy heading="Gastroenterologist" link="gastroenterologist" />} />
              <Route path='/seo-for/gay-&-lesbian-organization' element={<SEOCaseStudy heading="Gay & lesbian organization" link="gay-&-lesbian-organization" />} />
              <Route path='/seo-for/gay-bar' element={<SEOCaseStudy heading="Gay bar" link="gay-bar" />} />
              <Route path='/seo-for/gay-night-club' element={<SEOCaseStudy heading="Gay night club" link="gay-night-club" />} />
              <Route path='/seo-for/gay-sauna' element={<SEOCaseStudy heading="Gay sauna" link="gay-sauna" />} />
              <Route path='/seo-for/gazebo-builder' element={<SEOCaseStudy heading="Gazebo builder" link="gazebo-builder" />} />
              <Route path='/seo-for/gemologist' element={<SEOCaseStudy heading="Gemologist" link="gemologist" />} />
              <Route path='/seo-for/genealogist' element={<SEOCaseStudy heading="Genealogist" link="genealogist" />} />
              <Route path='/seo-for/general-contractor' element={<SEOCaseStudy heading="General contractor" link="general-contractor" />} />
              <Route path='/seo-for/general-education-school' element={<SEOCaseStudy heading="General education school" link="general-education-school" />} />
              <Route path='/seo-for/general-hospital' element={<SEOCaseStudy heading="General hospital" link="general-hospital" />} />
              <Route path='/seo-for/general-practice-attorney' element={<SEOCaseStudy heading="General practice attorney" link="general-practice-attorney" />} />
              <Route path='/seo-for/general-practitioner' element={<SEOCaseStudy heading="General practitioner" link="general-practitioner" />} />
              <Route path='/seo-for/general-store' element={<SEOCaseStudy heading="General store" link="general-store" />} />
              <Route path='/seo-for/geological-research-company' element={<SEOCaseStudy heading="Geological research company" link="geological-research-company" />} />
              <Route path='/seo-for/geotechnical-engineer' element={<SEOCaseStudy heading="Geotechnical engineer" link="geotechnical-engineer" />} />
              <Route path='/seo-for/german-language-school' element={<SEOCaseStudy heading="German language school" link="german-language-school" />} />
              <Route path='/seo-for/gift-basket-store' element={<SEOCaseStudy heading="Gift basket store" link="gift-basket-store" />} />
              <Route path='/seo-for/gift-shop' element={<SEOCaseStudy heading="Gift shop" link="gift-shop" />} />
              <Route path='/seo-for/gift-wrap-store' element={<SEOCaseStudy heading="Gift wrap store" link="gift-wrap-store" />} />
              <Route path='/seo-for/girl-bar' element={<SEOCaseStudy heading="Girl bar" link="girl-bar" />} />
              <Route path='/seo-for/glass-blower' element={<SEOCaseStudy heading="Glass blower" link="glass-blower" />} />
              <Route path='/seo-for/glass-merchant' element={<SEOCaseStudy heading="Glass merchant" link="glass-merchant" />} />
              <Route path='/seo-for/glass-repair-service' element={<SEOCaseStudy heading="Glass repair service" link="glass-repair-service" />} />
              <Route path='/seo-for/glass-shop' element={<SEOCaseStudy heading="Glass shop" link="glass-shop" />} />
              <Route path='/seo-for/glasses-repair-service' element={<SEOCaseStudy heading="Glasses repair service" link="glasses-repair-service" />} />
              <Route path='/seo-for/glassware-wholesaler' element={<SEOCaseStudy heading="Glassware wholesaler" link="glassware-wholesaler" />} />
              <Route path='/seo-for/glazier' element={<SEOCaseStudy heading="Glazier" link="glazier" />} />
              <Route path='/seo-for/gmc-dealer' element={<SEOCaseStudy heading="GMC dealer" link="gmc-dealer" />} />
              <Route path='/seo-for/go-kart-track' element={<SEOCaseStudy heading="Go-kart track" link="go-kart-track" />} />
              <Route path='/seo-for/gold-deller' element={<SEOCaseStudy heading="Gold Deller" link="gold-deller" />} />
              <Route path='/seo-for/gold-mining-company' element={<SEOCaseStudy heading="Gold mining company" link="gold-mining-company" />} />
              <Route path='/seo-for/goldfish-store' element={<SEOCaseStudy heading="Goldfish store" link="goldfish-store" />} />
              <Route path='/seo-for/goldsmith' element={<SEOCaseStudy heading="Goldsmith" link="goldsmith" />} />
              <Route path='/seo-for/golf-cart-dealer' element={<SEOCaseStudy heading="Golf cart dealer" link="golf-cart-dealer" />} />
              <Route path='/seo-for/golf-club' element={<SEOCaseStudy heading="Golf club" link="golf-club" />} />
              <Route path='/seo-for/golf-course' element={<SEOCaseStudy heading="Golf course" link="golf-course" />} />
              <Route path='/seo-for/golf-course-builder' element={<SEOCaseStudy heading="Golf course builder" link="golf-course-builder" />} />
              <Route path='/seo-for/golf-driving-range' element={<SEOCaseStudy heading="Golf driving range" link="golf-driving-range" />} />
              <Route path='/seo-for/golf-instructor' element={<SEOCaseStudy heading="Golf instructor" link="golf-instructor" />} />
              <Route path='/seo-for/golf-shop' element={<SEOCaseStudy heading="Golf shop" link="golf-shop" />} />
              <Route path='/seo-for/gospel-church' element={<SEOCaseStudy heading="Gospel church" link="gospel-church" />} />
              <Route path='/seo-for/government-college' element={<SEOCaseStudy heading="Government college" link="government-college" />} />
              <Route path='/seo-for/government-hospital' element={<SEOCaseStudy heading="Government hospital" link="government-hospital" />} />
              <Route path='/seo-for/government-school' element={<SEOCaseStudy heading="Government school" link="government-school" />} />
              <Route path='/seo-for/gps-supplier' element={<SEOCaseStudy heading="GPS supplier" link="gps-supplier" />} />
              <Route path='/seo-for/graduate-school' element={<SEOCaseStudy heading="Graduate school" link="graduate-school" />} />
              <Route path='/seo-for/graffiti-removal-service' element={<SEOCaseStudy heading="Graffiti removal service" link="graffiti-removal-service" />} />
              <Route path='/seo-for/grain-elevator' element={<SEOCaseStudy heading="Grain elevator" link="grain-elevator" />} />
              <Route path='/seo-for/grammar-school' element={<SEOCaseStudy heading="Grammar school" link="grammar-school" />} />
              <Route path='/seo-for/green-energy-supplier' element={<SEOCaseStudy heading="Green energy supplier" link="green-energy-supplier" />} />
              <Route path='/seo-for/greengrocer' element={<SEOCaseStudy heading="Greengrocer" link="greengrocer" />} />
              <Route path='/seo-for/greenhouse' element={<SEOCaseStudy heading="Greenhouse" link="greenhouse" />} />
              <Route path='/seo-for/greeting-card-shop' element={<SEOCaseStudy heading="Greeting card shop" link="greeting-card-shop" />} />
              <Route path='/seo-for/greyhound-stadium' element={<SEOCaseStudy heading="Greyhound stadium" link="greyhound-stadium" />} />
              <Route path='/seo-for/grill' element={<SEOCaseStudy heading="Grill" link="grill" />} />
              <Route path='/seo-for/grill-store' element={<SEOCaseStudy heading="Grill store" link="grill-store" />} />
              <Route path='/seo-for/grocery-delivery-service' element={<SEOCaseStudy heading="Grocery delivery service" link="grocery-delivery-service" />} />
              <Route path='/seo-for/grocery-store' element={<SEOCaseStudy heading="Grocery store" link="grocery-store" />} />
              <Route path='/seo-for/ground-self-defense-force' element={<SEOCaseStudy heading="Ground self defense force" link="ground-self-defense-force" />} />
              <Route path='/seo-for/group-home' element={<SEOCaseStudy heading="Group home" link="group-home" />} />
              <Route path='/seo-for/grow-shop' element={<SEOCaseStudy heading="Grow shop" link="grow-shop" />} />
              <Route path='/seo-for/guardia-civil' element={<SEOCaseStudy heading="Guardia Civil" link="guardia-civil" />} />
              <Route path='/seo-for/guest-house' element={<SEOCaseStudy heading="Guest house" link="guest-house" />} />
              <Route path='/seo-for/guitar-instructor' element={<SEOCaseStudy heading="Guitar instructor" link="guitar-instructor" />} />
              <Route path='/seo-for/guitar-store' element={<SEOCaseStudy heading="Guitar store" link="guitar-store" />} />
              <Route path='/seo-for/gun-club' element={<SEOCaseStudy heading="Gun club" link="gun-club" />} />
              <Route path='/seo-for/gun-shop' element={<SEOCaseStudy heading="Gun shop" link="gun-shop" />} />
              <Route path='/seo-for/gurudwara' element={<SEOCaseStudy heading="Gurudwara" link="gurudwara" />} />
              <Route path='/seo-for/gutter-cleaning-service' element={<SEOCaseStudy heading="Gutter cleaning service" link="gutter-cleaning-service" />} />
              <Route path='/seo-for/gym' element={<SEOCaseStudy heading="GYM" link="gym" />} />
              <Route path='/seo-for/gymnasium-school' element={<SEOCaseStudy heading="Gymnasium school" link="gymnasium-school" />} />
              <Route path='/seo-for/gymnastics-center' element={<SEOCaseStudy heading="Gymnastics center" link="gymnastics-center" />} />
              <Route path='/seo-for/gymnastics-club' element={<SEOCaseStudy heading="Gymnastics club" link="gymnastics-club" />} />
              <Route path='/seo-for/gynecologist' element={<SEOCaseStudy heading="Gynecologist" link="gynecologist" />} />
              <Route path='/seo-for/hair-extensions-supplier' element={<SEOCaseStudy heading="Hair extensions supplier" link="hair-extensions-supplier" />} />
              <Route path='/seo-for/hair-removal-service' element={<SEOCaseStudy heading="Hair removal service" link="hair-removal-service" />} />
              <Route path='/seo-for/hair-replacement-service' element={<SEOCaseStudy heading="Hair replacement service" link="hair-replacement-service" />} />
              <Route path='/seo-for/hair-salon' element={<SEOCaseStudy heading="Hair salon" link="hair-salon" />} />
              <Route path='/seo-for/hair-transplantation-clinic' element={<SEOCaseStudy heading="Hair transplantation clinic" link="hair-transplantation-clinic" />} />
              <Route path='/seo-for/hairdresser' element={<SEOCaseStudy heading="Hairdresser" link="hairdresser" />} />
              <Route path='/seo-for/ham-shop' element={<SEOCaseStudy heading="Ham shop" link="ham-shop" />} />
              <Route path='/seo-for/hand-surgeon' element={<SEOCaseStudy heading="Hand surgeon" link="hand-surgeon" />} />
              <Route path='/seo-for/handbags-shop' element={<SEOCaseStudy heading="Handbags shop" link="handbags-shop" />} />
              <Route path='/seo-for/handball-club' element={<SEOCaseStudy heading="Handball club" link="handball-club" />} />
              <Route path='/seo-for/handicapped-transportation-service' element={<SEOCaseStudy heading="Handicapped transportation service" link="handicapped-transportation-service" />} />
              <Route path='/seo-for/handicraft' element={<SEOCaseStudy heading="Handicraft" link="handicraft" />} />
              <Route path='/seo-for/handicraft-exporter' element={<SEOCaseStudy heading="Handicraft exporter" link="handicraft-exporter" />} />
              <Route path='/seo-for/handicraft-museum' element={<SEOCaseStudy heading="Handicraft museum" link="handicraft-museum" />} />
              <Route path='/seo-for/handicraft-school' element={<SEOCaseStudy heading="Handicraft school" link="handicraft-school" />} />
              <Route path='/seo-for/handicrafts-wholesaler' element={<SEOCaseStudy heading="Handicrafts wholesaler" link="handicrafts-wholesaler" />} />
              <Route path='/seo-for/hardware-store' element={<SEOCaseStudy heading="Hardware store" link="hardware-store" />} />
              <Route path='/seo-for/hat-shop' element={<SEOCaseStudy heading="Hat shop" link="hat-shop" />} />
              <Route path='/seo-for/haunted-house' element={<SEOCaseStudy heading="Haunted house" link="haunted-house" />} />
              <Route path='/seo-for/hawaiian-goods-store' element={<SEOCaseStudy heading="Hawaiian goods store" link="hawaiian-goods-store" />} />
              <Route path='/seo-for/hawker-center' element={<SEOCaseStudy heading="Hawker center" link="hawker-center" />} />
              <Route path='/seo-for/hawker-stall' element={<SEOCaseStudy heading="Hawker stall" link="hawker-stall" />} />
              <Route path='/seo-for/hay-supplier' element={<SEOCaseStudy heading="Hay supplier" link="hay-supplier" />} />
              <Route path='/seo-for/head-start-center' element={<SEOCaseStudy heading="Head start center" link="head-start-center" />} />
              <Route path='/seo-for/health-&-beauty-shop' element={<SEOCaseStudy heading="Health and beauty shop" link="health-&-beauty-shop" />} />
              <Route path='/seo-for/health-consultant' element={<SEOCaseStudy heading="Health consultant" link="health-consultant" />} />
              <Route path='/seo-for/health-counselor' element={<SEOCaseStudy heading="Health counselor" link="health-counselor" />} />
              <Route path='/seo-for/health-food-store' element={<SEOCaseStudy heading="Health food store" link="health-food-store" />} />
              <Route path='/seo-for/health-insurance-agency' element={<SEOCaseStudy heading="Health insurance agency" link="health-insurance-agency" />} />
              <Route path='/seo-for/health-resort' element={<SEOCaseStudy heading="Health resort" link="health-resort" />} />
              <Route path='/seo-for/hearing-aid-repair-service' element={<SEOCaseStudy heading="Hearing aid repair service" link="hearing-aid-repair-service" />} />
              <Route path='/seo-for/hearing-assistance-earphone-store' element={<SEOCaseStudy heading="Hearing assistance earphone store" link="hearing-assistance-earphone-store" />} />
              <Route path='/seo-for/heart-hospital' element={<SEOCaseStudy heading="Heart hospital" link="heart-hospital" />} />
              <Route path='/seo-for/heating-contractor' element={<SEOCaseStudy heading="Heating contractor" link="heating-contractor" />} />
              <Route path='/seo-for/heating-equipment-supplier' element={<SEOCaseStudy heading="Heating equipment supplier" link="heating-equipment-supplier" />} />
              <Route path='/seo-for/helicopter-tour-agency' element={<SEOCaseStudy heading="Helicopter tour agency" link="helicopter-tour-agency" />} />
              <Route path='/seo-for/heliport' element={<SEOCaseStudy heading="Heliport" link="heliport" />} />
              <Route path='/seo-for/helium-gas-supplier' element={<SEOCaseStudy heading="Helium gas supplier" link="helium-gas-supplier" />} />
              <Route path='/seo-for/helpline' element={<SEOCaseStudy heading="Helpline" link="helpline" />} />
              <Route path='/seo-for/hematologist' element={<SEOCaseStudy heading="Hematologist" link="hematologist" />} />
              <Route path='/seo-for/hepatologist' element={<SEOCaseStudy heading="Hepatologist" link="hepatologist" />} />
              <Route path='/seo-for/herb-shop' element={<SEOCaseStudy heading="Herb shop" link="herb-shop" />} />
              <Route path='/seo-for/herbalist' element={<SEOCaseStudy heading="Herbalist" link="herbalist" />} />
              <Route path='/seo-for/heritage-museum' element={<SEOCaseStudy heading="Heritage museum" link="heritage-museum" />} />
              <Route path='/seo-for/heritage-preservation' element={<SEOCaseStudy heading="Heritage preservation" link="heritage-preservation" />} />
              <Route path='/seo-for/high-school' element={<SEOCaseStudy heading="High school" link="high-school" />} />
              <Route path='/seo-for/higher-secondary-school' element={<SEOCaseStudy heading="Higher secondary school" link="higher-secondary-school" />} />
              <Route path='/seo-for/highway-patrol' element={<SEOCaseStudy heading="Highway patrol" link="highway-patrol" />} />
              <Route path='/seo-for/hiking-guide' element={<SEOCaseStudy heading="Hiking guide" link="hiking-guide" />} />
              <Route path='/seo-for/hip-hop-dance-class' element={<SEOCaseStudy heading="Hip hop dance class" link="hip-hop-dance-class" />} />
              <Route path='/seo-for/hispanic-church' element={<SEOCaseStudy heading="Hispanic church" link="hispanic-church" />} />
              <Route path='/seo-for/historical-landmark' element={<SEOCaseStudy heading="Historical landmark" link="historical-landmark" />} />
              <Route path='/seo-for/historical-place-museum' element={<SEOCaseStudy heading="Historical place museum" link="historical-place-museum" />} />
              <Route path='/seo-for/historical-society' element={<SEOCaseStudy heading="Historical society" link="historical-society" />} />
              <Route path='/seo-for/history-museum' element={<SEOCaseStudy heading="History museum" link="history-museum" />} />
              <Route path='/seo-for/hobby-store' element={<SEOCaseStudy heading="Hobby store" link="hobby-store" />} />
              <Route path='/seo-for/hockey-club' element={<SEOCaseStudy heading="Hockey club" link="hockey-club" />} />
              <Route path='/seo-for/hockey-rink' element={<SEOCaseStudy heading="Hockey rink" link="hockey-rink" />} />
              <Route path='/seo-for/hockey-supply-store' element={<SEOCaseStudy heading="Hockey supply store" link="hockey-supply-store" />} />
              <Route path='/seo-for/holding-company' element={<SEOCaseStudy heading="Holding company" link="holding-company" />} />
              <Route path='/seo-for/holiday-apartment' element={<SEOCaseStudy heading="Holiday apartment" link="holiday-apartment" />} />
              <Route path='/seo-for/holiday-apartment-rental' element={<SEOCaseStudy heading="Holiday apartment rental" link="holiday-apartment-rental" />} />
              <Route path='/seo-for/holiday-home' element={<SEOCaseStudy heading="Holiday home" link="holiday-home" />} />
              <Route path='/seo-for/home-audio-store' element={<SEOCaseStudy heading="Home audio store" link="home-audio-store" />} />
              <Route path='/seo-for/home-builder' element={<SEOCaseStudy heading="Home builder" link="home-builder" />} />
              <Route path='/seo-for/home-goods-store' element={<SEOCaseStudy heading="Home goods store" link="home-goods-store" />} />
              <Route path='/seo-for/home-hairdresser' element={<SEOCaseStudy heading="Home hairdresser" link="home-hairdresser" />} />
              <Route path='/seo-for/home-health-care-service' element={<SEOCaseStudy heading="Home health care service" link="home-health-care-service" />} />
              <Route path='/seo-for/home-help' element={<SEOCaseStudy heading="Home help" link="home-help" />} />
              <Route path='/seo-for/home-help-service-agency' element={<SEOCaseStudy heading="Home help service agency" link="home-help-service-agency" />} />
              <Route path='/seo-for/home-improvement-store' element={<SEOCaseStudy heading="Home improvement store" link="home-improvement-store" />} />
              <Route path='/seo-for/home-inspector' element={<SEOCaseStudy heading="Home inspector" link="home-inspector" />} />
              <Route path='/seo-for/home-insurance-agency' element={<SEOCaseStudy heading="Home insurance agency" link="home-insurance-agency" />} />
              <Route path='/seo-for/home-theater-store' element={<SEOCaseStudy heading="Home theater store" link="home-theater-store" />} />
              <Route path='/seo-for/homekill-service' element={<SEOCaseStudy heading="Homekill service" link="homekill-service" />} />
              <Route path='/seo-for/honey-farm' element={<SEOCaseStudy heading="Honey farm" link="honey-farm" />} />
              <Route path='/seo-for/horse-breeder' element={<SEOCaseStudy heading="Horse breeder" link="horse-breeder" />} />
              <Route path='/seo-for/horse-rental-service' element={<SEOCaseStudy heading="Horse rental service" link="horse-rental-service" />} />
              <Route path='/seo-for/horse-riding-school' element={<SEOCaseStudy heading="Horse riding school" link="horse-riding-school" />} />
              <Route path='/seo-for/horse-transport-supplier' element={<SEOCaseStudy heading="Horse transport supplier" link="horse-transport-supplier" />} />
              <Route path='/seo-for/horseback-riding-service' element={<SEOCaseStudy heading="Horseback riding service" link="horseback-riding-service" />} />
              <Route path='/seo-for/horseshoe-smith' element={<SEOCaseStudy heading="Horseshoe smith" link="horseshoe-smith" />} />
              <Route path='/seo-for/hose-supplier' element={<SEOCaseStudy heading="Hose supplier" link="hose-supplier" />} />
              <Route path='/seo-for/hospice' element={<SEOCaseStudy heading="Hospice" link="hospice" />} />
              <Route path='/seo-for/hospital-department' element={<SEOCaseStudy heading="Hospital department" link="hospital-department" />} />
              <Route path='/seo-for/hospital-equipment-&-supplies' element={<SEOCaseStudy heading="Hospital equipment and supplies" link="hospital-equipment-&-supplies" />} />
              <Route path='/seo-for/hospitality-&-tourism-school' element={<SEOCaseStudy heading="Hospitality and tourism school" link="hospitality-&-tourism-school" />} />
              <Route path='/seo-for/hospitality-high-school' element={<SEOCaseStudy heading="Hospitality high school" link="hospitality-high-school" />} />
              <Route path='/seo-for/host-club' element={<SEOCaseStudy heading="Host club" link="host-club" />} />
              <Route path='/seo-for/hot-tub-repair-service' element={<SEOCaseStudy heading="Hot tub repair service" link="hot-tub-repair-service" />} />
              <Route path='/seo-for/hot-tub-store' element={<SEOCaseStudy heading="Hot tub store" link="hot-tub-store" />} />
              <Route path='/seo-for/hot-water-system-supplier' element={<SEOCaseStudy heading="Hot water system supplier" link="hot-water-system-supplier" />} />
              <Route path='/seo-for/hotel' element={<SEOCaseStudy heading="Hotel" link="hotel" />} />
              <Route path='/seo-for/hotel-management-school' element={<SEOCaseStudy heading="Hotel management school" link="hotel-management-school" />} />
              <Route path='/seo-for/hotel-supply-store' element={<SEOCaseStudy heading="Hotel supply store" link="hotel-supply-store" />} />
              <Route path='/seo-for/house-cleaning-service' element={<SEOCaseStudy heading="House cleaning service" link="house-cleaning-service" />} />
              <Route path='/seo-for/house-clearance-service' element={<SEOCaseStudy heading="House clearance service" link="house-clearance-service" />} />
              <Route path='/seo-for/house-cleaning-service' element={<SEOCaseStudy heading="House cleaning service" link="house-cleaning-service" />} />
              <Route path='/seo-for/household-chemicals-supplier' element={<SEOCaseStudy heading="Household chemicals supplier" link="household-chemicals-supplier" />} />
              <Route path='/seo-for/household-goods-wholesaler' element={<SEOCaseStudy heading="Household goods wholesaler" link="household-goods-wholesaler" />} />
              <Route path='/seo-for/housing-authority' element={<SEOCaseStudy heading="Housing authority" link="housing-authority" />} />
              <Route path='/seo-for/housing-complex' element={<SEOCaseStudy heading="Housing complex" link="housing-complex" />} />
              <Route path='/seo-for/housing-cooperative' element={<SEOCaseStudy heading="Housing cooperative" link="housing-cooperative" />} />
              <Route path='/seo-for/housing-development' element={<SEOCaseStudy heading="Housing development" link="housing-development" />} />
              <Route path='/seo-for/housing-society' element={<SEOCaseStudy heading="Housing society" link="housing-society" />} />
              <Route path='/seo-for/human-resource-consulting' element={<SEOCaseStudy heading="Human resource consulting" link="human-resource-consulting" />} />
              <Route path='/seo-for/hunting-&-fishing-store' element={<SEOCaseStudy heading="Hunting and fishing store" link="hunting-&-fishing-store" />} />
              <Route path='/seo-for/hunting-area' element={<SEOCaseStudy heading="Hunting area" link="hunting-area" />} />
              <Route path='/seo-for/hunting-club' element={<SEOCaseStudy heading="Hunting club" link="hunting-club" />} />
              <Route path='/seo-for/hunting-store' element={<SEOCaseStudy heading="Hunting store" link="hunting-store" />} />
              <Route path='/seo-for/hvac-contractor' element={<SEOCaseStudy heading="HVAC contractor" link="hvac-contractor" />} />
              <Route path='/seo-for/hydroponics-equipment-supplier' element={<SEOCaseStudy heading="Hydroponics equipment supplier" link="hydroponics-equipment-supplier" />} />
              <Route path='/seo-for/hyperbaric-medicine-physician' element={<SEOCaseStudy heading="Hyperbaric medicine physician" link="hyperbaric-medicine-physician" />} />
              <Route path='/seo-for/hypnotherapy-service' element={<SEOCaseStudy heading="Hypnotherapy service" link="hypnotherapy-service" />} />
              <Route path='/seo-for/ice-cream-shop' element={<SEOCaseStudy heading="Ice cream shop" link="ice-cream-shop" />} />
              <Route path='/seo-for/ice-skating-club' element={<SEOCaseStudy heading="Ice skating club" link="ice-skating-club" />} />
              <Route path='/seo-for/ice-skating-rink' element={<SEOCaseStudy heading="Ice skating rink" link="ice-skating-rink" />} />
              <Route path='/seo-for/ice-supplier' element={<SEOCaseStudy heading="Ice supplier" link="ice-supplier" />} />
              <Route path='/seo-for/immigration-&-naturalization-service' element={<SEOCaseStudy heading="Immigration & naturalization service" link="immigration-&-naturalization-service" />} />
              <Route path='/seo-for/immigration-attorney' element={<SEOCaseStudy heading="Immigration attorney" link="immigration-attorney" />} />
              <Route path='/seo-for/immigration-detention-centre' element={<SEOCaseStudy heading="Immigration detention centre" link="immigration-detention-centre" />} />
              <Route path='/seo-for/immunologist' element={<SEOCaseStudy heading="Immunologist" link="immunologist" />} />
              <Route path='/seo-for/impermeabilization-service' element={<SEOCaseStudy heading="Impermeabilization service" link="impermeabilization-service" />} />
              <Route path='/seo-for/incineration-plant' element={<SEOCaseStudy heading="Incineration plant" link="incineration-plant" />} />
              <Route path='/seo-for/income-protection-insurance-agency' element={<SEOCaseStudy heading="Income protection insurance agency" link="income-protection-insurance-agency" />} />
              <Route path='/seo-for/income-tax-help-association' element={<SEOCaseStudy heading="Income tax help association" link="income-tax-help-association" />} />
              <Route path='/seo-for/indian-takeaway' element={<SEOCaseStudy heading="Indian takeaway" link="indian-takeaway" />} />
              <Route path='/seo-for/indoor-cycling' element={<SEOCaseStudy heading="Indoor cycling" link="indoor-cycling" />} />
              <Route path='/seo-for/indoor-golf-course' element={<SEOCaseStudy heading="Indoor golf course" link="indoor-golf-course" />} />
              <Route path='/seo-for/indoor-swimming-pool' element={<SEOCaseStudy heading="Indoor swimming pool" link="indoor-swimming-pool" />} />
              <Route path='/seo-for/industrial-chemicals-wholesaler' element={<SEOCaseStudy heading="Industrial chemicals wholesaler" link="industrial-chemicals-wholesaler" />} />
              <Route path='/seo-for/industrial-consultant' element={<SEOCaseStudy heading="Industrial consultant" link="industrial-consultant" />} />
              <Route path='/seo-for/industrial-design-company' element={<SEOCaseStudy heading="Industrial design company" link="industrial-design-company" />} />
              <Route path='/seo-for/industrial-engineer' element={<SEOCaseStudy heading="Industrial engineer" link="industrial-engineer" />} />
              <Route path='/seo-for/industrial-engineers-association' element={<SEOCaseStudy heading="Industrial engineers association" link="industrial-engineers-association" />} />
              <Route path='/seo-for/industrial-equipment-supplier' element={<SEOCaseStudy heading="Industrial equipment supplier" link="industrial-equipment-supplier" />} />
              <Route path='/seo-for/industrial-framework-supplier' element={<SEOCaseStudy heading="Industrial framework supplier" link="industrial-framework-supplier" />} />
              <Route path='/seo-for/industrial-real-estate-agency' element={<SEOCaseStudy heading="Industrial real estate agency" link="industrial-real-estate-agency" />} />
              <Route path='/seo-for/industrial-supermarket' element={<SEOCaseStudy heading="Industrial supermarket" link="industrial-supermarket" />} />
              <Route path='/seo-for/industrial-technical-engineers-association' element={<SEOCaseStudy heading="Industrial technical engineers association" link="industrial-technical-engineers-association" />} />
              <Route path='/seo-for/industrial-vacuum-equipment-supplier' element={<SEOCaseStudy heading="Industrial vacuum equipment supplier" link="industrial-vacuum-equipment-supplier" />} />
              <Route path='/seo-for/infectious-disease-physician' element={<SEOCaseStudy heading="Infectious disease physician" link="nfectious-disease-physician" />} />
              <Route path='/seo-for/infiniti-dealer' element={<SEOCaseStudy heading="Infiniti dealer" link="infiniti-dealer" />} />
              <Route path='/seo-for/information-services' element={<SEOCaseStudy heading="Information services" link="information-services" />} />
              <Route path='/seo-for/inn' element={<SEOCaseStudy heading="Inn" link="inn" />} />
              <Route path='/seo-for/insolvency-service' element={<SEOCaseStudy heading="Insolvency service" link="insolvency-service" />} />
              <Route path='/seo-for/institute-of-Geography-&-statistics' element={<SEOCaseStudy heading="Institute of Geography and Statistics" link="institute-of-Geography-&-statistics" />} />
              <Route path='/seo-for/institute-of-technology' element={<SEOCaseStudy heading="Institute of technology" link="institute-of-technology" />} />
              <Route path='/seo-for/instrumentation-engineer' element={<SEOCaseStudy heading="Instrumentation engineer" link="instrumentation-engineer" />} />
              {/* <Route path='/seo-for/financial' element={<SEOCaseStudy heading="Financial" link="financial" />} /> */}

              {/* Digital Marketing Services */}
              <Route path='/digital-marketing-for/abundant-life-church' element={<DigitalMarketingCaseStudy heading="Abundant Life church" link="abundant-life-church" />} />
              <Route path='/digital-marketing-for/acoustical-consultant' element={<DigitalMarketingCaseStudy heading="Acoustical consultant" link="acoustical-consultant" />} />
              <Route path='/digital-marketing-for/acrylic-store' element={<DigitalMarketingCaseStudy heading="Acrylic store" link="acrylic-store" />} />
              <Route path='/digital-marketing-for/acupuncture-school' element={<DigitalMarketingCaseStudy heading="Acupuncture school" link="acupuncture-school" />} />
              <Route path='/digital-marketing-for/acura-dealer' element={<DigitalMarketingCaseStudy heading="Acura dealer" link="acura-dealer" />} />
              <Route path='/digital-marketing-for/adaptive-sports-center' element={<DigitalMarketingCaseStudy heading="Adaptive sports center" link="adaptive-sports-center" />} />
              <Route path='/digital-marketing-for/administrative-attorney' element={<DigitalMarketingCaseStudy heading="Administrative attorney" link="administrative-attorney" />} />
              <Route path='/digital-marketing-for/adoption-agency' element={<DigitalMarketingCaseStudy heading="Adoption agency" link="adoption-agency" />} />
              <Route path='/digital-marketing-for/adult-day-care-center' element={<DigitalMarketingCaseStudy heading="Adult day care center" link="adult-day-care-center" />} />
              <Route path='/digital-marketing-for/adult-education-school' element={<DigitalMarketingCaseStudy heading="Adult education school" link="adult-education-school" />} />
              <Route path='/digital-marketing-for/adventure-sports-center' element={<DigitalMarketingCaseStudy heading="Adventure sports center" link="adventure-sports-center" />} />
              <Route path='/digital-marketing-for/aerated-drinks-supplier' element={<DigitalMarketingCaseStudy heading="Aerated drinks supplier" link="aerated-drinks-supplier" />} />
              <Route path='/digital-marketing-for/aerial-sports-center' element={<DigitalMarketingCaseStudy heading="Aerial sports center" link="aerial-sports-center" />} />
              <Route path='/digital-marketing-for/aeromodel-shop' element={<DigitalMarketingCaseStudy heading="Aeromodel shop" link="aeromodel-shop" />} />
              <Route path='/digital-marketing-for/aeronautical-engineer' element={<DigitalMarketingCaseStudy heading="Aeronautical engineer" link="aeronautical-engineer" />} />
              <Route path='/digital-marketing-for/after-school-program' element={<DigitalMarketingCaseStudy heading="After school program" link="after-school-program" />} />
              <Route path='/digital-marketing-for/aggregate-supplier' element={<DigitalMarketingCaseStudy heading="Aggregate supplier" link="aggregate-supplier" />} />
              <Route path='/digital-marketing-for/agricultural-product-wholesaler' element={<DigitalMarketingCaseStudy heading="Agricultural product wholesaler" link="agricultural-product-wholesaler" />} />
              <Route path='/digital-marketing-for/aikido-club' element={<DigitalMarketingCaseStudy heading="Aikido club" link="aikido-club" />} />
              <Route path='/digital-marketing-for/air-ambulance-service' element={<DigitalMarketingCaseStudy heading="Air ambulance service" link="air-ambulance-service" />} />
              <Route path='/digital-marketing-for/air-compressor-repair-service' element={<DigitalMarketingCaseStudy heading="Air compressor repair service" link="air-compressor-repair-service" />} />
              <Route path='/digital-marketing-for/air-conditioning-repair-service' element={<DigitalMarketingCaseStudy heading="Air conditioning repair service" link="air-conditioning-repair-service" />} />
              <Route path='/digital-marketing-for/airbrushing-service' element={<DigitalMarketingCaseStudy heading="Airbrushing service" link="airbrushing-service" />} />
              <Route path='/digital-marketing-for/airsoft-supply-store' element={<DigitalMarketingCaseStudy heading="Airsoft supply store" link="airsoft-supply-store" />} />
              <Route path='/digital-marketing-for/alcoholism-treatment-program' element={<DigitalMarketingCaseStudy heading="Alcoholism treatment program" link="alcoholism-treatment-program" />} />
              <Route path='/digital-marketing-for/alfa-Romeo-dealer' element={<DigitalMarketingCaseStudy heading="Alfa Romeo dealer" link="alfa-Romeo-dealer" />} />
              <Route path='/digital-marketing-for/alliance-church' element={<DigitalMarketingCaseStudy heading="Alliance church" link="alliance-church" />} />
              <Route path='/digital-marketing-for/alternative-fuel-station' element={<DigitalMarketingCaseStudy heading="Alternative fuel station" link="alternative-fuel-station" />} />
              <Route path='/digital-marketing-for/alternative-medicine-practitioner' element={<DigitalMarketingCaseStudy heading="Alternative medicine practitioner" link="alternative-medicine-practitioner" />} />
              <Route path='/digital-marketing-for/alternator-supplier' element={<DigitalMarketingCaseStudy heading="Alternator supplier" link="alternator-supplier" />} />
              <Route path='/digital-marketing-for/ambulance-service' element={<DigitalMarketingCaseStudy heading="Ambulance service" link="ambulance-service" />} />
              <Route path='/digital-marketing-for/amish-furniture-store' element={<DigitalMarketingCaseStudy heading="Amish furniture store" link="amish-furniture-store" />} />
              <Route path='/digital-marketing-for/ammunition-supplier' element={<DigitalMarketingCaseStudy heading="Ammunition supplier" link="ammunition-supplier" />} />
              <Route path='/digital-marketing-for/amphitheater' element={<DigitalMarketingCaseStudy heading="Amphitheater" link="amphitheater" />} />
              <Route path='/digital-marketing-for/amusement-machine-supplier' element={<DigitalMarketingCaseStudy heading="Amusement machine supplier" link="amusement-machine-supplier" />} />
              <Route path='/digital-marketing-for/animal-control-service' element={<DigitalMarketingCaseStudy heading="Animal control service" link="animal-control-service" />} />
              <Route path='/digital-marketing-for/animal-shelter' element={<DigitalMarketingCaseStudy heading="Animal shelter" link="animal-shelter" />} />
              <Route path='/digital-marketing-for/animal-watering-hole' element={<DigitalMarketingCaseStudy heading="Animal watering hole" link="animal-watering-hole" />} />
              <Route path='/digital-marketing-for/animation-studio' element={<DigitalMarketingCaseStudy heading="Animation studio" link="animation-studio" />} />
              <Route path='/digital-marketing-for/anodizing-service' element={<DigitalMarketingCaseStudy heading="Anodizing service" link="anodizing-service" />} />
              <Route path='/digital-marketing-for/antique-furniture-restoration-service' element={<DigitalMarketingCaseStudy heading="Antique furniture restoration service" link="antique-furniture-restoration-service" />} />
              <Route path='/digital-marketing-for/antique-store' element={<DigitalMarketingCaseStudy heading="Antique store" link="antique-store" />} />
              <Route path='/digital-marketing-for/aquarium' element={<DigitalMarketingCaseStudy heading="Aquarium" link="aquarium" />} />
              <Route path='/digital-marketing-for/aquarium-shop' element={<DigitalMarketingCaseStudy heading="Aquarium shop" link="aquarium-shop" />} />
              <Route path='/digital-marketing-for/architect' element={<DigitalMarketingCaseStudy heading="Architect" link="architect" />} />
              <Route path='/digital-marketing-for/architectural-&-engineering-model-maker' element={<DigitalMarketingCaseStudy heading="Architectural and engineering model maker" link="architectural-&-engineering-model-maker" />} />
              <Route path='/digital-marketing-for/audi-dealer' element={<DigitalMarketingCaseStudy heading="Audi dealer" link="audi-dealer" />} />
              <Route path='/digital-marketing-for/audio-visual-consultant' element={<DigitalMarketingCaseStudy heading="Audio visual consultant" link="audio-visual-consultant" />} />
              <Route path='/digital-marketing-for/auto-bodywork-mechanic' element={<DigitalMarketingCaseStudy heading="Auto bodywork mechanic" link="auto-bodywork-mechanic" />} />
              <Route path='/digital-marketing-for/auto-chemistry-shop' element={<DigitalMarketingCaseStudy heading="Auto chemistry shop" link="auto-chemistry-shop" />} />
              <Route path='/digital-marketing-for/auto-dent-removal-service' element={<DigitalMarketingCaseStudy heading="Auto dent removal service" link="auto-dent-removal-service" />} />
              <Route path='/digital-marketing-for/auto-radiator-repair-service' element={<DigitalMarketingCaseStudy heading="Auto radiator repair service" link="auto-radiator-repair-service" />} />
              <Route path='/digital-marketing-for/auto-sunroof-shop' element={<DigitalMarketingCaseStudy heading="Auto sunroof shop" link="auto-sunroof-shop" />} />
              <Route path='/digital-marketing-for/auto-upholsterer' element={<DigitalMarketingCaseStudy heading="Auto upholsterer" link="auto-upholsterer" />} />
              <Route path='/digital-marketing-for/awning-supplier' element={<DigitalMarketingCaseStudy heading="Awning supplier" link="awning-supplier" />} />
              <Route path='/digital-marketing-for/ayurvedic-clinic' element={<DigitalMarketingCaseStudy heading="Ayurvedic clinic" link="ayurvedic-clinic" />} />
              <Route path='/digital-marketing-for/baby-clothing-store' element={<DigitalMarketingCaseStudy heading="Baby clothing store" link="baby-clothing-store" />} />
              <Route path='/digital-marketing-for/baby-swimming-school' element={<DigitalMarketingCaseStudy heading="Baby swimming school" link="baby-swimming-school" />} />
              <Route path='/digital-marketing-for/badminton-club' element={<DigitalMarketingCaseStudy heading="Badminton club" link="badminton-club" />} />
              <Route path='/digital-marketing-for/badminton-complex' element={<DigitalMarketingCaseStudy heading="Badminton complex" link="badminton-complex" />} />
              <Route path='/digital-marketing-for/badminton-court' element={<DigitalMarketingCaseStudy heading="Badminton court" link="badminton-court" />} />
              <Route path='/digital-marketing-for/ballet-theater' element={<DigitalMarketingCaseStudy heading="Ballet theater" link="ballet-theater" />} />
              <Route path='/digital-marketing-for/balloon-store' element={<DigitalMarketingCaseStudy heading="Balloon store" link="balloon-store" />} />
              <Route path='/digital-marketing-for/banner-store' element={<DigitalMarketingCaseStudy heading="Banner store" link="banner-store" />} />
              <Route path='/digital-marketing-for/bar-pmu' element={<DigitalMarketingCaseStudy heading="Bar PMU" link="bar-pmu" />} />
              <Route path='/digital-marketing-for/bark-supplier' element={<DigitalMarketingCaseStudy heading="Bark supplier" link="bark-supplier" />} />
              <Route path='/digital-marketing-for/baseball-goods-store' element={<DigitalMarketingCaseStudy heading="Baseball goods store" link="baseball-goods-store" />} />
              <Route path='/digital-marketing-for/basilica' element={<DigitalMarketingCaseStudy heading="Basilica" link="basilica" />} />
              <Route path='/digital-marketing-for/basket-supplier' element={<DigitalMarketingCaseStudy heading="Basket supplier" link="basket-supplier" />} />
              <Route path='/digital-marketing-for/bathroom-supply-store' element={<DigitalMarketingCaseStudy heading="Bathroom supply store" link="bathroom-supply-store" />} />
              <Route path='/digital-marketing-for/bazar' element={<DigitalMarketingCaseStudy heading="Bazar" link="bazar" />} />
              <Route path='/digital-marketing-for/beach-pavillion' element={<DigitalMarketingCaseStudy heading="Beach pavillion" link="beach-pavillion" />} />
              <Route path='/digital-marketing-for/beach-volleyball-court' element={<DigitalMarketingCaseStudy heading="Beach volleyball court" link="beach-volleyball-court" />} />
              <Route path='/digital-marketing-for/bead-store' element={<DigitalMarketingCaseStudy heading="Bead store" link="bead-store" />} />
              <Route path='/digital-marketing-for/bead-wholesaler' element={<DigitalMarketingCaseStudy heading="Bead wholesaler" link="bead-wholesaler" />} />
              <Route path='/digital-marketing-for/bearing-supplier' element={<DigitalMarketingCaseStudy heading="Bearing supplier" link="bearing-supplier" />} />
              <Route path='/digital-marketing-for/beautician' element={<DigitalMarketingCaseStudy heading="Beautician" link="beautician" />} />
              <Route path='/digital-marketing-for/bicycle-rack' element={<DigitalMarketingCaseStudy heading="Bicycle rack" link="bicycle-rack" />} />
              <Route path='/digital-marketing-for/bicycle-rental-service' element={<DigitalMarketingCaseStudy heading="Bicycle rental service" link="bicycle-rental-service" />} />
              <Route path='/digital-marketing-for/bicycle-repair-shop' element={<DigitalMarketingCaseStudy heading="Bicycle repair shop" link="bicycle-repair-shop" />} />
              <Route path='/digital-marketing-for/bike-wash' element={<DigitalMarketingCaseStudy heading="Bike wash" link="bike-wash" />} />
              <Route path='/digital-marketing-for/bikram-yoga-studio' element={<DigitalMarketingCaseStudy heading="Bikram yoga studio" link="bikram-yoga-studio" />} />
              <Route path='/digital-marketing-for/bilingual-school' element={<DigitalMarketingCaseStudy heading="Bilingual school" link="bilingual-school" />} />
              <Route path='/digital-marketing-for/bingo-hall' element={<DigitalMarketingCaseStudy heading="Bingo hall" link="bingo-hall" />} />
              <Route path='/digital-marketing-for/biochemistry-lab' element={<DigitalMarketingCaseStudy heading="Biochemistry lab" link="biochemistry-lab" />} />
              <Route path='/digital-marketing-for/biofeedback-therapist' element={<DigitalMarketingCaseStudy heading="Biofeedback therapist" link="biofeedback-therapist" />} />
              <Route path='/digital-marketing-for/birth-control-center' element={<DigitalMarketingCaseStudy heading="Birth control center" link="birth-control-center" />} />
              <Route path='/digital-marketing-for/blood-testing-service' element={<DigitalMarketingCaseStudy heading="Blood testing service" link="blood-testing-service" />} />
              <Route path='/digital-marketing-for/blues-club' element={<DigitalMarketingCaseStudy heading="Blues club" link="blues-club" />} />
              <Route path='/digital-marketing-for/bmw-motorcycle-dealer' element={<DigitalMarketingCaseStudy heading="BMW motorcycle dealer" link="bmw-motorcycle-dealer" />} />
              <Route path='/digital-marketing-for/bmx-club' element={<DigitalMarketingCaseStudy heading="BMX club" link="bmx-club" />} />
              <Route path='/digital-marketing-for/bmx-park' element={<DigitalMarketingCaseStudy heading="BMX park" link="bmx-park" />} />
              <Route path='/digital-marketing-for/bmx-track' element={<DigitalMarketingCaseStudy heading="BMX track" link="bmx-track" />} />
              <Route path='/digital-marketing-for/board-of-education' element={<DigitalMarketingCaseStudy heading="Board of education" link="board-of-education" />} />
              <Route path='/digital-marketing-for/boarding-house' element={<DigitalMarketingCaseStudy heading="Boarding house" link="boarding-house" />} />
              <Route path='/digital-marketing-for/boat-accessories-supplier' element={<DigitalMarketingCaseStudy heading="Boat accessories supplier" link="boat-accessories-supplier" />} />
              <Route path='/digital-marketing-for/boat-builders' element={<DigitalMarketingCaseStudy heading="Boat builders" link="boat-builders" />} />
              <Route path='/digital-marketing-for/boat-cleaning-service' element={<DigitalMarketingCaseStudy heading="Boat cleaning service" link="boat-cleaning-service" />} />
              <Route path='/digital-marketing-for/boat-club' element={<DigitalMarketingCaseStudy heading="Boat club" link="boat-club" />} />
              <Route path='/digital-marketing-for/boat-detailing-service' element={<DigitalMarketingCaseStudy heading="Boat detailing service" link="boat-detailing-service" />} />
              <Route path='/digital-marketing-for/boat-rental-service' element={<DigitalMarketingCaseStudy heading="Boat rental service" link="boat-rental-service" />} />
              <Route path='/digital-marketing-for/boat-repair-shop' element={<DigitalMarketingCaseStudy heading="Boat repair shop" link="boat-repair-shop" />} />
              <Route path='/digital-marketing-for/boat-storage-facility' element={<DigitalMarketingCaseStudy heading="Boat storage facility" link="boat-storage-facility" />} />
              <Route path='/digital-marketing-for/boat-tour-agency' element={<DigitalMarketingCaseStudy heading="Boat tour agency" link="boat-tour-agency" />} />
              <Route path='/digital-marketing-for/boat-trailer-dealer' element={<DigitalMarketingCaseStudy heading="Boat trailer dealer" link="boat-trailer-dealer" />} />
              <Route path='/digital-marketing-for/boating-instructor' element={<DigitalMarketingCaseStudy heading="Boating instructor" link="boating-instructor" />} />
              <Route path='/digital-marketing-for/body-piercing-shop' element={<DigitalMarketingCaseStudy heading="Body piercing shop" link="body-piercing-shop" />} />
              <Route path='/digital-marketing-for/bonesetting-house' element={<DigitalMarketingCaseStudy heading="Bonesetting house" link="bonesetting-house" />} />
              <Route path='/digital-marketing-for/bookbinder' element={<DigitalMarketingCaseStudy heading="Bookbinder" link="bookbinder" />} />
              <Route path='/digital-marketing-for/boot-repair-shop' element={<DigitalMarketingCaseStudy heading="Boot repair shop" link="boot-repair-shop" />} />
              <Route path='/digital-marketing-for/boot-store' element={<DigitalMarketingCaseStudy heading="Boot store" link="boot-store" />} />
              <Route path='/digital-marketing-for/botanica' element={<DigitalMarketingCaseStudy heading="Botanica" link="botanica" />} />
              <Route path='/digital-marketing-for/bottle-&-can-redemption-center' element={<DigitalMarketingCaseStudy heading="Bottle & can redemption center" link="bottle-&-can-redemption-center" />} />
              <Route path='/digital-marketing-for/bouncy-castle-hire' element={<DigitalMarketingCaseStudy heading="Bouncy castle hire" link="bouncy-castle-hire" />} />
              <Route path='/digital-marketing-for/box-lunch-supplier' element={<DigitalMarketingCaseStudy heading="Box lunch supplier" link="box-lunch-supplier" />} />
              <Route path='/digital-marketing-for/boxing-gym' element={<DigitalMarketingCaseStudy heading="Boxing gym" link="boxing-gym" />} />
              <Route path='/digital-marketing-for/boxing-ring' element={<DigitalMarketingCaseStudy heading="Boxing ring" link="boxing-ring" />} />
              <Route path='/digital-marketing-for/branding-agency' element={<DigitalMarketingCaseStudy heading="Branding agency" link="branding-agency" />} />
              <Route path='/digital-marketing-for/bricklayer' element={<DigitalMarketingCaseStudy heading="Bricklayer" link="bricklayer" />} />
              <Route path='/digital-marketing-for/bubble-tea-store' element={<DigitalMarketingCaseStudy heading="Bubble tea store" link="bubble-tea-store" />} />
              <Route path='/digital-marketing-for/buddhist-temple' element={<DigitalMarketingCaseStudy heading="Buddhist temple" link="buddhist-temple" />} />
              <Route path='/digital-marketing-for/bugatti-dealer' element={<DigitalMarketingCaseStudy heading="Bugatti dealer" link="bugatti-dealer" />} />
              <Route path='/digital-marketing-for/buick-dealer' element={<DigitalMarketingCaseStudy heading="Buick dealer" link="buick-dealer" />} />
              <Route path='/digital-marketing-for/building-design-company' element={<DigitalMarketingCaseStudy heading="Building design company" link="building-design-company" />} />
              <Route path='/digital-marketing-for/building-equipment-hire-service' element={<DigitalMarketingCaseStudy heading="Building equipment hire service" link="building-equipment-hire-service" />} />
              <Route path='/digital-marketing-for/building-materials-market' element={<DigitalMarketingCaseStudy heading="Building materials market" link="building-materials-market" />} />
              <Route path='/digital-marketing-for/building-materials-store' element={<DigitalMarketingCaseStudy heading="Building materials store" link="building-materials-store" />} />
              <Route path='/digital-marketing-for/building-materials-supplier' element={<DigitalMarketingCaseStudy heading="Building materials supplier" link="financial" />} />
              <Route path='/digital-marketing-for/bullring' element={<DigitalMarketingCaseStudy heading="Bullring" link="bullring" />} />
              <Route path='/digital-marketing-for/burglar-alarm-store' element={<DigitalMarketingCaseStudy heading="Burglar alarm store" link="burglar-alarm-store" />} />
              <Route path='/digital-marketing-for/butane-gas-supplier' element={<DigitalMarketingCaseStudy heading="Butane gas supplier" link="butane-gas-supplier" />} />
              <Route path='/digital-marketing-for/butcher-shop' element={<DigitalMarketingCaseStudy heading="Butcher shop" link="butcher-shop" />} />
              <Route path='/digital-marketing-for/butcher-shop-deli' element={<DigitalMarketingCaseStudy heading="Butcher shop deli" link="butcher-shop-deli" />} />
              <Route path='/digital-marketing-for/butsudan-store' element={<DigitalMarketingCaseStudy heading="Butsudan store" link="butsudan-store" />} />
              <Route path='/digital-marketing-for/cable-company' element={<DigitalMarketingCaseStudy heading="Cable company" link="cable-company" />} />
              <Route path='/digital-marketing-for/cadillac-dealer' element={<DigitalMarketingCaseStudy heading="Cadillac dealer" link="cadillac-dealer" />} />
              <Route path='/digital-marketing-for/cafe' element={<DigitalMarketingCaseStudy heading="Cafe" link="cafe" />} />
              <Route path='/digital-marketing-for/cafeteria' element={<DigitalMarketingCaseStudy heading="Cafeteria" link="cafeteria" />} />
              <Route path='/digital-marketing-for/cake-decorating-equipment-shop' element={<DigitalMarketingCaseStudy heading="Cake decorating equipment shop" link="cake-decorating-equipment-shop" />} />
              <Route path='/digital-marketing-for/cake-shop' element={<DigitalMarketingCaseStudy heading="Cake shop" link="cake-shop" />} />
              <Route path='/digital-marketing-for/canoe-&-kayak-rental-service' element={<DigitalMarketingCaseStudy heading="Canoe & kayak rental service" link="canoe-&-kayak-rental-service" />} />
              <Route path='/digital-marketing-for/canoe-&-kayak-store' element={<DigitalMarketingCaseStudy heading="Canoe & kayak store" link="canoe-&-kayak-store" />} />
              <Route path='/digital-marketing-for/canoe-&-kayak-club' element={<DigitalMarketingCaseStudy heading="Canoe and kayak club" link="canoe-&-kayak-club" />} />
              <Route path='/digital-marketing-for/capsule-hotel' element={<DigitalMarketingCaseStudy heading="Capsule hotel" link="capsule-hotel" />} />
              <Route path='/digital-marketing-for/carnival-club' element={<DigitalMarketingCaseStudy heading="Carnival club" link="carnival-club" />} />
              <Route path='/digital-marketing-for/carpet-wholesaler' element={<DigitalMarketingCaseStudy heading="Carpet wholesaler" link="carpet-wholesaler" />} />
              <Route path='/digital-marketing-for/charity' element={<DigitalMarketingCaseStudy heading="Charity" link="charity" />} />
              <Route path='/digital-marketing-for/charter-school' element={<DigitalMarketingCaseStudy heading="Charter school" link="charter-school" />} />
              <Route path='/digital-marketing-for/chauffeur-service' element={<DigitalMarketingCaseStudy heading="Chauffeur service" link="chauffeur-service" />} />
              <Route path='/digital-marketing-for/cheese-shop' element={<DigitalMarketingCaseStudy heading="Cheese shop" link="cheese-shop" />} />
              <Route path='/digital-marketing-for/chevrolet-dealer' element={<DigitalMarketingCaseStudy heading="Chevrolet dealer" link="chevrolet-dealer" />} />
              <Route path='/digital-marketing-for/chicken-hatchery' element={<DigitalMarketingCaseStudy heading="Chicken hatchery" link="chicken-hatchery" />} />
              <Route path='/digital-marketing-for/chicken-shop' element={<DigitalMarketingCaseStudy heading="Chicken shop" link="chicken-shop" />} />
              <Route path='/digital-marketing-for/childminder' element={<DigitalMarketingCaseStudy heading="Childminder" link="childminder" />} />
              <Route path='/digital-marketing-for/children-policlinic' element={<DigitalMarketingCaseStudy heading="Children Policlinic" link="children-policlinic" />} />
              <Route path='/digital-marketing-for/childrens-home' element={<DigitalMarketingCaseStudy heading="Children’s home" link="childrens-home" />} />
              <Route path='/digital-marketing-for/childrens-book-store' element={<DigitalMarketingCaseStudy heading="Childrens book store" link="childrens-book-store" />} />
              <Route path='/digital-marketing-for/childrens-party-buffet' element={<DigitalMarketingCaseStudy heading="Childrens party buffet" link="childrens-party-buffet" />} />
              <Route path='/digital-marketing-for/childrens-store' element={<DigitalMarketingCaseStudy heading="Childrens store" link="childrens-store" />} />
              <Route path='/digital-marketing-for/chinese-bakery' element={<DigitalMarketingCaseStudy heading="Chinese bakery" link="chinese-bakery" />} />
              <Route path='/digital-marketing-for/choir' element={<DigitalMarketingCaseStudy heading="Choir" link="choir" />} />
              <Route path='/digital-marketing-for/churreria' element={<DigitalMarketingCaseStudy heading="Churreria" link="churreria" />} />
              <Route path='/digital-marketing-for/circus' element={<DigitalMarketingCaseStudy heading="Circus" link="circus" />} />
              <Route path='/digital-marketing-for/citizens-advice-bureau' element={<DigitalMarketingCaseStudy heading="Citizens advice bureau" link="citizens-advice-bureau" />} />
              <Route path='/digital-marketing-for/citroen-dealer' element={<DigitalMarketingCaseStudy heading="Citroen dealer" link="citroen-dealer" />} />
              <Route path='/digital-marketing-for/city-clerks-office' element={<DigitalMarketingCaseStudy heading="City clerk’s office" link="city-clerks-office" />} />
              <Route path='/digital-marketing-for/city-pillar-shrine' element={<DigitalMarketingCaseStudy heading="City pillar shrine" link="city-pillar-shrine" />} />
              <Route path='/digital-marketing-for/civic-center' element={<DigitalMarketingCaseStudy heading="Civic center" link="civic-center" />} />
              <Route path='/digital-marketing-for/civil-defense' element={<DigitalMarketingCaseStudy heading="Civil defense" link="civil-defense" />} />
              <Route path='/digital-marketing-for/civil-police' element={<DigitalMarketingCaseStudy heading="Civil police" link="civil-police" />} />
              <Route path='/digital-marketing-for/clock-&-watch-maker' element={<DigitalMarketingCaseStudy heading="Clock and watch maker" link="clock-&-watch-maker" />} />
              <Route path='/digital-marketing-for/clock-repair-service' element={<DigitalMarketingCaseStudy heading="Clock repair service" link="clock-repair-service" />} />
              <Route path='/digital-marketing-for/closed-circuit-television' element={<DigitalMarketingCaseStudy heading="Closed circuit television" link="closed-circuit-television" />} />
              <Route path='/digital-marketing-for/clothes-&-fabric-wholesaler' element={<DigitalMarketingCaseStudy heading="Clothes and fabric wholesaler" link="clothes-&-fabric-wholesaler" />} />
              <Route path='/digital-marketing-for/clothing-alteration-service' element={<DigitalMarketingCaseStudy heading="Clothing alteration service" link="clothing-alteration-service" />} />
              <Route path='/digital-marketing-for/coal-supplier' element={<DigitalMarketingCaseStudy heading="Coal supplier" link="coal-supplier" />} />
              <Route path='/digital-marketing-for/coat-wholesaler' element={<DigitalMarketingCaseStudy heading="Coat wholesaler" link="coat-wholesaler" />} />
              <Route path='/digital-marketing-for/coffee-machine-supplier' element={<DigitalMarketingCaseStudy heading="Coffee machine supplier" link="coffee-machine-supplier" />} />
              <Route path='/digital-marketing-for/coffin-supplier' element={<DigitalMarketingCaseStudy heading="Coffin supplier" link="coffin-supplier" />} />
              <Route path='/digital-marketing-for/comic-book-store' element={<DigitalMarketingCaseStudy heading="Comic book store" link="comic-book-store" />} />
              <Route path='/digital-marketing-for/comic-cafe' element={<DigitalMarketingCaseStudy heading="Comic cafe" link="comic-cafe" />} />
              <Route path='/digital-marketing-for/commercial-refrigeration' element={<DigitalMarketingCaseStudy heading="Commercial refrigeration" link="commercial-refrigeration" />} />
              <Route path='/digital-marketing-for/commercial-refrigerator-supplier' element={<DigitalMarketingCaseStudy heading="Commercial refrigerator supplier" link="commercial-refrigerator-supplier" />} />
              <Route path='/digital-marketing-for/computer-club' element={<DigitalMarketingCaseStudy heading="Computer club" link="computer-club" />} />
              <Route path='/digital-marketing-for/computer-software-store' element={<DigitalMarketingCaseStudy heading="Computer software store" link="computer-software-store" />} />
              <Route path='/digital-marketing-for/computer-training-school' element={<DigitalMarketingCaseStudy heading="Computer training school" link="computer-training-school" />} />
              <Route path='/digital-marketing-for/computer-wholesaler' element={<DigitalMarketingCaseStudy heading="Computer wholesaler" link="computer-wholesaler" />} />
              <Route path='/digital-marketing-for/concrete-metal-framework-supplier' element={<DigitalMarketingCaseStudy heading="Concrete metal framework supplier" link="concrete-metal-framework-supplier" />} />
              <Route path='/digital-marketing-for/concrete-product-supplier' element={<DigitalMarketingCaseStudy heading="Concrete product supplier" link="concrete-product-supplier" />} />
              <Route path='/digital-marketing-for/condiments-supplier' element={<DigitalMarketingCaseStudy heading="Condiments supplier" link="condiments-supplier" />} />
              <Route path='/digital-marketing-for/confectionery' element={<DigitalMarketingCaseStudy heading="Confectionery" link="confectionery" />} />
              <Route path='/digital-marketing-for/confectionery-wholesaler' element={<DigitalMarketingCaseStudy heading="Confectionery wholesaler" link="confectionery-wholesaler" />} />
              <Route path='/digital-marketing-for/conservative-club' element={<DigitalMarketingCaseStudy heading="Conservative club" link="conservative-club" />} />
              <Route path='/digital-marketing-for/conservatory-construction-contractor' element={<DigitalMarketingCaseStudy heading="Conservatory construction contractor" link="conservatory-construction-contractor" />} />
              <Route path='/digital-marketing-for/construction-machine-dealer' element={<DigitalMarketingCaseStudy heading="Construction machine dealer" link="construction-machine-dealer" />} />
              <Route path='/digital-marketing-for/consultant' element={<DigitalMarketingCaseStudy heading="Consultant" link="consultant" />} />
              <Route path='/digital-marketing-for/contact-lenses-supplier' element={<DigitalMarketingCaseStudy heading="Contact lenses supplier" link="contact-lenses-supplier" />} />
              <Route path='/digital-marketing-for/container-service' element={<DigitalMarketingCaseStudy heading="Container service" link="container-service" />} />
              <Route path='/digital-marketing-for/convent' element={<DigitalMarketingCaseStudy heading="Convent" link="convent" />} />
              <Route path='/digital-marketing-for/cooling-plant' element={<DigitalMarketingCaseStudy heading="Cooling plant" link="cooling-plant" />} />
              <Route path='/digital-marketing-for/copper-supplier' element={<DigitalMarketingCaseStudy heading="Copper supplier" link="copper-supplier" />} />
              <Route path='/digital-marketing-for/coppersmith' element={<DigitalMarketingCaseStudy heading="Coppersmith" link="coppersmith" />} />
              <Route path='/digital-marketing-for/copying-supply-store' element={<DigitalMarketingCaseStudy heading="Copying supply store" link="copying-supply-store" />} />
              <Route path='/digital-marketing-for/correctional-services-department' element={<DigitalMarketingCaseStudy heading="Correctional services department" link="correctional-services-department" />} />
              <Route path='/digital-marketing-for/cosmetic-dentist' element={<DigitalMarketingCaseStudy heading="Cosmetic dentist" link="cosmetic-dentist" />} />
              <Route path='/digital-marketing-for/cosmetics-&-perfumes-supplier' element={<DigitalMarketingCaseStudy heading="Cosmetics and perfumes supplier" link="cosmetics-&-perfumes-supplier" />} />
              <Route path='/digital-marketing-for/cosmetics-wholesaler' element={<DigitalMarketingCaseStudy heading="Cosmetics wholesaler" link="cosmetics-wholesaler" />} />
              <Route path='/digital-marketing-for/cosplay-cafe' element={<DigitalMarketingCaseStudy heading="Cosplay cafe" link="cosplay-cafe" />} />
              <Route path='/digital-marketing-for/costume-rental-service' element={<DigitalMarketingCaseStudy heading="Costume rental service" link="costume-rental-service" />} />
              <Route path='/digital-marketing-for/cottage' element={<DigitalMarketingCaseStudy heading="Cottage" link="cottage" />} />
              <Route path='/digital-marketing-for/cottage-rental' element={<DigitalMarketingCaseStudy heading="Cottage rental" link="cottage-rental" />} />
              <Route path='/digital-marketing-for/cotton-exporter' element={<DigitalMarketingCaseStudy heading="Cotton exporter" link="cotton-exporter" />} />
              <Route path='/digital-marketing-for/cotton-mill' element={<DigitalMarketingCaseStudy heading="Cotton mill" link="cotton-mill" />} />
              <Route path='/digital-marketing-for/cotton-supplier' element={<DigitalMarketingCaseStudy heading="Cotton supplier" link="cotton-supplier" />} />
              <Route path='/digital-marketing-for/country-park' element={<DigitalMarketingCaseStudy heading="Country park" link="country-park" />} />
              <Route path='/digital-marketing-for/craft-store' element={<DigitalMarketingCaseStudy heading="Craft store" link="craft-store" />} />
              <Route path='/digital-marketing-for/cramming-school' element={<DigitalMarketingCaseStudy heading="Cramming school" link="cramming-school" />} />
              <Route path='/digital-marketing-for/crane-dealer' element={<DigitalMarketingCaseStudy heading="Crane dealer" link="crane-dealer" />} />
              <Route path='/digital-marketing-for/crane-service' element={<DigitalMarketingCaseStudy heading="Crane service" link="crane-service" />} />
              <Route path='/digital-marketing-for/craniosacral-therapy' element={<DigitalMarketingCaseStudy heading="Craniosacral therapy" link="craniosacral-therapy" />} />
              <Route path='/digital-marketing-for/credit-counseling-service' element={<DigitalMarketingCaseStudy heading="Credit counseling service" link="credit-counseling-service" />} />
              <Route path='/digital-marketing-for/crop-grower' element={<DigitalMarketingCaseStudy heading="Crop grower" link="crop-grower" />} />
              <Route path='/digital-marketing-for/croquet-club' element={<DigitalMarketingCaseStudy heading="Croquet club" link="croquet-club" />} />
              <Route path='/digital-marketing-for/cruise-line-company' element={<DigitalMarketingCaseStudy heading="Cruise line company" link="cruise-line-company" />} />
              <Route path='/digital-marketing-for/cruise-terminal' element={<DigitalMarketingCaseStudy heading="Cruise terminal" link="cruise-terminal" />} />
              <Route path='/digital-marketing-for/culinary-school' element={<DigitalMarketingCaseStudy heading="Culinary school" link="culinary-school" />} />
              <Route path='/digital-marketing-for/cured-ham-bar' element={<DigitalMarketingCaseStudy heading="Cured ham bar" link="cured-ham-bar" />} />
              <Route path='/digital-marketing-for/cured-ham-store' element={<DigitalMarketingCaseStudy heading="Cured ham store" link="cured-ham-store" />} />
              <Route path='/digital-marketing-for/cured-ham-warehouse' element={<DigitalMarketingCaseStudy heading="Cured ham warehouse" link="cured-ham-warehouse" />} />
              <Route path='/digital-marketing-for/curtain-&-upholstery-cleaning-service' element={<DigitalMarketingCaseStudy heading="Curtain and upholstery cleaning service" link="curtain-&-upholstery-cleaning-service" />} />
              <Route path='/digital-marketing-for/curtain-store' element={<DigitalMarketingCaseStudy heading="Curtain store" link="curtain-store" />} />
              <Route path='/digital-marketing-for/curtain-supplier-&-maker' element={<DigitalMarketingCaseStudy heading="Curtain supplier and maker" link="curtain-supplier-&-maker" />} />
              <Route path='/digital-marketing-for/cutlery-store' element={<DigitalMarketingCaseStudy heading="Cutlery store" link="cutlery-store" />} />
              <Route path='/digital-marketing-for/dairy' element={<DigitalMarketingCaseStudy heading="Dairy" link="dairy" />} />
              <Route path='/digital-marketing-for/dairy-farm' element={<DigitalMarketingCaseStudy heading="Dairy farm" link="dairy-farm" />} />
              <Route path='/digital-marketing-for/dart-bar' element={<DigitalMarketingCaseStudy heading="Dart bar" link="dart-bar" />} />
              <Route path='/digital-marketing-for/database-management-company' element={<DigitalMarketingCaseStudy heading="Database management company" link="database-management-company" />} />
              <Route path='/digital-marketing-for/deck-builder' element={<DigitalMarketingCaseStudy heading="Deck builder" link="deck-builder" />} />
              <Route path='/digital-marketing-for/dental-implants-periodontist' element={<DigitalMarketingCaseStudy heading="Dental implants periodontist" link="dental-implants-periodontist" />} />
              <Route path='/digital-marketing-for/diabetes-center' element={<DigitalMarketingCaseStudy heading="Diabetes center" link="diabetes-center" />} />
              <Route path='/digital-marketing-for/diabetologist' element={<DigitalMarketingCaseStudy heading="Diabetologist" link="diabetologist" />} />
              <Route path='/digital-marketing-for/diagnostic-center' element={<DigitalMarketingCaseStudy heading="Diagnostic center" link="diagnostic-center" />} />
              <Route path='/digital-marketing-for/dialysis-center' element={<DigitalMarketingCaseStudy heading="Dialysis center" link="dialysis-center" />} />
              <Route path='/digital-marketing-for/diamond-buyer' element={<DigitalMarketingCaseStudy heading="Diamond buyer" link="diamond-buyer" />} />
              <Route path='/digital-marketing-for/direct-mail-advertising' element={<DigitalMarketingCaseStudy heading="Direct mail advertising" link="direct-mail-advertising" />} />
              <Route path='/digital-marketing-for/disc-golf-course' element={<DigitalMarketingCaseStudy heading="Disc golf course" link="disc-golf-course" />} />
              <Route path='/digital-marketing-for/disco-club' element={<DigitalMarketingCaseStudy heading="Disco club" link="disco-club" />} />
              <Route path='/digital-marketing-for/discount-supermarket' element={<DigitalMarketingCaseStudy heading="Discount supermarket" link="discount-supermarket" />} />
              <Route path='/digital-marketing-for/display-home-centre' element={<DigitalMarketingCaseStudy heading="Display home centre" link="display-home-centre" />} />
              <Route path='/digital-marketing-for/display-stand-manufacturer' element={<DigitalMarketingCaseStudy heading="Display stand manufacturer" link="display-stand-manufacturer" />} />
              <Route path='/digital-marketing-for/disposable-tableware-supplier' element={<DigitalMarketingCaseStudy heading="Disposable tableware supplier" link="disposable-tableware-supplier" />} />
              <Route path='/digital-marketing-for/distillery' element={<DigitalMarketingCaseStudy heading="Distillery" link="distillery" />} />
              <Route path='/digital-marketing-for/diving-center' element={<DigitalMarketingCaseStudy heading="Diving center" link="diving-center" />} />
              <Route path='/digital-marketing-for/dj-service' element={<DigitalMarketingCaseStudy heading="DJ service" link="dj-service" />} />
              <Route path='/digital-marketing-for/dogsled-ride-service' element={<DigitalMarketingCaseStudy heading="Dogsled ride service" link="dogsled-ride-service" />} />
              <Route path='/digital-marketing-for/donations-center' element={<DigitalMarketingCaseStudy heading="Donations center" link="donations-center" />} />
              <Route path='/digital-marketing-for/double-glazing-installer' element={<DigitalMarketingCaseStudy heading="Double glazing installer" link="double-glazing-installer" />} />
              <Route path='/digital-marketing-for/drainage-service' element={<DigitalMarketingCaseStudy heading="Drainage service" link="drainage-service" />} />
              <Route path='/digital-marketing-for/drinking-water-fountain' element={<DigitalMarketingCaseStudy heading="Drinking water fountain" link="drinking-water-fountain" />} />
              <Route path='/digital-marketing-for/driver-&-vehicle-licensing-agency' element={<DigitalMarketingCaseStudy heading="Driver and vehicle licensing agency" link="driver-&-vehicle-licensing-agency" />} />
              <Route path='/digital-marketing-for/drone-shop' element={<DigitalMarketingCaseStudy heading="Drone shop" link="drone-shop" />} />
              <Route path='/digital-marketing-for/drum-school' element={<DigitalMarketingCaseStudy heading="Drum school" link="drum-school" />} />
              <Route path='/digital-marketing-for/drum-store' element={<DigitalMarketingCaseStudy heading="Drum store" link="drum-store" />} />
              <Route path='/digital-marketing-for/dry-fruit-store' element={<DigitalMarketingCaseStudy heading="Dry fruit store" link="dry-fruit-store" />} />
              <Route path='/digital-marketing-for/dry-ice-supplier' element={<DigitalMarketingCaseStudy heading="Dry ice supplier" link="dry-ice-supplier" />} />
              <Route path='/digital-marketing-for/dry-wall-contractor' element={<DigitalMarketingCaseStudy heading="Dry wall contractor" link="dry-wall-contractor" />} />
              <Route path='/digital-marketing-for/dry-wall-supply-store' element={<DigitalMarketingCaseStudy heading="Dry wall supply store" link="dry-wall-supply-store" />} />
              <Route path='/digital-marketing-for/dude-ranch' element={<DigitalMarketingCaseStudy heading="Dude ranch" link="dude-ranch" />} />
              <Route path='/digital-marketing-for/dynamometer-supplier' element={<DigitalMarketingCaseStudy heading="Dynamometer supplier" link="dynamometer-supplier" />} />
              <Route path='/digital-marketing-for/egg-supplier' element={<DigitalMarketingCaseStudy heading="Egg supplier" link="egg-supplier" />} />
              <Route path='/digital-marketing-for/electric-motor-store' element={<DigitalMarketingCaseStudy heading="Electric motor store" link="electric-motor-store" />} />
              <Route path='/digital-marketing-for/electrical-appliance-wholesaler' element={<DigitalMarketingCaseStudy heading="Electrical appliance wholesaler" link="electrical-appliance-wholesaler" />} />
              <Route path='/digital-marketing-for/electrical-substation' element={<DigitalMarketingCaseStudy heading="Electrical substation" link="electrical-substation" />} />
              <Route path='/digital-marketing-for/electrolysis-hair-removal-service' element={<DigitalMarketingCaseStudy heading="Electrolysis hair removal service" link="electrolysis-hair-removal-service" />} />
              <Route path='/digital-marketing-for/electronics-company' element={<DigitalMarketingCaseStudy heading="Electronics company" link="electronics-company" />} />
              <Route path='/digital-marketing-for/electronics-engineer' element={<DigitalMarketingCaseStudy heading="Electronics engineer" link="electronics-engineer" />} />
              <Route path='/digital-marketing-for/electronics-repair-shop' element={<DigitalMarketingCaseStudy heading="Electronics repair shop" link="electronics-repair-shop" />} />
              <Route path='/digital-marketing-for/embassy' element={<DigitalMarketingCaseStudy heading="Embassy" link="embassy" />} />
              <Route path='/digital-marketing-for/embossing-service' element={<DigitalMarketingCaseStudy heading="Embossing service" link="embossing-service" />} />
              <Route path='/digital-marketing-for/embroidery-service' element={<DigitalMarketingCaseStudy heading="Embroidery service" link="embroidery-service" />} />
              <Route path='/digital-marketing-for/embroidery-shop' element={<DigitalMarketingCaseStudy heading="Embroidery shop" link="embroidery-shop" />} />
              <Route path='/digital-marketing-for/endocrinologist' element={<DigitalMarketingCaseStudy heading="Endocrinologist" link="endocrinologist" />} />
              <Route path='/digital-marketing-for/energy-supplier' element={<DigitalMarketingCaseStudy heading="Energy supplier" link="energy-supplier" />} />
              <Route path='/digital-marketing-for/engraver' element={<DigitalMarketingCaseStudy heading="Engraver" link="engraver" />} />
              <Route path='/digital-marketing-for/environmental-attorney' element={<DigitalMarketingCaseStudy heading="Environmental attorney" link="environmental-attorney" />} />
              <Route path='/digital-marketing-for/evening-dress-rental-service' element={<DigitalMarketingCaseStudy heading="Evening dress rental service" link="evening-dress-rental-service" />} />
              <Route path='/digital-marketing-for/fabric-store' element={<DigitalMarketingCaseStudy heading="Fabric store" link="fabric-store" />} />
              <Route path='/digital-marketing-for/farm-school' element={<DigitalMarketingCaseStudy heading="Farm school" link="farm-school" />} />
              <Route path='/digital-marketing-for/feed-manufacturer' element={<DigitalMarketingCaseStudy heading="Feed manufacturer" link="feed-manufacturer" />} />
              <Route path='/digital-marketing-for/feng-shui-consultant' element={<DigitalMarketingCaseStudy heading="Feng shui consultant" link="feng-shui-consultant" />} />
              <Route path='/digital-marketing-for/ferrari-dealer' element={<DigitalMarketingCaseStudy heading="Ferrari dealer" link="ferrari-dealer" />} />
              <Route path='/digital-marketing-for/fertilizer-supplier' element={<DigitalMarketingCaseStudy heading="Fertilizer supplier" link="fertilizer-supplier" />} />
              <Route path='/digital-marketing-for/festival-hall' element={<DigitalMarketingCaseStudy heading="Festival hall" link="festival-hall" />} />
              <Route path='/digital-marketing-for/fiber-optic-products-supplier' element={<DigitalMarketingCaseStudy heading="Fiber optic products supplier" link="fiber-optic-products-supplier" />} />
              <Route path='/digital-marketing-for/fireworks-store' element={<DigitalMarketingCaseStudy heading="Fireworks store" link="fireworks-store" />} />
              <Route path='/digital-marketing-for/fireworks-supplier' element={<DigitalMarketingCaseStudy heading="Fireworks supplier" link="fireworks-supplier" />} />
              <Route path='/digital-marketing-for/first-aid-station' element={<DigitalMarketingCaseStudy heading="First aid station" link="first-aid-station" />} />
              <Route path='/digital-marketing-for/fish-&-chips-takeaway' element={<DigitalMarketingCaseStudy heading="Fish and chips takeaway" link="fish-&-chips-takeaway" />} />
              <Route path='/digital-marketing-for/fish-farm' element={<DigitalMarketingCaseStudy heading="Fish farm" link="fish-farm" />} />
              <Route path='/digital-marketing-for/fish-processing' element={<DigitalMarketingCaseStudy heading="Fish processing" link="fish-processing" />} />
              <Route path='/digital-marketing-for/fishing-pier' element={<DigitalMarketingCaseStudy heading="Fishing pier" link="fishing-pier" />} />
              <Route path='/digital-marketing-for/fixed-base-operator' element={<DigitalMarketingCaseStudy heading="Fixed-base operator" link="fixed-base-operator" />} />
              <Route path='/digital-marketing-for/flamenco-dance-store' element={<DigitalMarketingCaseStudy heading="Flamenco dance store" link="flamenco-dance-store" />} />
              <Route path='/digital-marketing-for/flamenco-school' element={<DigitalMarketingCaseStudy heading="Flamenco school" link="flamenco-school" />} />
              <Route path='/digital-marketing-for/flamenco-theater' element={<DigitalMarketingCaseStudy heading="Flamenco theater" link="flamenco-theater" />} />
              <Route path='/digital-marketing-for/flavours-fragrances-&-aroma-supplier' element={<DigitalMarketingCaseStudy heading="Flavours fragrances and aroma supplier" link="flavours-fragrances-&-aroma-supplier" />} />
              <Route path='/digital-marketing-for/flea-market' element={<DigitalMarketingCaseStudy heading="Flea market" link="flea-market" />} />
              <Route path='/digital-marketing-for/floating-market' element={<DigitalMarketingCaseStudy heading="Floating market" link="floating-market" />} />
              <Route path='/digital-marketing-for/flour-mill' element={<DigitalMarketingCaseStudy heading="Flour mill" link="flour-mill" />} />
              <Route path='/digital-marketing-for/foie-gras-producer' element={<DigitalMarketingCaseStudy heading="Foie gras producer" link="foie-gras-producer" />} />
              <Route path='/digital-marketing-for/footwear-wholesaler' element={<DigitalMarketingCaseStudy heading="Footwear wholesaler" link="footwear-wholesaler" />} />
              <Route path='/digital-marketing-for/foreclosure-service' element={<DigitalMarketingCaseStudy heading="Foreclosure service" link="foreclosure-service" />} />
              <Route path='/digital-marketing-for/foreman-builders-association' element={<DigitalMarketingCaseStudy heading="Foreman builders association" link="foreman-builders-association" />} />
              <Route path='/digital-marketing-for/foster-care-service' element={<DigitalMarketingCaseStudy heading="Foster care service" link="foster-care-service" />} />
              <Route path='/digital-marketing-for/foundation' element={<DigitalMarketingCaseStudy heading="Foundation" link="foundation" />} />
              <Route path='/digital-marketing-for/foundry' element={<DigitalMarketingCaseStudy heading="Foundry" link="foundry" />} />
              <Route path='/digital-marketing-for/fountain-contractor' element={<DigitalMarketingCaseStudy heading="Fountain contractor" link="fountain-contractor" />} />
              <Route path='/digital-marketing-for/fried-chicken-takeaway' element={<DigitalMarketingCaseStudy heading="Fried chicken takeaway" link="fried-chicken-takeaway" />} />
              <Route path='/digital-marketing-for/fruit-&-vegetable-wholesaler' element={<DigitalMarketingCaseStudy heading="Fruit and vegetable wholesaler" link="fruit-&-vegetable-wholesaler" />} />
              <Route path='/digital-marketing-for/fruit-parlor' element={<DigitalMarketingCaseStudy heading="Fruit parlor" link="fruit-parlor" />} />
              <Route path='/digital-marketing-for/fruit-wholesaler' element={<DigitalMarketingCaseStudy heading="Fruit wholesaler" link="fruit-wholesaler" />} />
              <Route path='/digital-marketing-for/fruits-wholesaler' element={<DigitalMarketingCaseStudy heading="Fruits wholesaler" link="fruits-wholesaler" />} />
              <Route path='/digital-marketing-for/fuel-supplier' element={<DigitalMarketingCaseStudy heading="Fuel supplier" link="fuel-supplier" />} />
              <Route path='/digital-marketing-for/furnished-apartment-building' element={<DigitalMarketingCaseStudy heading="Furnished apartment building" link="furnished-apartment-building" />} />
              <Route path='/digital-marketing-for/furniture-wholesaler' element={<DigitalMarketingCaseStudy heading="Furniture wholesaler" link="furniture-wholesaler" />} />
              <Route path='/digital-marketing-for/geological-service' element={<DigitalMarketingCaseStudy heading="Geological service" link="geological-service" />} />
              <Route path='/digital-marketing-for/glass-&-mirror-shop' element={<DigitalMarketingCaseStudy heading="Glass & mirror shop" link="glass-&-mirror-shop" />} />
              <Route path='/digital-marketing-for/glass-block-supplier' element={<DigitalMarketingCaseStudy heading="Glass block supplier" link="glass-block-supplier" />} />
              <Route path='/digital-marketing-for/glass-etching-service' element={<DigitalMarketingCaseStudy heading="Glass etching service" link="glass-etching-service" />} />
              <Route path='/digital-marketing-for/glassware-manufacturer' element={<DigitalMarketingCaseStudy heading="Glassware manufacturer" link="glassware-manufacturer" />} />
              <Route path='/digital-marketing-for/glassware-store' element={<DigitalMarketingCaseStudy heading="Glassware store" link="glassware-store" />} />
              <Route path='/digital-marketing-for/gravel-pit' element={<DigitalMarketingCaseStudy heading="Gravel pit" link="gravel-pit" />} />
              <Route path='/digital-marketing-for/gravel-plant' element={<DigitalMarketingCaseStudy heading="Gravel plant" link="gravel-plant" />} />
              <Route path='/digital-marketing-for/greco-roman-wrestling' element={<DigitalMarketingCaseStudy heading="Greco-Roman wrestling" link="greco-roman-wrestling" />} />
              <Route path='/digital-marketing-for/haberdashery' element={<DigitalMarketingCaseStudy heading="Haberdashery" link="haberdashery" />} />
              <Route path='/digital-marketing-for/halfway-house' element={<DigitalMarketingCaseStudy heading="Halfway house" link="halfway-house" />} />
              <Route path='/digital-marketing-for/hammam' element={<DigitalMarketingCaseStudy heading="Hammam" link="hammam" />} />
              <Route path='/digital-marketing-for/handball-court' element={<DigitalMarketingCaseStudy heading="Handball court" link="handball-court" />} />
              <Route path='/digital-marketing-for/handicraft-fair' element={<DigitalMarketingCaseStudy heading="Handicraft fair" link="handicraft-fair" />} />
              <Route path='/digital-marketing-for/hang-gliding-center' element={<DigitalMarketingCaseStudy heading="Hang gliding center" link="hang-gliding-center" />} />
              <Route path='/digital-marketing-for/hardware-training-institute' element={<DigitalMarketingCaseStudy heading="Hardware training institute" link="hardware-training-institute" />} />
              <Route path='/digital-marketing-for/haute-couture-fashion-house' element={<DigitalMarketingCaseStudy heading="Haute couture fashion house" link="haute-couture-fashion-house" />} />
              <Route path='/digital-marketing-for/health-spa' element={<DigitalMarketingCaseStudy heading="Health spa" link="health-spa" />} />
              <Route path='/digital-marketing-for/heating-oil-supplier' element={<DigitalMarketingCaseStudy heading="Heating oil supplier" link="heating-oil-supplier" />} />
              <Route path='/digital-marketing-for/helicopter-charter' element={<DigitalMarketingCaseStudy heading="Helicopter charter" link="helicopter-charter" />} />
              <Route path='/digital-marketing-for/high-ropes-course' element={<DigitalMarketingCaseStudy heading="High ropes course" link="high-ropes-course" />} />
              <Route path='/digital-marketing-for/hiking-area' element={<DigitalMarketingCaseStudy heading="Hiking area" link="hiking-area" />} />
              <Route path='/digital-marketing-for/hindu-priest' element={<DigitalMarketingCaseStudy heading="Hindu priest" link="hindu-priest" />} />
              <Route path='/digital-marketing-for/hiv-testing-center' element={<DigitalMarketingCaseStudy heading="HIV testing center" link="hiv-testing-center" />} />
              <Route path='/digital-marketing-for/hockey-field' element={<DigitalMarketingCaseStudy heading="Hockey field" link="hockey-field" />} />
              <Route path='/digital-marketing-for/holistic-medicine-practitioner' element={<DigitalMarketingCaseStudy heading="Holistic medicine practitioner" link="holistic-medicine-practitioner" />} />
              <Route path='/digital-marketing-for/home-cinema-installation' element={<DigitalMarketingCaseStudy heading="Home cinema installation" link="home-cinema-installation" />} />
              <Route path='/digital-marketing-for/homeless-service' element={<DigitalMarketingCaseStudy heading="Homeless service" link="homeless-service" />} />
              <Route path='/digital-marketing-for/homeless-shelter' element={<DigitalMarketingCaseStudy heading="Homeless shelter" link="homeless-shelter" />} />
              <Route path='/digital-marketing-for/homeopath' element={<DigitalMarketingCaseStudy heading="Homeopath" link="homeopath" />} />
              <Route path='/digital-marketing-for/homeopathic-pharmacy' element={<DigitalMarketingCaseStudy heading="Homeopathic pharmacy" link="homeopathic-pharmacy" />} />
              <Route path='/digital-marketing-for/hookah-bar' element={<DigitalMarketingCaseStudy heading="Hookah bar" link="hookah-bar" />} />
              <Route path='/digital-marketing-for/hookah-store' element={<DigitalMarketingCaseStudy heading="Hookah store" link="hookah-store" />} />
              <Route path='/digital-marketing-for/horse-boarding-stable' element={<DigitalMarketingCaseStudy heading="Horse boarding stable" link="horse-boarding-stable" />} />
              <Route path='/digital-marketing-for/horse-riding-field' element={<DigitalMarketingCaseStudy heading="Horse riding field" link="horse-riding-field" />} />
              <Route path='/digital-marketing-for/horsestable-studfarm' element={<DigitalMarketingCaseStudy heading="Horsestable studfarm" link="horsestable-studfarm" />} />
              <Route path='/digital-marketing-for/hostel' element={<DigitalMarketingCaseStudy heading="Hostel" link="hostel" />} />
              <Route path='/digital-marketing-for/hot-bedstone-spa' element={<DigitalMarketingCaseStudy heading="Hot bedstone spa" link="hot-bedstone-spa" />} />
              <Route path='/digital-marketing-for/houseboat-rental-service' element={<DigitalMarketingCaseStudy heading="Houseboat rental service" link="houseboat-rental-service" />} />
              <Route path='/digital-marketing-for/household-goods-wholesaler' element={<DigitalMarketingCaseStudy heading="Household goods wholesaler" link="household-goods-wholesaler" />} />
              <Route path='/digital-marketing-for/hua-gong-shop' element={<DigitalMarketingCaseStudy heading="Hua gong shop" link="hua-gong-shop" />} />
              <Route path='/digital-marketing-for/hua-niao-market-place' element={<DigitalMarketingCaseStudy heading="Hua niao market place" link="hua-niao-market-place" />} />
              <Route path='/digital-marketing-for/hunting-preserve' element={<DigitalMarketingCaseStudy heading="Hunting preserve" link="hunting-preserve" />} />
              <Route path='/digital-marketing-for/hydraulic-engineer' element={<DigitalMarketingCaseStudy heading="Hydraulic engineer" link="hydraulic-engineer" />} />
              <Route path='/digital-marketing-for/hydraulic-equipment-supplier' element={<DigitalMarketingCaseStudy heading="Hydraulic equipment supplier" link="hydraulic-equipment-supplier" />} />
              <Route path='/digital-marketing-for/hydraulic-repair-service' element={<DigitalMarketingCaseStudy heading="Hydraulic repair service" link="hydraulic-repair-service" />} />
              <Route path='/digital-marketing-for/hydroelectric-power-plant' element={<DigitalMarketingCaseStudy heading="Hydroelectric power plant" link="hydroelectric-power-plant" />} />
              <Route path='/digital-marketing-for/hygiene-articles-wholesaler' element={<DigitalMarketingCaseStudy heading="Hygiene articles wholesaler" link="hygiene-articles-wholesale" />} />
              <Route path='/digital-marketing-for/hypermarket' element={<DigitalMarketingCaseStudy heading="Hypermarket" link="hypermarket" />} />
              <Route path='/digital-marketing-for/hyundai-dealer' element={<DigitalMarketingCaseStudy heading="Hyundai dealer" link="hyundai-dealer" />} />
              <Route path='/digital-marketing-for/ice-cream-equipment-supplier' element={<DigitalMarketingCaseStudy heading="Ice cream equipment supplier" link="ice-cream-equipment-supplier" />} />
              <Route path='/digital-marketing-for/ice-hockey-club' element={<DigitalMarketingCaseStudy heading="Ice hockey club" link="ice-hockey-club" />} />
              <Route path='/digital-marketing-for/ice-skating-instructor' element={<DigitalMarketingCaseStudy heading="Ice skating instructor" link="ice-skating-instructo" />} />
              <Route path='/digital-marketing-for/icse-school' element={<DigitalMarketingCaseStudy heading="ICSE school" link="icse-school" />} />
              <Route path='/digital-marketing-for/imax-theater' element={<DigitalMarketingCaseStudy heading="IMAX theater" link="imax-theater" />} />
              <Route path='/digital-marketing-for/import-export-company' element={<DigitalMarketingCaseStudy heading="Import export company" link="import-export-company" />} />
              <Route path='/digital-marketing-for/importer' element={<DigitalMarketingCaseStudy heading="Importer" link="importer" />} />
              <Route path='/digital-marketing-for/incense-supplier' element={<DigitalMarketingCaseStudy heading="Incense supplier" link="incense-supplier" />} />
              <Route path='/digital-marketing-for/indian-grocery-store' element={<DigitalMarketingCaseStudy heading="Indian grocery store" link="indian-grocery-store" />} />
              <Route path='/digital-marketing-for/indian-sweets-shop' element={<DigitalMarketingCaseStudy heading="Indian sweets shop" link="indian-sweets-shop" />} />
              <Route path='/digital-marketing-for/indoor-playground' element={<DigitalMarketingCaseStudy heading="Indoor playground" link="indoor-playground" />} />
              <Route path='/digital-marketing-for/internal-medicine-ward' element={<DigitalMarketingCaseStudy heading="Internal medicine ward" link="internal-medicine-ward" />} />
              <Route path='/digital-marketing-for/internet-cafe' element={<DigitalMarketingCaseStudy heading="Internet cafe" link="internet-cafe" />} />
              <Route path='/digital-marketing-for/invitation-printing-service' element={<DigitalMarketingCaseStudy heading="Invitation printing service" link="invitation-printing-service" />} />
              <Route path='/digital-marketing-for/isuzu-dealer' element={<DigitalMarketingCaseStudy heading="Isuzu dealer" link="isuzu-dealer" />} />
              <Route path='/digital-marketing-for/jaguar-dealer' element={<DigitalMarketingCaseStudy heading="Jaguar dealer" link="jaguar-dealer" />} />
              <Route path='/digital-marketing-for/japanese-cheap-sweets-shop' element={<DigitalMarketingCaseStudy heading="Japanese cheap sweets shop" link="japanese-cheap-sweets-shop" />} />
              <Route path='/digital-marketing-for/jazz-club' element={<DigitalMarketingCaseStudy heading="Jazz club" link="jazz-club" />} />
              <Route path='/digital-marketing-for/jeans-shop' element={<DigitalMarketingCaseStudy heading="Jeans shop" link="jeans-shop" />} />
              <Route path='/digital-marketing-for/jehovahs-witness-kingdom-hall' element={<DigitalMarketingCaseStudy heading="Jehovah’s Witness Kingdom Hall" link="jehovahs-witness-kingdom-hall" />} />
              <Route path='/digital-marketing-for/judo-club' element={<DigitalMarketingCaseStudy heading="Judo club" link="judo-club" />} />
              <Route path='/digital-marketing-for/juice-shop' element={<DigitalMarketingCaseStudy heading="Juice shop" link="juice-shop" />} />
              <Route path='/digital-marketing-for/junkyard' element={<DigitalMarketingCaseStudy heading="Junkyard" link="junkyard" />} />
              <Route path='/digital-marketing-for/jute-exporter' element={<DigitalMarketingCaseStudy heading="Jute exporter" link="jute-exporter" />} />
              <Route path='/digital-marketing-for/jute-mill' element={<DigitalMarketingCaseStudy heading="Jute mill" link="jute-mill" />} />
              <Route path='/digital-marketing-for/juvenile-detention-center' element={<DigitalMarketingCaseStudy heading="Juvenile detention center" link="juvenile-detention-center" />} />
              <Route path='/digital-marketing-for/kabaddi-club' element={<DigitalMarketingCaseStudy heading="Kabaddi club" link="kabaddi-club" />} />
              <Route path='/digital-marketing-for/karaoke' element={<DigitalMarketingCaseStudy heading="Karaoke" link="karaoke" />} />
              <Route path='/digital-marketing-for/karaoke-equipment-rental-service' element={<DigitalMarketingCaseStudy heading="Karaoke equipment rental service" link="karaoke-equipment-rental-service" />} />
              <Route path='/digital-marketing-for/kebab-shop' element={<DigitalMarketingCaseStudy heading="Kebab shop" link="kebab-shop" />} />
              <Route path='/digital-marketing-for/kindergarten' element={<DigitalMarketingCaseStudy heading="Kindergarten" link="kindergarten" />} />
              <Route path='/digital-marketing-for/kite-shop' element={<DigitalMarketingCaseStudy heading="Kite shop" link="kite-shop" />} />
              <Route path='/digital-marketing-for/knit-shop' element={<DigitalMarketingCaseStudy heading="Knit shop" link="knit-shop" />} />
              <Route path='/digital-marketing-for/knitting-instructor' element={<DigitalMarketingCaseStudy heading="Knitting instructor" link="knitting-instructor" />} />
              <Route path='/digital-marketing-for/kosher-grocery-store' element={<DigitalMarketingCaseStudy heading="Kosher grocery store" link="kosher-grocery-store" />} />
              <Route path='/digital-marketing-for/labor-union' element={<DigitalMarketingCaseStudy heading="Labor union" link="labor-union" />} />
              <Route path='/digital-marketing-for/laboratory-equipment-supplier' element={<DigitalMarketingCaseStudy heading="Laboratory equipment supplier" link="laboratory-equipment-supplier" />} />
              <Route path='/digital-marketing-for/ladder-supplier' element={<DigitalMarketingCaseStudy heading="Ladder supplier" link="ladder-supplier" />} />
              <Route path='/digital-marketing-for/lamborghini-dealer' element={<DigitalMarketingCaseStudy heading="Lamborghini dealer" link="lamborghini-dealer" />} />
              <Route path='/digital-marketing-for/laminating-equipment-supplier' element={<DigitalMarketingCaseStudy heading="Laminating equipment supplier" link="laminating-equipment-supplier" />} />
              <Route path='/digital-marketing-for/lamination-service' element={<DigitalMarketingCaseStudy heading="Lamination service" link="lamination-service" />} />
              <Route path='/digital-marketing-for/lamp-shade-supplier' element={<DigitalMarketingCaseStudy heading="Lamp shade supplier" link="lamp-shade-supplier" />} />
              <Route path='/digital-marketing-for/land-reform-institute' element={<DigitalMarketingCaseStudy heading="Land reform institute" link="land-reform-institute" />} />
              <Route path='/digital-marketing-for/land-rover-dealer' element={<DigitalMarketingCaseStudy heading="Land Rover dealer" link="land-rover-dealer" />} />
              <Route path='/digital-marketing-for/land-surveying-office' element={<DigitalMarketingCaseStudy heading="Land surveying office" link="land-surveying-office" />} />
              <Route path='/digital-marketing-for/laser-cutting-service' element={<DigitalMarketingCaseStudy heading="Laser cutting service" link="laser-cutting-service" />} />
              <Route path='/digital-marketing-for/laser-tag-center' element={<DigitalMarketingCaseStudy heading="Laser tag center" link="laser-tag-center" />} />
              <Route path='/digital-marketing-for/lawn-mower-repair-service' element={<DigitalMarketingCaseStudy heading="Lawn mower repair service" link="lawn-mower-repair-service" />} />
              <Route path='/digital-marketing-for/lawn-mower-store' element={<DigitalMarketingCaseStudy heading="Lawn mower store" link="lawn-mower-store" />} />
              <Route path='/digital-marketing-for/leather-goods-manufacturer' element={<DigitalMarketingCaseStudy heading="Leather goods manufacturer" link="leather-goods-manufacturer" />} />
              <Route path='/digital-marketing-for/leather-wholesaler' element={<DigitalMarketingCaseStudy heading="Leather wholesaler" link="leather-wholesaler" />} />
              <Route path='/digital-marketing-for/lexus-dealer' element={<DigitalMarketingCaseStudy heading="Lexus dealer" link="lexus-dealer" />} />
              <Route path='/digital-marketing-for/lingerie-manufacturer' element={<DigitalMarketingCaseStudy heading="Lingerie manufacturer" link="lingerie-manufacturer" />} />
              <Route path='/digital-marketing-for/liquidator' element={<DigitalMarketingCaseStudy heading="Liquidator" link="liquidator" />} />
              <Route path='/digital-marketing-for/livestock-auction-house' element={<DigitalMarketingCaseStudy heading="Livestock auction house" link="livestock-auction-house" />} />
              <Route path='/digital-marketing-for/livestock-producer' element={<DigitalMarketingCaseStudy heading="Livestock producer" link="livestock-producer" />} />
              <Route path='/digital-marketing-for/lottery-retailer' element={<DigitalMarketingCaseStudy heading="Lottery retailer" link="lottery-retailer" />} />
              <Route path='/digital-marketing-for/lounge' element={<DigitalMarketingCaseStudy heading="Lounge" link="lounge" />} />
              <Route path='/digital-marketing-for/low-income-housing-program' element={<DigitalMarketingCaseStudy heading="Low income housing program" link="low-income-housing-program" />} />
              <Route path='/digital-marketing-for/lpg-conversion' element={<DigitalMarketingCaseStudy heading="LPG conversion" link="lpg-conversion" />} />
              <Route path='/digital-marketing-for/luggage-repair-service' element={<DigitalMarketingCaseStudy heading="Luggage repair service" link="luggage-repair-service" />} />
              <Route path='/digital-marketing-for/lyceum' element={<DigitalMarketingCaseStudy heading="Lyceum" link="lyceum" />} />
              <Route path='/digital-marketing-for/lymph-drainage-therapist' element={<DigitalMarketingCaseStudy heading="Lymph drainage therapist" link="lymph-drainage-therapist" />} />
              <Route path='/digital-marketing-for/machine-workshop' element={<DigitalMarketingCaseStudy heading="Machine workshop" link="machine-workshop" />} />
              <Route path='/digital-marketing-for/mailbox-rental-service' element={<DigitalMarketingCaseStudy heading="Mailbox rental service" link="mailbox-rental-service" />} />
              <Route path='/digital-marketing-for/mailing-machine-supplier' element={<DigitalMarketingCaseStudy heading="Mailing machine supplier" link="mailing-machine-supplier" />} />
              <Route path='/digital-marketing-for/makerspace' element={<DigitalMarketingCaseStudy heading="Makerspace" link="makerspace" />} />
              <Route path='/digital-marketing-for/manor-house' element={<DigitalMarketingCaseStudy heading="Manor house" link="manor-house" />} />
              <Route path='/digital-marketing-for/marble-supplier' element={<DigitalMarketingCaseStudy heading="Marble supplier" link="marble-supplier" />} />
              <Route path='/digital-marketing-for/marine-surveyor' element={<DigitalMarketingCaseStudy heading="Marine surveyor" link="marine-surveyor" />} />
              <Route path='/digital-marketing-for/marquee-hire-service' element={<DigitalMarketingCaseStudy heading="Marquee hire service" link="marquee-hire-service" />} />
              <Route path='/digital-marketing-for/maserati-dealer' element={<DigitalMarketingCaseStudy heading="Maserati dealer" link="maserati-dealer" />} />
              <Route path='/digital-marketing-for/masonry-contractor' element={<DigitalMarketingCaseStudy heading="Masonry contractor" link="masonry-contractor" />} />
              <Route path='/digital-marketing-for/match-box-manufacturer' element={<DigitalMarketingCaseStudy heading="Match box manufacturer" link="match-box-manufacturer" />} />
              <Route path='/digital-marketing-for/mausoleum-builder' element={<DigitalMarketingCaseStudy heading="Mausoleum builder" link="mausoleum-builder" />} />
              <Route path='/digital-marketing-for/maybach-dealer' element={<DigitalMarketingCaseStudy heading="Maybach dealer" link="maybach-dealer" />} />
              <Route path='/digital-marketing-for/mazda-dealer' element={<DigitalMarketingCaseStudy heading="Mazda dealer" link="mazda-dealer" />} />
              <Route path='/digital-marketing-for/mclaren-dealer' element={<DigitalMarketingCaseStudy heading="McLaren dealer" link="mclaren-dealer" />} />
              <Route path='/digital-marketing-for/mechanical-plant' element={<DigitalMarketingCaseStudy heading="Mechanical plant" link="mechanical-plant" />} />
              <Route path='/digital-marketing-for/media-house' element={<DigitalMarketingCaseStudy heading="Media house" link="media-house" />} />
              <Route path='/digital-marketing-for/medical-diagnostic-imaging-center' element={<DigitalMarketingCaseStudy heading="Medical diagnostic imaging center" link="medical-diagnostic-imaging-center" />} />
              <Route path='/digital-marketing-for/medical-supply-store' element={<DigitalMarketingCaseStudy heading="Medical supply store" link="medical-supply-store" />} />
              <Route path='/digital-marketing-for/medical-transcription-service' element={<DigitalMarketingCaseStudy heading="Medical transcription service" link="medical-transcription-service" />} />
              <Route path='/digital-marketing-for/meditation-center' element={<DigitalMarketingCaseStudy heading="Meditation center" link="meditation-center" />} />
              <Route path='/digital-marketing-for/mehndi-designer' element={<DigitalMarketingCaseStudy heading="Mehndi designer" link="mehndi-designer" />} />
              <Route path='/digital-marketing-for/memorial-park' element={<DigitalMarketingCaseStudy heading="Memorial park" link="memorial-park" />} />
              <Route path='/digital-marketing-for/mens-health-physician' element={<DigitalMarketingCaseStudy heading="Men’s health physician" link="mens-health-physician" />} />
              <Route path='/digital-marketing-for/mercedes-benz-dealer' element={<DigitalMarketingCaseStudy heading="Mercedes-Benz dealer" link="mercedes-benz-dealer" />} />
              <Route path='/digital-marketing-for/messianic-synagogue' element={<DigitalMarketingCaseStudy heading="Messianic synagogue" link="messianic-synagogue" />} />
              <Route path='/digital-marketing-for/metal-detecting-equipment-supplier' element={<DigitalMarketingCaseStudy heading="Metal detecting equipment supplier" link="metal-detecting-equipment-supplier" />} />
              <Route path='/digital-marketing-for/metal-finisher' element={<DigitalMarketingCaseStudy heading="Metal finisher" link="metal-finisher" />} />
              <Route path='/digital-marketing-for/metal-stamping-service' element={<DigitalMarketingCaseStudy heading="Metal stamping service" link="metal-stamping-service" />} />
              <Route path='/digital-marketing-for/milk-delivery-service' element={<DigitalMarketingCaseStudy heading="Milk delivery service" link="milk-delivery-service" />} />
              <Route path='/digital-marketing-for/mill' element={<DigitalMarketingCaseStudy heading="Mill" link="mill" />} />
              <Route path='/digital-marketing-for/mineral-water-wholesaler' element={<DigitalMarketingCaseStudy heading="Mineral water wholesaler" link="mineral-water-wholesaler" />} />
              <Route path='/digital-marketing-for/miniatures-store' element={<DigitalMarketingCaseStudy heading="Miniatures store" link="miniatures-store" />} />
              <Route path='/digital-marketing-for/mining-engineer' element={<DigitalMarketingCaseStudy heading="Mining engineer" link="mining-engineer" />} />
              <Route path='/digital-marketing-for/mirror-shop' element={<DigitalMarketingCaseStudy heading="Mirror shop" link="mirror-shop" />} />
              <Route path='/digital-marketing-for/mobile-disco' element={<DigitalMarketingCaseStudy heading="Mobile disco" link="mobile-disco" />} />
              <Route path='/digital-marketing-for/mobile-home-supply-store' element={<DigitalMarketingCaseStudy heading="Mobile home supply store" link="mobile-home-supply-store" />} />
              <Route path='/digital-marketing-for/mobile-money-agent' element={<DigitalMarketingCaseStudy heading="Mobile money agent" link="mobile-money-agent" />} />
              <Route path='/digital-marketing-for/model-car-play-area' element={<DigitalMarketingCaseStudy heading="Model car play area" link="model-car-play-area" />} />
              <Route path='/digital-marketing-for/monument-maker' element={<DigitalMarketingCaseStudy heading="Monument maker" link="monument-maker" />} />
              <Route path='/digital-marketing-for/mortgage-broker' element={<DigitalMarketingCaseStudy heading="Mortgage broker" link="mortgage-broker" />} />
              <Route path='/digital-marketing-for/mortgage-lender' element={<DigitalMarketingCaseStudy heading="Mortgage lender" link="mortgage-lender" />} />
              <Route path='/digital-marketing-for/mosque' element={<DigitalMarketingCaseStudy heading="Mosque" link="mosque" />} />
              <Route path='/digital-marketing-for/motorcycle-shop' element={<DigitalMarketingCaseStudy heading="Motorcycle shop" link="motorcycle-shop" />} />
              <Route path='/digital-marketing-for/mountain-cable-car' element={<DigitalMarketingCaseStudy heading="Mountain cable car" link="mountain-cable-car" />} />
              <Route path='/digital-marketing-for/movie-rental-store' element={<DigitalMarketingCaseStudy heading="Movie rental store" link="movie-rental-store" />} />
              <Route path='/digital-marketing-for/multimedia-&-electronic-book-publisher' element={<DigitalMarketingCaseStudy heading="Multimedia and electronic book publisher" link="multimedia-&-electronic-book-publisher" />} />
              <Route path='/digital-marketing-for/municipal-corporation' element={<DigitalMarketingCaseStudy heading="Municipal corporation" link="municipal-corporation" />} />
              <Route path='/digital-marketing-for/municipal-department-of-agriculture-food-supply' element={<DigitalMarketingCaseStudy heading="Municipal department of agriculture food supply" link="municipal-department-of-agriculture-food-supply" />} />
              <Route path='/digital-marketing-for/municipal-department-of-sports' element={<DigitalMarketingCaseStudy heading="Municipal Department of Sports" link="municipal-department-of-sports" />} />
              <Route path='/digital-marketing-for/municipal-health-department' element={<DigitalMarketingCaseStudy heading="Municipal health department" link="municipal-health-department" />} />
              <Route path='/digital-marketing-for/museum-of-space-history' element={<DigitalMarketingCaseStudy heading="Museum of space history" link="museum-of-space-history" />} />
              <Route path='/digital-marketing-for/national-library' element={<DigitalMarketingCaseStudy heading="National library" link="national-library" />} />
              <Route path='/digital-marketing-for/natural-goods-store' element={<DigitalMarketingCaseStudy heading="Natural goods store" link="natural-goods-store" />} />
              <Route path='/digital-marketing-for/nephrologist' element={<DigitalMarketingCaseStudy heading="Nephrologist" link="nephrologist" />} />
              <Route path='/digital-marketing-for/netball-club' element={<DigitalMarketingCaseStudy heading="Netball club" link="netball-club" />} />
              <Route path='/digital-marketing-for/neurologist' element={<DigitalMarketingCaseStudy heading="Neurologist" link="Neurologist" />} />
              <Route path='/digital-marketing-for/neurosurgeon' element={<DigitalMarketingCaseStudy heading="Neurosurgeon" link="neurosurgeon" />} />
              <Route path='/digital-marketing-for/newspaper-distribution-service' element={<DigitalMarketingCaseStudy heading="Newspaper distribution service" link="newspaper-distribution-service" />} />
              <Route path='/digital-marketing-for/night-club' element={<DigitalMarketingCaseStudy heading="Night club" link="night-club" />} />
              <Route path='/digital-marketing-for/night-market' element={<DigitalMarketingCaseStudy heading="Night market" link="night-market" />} />
              <Route path='/digital-marketing-for/noodle-shop' element={<DigitalMarketingCaseStudy heading="Noodle shop" link="noodle-shop" />} />
              <Route path='/digital-marketing-for/notary-public' element={<DigitalMarketingCaseStudy heading="Notary public" link="notary-public" />} />
              <Route path='/digital-marketing-for/novelties-wholesaler' element={<DigitalMarketingCaseStudy heading="Novelties wholesaler" link="novelties-wholesaler" />} />
              <Route path='/digital-marketing-for/nudist-club' element={<DigitalMarketingCaseStudy heading="Nudist club" link="nudist-club" />} />
              <Route path='/digital-marketing-for/nudist-park' element={<DigitalMarketingCaseStudy heading="Nudist park" link="nudist-park" />} />
              <Route path='/digital-marketing-for/nursery-school' element={<DigitalMarketingCaseStudy heading="Nursery school" link="nursery-school" />} />
              <Route path='/digital-marketing-for/observatory' element={<DigitalMarketingCaseStudy heading="Observatory" link="observatory" />} />
              <Route path='/digital-marketing-for/occupational-safety-&-health' element={<DigitalMarketingCaseStudy heading="Occupational safety and health" link="occupational-safety-&-health" />} />
              <Route path='/digital-marketing-for/off-roading-area' element={<DigitalMarketingCaseStudy heading="Off roading area" link="off-roading-area" />} />
              <Route path='/digital-marketing-for/off-road-race-track' element={<DigitalMarketingCaseStudy heading="Off-road race track" link="Off-road-race-track" />} />
              <Route path='/digital-marketing-for/office-of-a-fair-trade-organization' element={<DigitalMarketingCaseStudy heading="Office of a fair trade organization" link="office-of-a-fair-trade-organization" />} />
              <Route path='/digital-marketing-for/olive-oil-bottling-company' element={<DigitalMarketingCaseStudy heading="Olive oil bottling company" link="olive-oil-bottling-company" />} />
              <Route path='/digital-marketing-for/olive-oil-cooperative' element={<DigitalMarketingCaseStudy heading="Olive oil cooperative" link="olive-oil-cooperative" />} />
              <Route path='/digital-marketing-for/optical-products-manufacturer' element={<DigitalMarketingCaseStudy heading="Optical products manufacturer" link="optical-products-manufacturer" />} />
              <Route path='/digital-marketing-for/optical-wholesaler' element={<DigitalMarketingCaseStudy heading="Optical wholesaler" link="optical-wholesaler" />} />
              <Route path='/digital-marketing-for/orchestra' element={<DigitalMarketingCaseStudy heading="Orchestra" link="orchestra" />} />
              <Route path='/digital-marketing-for/organ-donation-&-tissue-bank' element={<DigitalMarketingCaseStudy heading="Organ donation and tissue bank" link="organ-donation-&-tissue-bank" />} />
              <Route path='/digital-marketing-for/orphan-asylum' element={<DigitalMarketingCaseStudy heading="Orphan asylum" link="orphan-asylum" />} />
              <Route path='/digital-marketing-for/orthodox-synagogue' element={<DigitalMarketingCaseStudy heading="Orthodox synagogue" link="orthodox-synagogue" />} />
              <Route path='/digital-marketing-for/orthopedic-clinic' element={<DigitalMarketingCaseStudy heading="Orthopedic clinic" link="orthopedic-clinic" />} />
              <Route path='/digital-marketing-for/outdoor-activity-organiser' element={<DigitalMarketingCaseStudy heading="Outdoor activity organiser" link="outdoor-activity-organiser" />} />
              <Route path='/digital-marketing-for/oxygen-cocktail-spot' element={<DigitalMarketingCaseStudy heading="Oxygen cocktail spot" link="oxygen-cocktail-spot" />} />
              <Route path='/digital-marketing-for/oyster-supplier' element={<DigitalMarketingCaseStudy heading="Oyster supplier" link="oyster-supplier" />} />
              <Route path='/digital-marketing-for/pachinko-parlor' element={<DigitalMarketingCaseStudy heading="Pachinko parlor" link="pachinko-parlor" />} />
              <Route path='/digital-marketing-for/package-locker' element={<DigitalMarketingCaseStudy heading="Package locker" link="package-locker" />} />
              <Route path='/digital-marketing-for/padel-court' element={<DigitalMarketingCaseStudy heading="Padel court" link="padel-court" />} />
              <Route path='/digital-marketing-for/pagoda' element={<DigitalMarketingCaseStudy heading="Pagoda" link="pagoda" />} />
              <Route path='/digital-marketing-for/paper-bag-supplier' element={<DigitalMarketingCaseStudy heading="Paper bag supplier" link="paper-bag-supplier" />} />
              <Route path='/digital-marketing-for/parking-lot-for-bicycles' element={<DigitalMarketingCaseStudy heading="Parking lot for bicycles" link="parking-lot-for-bicycles" />} />
              <Route path='/digital-marketing-for/parkour-spot' element={<DigitalMarketingCaseStudy heading="Parkour spot" link="parkour-spot" />} />
              <Route path='/digital-marketing-for/parsi-temple' element={<DigitalMarketingCaseStudy heading="Parsi temple" link="parsi-temple" />} />
              <Route path='/digital-marketing-for/passport-photo-processor' element={<DigitalMarketingCaseStudy heading="Passport photo processor" link="passport-photo-processor" />} />
              <Route path='/digital-marketing-for/pathologist' element={<DigitalMarketingCaseStudy heading="Pathologist" link="pathologist" />} />
              <Route path='/digital-marketing-for/pet-moving-service' element={<DigitalMarketingCaseStudy heading="Pet moving service" link="pet-moving-service" />} />
              <Route path='/digital-marketing-for/photo-restoration-service' element={<DigitalMarketingCaseStudy heading="Photo restoration service" link="photo-restoration-service" />} />
              <Route path='/digital-marketing-for/pilgrimage-place' element={<DigitalMarketingCaseStudy heading="Pilgrimage place" link="pilgrimage-place" />} />
              <Route path='/digital-marketing-for/plastic-bag-supplier' element={<DigitalMarketingCaseStudy heading="Plastic bag supplier" link="plastic-bag-supplier" />} />
              <Route path='/digital-marketing-for/plastic-bags-wholesaler' element={<DigitalMarketingCaseStudy heading="Plastic bags wholesaler" link="plastic-bags-wholesaler" />} />
              <Route path='/digital-marketing-for/play-school' element={<DigitalMarketingCaseStudy heading="Play school" link="play-school" />} />
              <Route path='/digital-marketing-for/playgroup' element={<DigitalMarketingCaseStudy heading="Playgroup" link="playgroup" />} />
              <Route path='/digital-marketing-for/plywood-supplier' element={<DigitalMarketingCaseStudy heading="Plywood supplier" link="plywood-supplier" />} />
              <Route path='/digital-marketing-for/pneumatic-tools-supplier' element={<DigitalMarketingCaseStudy heading="Pneumatic tools supplier" link="pneumatic-tools-supplier" />} />
              <Route path='/digital-marketing-for/police-supply-store' element={<DigitalMarketingCaseStudy heading="Police supply store" link="police-supply-store" />} />
              <Route path='/digital-marketing-for/political-party-office' element={<DigitalMarketingCaseStudy heading="Political party office" link="political-party-office" />} />
              <Route path='/digital-marketing-for/polo-club' element={<DigitalMarketingCaseStudy heading="Polo club" link="polo-club" />} />
              <Route path='/digital-marketing-for/polygraph-service' element={<DigitalMarketingCaseStudy heading="Polygraph service" link="polygraph-service" />} />
              <Route path='/digital-marketing-for/polymer-supplier' element={<DigitalMarketingCaseStudy heading="Polymer supplier" link="polymer-supplier" />} />
              <Route path='/digital-marketing-for/pond-fish-supplier' element={<DigitalMarketingCaseStudy heading="Pond fish supplier" link="pond-fish-supplier" />} />
              <Route path='/digital-marketing-for/pool-billard-club' element={<DigitalMarketingCaseStudy heading="Pool billard club" link="pool-billard-club" />} />
              <Route path='/digital-marketing-for/pool-hall' element={<DigitalMarketingCaseStudy heading="Pool hall" link="pool-hall" />} />
              <Route path='/digital-marketing-for/popcorn-store' element={<DigitalMarketingCaseStudy heading="Popcorn store" link="popcorn-store" />} />
              <Route path='/digital-marketing-for/porsche-dealer' element={<DigitalMarketingCaseStudy heading="Porsche dealer" link="porsche-dealer" />} />
              <Route path='/digital-marketing-for/poster-store' element={<DigitalMarketingCaseStudy heading="Poster store" link="poster-store" />} />
              <Route path='/digital-marketing-for/pottery-classes' element={<DigitalMarketingCaseStudy heading="Pottery classes" link="pottery-classes" />} />
              <Route path='/digital-marketing-for/poultry-farm' element={<DigitalMarketingCaseStudy heading="Poultry farm" link="poultry-farm" />} />
              <Route path='/digital-marketing-for/poultry-store' element={<DigitalMarketingCaseStudy heading="Poultry store" link="poultry-store" />} />
              <Route path='/digital-marketing-for/powder-coating-service' element={<DigitalMarketingCaseStudy heading="Powder coating service" link="powder-coating-service" />} />
              <Route path='/digital-marketing-for/power-plant-consultant' element={<DigitalMarketingCaseStudy heading="Power plant consultant" link="power-plant-consultant" />} />
              <Route path='/digital-marketing-for/powersports-vehicle-dealer' element={<DigitalMarketingCaseStudy heading="Powersports vehicle dealer" link="powersports-vehicle-dealer" />} />
              <Route path='/digital-marketing-for/pretzel-store' element={<DigitalMarketingCaseStudy heading="Pretzel store" link="pretzel-store" />} />
              <Route path='/digital-marketing-for/printer-ink-refill-store' element={<DigitalMarketingCaseStudy heading="Printer ink refill store" link="printer-ink-refill-store" />} />
              <Route path='/digital-marketing-for/printer-repair-service' element={<DigitalMarketingCaseStudy heading="Printer repair service" link="printer-repair-service" />} />
              <Route path='/digital-marketing-for/process-server' element={<DigitalMarketingCaseStudy heading="Process server" link="process-server" />} />
              <Route path='/digital-marketing-for/proctologist' element={<DigitalMarketingCaseStudy heading="Proctologist" link="proctologist" />} />
              <Route path='/digital-marketing-for/promenade' element={<DigitalMarketingCaseStudy heading="Promenade" link="promenade" />} />
              <Route path='/digital-marketing-for/propane-supplier' element={<DigitalMarketingCaseStudy heading="Propane supplier" link="propane-supplier" />} />
              <Route path='/digital-marketing-for/prosthetics' element={<DigitalMarketingCaseStudy heading="Prosthetics" link="prosthetics" />} />
              <Route path='/digital-marketing-for/provincial-council' element={<DigitalMarketingCaseStudy heading="Provincial council" link="provincial-council" />} />
              <Route path='/digital-marketing-for/psychoneurological-specialized-clinic' element={<DigitalMarketingCaseStudy heading="Psychoneurological specialized clinic" link="psychoneurological-specialized-clinic" />} />
              <Route path='/digital-marketing-for/public-bath' element={<DigitalMarketingCaseStudy heading="Public bath" link="public-bath" />} />
              <Route path='/digital-marketing-for/public-defenders-office' element={<DigitalMarketingCaseStudy heading="Public defender’s office" link="public-defenders-office" />} />
              <Route path='/digital-marketing-for/public-relations-firm' element={<DigitalMarketingCaseStudy heading="Public relations firm" link="public-relations-firm" />} />
              <Route path='/digital-marketing-for/pulmonologist' element={<DigitalMarketingCaseStudy heading="Pulmonologist" link="pulmonologist" />} />
              <Route path='/digital-marketing-for/pvc-industry' element={<DigitalMarketingCaseStudy heading="PVC industry" link="pvc-industry" />} />
              <Route path='/digital-marketing-for/qing-fang-market-place' element={<DigitalMarketingCaseStudy heading="Qing fang market place" link="qing-fang-market-place" />} />
              <Route path='/digital-marketing-for/quantity-surveyor' element={<DigitalMarketingCaseStudy heading="Quantity surveyor" link="quantity-surveyor" />} />
              <Route path='/digital-marketing-for/quarry' element={<DigitalMarketingCaseStudy heading="Quarry" link="quarry" />} />
              <Route path='/digital-marketing-for/quilt-shop' element={<DigitalMarketingCaseStudy heading="Quilt shop" link="quilt-shop" />} />
              <Route path='/digital-marketing-for/radiator-shop' element={<DigitalMarketingCaseStudy heading="Radiator shop" link="radiator-shop" />} />
              <Route path='/digital-marketing-for/raft-trip-outfitter' element={<DigitalMarketingCaseStudy heading="Raft trip outfitter" link="raft-trip-outfitter" />} />
              <Route path='/digital-marketing-for/rail-museum' element={<DigitalMarketingCaseStudy heading="Rail museum" link="rail-museum" />} />
              <Route path='/digital-marketing-for/railroad-ties-supplier' element={<DigitalMarketingCaseStudy heading="Railroad ties supplier" link="railroad-ties-supplier" />} />
              <Route path='/digital-marketing-for/rainwater-tank-supplier' element={<DigitalMarketingCaseStudy heading="Rainwater tank supplier" link="rainwater-tank-supplier" />} />
              <Route path='/digital-marketing-for/ready-mix-concrete-supplier' element={<DigitalMarketingCaseStudy heading="Ready mix concrete supplier" link="ready-mix-concrete-supplier" />} />
              <Route path='/digital-marketing-for/recreational-vehicle-rental-agency' element={<DigitalMarketingCaseStudy heading="Recreational vehicle rental agency" link="recreational-vehicle-rental-agency" />} />
              <Route path='/digital-marketing-for/reform-synagogue' element={<DigitalMarketingCaseStudy heading="Reform synagogue" link="reform-synagogue" />} />
              <Route path='/digital-marketing-for/regional-airport' element={<DigitalMarketingCaseStudy heading="Regional airport" link="regional-airport" />} />
              <Route path='/digital-marketing-for/residential-college' element={<DigitalMarketingCaseStudy heading="Residential college" link="residential-college" />} />
              <Route path='/digital-marketing-for/resume-service' element={<DigitalMarketingCaseStudy heading="Resume service" link="resume-service" />} />
              <Route path='/digital-marketing-for/rheumatologist' element={<DigitalMarketingCaseStudy heading="Rheumatologist" link="rheumatologist" />} />
              <Route path='/digital-marketing-for/rice-Mill' element={<DigitalMarketingCaseStudy heading="Rice Mill" link="rice-Mill" />} />
              <Route path='/digital-marketing-for/rice-shop' element={<DigitalMarketingCaseStudy heading="Rice shop" link="rice-shop" />} />
              <Route path='/digital-marketing-for/rice-wholesaler' element={<DigitalMarketingCaseStudy heading="Rice wholesaler" link="rice-wholesaler" />} />
              <Route path='/digital-marketing-for/recreational-vehicle-rental-agency' element={<DigitalMarketingCaseStudy heading="Recreational vehicle rental agency" link="recreational-vehicle-rental-agency" />} />
              <Route path='/digital-marketing-for/road-safety-town' element={<DigitalMarketingCaseStudy heading="Road safety town" link="Road-safety-town" />} />
              <Route path='/digital-marketing-for/roads-ports-&-canals-engineers-association' element={<DigitalMarketingCaseStudy heading="Roads ports and canals engineers association" link="roads-ports-&-canals-engineers-association" />} />
              <Route path='/digital-marketing-for/rodeo' element={<DigitalMarketingCaseStudy heading="Rodeo" link="rodeo" />} />
              <Route path='/digital-marketing-for/rsl-club' element={<DigitalMarketingCaseStudy heading="Rsl club" link="rsl-club" />} />
              <Route path='/digital-marketing-for/rubber-stamp-store' element={<DigitalMarketingCaseStudy heading="Rubber stamp store" link="rubber-stamp-store" />} />
              <Route path='/digital-marketing-for/rugby-club' element={<DigitalMarketingCaseStudy heading="Rugby club" link="rugby-club" />} />
              <Route path='/digital-marketing-for/rugby-league-club' element={<DigitalMarketingCaseStudy heading="Rugby league club" link="rugby-league-club" />} />
              <Route path='/digital-marketing-for/sacem' element={<DigitalMarketingCaseStudy heading="Sacem" link="sacem" />} />
              <Route path='/digital-marketing-for/safe-&-vault-shop' element={<DigitalMarketingCaseStudy heading="Safe & vault shop" link="safe-&-vault-shop" />} />
              <Route path='/digital-marketing-for/sailing-school' element={<DigitalMarketingCaseStudy heading="Sailing school" link="sailing-school" />} />
              <Route path='/digital-marketing-for/salsa-bar' element={<DigitalMarketingCaseStudy heading="Salsa bar" link="salsa-bar" />} />
              <Route path='/digital-marketing-for/sambodrome' element={<DigitalMarketingCaseStudy heading="Sambodrome" link="sambodrome" />} />
              <Route path='/digital-marketing-for/sanitary-inspection' element={<DigitalMarketingCaseStudy heading="Sanitary inspection" link="sanitary-inspection" />} />
              <Route path='/digital-marketing-for/sauna' element={<DigitalMarketingCaseStudy heading="Sauna" link="sauna" />} />
              <Route path='/digital-marketing-for/sauna-club' element={<DigitalMarketingCaseStudy heading="Sauna club" link="sauna-club" />} />
              <Route path='/digital-marketing-for/school-bus-service' element={<DigitalMarketingCaseStudy heading="School bus service" link="school-bus-service" />} />
              <Route path='/digital-marketing-for/school-for-the-deaf' element={<DigitalMarketingCaseStudy heading="School for the deaf" link="school-for-the-deaf" />} />
              <Route path='/digital-marketing-for/scooter-rental-service' element={<DigitalMarketingCaseStudy heading="Scooter rental service" link="scooter-rental-service" />} />
              <Route path='/digital-marketing-for/scrap-metal-dealer' element={<DigitalMarketingCaseStudy heading="Scrap metal dealer" link="scrap-metal-dealer" />} />
              <Route path='/digital-marketing-for/scrapbooking-store' element={<DigitalMarketingCaseStudy heading="Scrapbooking store" link="scrapbooking-store" />} />
              <Route path='/digital-marketing-for/screw-supplier' element={<DigitalMarketingCaseStudy heading="Screw supplier" link="screw-supplier" />} />
              <Route path='/digital-marketing-for/seitai' element={<DigitalMarketingCaseStudy heading="Seitai" link="seitai" />} />
              <Route path='/digital-marketing-for/semi-conductor-supplier' element={<DigitalMarketingCaseStudy heading="Semi conductor supplier" link="semi-conductor-supplier" />} />
              <Route path='/digital-marketing-for/seminary' element={<DigitalMarketingCaseStudy heading="Seminary" link="seminary" />} />
              <Route path='/digital-marketing-for/sewing-machine-repair-service' element={<DigitalMarketingCaseStudy heading="Sewing machine repair service" link="sewing-machine-repair-service" />} />
              <Route path='/digital-marketing-for/sexologist' element={<DigitalMarketingCaseStudy heading="Sexologist" link="sexologist" />} />
              <Route path='/digital-marketing-for/sheep-shearer' element={<DigitalMarketingCaseStudy heading="Sheep shearer" link="sheep-shearer" />} />
              <Route path='/digital-marketing-for/sheepskin-&-wool-products-supplier' element={<DigitalMarketingCaseStudy heading="Sheepskin and wool products supplier" link="sheepskin-&-wool-products-supplier" />} />
              <Route path='/digital-marketing-for/shipbuilding-&-repair-company' element={<DigitalMarketingCaseStudy heading="Shipbuilding and repair company" link="shipbuilding-&-repair-company" />} />
              <Route path='/digital-marketing-for/shogi-lesson' element={<DigitalMarketingCaseStudy heading="Shogi lesson" link="shogi-lesson" />} />
              <Route path='/digital-marketing-for/shopfitter' element={<DigitalMarketingCaseStudy heading="Shopfitter" link="shopfitter" />} />
              <Route path='/digital-marketing-for/shopping-mall' element={<DigitalMarketingCaseStudy heading="Shopping mall" link="shopping-mall" />} />
              <Route path='/digital-marketing-for/shrimp-farm' element={<DigitalMarketingCaseStudy heading="Shrimp farm" link="shrimp-farm" />} />
              <Route path='/digital-marketing-for/skate-sharpening-service' element={<DigitalMarketingCaseStudy heading="Skate sharpening service" link="skate-sharpening-service" />} />
              <Route path='/digital-marketing-for/skate-shop' element={<DigitalMarketingCaseStudy heading="Skate shop" link="skate-shop" />} />
              <Route path='/digital-marketing-for/skateboard-park' element={<DigitalMarketingCaseStudy heading="Skateboard park" link="skateboard-park" />} />
              <Route path='/digital-marketing-for/skateboard-shop' element={<DigitalMarketingCaseStudy heading="Skateboard shop" link="skateboard-shop" />} />
              <Route path='/digital-marketing-for/skating-instructor' element={<DigitalMarketingCaseStudy heading="Skating instructor" link="skating-instructor" />} />
              <Route path='/digital-marketing-for/skoda-dealer' element={<DigitalMarketingCaseStudy heading="Skoda dealer" link="skoda-dealer" />} />
              <Route path='/digital-marketing-for/skydiving-center' element={<DigitalMarketingCaseStudy heading="Skydiving center" link="skydiving-center" />} />
              <Route path='/digital-marketing-for/slaughterhouse' element={<DigitalMarketingCaseStudy heading="Slaughterhouse" link="slaughterhouse" />} />
              <Route path='/digital-marketing-for/smog-inspection-station' element={<DigitalMarketingCaseStudy heading="Smog inspection station" link="smog-inspection-station" />} />
              <Route path='/digital-marketing-for/snowboard-rental-service' element={<DigitalMarketingCaseStudy heading="Snowboard rental service" link="snowboard-rental-service" />} />
              <Route path='/digital-marketing-for/soapland' element={<DigitalMarketingCaseStudy heading="Soapland" link="soapland" />} />
              <Route path='/digital-marketing-for/social-welfare-center' element={<DigitalMarketingCaseStudy heading="Social welfare center" link="social-welfare-center" />} />
              <Route path='/digital-marketing-for/societe-de-flocage' element={<DigitalMarketingCaseStudy heading="Societe de Flocage" link="societe-de-flocage" />} />
              <Route path='/digital-marketing-for/sokol-house' element={<DigitalMarketingCaseStudy heading="Sokol house" link="sokol-house" />} />
              <Route path='/digital-marketing-for/soup-kitchen' element={<DigitalMarketingCaseStudy heading="Soup kitchen" link="soup-kitchen" />} />
              <Route path='/digital-marketing-for/soy-sauce-maker' element={<DigitalMarketingCaseStudy heading="Soy sauce maker" link="soy-sauce-maker" />} />
              <Route path='/digital-marketing-for/special-education-school' element={<DigitalMarketingCaseStudy heading="Special education school" link="special-education-school" />} />
              <Route path='/digital-marketing-for/sperm-bank' element={<DigitalMarketingCaseStudy heading="Sperm bank" link="sperm-bank" />} />
              <Route path='/digital-marketing-for/sports-memorabilia-store' element={<DigitalMarketingCaseStudy heading="Sports memorabilia store" link="sports-memorabilia-store" />} />
              <Route path='/digital-marketing-for/squash-club' element={<DigitalMarketingCaseStudy heading="Squash club" link="squash-club" />} />
              <Route path='/digital-marketing-for/squash-court' element={<DigitalMarketingCaseStudy heading="Squash court" link="squash-court" />} />
              <Route path='/digital-marketing-for/stage-lighting-equipment-supplier' element={<DigitalMarketingCaseStudy heading="Stage lighting equipment supplier" link="stage-lighting-equipment-supplier" />} />
              <Route path='/digital-marketing-for/stamp-collectors-club' element={<DigitalMarketingCaseStudy heading="Stamp collectors club" link="stamp-collectors-club" />} />
              <Route path='/digital-marketing-for/stand-bar' element={<DigitalMarketingCaseStudy heading="Stand bar" link="stand-bar" />} />
              <Route path='/digital-marketing-for/state-police' element={<DigitalMarketingCaseStudy heading="State police" link="state-police" />} />
              <Route path='/digital-marketing-for/std-clinic' element={<DigitalMarketingCaseStudy heading="STD clinic" link="std-clinic" />} />
              <Route path='/digital-marketing-for/std-testing-service' element={<DigitalMarketingCaseStudy heading="STD testing service" link="std-testing-service" />} />
              <Route path='/digital-marketing-for/steel-erector' element={<DigitalMarketingCaseStudy heading="Steel erector" link="steel-erector" />} />
              <Route path='/digital-marketing-for/stitching-class' element={<DigitalMarketingCaseStudy heading="Stitching class" link="stitching-class" />} />
              <Route path='/digital-marketing-for/stone-cutter' element={<DigitalMarketingCaseStudy heading="Stone cutter" link="stone-cutter" />} />
              <Route path='/digital-marketing-for/stucco-contractor' element={<DigitalMarketingCaseStudy heading="Stucco contractor" link="stucco-contractor" />} />
              <Route path='/digital-marketing-for/student-housing-center' element={<DigitalMarketingCaseStudy heading="Student housing center" link="student-housing-center" />} />
              <Route path='/digital-marketing-for/subaru-dealer' element={<DigitalMarketingCaseStudy heading="Subaru dealer" link="subaru-dealer" />} />
              <Route path='/digital-marketing-for/sugar-factory' element={<DigitalMarketingCaseStudy heading="Sugar factory" link="sugar-factory" />} />
              <Route path='/digital-marketing-for/sugar-shack' element={<DigitalMarketingCaseStudy heading="Sugar shack" link="sugar-shack" />} />
              <Route path='/digital-marketing-for/summer-toboggan-run' element={<DigitalMarketingCaseStudy heading="Summer toboggan run" link="summer-toboggan-run" />} />
              <Route path='/digital-marketing-for/sunroom-contractor' element={<DigitalMarketingCaseStudy heading="Sunroom contractor" link="sunroom-contractor" />} />
              <Route path='/digital-marketing-for/super-public-bath' element={<DigitalMarketingCaseStudy heading="Super public bath" link="super-public-bath" />} />
              <Route path='/digital-marketing-for/superfund-site' element={<DigitalMarketingCaseStudy heading="Superfund site" link="superfund-site" />} />
              <Route path='/digital-marketing-for/supermarket' element={<DigitalMarketingCaseStudy heading="Supermarket" link="supermarket" />} />
              <Route path='/digital-marketing-for/surf-lifesaving-club' element={<DigitalMarketingCaseStudy heading="Surf lifesaving club" link="surf-lifesaving-club" />} />
              <Route path='/digital-marketing-for/surgical-center' element={<DigitalMarketingCaseStudy heading="Surgical center" link="surgical-center" />} />
              <Route path='/digital-marketing-for/suzuki-dealer' element={<DigitalMarketingCaseStudy heading="Suzuki dealer" link="suzuki-dealer" />} />
              <Route path='/digital-marketing-for/suzuki-motorcycle-dealer' element={<DigitalMarketingCaseStudy heading="Suzuki motorcycle dealer" link="suzuki-motorcycle-dealer" />} />
              <Route path='/digital-marketing-for/swimming-instructor' element={<DigitalMarketingCaseStudy heading="Swimming instructor" link="swimming-instructor" />} />
              <Route path='/digital-marketing-for/swimming-lake' element={<DigitalMarketingCaseStudy heading="Swimming lake" link="swimming-lake" />} />
              <Route path='/digital-marketing-for/tack-shop ' element={<DigitalMarketingCaseStudy heading="Tack shop " link="tack-shop " />} />
              <Route path='/digital-marketing-for/tamale-shop' element={<DigitalMarketingCaseStudy heading="Tamale shop" link="tamale-shop" />} />
              <Route path='/digital-marketing-for/tanning-salon' element={<DigitalMarketingCaseStudy heading="Tanning salon" link="tanning-salon" />} />
              <Route path='/digital-marketing-for/tapas-bar' element={<DigitalMarketingCaseStudy heading="Tapas bar" link="tapas-bar" />} />
              <Route path='/digital-marketing-for/tattoo-&-piercing-shop' element={<DigitalMarketingCaseStudy heading="Tattoo and piercing shop" link="tattoo-&-piercing-shop" />} />
              <Route path='/digital-marketing-for/tax-consultant' element={<DigitalMarketingCaseStudy heading="Tax consultant" link="tax-consultant" />} />
              <Route path='/digital-marketing-for/tennis-court ' element={<DigitalMarketingCaseStudy heading="Tennis court " link="tennis-court " />} />
              <Route path='/digital-marketing-for/tesla-showroom' element={<DigitalMarketingCaseStudy heading="Tesla showroom" link="tesla-showroom" />} />
              <Route path='/digital-marketing-for/textile-engineer' element={<DigitalMarketingCaseStudy heading="Textile engineer" link="textile-engineer" />} />
              <Route path='/digital-marketing-for/tiffin-center' element={<DigitalMarketingCaseStudy heading="Tiffin center" link="tiffin-center" />} />
              <Route path='/digital-marketing-for/toll-booth' element={<DigitalMarketingCaseStudy heading="Toll booth" link="toll-booth" />} />
              <Route path='/digital-marketing-for/topography-company' element={<DigitalMarketingCaseStudy heading="Topography company" link="topography-company" />} />
              <Route path='/digital-marketing-for/toy-library' element={<DigitalMarketingCaseStudy heading="Toy library" link="toy-library" />} />
              <Route path='/digital-marketing-for/toy-museum' element={<DigitalMarketingCaseStudy heading="Toy museum" link="toy-museum" />} />
              <Route path='/digital-marketing-for/tractor-repair-shop' element={<DigitalMarketingCaseStudy heading="Tractor repair shop" link="tractor-repair-shop" />} />
              <Route path='/digital-marketing-for/traditional-teahouse' element={<DigitalMarketingCaseStudy heading="Traditional teahouse" link="traditional-teahouse" />} />
              <Route path='/digital-marketing-for/trailer-dealer' element={<DigitalMarketingCaseStudy heading="Trailer dealer" link="trailer-dealer" />} />
              <Route path='/digital-marketing-for/transportation-escort-service' element={<DigitalMarketingCaseStudy heading="Transportation escort service" link="transportation-escort-service" />} />
              <Route path='/digital-marketing-for/tribal-headquarters' element={<DigitalMarketingCaseStudy heading="Tribal headquarters" link="tribal-headquarters" />} />
              <Route path='/digital-marketing-for/tropical-fish-store' element={<DigitalMarketingCaseStudy heading="Tropical fish store" link="tropical-fish-store" />} />
              <Route path='/digital-marketing-for/truck-topper-supplier' element={<DigitalMarketingCaseStudy heading="Truck topper supplier" link="truck-topper-supplier" />} />
              <Route path='/digital-marketing-for/turf-supplier' element={<DigitalMarketingCaseStudy heading="Turf supplier" link="turf-supplier" />} />
              <Route path='/digital-marketing-for/typewriter-repair-service' element={<DigitalMarketingCaseStudy heading="Typewriter repair service" link="typewriter-repair-service" />} />
              <Route path='/digital-marketing-for/typewriter-supplier' element={<DigitalMarketingCaseStudy heading="Typewriter supplier" link="typewriter-supplier" />} />
              <Route path='/digital-marketing-for/typing-service' element={<DigitalMarketingCaseStudy heading="Typing service" link="typing-service" />} />
              <Route path='/digital-marketing-for/united-church-of-canada' element={<DigitalMarketingCaseStudy heading="United Church of Canada" link="united-church-of-canada" />} />
              <Route path='/digital-marketing-for/urban-planning-department' element={<DigitalMarketingCaseStudy heading="Urban planning department" link="urban-planning-department" />} />
              <Route path='/digital-marketing-for/utility-trailer-dealer' element={<DigitalMarketingCaseStudy heading="Utility trailer dealer" link="utility-trailer-dealer" />} />
              <Route path='/digital-marketing-for/vacuum-cleaner-repair-shop' element={<DigitalMarketingCaseStudy heading="Vacuum cleaner repair shop" link="vacuum-cleaner-repair-shop" />} />
              <Route path='/digital-marketing-for/vacuum-cleaning-system-supplier' element={<DigitalMarketingCaseStudy heading="Vacuum cleaning system supplier" link="vacuum-cleaning-system-supplier" />} />
              <Route path='/digital-marketing-for/vastu-consultant' element={<DigitalMarketingCaseStudy heading="Vastu consultant" link="vastu-consultant" />} />
              <Route path='/digital-marketing-for/vegetable-wholesaler' element={<DigitalMarketingCaseStudy heading="Vegetable wholesaler" link="vegetable-wholesaler" />} />
              <Route path='/digital-marketing-for/vegetable-wholesale-market' element={<DigitalMarketingCaseStudy heading="Vegetable wholesale market" link="vegetable-wholesale-market" />} />
              <Route path='/digital-marketing-for/video-camera-repair-service' element={<DigitalMarketingCaseStudy heading="Video camera repair service" link="video-camera-repair-service" />} />
              <Route path='/digital-marketing-for/video-game-rental-kiosk' element={<DigitalMarketingCaseStudy heading="Video game rental kiosk" link="video-game-rental-kiosk" />} />
              <Route path='/digital-marketing-for/vintage-clothing-store' element={<DigitalMarketingCaseStudy heading="Vintage clothing store" link="vintage-clothing-store" />} />
              <Route path='/digital-marketing-for/visa-&-passport-office' element={<DigitalMarketingCaseStudy heading="Visa and passport office" link="visa-&-passport-office" />} />
              <Route path='/digital-marketing-for/vocal-instructor' element={<DigitalMarketingCaseStudy heading="Vocal instructor" link="vocal-instructor" />} />
              <Route path='/digital-marketing-for/volleyball-court' element={<DigitalMarketingCaseStudy heading="Volleyball court" link="volleyball-court" />} />
              <Route path='/digital-marketing-for/volvo-dealer' element={<DigitalMarketingCaseStudy heading="Volvo dealer" link="volvo-dealer" />} />
              <Route path='/digital-marketing-for/waldorf-kindergarten' element={<DigitalMarketingCaseStudy heading="Waldorf kindergarten" link="waldorf-kindergarten" />} />
              <Route path='/digital-marketing-for/waldorf-school' element={<DigitalMarketingCaseStudy heading="Waldorf school" link="waldorf-school" />} />
              <Route path='/digital-marketing-for/war-museum' element={<DigitalMarketingCaseStudy heading="War museum" link="war-museum" />} />
              <Route path='/digital-marketing-for/water-cooler-supplier' element={<DigitalMarketingCaseStudy heading="Water cooler supplier" link="water-cooler-supplier" />} />
              <Route path='/digital-marketing-for/water-mill' element={<DigitalMarketingCaseStudy heading="Water mill" link="water-mill" />} />
              <Route path='/digital-marketing-for/water-park' element={<DigitalMarketingCaseStudy heading="Water park" link="water-park" />} />
              <Route path='/digital-marketing-for/water-pump-supplier' element={<DigitalMarketingCaseStudy heading="Water pump supplier" link="water-pump-supplier" />} />
              <Route path='/digital-marketing-for/water-sports-equipment-rental-service' element={<DigitalMarketingCaseStudy heading="Water sports equipment rental service" link="water-sports-equipment-rental-service" />} />
              <Route path='/digital-marketing-for/water-tank-cleaning-service' element={<DigitalMarketingCaseStudy heading="Water tank cleaning service" link="water-tank-cleaning-service" />} />
              <Route path='/digital-marketing-for/waterbed-store' element={<DigitalMarketingCaseStudy heading="Waterbed store" link="waterbed-store" />} />
              <Route path='/digital-marketing-for/weaving-mill' element={<DigitalMarketingCaseStudy heading="Weaving mill" link="weaving-mill" />} />
              <Route path='/digital-marketing-for/weir' element={<DigitalMarketingCaseStudy heading="Weir" link="weir" />} />
              <Route path='/digital-marketing-for/welder' element={<DigitalMarketingCaseStudy heading="Welder" link="welder" />} />
              <Route path='/digital-marketing-for/wheel-alignment-service' element={<DigitalMarketingCaseStudy heading="Wheel alignment service" link="wheel-alignment-service" />} />
              <Route path='/digital-marketing-for/wheel-store' element={<DigitalMarketingCaseStudy heading="Wheel store" link="wheel-store" />} />
              <Route path='/digital-marketing-for/wheelchair-rental-service' element={<DigitalMarketingCaseStudy heading="Wheelchair rental service" link="wheelchair-rental-service" />} />
              <Route path='/digital-marketing-for/wheelchair-repair-service' element={<DigitalMarketingCaseStudy heading="Wheelchair repair service" link="wheelchair-repair-service" />} />
              <Route path='/digital-marketing-for/wicker-store' element={<DigitalMarketingCaseStudy heading="Wicker store" link="wicker-store" />} />
              <Route path='/digital-marketing-for/wig-shop' element={<DigitalMarketingCaseStudy heading="Wig shop" link="wig-shop" />} />
              <Route path='/digital-marketing-for/wildlife-&-safari-park' element={<DigitalMarketingCaseStudy heading="Wildlife and safari park" link="wildlife-&-safari-park" />} />
              <Route path='/digital-marketing-for/wind-farm' element={<DigitalMarketingCaseStudy heading="Wind farm" link="wind-farm" />} />
              <Route path='/digital-marketing-for/windsurfing-store' element={<DigitalMarketingCaseStudy heading="Windsurfing store" link="windsurfing-store" />} />
              <Route path='/digital-marketing-for/wine-bar' element={<DigitalMarketingCaseStudy heading="Wine bar" link="wine-bar" />} />
              <Route path='/digital-marketing-for/wine-cellar' element={<DigitalMarketingCaseStudy heading="Wine cellar" link="wine-cellar" />} />
              <Route path='/digital-marketing-for/wine-storage-facility' element={<DigitalMarketingCaseStudy heading="Wine storage facility" link="wine-storage-facility" />} />
              <Route path='/digital-marketing-for/wrestling-school' element={<DigitalMarketingCaseStudy heading="Wrestling school" link="wrestling-school" />} />
              <Route path='/digital-marketing-for/x-ray-equipment-supplier' element={<DigitalMarketingCaseStudy heading="X-ray equipment supplier" link="x-ray-equipment-supplier" />} />
              <Route path='/digital-marketing-for/x-ray-lab' element={<DigitalMarketingCaseStudy heading="X-ray lab" link="x-ray-lab" />} />
              <Route path='/digital-marketing-for/yacht-broker' element={<DigitalMarketingCaseStudy heading="Yacht broker" link="yacht-broker" />} />
              <Route path='/digital-marketing-for/yacht-club' element={<DigitalMarketingCaseStudy heading="Yacht club" link="yacht-club" />} />
              <Route path='/digital-marketing-for/yakatabune' element={<DigitalMarketingCaseStudy heading="Yakatabune" link="yakatabune" />} />
              <Route path='/digital-marketing-for/yamaha-motorcycle-dealer' element={<DigitalMarketingCaseStudy heading="Yamaha motorcycle dealer" link="yamaha-motorcycle-dealer" />} />
              <Route path='/digital-marketing-for/yeshiva' element={<DigitalMarketingCaseStudy heading="Yeshiva" link="yeshiva" />} />
              <Route path='/digital-marketing-for/yoga-instructor' element={<DigitalMarketingCaseStudy heading="Yoga instructor" link="yoga-instructor" />} />
              <Route path='/digital-marketing-for/yoga-retreat-center' element={<DigitalMarketingCaseStudy heading="Yoga retreat center" link="yoga-retreat-center" />} />
              <Route path='/digital-marketing-for/yoga-studio' element={<DigitalMarketingCaseStudy heading="Yoga studio" link="yoga-studio" />} />
              <Route path='/digital-marketing-for/yucatan-restaurant' element={<DigitalMarketingCaseStudy heading="Yucatan restaurant" link="yucatan-restaurant" />} />
              <Route path='/digital-marketing-for/insulation-materials-store' element={<DigitalMarketingCaseStudy heading="Insulation materials store" link="insulation-materials-store" />} />
              <Route path='/digital-marketing-for/insulator-supplier' element={<DigitalMarketingCaseStudy heading="Insulator supplier" link="insulator-supplier" />} />
              <Route path='/digital-marketing-for/insurance-agency' element={<DigitalMarketingCaseStudy heading="Insurance agency" link="insurance-agency" />} />
              <Route path='/digital-marketing-for/insurance-attorney' element={<DigitalMarketingCaseStudy heading="Insurance attorney" link="insurance-attorney" />} />
              <Route path='/digital-marketing-for/insurance-broker' element={<DigitalMarketingCaseStudy heading="Insurance broker" link="insurance-broker" />} />
              <Route path='/digital-marketing-for/insurance-company' element={<DigitalMarketingCaseStudy heading="Insurance company" link="insurance-company" />} />
              <Route path='/digital-marketing-for/insurance-school' element={<DigitalMarketingCaseStudy heading="Insurance school" link="insurance-school" />} />
              <Route path='/digital-marketing-for/intellectual-property-registry' element={<DigitalMarketingCaseStudy heading="Intellectual property registry" link="intellectual-property-registry" />} />
              <Route path='/digital-marketing-for/interior-architect-office' element={<DigitalMarketingCaseStudy heading="Interior architect office" link="interior-architect-office" />} />
              <Route path='/digital-marketing-for/interior-construction-contractor' element={<DigitalMarketingCaseStudy heading="Interior construction contractor" link="interior-construction-contractor" />} />
              <Route path='/digital-marketing-for/interior-decorator' element={<DigitalMarketingCaseStudy heading="Interior Decorator" link="interior-decorator" />} />
              <Route path='/digital-marketing-for/interior-designer' element={<DigitalMarketingCaseStudy heading="Interior designer" link="interior-designer" />} />
              <Route path='/digital-marketing-for/interior-door-shop' element={<DigitalMarketingCaseStudy heading="Interior door shop" link="interior-door-shop" />} />
              <Route path='/digital-marketing-for/interior-fitting-contractor' element={<DigitalMarketingCaseStudy heading="Interior fitting contractor" link="interior-fitting-contractor" />} />
              <Route path='/digital-marketing-for/interior-plant-service' element={<DigitalMarketingCaseStudy heading="Interior plant service" link="interior-plant-service" />} />
              <Route path='/digital-marketing-for/international-airport' element={<DigitalMarketingCaseStudy heading="International airport" link="international-airport" />} />
              <Route path='/digital-marketing-for/international-school' element={<DigitalMarketingCaseStudy heading="International school" link="international-school" />} />
              <Route path='/digital-marketing-for/international-trade-consultant' element={<DigitalMarketingCaseStudy heading="International trade consultant" link="international-trade-consultant" />} />
              <Route path='/digital-marketing-for/internet-marketing-service' element={<DigitalMarketingCaseStudy heading="Internet marketing service" link="internet-marketing-service" />} />
              <Route path='/digital-marketing-for/internet-service-provider' element={<DigitalMarketingCaseStudy heading="Internet service provider" link="internet-service-vprovider" />} />
              <Route path='/digital-marketing-for/internet-shop' element={<DigitalMarketingCaseStudy heading="Internet shop" link="internet-shop" />} />
              <Route path='/digital-marketing-for/internist' element={<DigitalMarketingCaseStudy heading="Internist" link="internist" />} />
              <Route path='/digital-marketing-for/investment-bank' element={<DigitalMarketingCaseStudy heading="Investment bank" link="investment-bank" />} />
              <Route path='/digital-marketing-for/investment-company' element={<DigitalMarketingCaseStudy heading="Investment company" link="investment-company" />} />
              <Route path='/digital-marketing-for/investment-service' element={<DigitalMarketingCaseStudy heading="Investment service" link="investment-service" />} />
              <Route path='/digital-marketing-for/irish-goods-store' element={<DigitalMarketingCaseStudy heading="Irish goods store" link="irish-goods-store" />} />
              <Route path='/digital-marketing-for/irish-pub' element={<DigitalMarketingCaseStudy heading="Irish pub" link="irish-pub" />} />
              <Route path='/digital-marketing-for/iron-steel-contractor' element={<DigitalMarketingCaseStudy heading="Iron steel contractor" link="iron-steel-contractor" />} />
              <Route path='/digital-marketing-for/iron-ware-dealer' element={<DigitalMarketingCaseStudy heading="Iron ware dealer" link="iron-ware-dealer" />} />
              <Route path='/digital-marketing-for/irrigation-equipment-supplier' element={<DigitalMarketingCaseStudy heading="Irrigation equipment supplier" link="irrigation-equipment-supplier" />} />
              <Route path='/digital-marketing-for/italian-grocery-store' element={<DigitalMarketingCaseStudy heading="Italian grocery store" link="italian-grocery-store" />} />
              <Route path='/digital-marketing-for/jain-temple' element={<DigitalMarketingCaseStudy heading="Jain temple" link="jain-temple" />} />
              <Route path='/digital-marketing-for/janitorial-equipment-supplier' element={<DigitalMarketingCaseStudy heading="Janitorial equipment supplier" link="janitorial-equipment-supplier" />} />
              <Route path='/digital-marketing-for/janitorial-service' element={<DigitalMarketingCaseStudy heading="Janitorial service" link="janitorial-service" />} />
              <Route path='/digital-marketing-for/japanese-delicatessen' element={<DigitalMarketingCaseStudy heading="Japanese delicatessen" link="japanese-delicatessen" />} />
              <Route path='/digital-marketing-for/japanese-grocery-store' element={<DigitalMarketingCaseStudy heading="Japanese grocery store" link="japanese-grocery-store" />} />
              <Route path='/digital-marketing-for/japanese-language-instructor' element={<DigitalMarketingCaseStudy heading="Japanese language instructor" link="japanese-language-instructor" />} />
              <Route path='/digital-marketing-for/japanese-prefecture-government-office' element={<DigitalMarketingCaseStudy heading="Japanese prefecture government office" link="japanese-prefecture-government-office" />} />
              <Route path='/digital-marketing-for/japanese-steakhouse' element={<DigitalMarketingCaseStudy heading="Japanese steakhouse" link="japanese-steakhouse" />} />
              <Route path='/digital-marketing-for/jeep-dealer' element={<DigitalMarketingCaseStudy heading="Jeep dealer" link="jeep-dealer" />} />
              <Route path='/digital-marketing-for/jeweler' element={<DigitalMarketingCaseStudy heading="Jeweler" link="jeweler" />} />
              <Route path='/digital-marketing-for/jewelry-appraiser' element={<DigitalMarketingCaseStudy heading="Jewelry appraiser" link="jewelry-appraiser" />} />
              <Route path='/digital-marketing-for/jewelry-buyer' element={<DigitalMarketingCaseStudy heading="Jewelry buyer" link="jewelry-buyer" />} />
              <Route path='/digital-marketing-for/jewelry-designer' element={<DigitalMarketingCaseStudy heading="Jewelry designer" link="jewelry-designer" />} />
              <Route path='/digital-marketing-for/jewelry-engraver' element={<DigitalMarketingCaseStudy heading="Jewelry engraver" link="jewelry-engraver" />} />
              <Route path='/digital-marketing-for/jewelry-equipment-supplier' element={<DigitalMarketingCaseStudy heading="Jewelry equipment supplier" link="jewelry-equipment-supplier" />} />
              <Route path='/digital-marketing-for/jewelry-exporter' element={<DigitalMarketingCaseStudy heading="Jewelry exporter" link="jewelry-exporter" />} />
              <Route path='/digital-marketing-for/jewelry-manufacturer' element={<DigitalMarketingCaseStudy heading="Jewelry manufacturer" link="jewelry-manufacturer" />} />
              <Route path='/digital-marketing-for/jewelry-repair-service' element={<DigitalMarketingCaseStudy heading="Jewelry repair service" link="jewelry-repair-service" />} />
              <Route path='/digital-marketing-for/jewelry-store' element={<DigitalMarketingCaseStudy heading="Jewelry store" link="jewelry-store" />} />
              <Route path='/digital-marketing-for/joiner' element={<DigitalMarketingCaseStudy heading="Joiner" link="joiner" />} />
              <Route path='/digital-marketing-for/judicial-auction' element={<DigitalMarketingCaseStudy heading="Judicial auction" link="judicial-auction" />} />
              <Route path='/digital-marketing-for/judicial-scrivener' element={<DigitalMarketingCaseStudy heading="Judicial scrivener" link="judicial-scrivener" />} />
              <Route path='/digital-marketing-for/judo-school' element={<DigitalMarketingCaseStudy heading="Judo school" link="judo-school" />} />
              <Route path='/digital-marketing-for/jujitsu-school' element={<DigitalMarketingCaseStudy heading="Jujitsu school" link="jujitsu-school" />} />
              <Route path='/digital-marketing-for/junior-college' element={<DigitalMarketingCaseStudy heading="Junior college" link="junior-college" />} />
              <Route path='/digital-marketing-for/junk-dealer' element={<DigitalMarketingCaseStudy heading="Junk dealer" link="junk-dealer" />} />
              <Route path='/digital-marketing-for/junk-store' element={<DigitalMarketingCaseStudy heading="Junk store" link="junk-store" />} />
              <Route path='/digital-marketing-for/karaoke-bar' element={<DigitalMarketingCaseStudy heading="Karaoke bar" link="karaoke-bar" />} />
              <Route path='/digital-marketing-for/karate-club' element={<DigitalMarketingCaseStudy heading="Karate club" link="karate-club" />} />
              <Route path='/digital-marketing-for/karate-school' element={<DigitalMarketingCaseStudy heading="Karate school" link="karate-school" />} />
              <Route path='/digital-marketing-for/karma-dealer' element={<DigitalMarketingCaseStudy heading="Karma dealer" link="karma-dealer" />} />
              <Route path='/digital-marketing-for/kawasaki-motorcycle-dealer' element={<DigitalMarketingCaseStudy heading="Kawasaki motorcycle dealer" link="kawasaki-motorcycle-dealer" />} />
              <Route path='/digital-marketing-for/kennel' element={<DigitalMarketingCaseStudy heading="Kennel" link="kennel" />} />
              <Route path='/digital-marketing-for/kerosene-supplier' element={<DigitalMarketingCaseStudy heading="Kerosene supplier" link="kerosene-supplier" />} />
              <Route path='/digital-marketing-for/key-duplication-service' element={<DigitalMarketingCaseStudy heading="Key duplication service" link="key-duplication-service" />} />
              <Route path='/digital-marketing-for/kia-dealer' element={<DigitalMarketingCaseStudy heading="Kia dealer" link="kia-dealer" />} />
              <Route path='/digital-marketing-for/kickboxing-school' element={<DigitalMarketingCaseStudy heading="Kickboxing school" link="kickboxing-school" />} />
              <Route path='/digital-marketing-for/kilt-shop-&-hire' element={<DigitalMarketingCaseStudy heading="Kilt shop and hire" link="kilt-shop-&-hire" />} />
              <Route path='/digital-marketing-for/kimono-store' element={<DigitalMarketingCaseStudy heading="Kimono store" link="kimono-store" />} />
              <Route path='/digital-marketing-for/kinesiotherapist' element={<DigitalMarketingCaseStudy heading="Kinesiotherapist" link="kinesiotherapist" />} />
              <Route path='/digital-marketing-for/kitchen-furniture-store' element={<DigitalMarketingCaseStudy heading="Kitchen furniture store" link="kitchen-furniture-store" />} />
              <Route path='/digital-marketing-for/kitchen-remodeler' element={<DigitalMarketingCaseStudy heading="Kitchen remodeler" link="kitchen-remodeler" />} />
              <Route path='/digital-marketing-for/kitchen-supply-store' element={<DigitalMarketingCaseStudy heading="Kitchen supply store" link="kitchen-supply-store" />} />
              <Route path='/digital-marketing-for/knife-manufacturer' element={<DigitalMarketingCaseStudy heading="Knife manufacturer" link="knife-manufacturer" />} />
              <Route path='/digital-marketing-for/korean-church' element={<DigitalMarketingCaseStudy heading="Korean church" link="korean-church" />} />
              <Route path='/digital-marketing-for/korean-grocery-store' element={<DigitalMarketingCaseStudy heading="Korean grocery store" link="korean-grocery-store" />} />
              <Route path='/digital-marketing-for/kung-fu-school' element={<DigitalMarketingCaseStudy heading="Kung fu school" link="kung-fu-school" />} />
              <Route path='/digital-marketing-for/labor-relations-attorney' element={<DigitalMarketingCaseStudy heading="Labor relations attorney" link="labor-relations-attorney" />} />
              <Route path='/digital-marketing-for/laboratory' element={<DigitalMarketingCaseStudy heading="Laboratory" link="laboratory" />} />
              <Route path='/digital-marketing-for/lamp-repair-service' element={<DigitalMarketingCaseStudy heading="Lamp repair service" link="lamp-repair-service" />} />
              <Route path='/digital-marketing-for/lancia-dealer' element={<DigitalMarketingCaseStudy heading="Lancia dealer" link="lancia-dealer" />} />
              <Route path='/digital-marketing-for/land-allotment' element={<DigitalMarketingCaseStudy heading="Land allotment" link="land-allotment" />} />
              <Route path='/digital-marketing-for/land-planning-authority' element={<DigitalMarketingCaseStudy heading="Land planning authority" link="land-planning-authority" />} />
              <Route path='/digital-marketing-for/land-surveyor' element={<DigitalMarketingCaseStudy heading="Land surveyor" link="land-surveyor" />} />
              <Route path='/digital-marketing-for/landscape-architect' element={<DigitalMarketingCaseStudy heading="Landscape architect" link="landscape-architect" />} />
              <Route path='/digital-marketing-for/landscape-designer' element={<DigitalMarketingCaseStudy heading="Landscape designer" link="landscape-designer" />} />
              <Route path='/digital-marketing-for/landscaper' element={<DigitalMarketingCaseStudy heading="Landscaper" link="landscaper" />} />
              <Route path='/digital-marketing-for/landscaping-supply-store' element={<DigitalMarketingCaseStudy heading="Landscaping supply store" link="landscaping-supply-store" />} />
              <Route path='/digital-marketing-for/language-school' element={<DigitalMarketingCaseStudy heading="Language school" link="language-school" />} />
              <Route path='/digital-marketing-for/lapidary' element={<DigitalMarketingCaseStudy heading="Lapidary" link="lapidary" />} />
              <Route path='/digital-marketing-for/laser-equipment-supplier' element={<DigitalMarketingCaseStudy heading="Laser equipment supplier" link="laser-equipment-supplier" />} />
              <Route path='/digital-marketing-for/laser-hair-removal-service' element={<DigitalMarketingCaseStudy heading="Laser hair removal service" link="laser-hair-removal-service" />} />
              <Route path='/digital-marketing-for/lasik-surgeon' element={<DigitalMarketingCaseStudy heading="Lasik surgeon" link="lasik-surgeon" />} />
              <Route path='/digital-marketing-for/laundromat' element={<DigitalMarketingCaseStudy heading="Laundromat" link="laundromat" />} />
              <Route path='/digital-marketing-for/laundry' element={<DigitalMarketingCaseStudy heading="Laundry" link="laundry" />} />
              <Route path='/digital-marketing-for/laundry-service' element={<DigitalMarketingCaseStudy heading="Laundry service" link="laundry-service" />} />
              <Route path='/digital-marketing-for/law-book-store' element={<DigitalMarketingCaseStudy heading="Law book store" link="law-book-store" />} />
              <Route path='/digital-marketing-for/law-firm' element={<DigitalMarketingCaseStudy heading="Law firm" link="law-firm" />} />
              <Route path='/digital-marketing-for/law-library' element={<DigitalMarketingCaseStudy heading="Law library" link="law-library" />} />
              <Route path='/digital-marketing-for/lawn-sprinkler-system-contractor' element={<DigitalMarketingCaseStudy heading="Lawn Sprinkler System Contractor" link="lawn-sprinkler-system-contractor" />} />
              <Route path='/digital-marketing-for/lawyer' element={<DigitalMarketingCaseStudy heading="Lawyer" link="lawyer" />} />
              <Route path='/digital-marketing-for/lawyers-association' element={<DigitalMarketingCaseStudy heading="Lawyers Association" link="lawyers-association" />} />
              <Route path='/digital-marketing-for/leagues-club' element={<DigitalMarketingCaseStudy heading="Leagues Club" link="leagues-club" />} />
              <Route path='/digital-marketing-for/learner-driver-training-area' element={<DigitalMarketingCaseStudy heading="Learner Driver Training Area" link="learner-driver-training-area" />} />
              <Route path='/digital-marketing-for/learning-center' element={<DigitalMarketingCaseStudy heading="Learning Center" link="learning-center" />} />
              <Route path='/digital-marketing-for/leasing-service' element={<DigitalMarketingCaseStudy heading="Leasing Service" link="leasing-service" />} />
              <Route path='/digital-marketing-for/leather-cleaning-service' element={<DigitalMarketingCaseStudy heading="Leather Cleaning Service" link="leather-cleaning-service" />} />
              <Route path='/digital-marketing-for/leather-coats-store' element={<DigitalMarketingCaseStudy heading="Leather Coats Store" link="leather-coats-store" />} />
              <Route path='/digital-marketing-for/leather-exporter' element={<DigitalMarketingCaseStudy heading="Leather Exporter" link="leather-exporter" />} />
              <Route path='/digital-marketing-for/legal-aid-office' element={<DigitalMarketingCaseStudy heading="Legal Aid Office" link="legal-aid-office" />} />
              <Route path='/digital-marketing-for/legal-services' element={<DigitalMarketingCaseStudy heading="Legal Services" link="legal-services" />} />
              <Route path='/digital-marketing-for/leisure-centre' element={<DigitalMarketingCaseStudy heading="Leisure Centre" link="leisure-centre" />} />
              <Route path='/digital-marketing-for/lesbian-bar' element={<DigitalMarketingCaseStudy heading="Lesbian Bar" link="lesbian-bar" />} />
              <Route path='/digital-marketing-for/library' element={<DigitalMarketingCaseStudy heading="Library" link="library" />} />
              <Route path='/digital-marketing-for/license-bureau' element={<DigitalMarketingCaseStudy heading="License Bureau" link="license-bureau" />} />
              <Route path='/digital-marketing-for/license-plate-frames-supplier' element={<DigitalMarketingCaseStudy heading="License Plate Frames Supplier" link="license-plate-frames-supplier" />} />
              <Route path='/digital-marketing-for/lido' element={<DigitalMarketingCaseStudy heading="Lido" link="lido" />} />
              <Route path='/digital-marketing-for/life-coach' element={<DigitalMarketingCaseStudy heading="Life Coach" link="life-coach" />} />
              <Route path='/digital-marketing-for/life-insurance-agency' element={<DigitalMarketingCaseStudy heading="Life Insurance Agency" link="life-insurance-agency" />} />
              <Route path='/digital-marketing-for/light-bulb-supplier' element={<DigitalMarketingCaseStudy heading="Light Bulb Supplier" link="light-bulb-supplier" />} />
              <Route path='/digital-marketing-for/lighting-consultant' element={<DigitalMarketingCaseStudy heading="Lighting Consultant" link="lighting-consultant" />} />
              <Route path='/digital-marketing-for/lighting-contractor' element={<DigitalMarketingCaseStudy heading="Lighting Contractor" link="lighting-contractor" />} />
              <Route path='/digital-marketing-for/lighting-manufacturer' element={<DigitalMarketingCaseStudy heading="Lighting Manufacturer" link="lighting-manufacturer" />} />
              <Route path='/digital-marketing-for/lighting-products-wholesaler' element={<DigitalMarketingCaseStudy heading="Lighting Products Wholesaler" link="lighting-products-wholesaler" />} />
              <Route path='/digital-marketing-for/lighting-store' element={<DigitalMarketingCaseStudy heading="Lighting Store" link="lighting-store" />} />
              <Route path='/digital-marketing-for/lincoln-dealer' element={<DigitalMarketingCaseStudy heading="Lincoln Dealer" link="lincoln-dealer" />} />
              <Route path='/digital-marketing-for/line-marking-service' element={<DigitalMarketingCaseStudy heading="Line Marking Service" link="line-marking-service" />} />
              <Route path='/digital-marketing-for/linens-store' element={<DigitalMarketingCaseStudy heading="Linens Store" link="linens-store" />} />
              <Route path='/digital-marketing-for/liquor-store' element={<DigitalMarketingCaseStudy heading="Liquor Store" link="liquor-store" />} />
              <Route path='/digital-marketing-for/literacy-program' element={<DigitalMarketingCaseStudy heading="Literacy Program" link="literacy-program" />} />
              <Route path='/digital-marketing-for/little-league-club' element={<DigitalMarketingCaseStudy heading="Little League Club" link="little-league-club" />} />
              <Route path='/digital-marketing-for/little-league-field' element={<DigitalMarketingCaseStudy heading="Little League Field" link="little-league-field" />} />
              <Route path='/digital-marketing-for/live-music-bar' element={<DigitalMarketingCaseStudy heading="Live Music Bar" link="live-music-bar" />} />
              <Route path='/digital-marketing-for/live-music-venue' element={<DigitalMarketingCaseStudy heading="Live Music Venue" link="live-music-venue" />} />
              <Route path='/digital-marketing-for/livestock-producer' element={<DigitalMarketingCaseStudy heading="Livestock Producer" link="livestock-producer" />} />
              <Route path='/digital-marketing-for/loan-agency' element={<DigitalMarketingCaseStudy heading="Loan Agency" link="loan-agency" />} />
              <Route path='/digital-marketing-for/local-government-office' element={<DigitalMarketingCaseStudy heading="Local Government Office" link="local-government-office" />} />
              <Route path='/digital-marketing-for/local-history-museum' element={<DigitalMarketingCaseStudy heading="Local History Museum" link="local-history-museum" />} />
              <Route path='/digital-marketing-for/local-medical-services' element={<DigitalMarketingCaseStudy heading="Local Medical Services" link="local-medical-services" />} />
              <Route path='/digital-marketing-for/lock-store' element={<DigitalMarketingCaseStudy heading="Lock Store" link="lock-store" />} />
              <Route path='/digital-marketing-for/locks-supplier' element={<DigitalMarketingCaseStudy heading="Locks Supplier" link="locks-supplier" />} />
              <Route path='/digital-marketing-for/locksmith' element={<DigitalMarketingCaseStudy heading="Locksmith" link="locksmith" />} />
              <Route path='/digital-marketing-for/loctician-service' element={<DigitalMarketingCaseStudy heading="Loctician Service" link="loctician-service" />} />
              <Route path='/digital-marketing-for/lodge' element={<DigitalMarketingCaseStudy heading="Lodge" link="lodge" />} />
              <Route path='/digital-marketing-for/lodging' element={<DigitalMarketingCaseStudy heading="Lodging" link="lodging" />} />
              <Route path='/digital-marketing-for/log-cabins' element={<DigitalMarketingCaseStudy heading="Log Cabins" link="log-cabins" />} />
              <Route path='/digital-marketing-for/log-home-builder' element={<DigitalMarketingCaseStudy heading="Log Home Builder" link="log-home-builder" />} />
              <Route path='/digital-marketing-for/logging-contractor' element={<DigitalMarketingCaseStudy heading="Logging Contractor" link="logging-contractor" />} />
              <Route path='/digital-marketing-for/logistics-service' element={<DigitalMarketingCaseStudy heading="Logistics Service" link="logistics-service" />} />
              <Route path='/digital-marketing-for/loss-adjuster' element={<DigitalMarketingCaseStudy heading="Loss Adjuster" link="loss-adjuster" />} />
              <Route path='/digital-marketing-for/lost-property-office' element={<DigitalMarketingCaseStudy heading="Lost Property Office" link="lost-property-office" />} />
              <Route path='/digital-marketing-for/manufactured-home-transporter' element={<DigitalMarketingCaseStudy heading="Manufactured Home Transporter" link="manufactured-home-transporter" />} />
              <Route path='/digital-marketing-for/manufacturer' element={<DigitalMarketingCaseStudy heading="Manufacturer" link="manufacturer" />} />
              <Route path='/digital-marketing-for/maori-organization' element={<DigitalMarketingCaseStudy heading="Maori Organization" link="maori-organization" />} />
              <Route path='/digital-marketing-for/map-store' element={<DigitalMarketingCaseStudy heading="Map Store" link="map-store" />} />
              <Route path='/digital-marketing-for/mapping-service' element={<DigitalMarketingCaseStudy heading="Mapping Service" link="mapping-service" />} />
              <Route path='/digital-marketing-for/marae' element={<DigitalMarketingCaseStudy heading="Marae" link="marae" />} />
              <Route path='/digital-marketing-for/marble-contractor' element={<DigitalMarketingCaseStudy heading="Marble Contractor" link="marble-contractor" />} />
              <Route path='/digital-marketing-for/machinery-parts-manufacturer' element={<DigitalMarketingCaseStudy heading="Machinery Parts Manufacturer" link="machinery-parts-manufacturer" />} />
              <Route path='/digital-marketing-for/machining-manufacturer' element={<DigitalMarketingCaseStudy heading="Machining Manufacturer" link="machining-manufacturer" />} />
              <Route path='/digital-marketing-for/magazine-store' element={<DigitalMarketingCaseStudy heading="Magazine Store" link="magazine-store" />} />
              <Route path='/digital-marketing-for/magic-store' element={<DigitalMarketingCaseStudy heading="Magic Store" link="magic-store" />} />
              <Route path='/digital-marketing-for/magician' element={<DigitalMarketingCaseStudy heading="Magician" link="magician" />} />
              <Route path='/digital-marketing-for/mahjong-house' element={<DigitalMarketingCaseStudy heading="Mahjong House" link="mahjong-house" />} />
              <Route path='/digital-marketing-for/machine-construction' element={<DigitalMarketingCaseStudy heading="Machine Construction" link="machine-construction" />} />
              <Route path='/digital-marketing-for/machine-knife-supplier' element={<DigitalMarketingCaseStudy heading="Machine Knife Supplier" link="machine-knife-supplier" />} />
              <Route path='/digital-marketing-for/machine-maintenance-service' element={<DigitalMarketingCaseStudy heading="Machine Maintenance Service" link="machine-maintenance-service" />} />
              <Route path='/digital-marketing-for/machine-repair-service' element={<DigitalMarketingCaseStudy heading="Machine Repair Service" link="machine-repair-service" />} />
              <Route path='/digital-marketing-for/machine-shop' element={<DigitalMarketingCaseStudy heading="Machine Shop" link="machine-shop" />} />
              <Route path='/digital-marketing-for/luggage-storage-facility' element={<DigitalMarketingCaseStudy heading="Luggage Storage Facility" link="luggage-storage-facility" />} />
              <Route path='/digital-marketing-for/luggage-store' element={<DigitalMarketingCaseStudy heading="Luggage Store" link="luggage-store" />} />
              <Route path='/digital-marketing-for/luggage-wholesaler' element={<DigitalMarketingCaseStudy heading="Luggage Wholesaler" link="luggage-wholesaler" />} />
              <Route path='/digital-marketing-for/lumber-store' element={<DigitalMarketingCaseStudy heading="Lumber Store" link="lumber-store" />} />
              <Route path='/digital-marketing-for/lutheran-church' element={<DigitalMarketingCaseStudy heading="Lutheran Church" link="lutheran-church" />} />
              <Route path='/digital-marketing-for/marina' element={<DigitalMarketingCaseStudy heading="Marina" link="marina" />} />
              <Route path='/digital-marketing-for/marine-engineer' element={<DigitalMarketingCaseStudy heading="Marine Engineer" link="marine-engineer" />} />
              <Route path='/digital-marketing-for/marine-self-defense-force' element={<DigitalMarketingCaseStudy heading="Marine Self Defense Force" link="marine-self-defense-force" />} />
              <Route path='/digital-marketing-for/marine-supply-store' element={<DigitalMarketingCaseStudy heading="Marine Supply Store" link="marine-supply-store" />} />
              <Route path='/digital-marketing-for/maritime-museum' element={<DigitalMarketingCaseStudy heading="Maritime Museum" link="maritime-museum" />} />
              <Route path='/digital-marketing-for/market' element={<DigitalMarketingCaseStudy heading="Market" link="market" />} />
              <Route path='/digital-marketing-for/market-researcher' element={<DigitalMarketingCaseStudy heading="Market Researcher" link="market-researcher" />} />
              <Route path='/digital-marketing-for/marketing-agency' element={<DigitalMarketingCaseStudy heading="Marketing Agency" link="marketing-agency" />} />
              <Route path='/digital-marketing-for/marketing-consultant' element={<DigitalMarketingCaseStudy heading="Marketing Consultant" link="marketing-consultant" />} />
              <Route path='/digital-marketing-for/markmens-clubhouse' element={<DigitalMarketingCaseStudy heading="Markmens Clubhouse" link="markmens-clubhouse" />} />
              <Route path='/digital-marketing-for/marriage-celebrant' element={<DigitalMarketingCaseStudy heading="Marriage Celebrant" link="marriage-celebrant" />} />
              <Route path='/digital-marketing-for/marriage-license-bureau' element={<DigitalMarketingCaseStudy heading="Marriage License Bureau" link="marriage-license-bureau" />} />
              <Route path='/digital-marketing-for/marriage-or-relationship-counselor' element={<DigitalMarketingCaseStudy heading="Marriage or Relationship Counselor" link="marriage-or-relationship-counselor" />} />
              <Route path='/digital-marketing-for/martial-arts-club' element={<DigitalMarketingCaseStudy heading="Martial Arts Club" link="martial-arts-club" />} />
              <Route path='/digital-marketing-for/martial-arts-school' element={<DigitalMarketingCaseStudy heading="Martial Arts School" link="martial-arts-school" />} />
              <Route path='/digital-marketing-for/martial-arts-supply-store' element={<DigitalMarketingCaseStudy heading="Martial Arts Supply Store" link="martial-arts-supply-store" />} />
              <Route path='/digital-marketing-for/masonry-supply-store' element={<DigitalMarketingCaseStudy heading="Masonry Supply Store" link="masonry-supply-store" />} />
              <Route path='/digital-marketing-for/massage-school' element={<DigitalMarketingCaseStudy heading="Massage School" link="massage-school" />} />
              <Route path='/digital-marketing-for/massage-spa' element={<DigitalMarketingCaseStudy heading="Massage Spa" link="massage-spa" />} />
              <Route path='/digital-marketing-for/massage-supply-store' element={<DigitalMarketingCaseStudy heading="Massage Supply Store" link="massage-supply-store" />} />
              <Route path='/digital-marketing-for/massage-therapist' element={<DigitalMarketingCaseStudy heading="Massage Therapist" link="massage-therapist" />} />
              <Route path='/digital-marketing-for/maternity-hospital' element={<DigitalMarketingCaseStudy heading="Maternity Hospital" link="maternity-hospital" />} />
              <Route path='/digital-marketing-for/maternity-store' element={<DigitalMarketingCaseStudy heading="Maternity Store" link="maternity-store" />} />
              <Route path='/digital-marketing-for/mathematics-school' element={<DigitalMarketingCaseStudy heading="Mathematics School" link="mathematics-school" />} />
              <Route path='/digital-marketing-for/mattress-store' element={<DigitalMarketingCaseStudy heading="Mattress Store" link="mattress-store" />} />
              <Route path='/digital-marketing-for/metal-supplier' element={<DigitalMarketingCaseStudy heading="Metal Supplier" link="metal-supplier" />} />
              <Route path='/digital-marketing-for/metal-working-shop' element={<DigitalMarketingCaseStudy heading="Metal Working Shop" link="metal-working-shop" />} />
              <Route path='/digital-marketing-for/metal-workshop' element={<DigitalMarketingCaseStudy heading="Metal Workshop" link="metal-workshop" />} />
              <Route path='/digital-marketing-for/metallurgy-company' element={<DigitalMarketingCaseStudy heading="Metallurgy Company" link="metallurgy-company" />} />
              <Route path='/digital-marketing-for/metalware-dealer' element={<DigitalMarketingCaseStudy heading="Metalware Dealer" link="metalware-dealer" />} />
              <Route path='/digital-marketing-for/metalware-producer' element={<DigitalMarketingCaseStudy heading="Metalware Producer" link="metalware-producer" />} />
              <Route path='/digital-marketing-for/metaphysical-supply-store' element={<DigitalMarketingCaseStudy heading="Metaphysical Supply Store" link="metaphysical-supply-store" />} />
              <Route path='/digital-marketing-for/methodist-church' element={<DigitalMarketingCaseStudy heading="Methodist Church" link="methodist-church" />} />
              <Route path='/digital-marketing-for/metropolitan-train-company' element={<DigitalMarketingCaseStudy heading="Metropolitan Train Company" link="metropolitan-train-company" />} />
              <Route path='/digital-marketing-for/mexican-goods-store' element={<DigitalMarketingCaseStudy heading="Mexican Goods Store" link="mexican-goods-store" />} />
              <Route path='/digital-marketing-for/mexican-grocery-store' element={<DigitalMarketingCaseStudy heading="Mexican Grocery Store" link="mexican-grocery-store" />} />
              <Route path='/digital-marketing-for/mennonite-church' element={<DigitalMarketingCaseStudy heading="Mennonite Church" link="mennonite-church" />} />
              <Route path='/digital-marketing-for/mens-tailor' element={<DigitalMarketingCaseStudy heading="Mens Tailor" link="mens-tailor" />} />
              <Route path='/digital-marketing-for/mental-health-clinic' element={<DigitalMarketingCaseStudy heading="Mental Health Clinic" link="mental-health-clinic" />} />
              <Route path='/digital-marketing-for/mental-health-service' element={<DigitalMarketingCaseStudy heading="Mental Health Service" link="mental-health-service" />} />
              <Route path='/digital-marketing-for/mercantile-development' element={<DigitalMarketingCaseStudy heading="Mercantile Development" link="mercantile-development" />} />
              <Route path='/digital-marketing-for/medical-equipment-manufacturer' element={<DigitalMarketingCaseStudy heading="Medical Equipment Manufacturer" link="medical-equipment-manufacturer" />} />
              <Route path='/digital-marketing-for/medical-equipment-supplier' element={<DigitalMarketingCaseStudy heading="Medical Equipment Supplier" link="medical-equipment-supplier" />} />
              <Route path='/digital-marketing-for/medical-examiner' element={<DigitalMarketingCaseStudy heading="Medical Examiner" link="medical-examiner" />} />
              <Route path='/digital-marketing-for/medical-group' element={<DigitalMarketingCaseStudy heading="Medical Group" link="medical-group" />} />
              <Route path='/digital-marketing-for/medical-laboratory' element={<DigitalMarketingCaseStudy heading="Medical Laboratory" link="medical-laboratory" />} />
              <Route path='/digital-marketing-for/medical-lawyer' element={<DigitalMarketingCaseStudy heading="Medical Lawyer" link="medical-lawyer" />} />
              <Route path='/digital-marketing-for/medical-office' element={<DigitalMarketingCaseStudy heading="Medical Office" link="medical-office" />} />
              <Route path='/digital-marketing-for/medical-school' element={<DigitalMarketingCaseStudy heading="Medical School" link="medical-school" />} />
              <Route path='/digital-marketing-for/medical-spa' element={<DigitalMarketingCaseStudy heading="Medical Spa" link="medical-spa" />} />
              <Route path='/digital-marketing-for/mediation-service' element={<DigitalMarketingCaseStudy heading="Mediation Service" link="mediation-service" />} />
              <Route path='/digital-marketing-for/medical-billing-service' element={<DigitalMarketingCaseStudy heading="Medical Billing Service" link="medical-billing-service" />} />
              <Route path='/digital-marketing-for/medical-book-store' element={<DigitalMarketingCaseStudy heading="Medical Book Store" link="medical-book-store" />} />
              <Route path='/digital-marketing-for/medical-center' element={<DigitalMarketingCaseStudy heading="Medical Center" link="medical-center" />} />
              <Route path='/digital-marketing-for/medical-certificate-service' element={<DigitalMarketingCaseStudy heading="Medical Certificate Service" link="medical-certificate-service" />} />
              <Route path='/digital-marketing-for/medical-clinic' element={<DigitalMarketingCaseStudy heading="Medical Clinic" link="medical-clinic" />} />
              <Route path='/digital-marketing-for/meat-processor' element={<DigitalMarketingCaseStudy heading="Meat Processor" link="meat-processor" />} />
              <Route path='/digital-marketing-for/meat-products-store' element={<DigitalMarketingCaseStudy heading="Meat Products Store" link="meat-products-store" />} />
              <Route path='/digital-marketing-for/meat-wholesaler' element={<DigitalMarketingCaseStudy heading="Meat Wholesaler" link="meat-wholesaler" />} />
              <Route path='/digital-marketing-for/mechanic' element={<DigitalMarketingCaseStudy heading="Mechanic" link="mechanic" />} />
              <Route path='/digital-marketing-for/mechanical-contractor' element={<DigitalMarketingCaseStudy heading="Mechanical Contractor" link="mechanical-contractor" />} />
              <Route path='/digital-marketing-for/mechanical-engineer' element={<DigitalMarketingCaseStudy heading="Mechanical Engineer" link="mechanical-engineer" />} />
              <Route path='/digital-marketing-for/meyhane' element={<DigitalMarketingCaseStudy heading="Meyhane" link="meyhane" />} />
              <Route path='/digital-marketing-for/mg-dealer' element={<DigitalMarketingCaseStudy heading="MG Dealer" link="mg-dealer" />} />
              <Route path='/digital-marketing-for/microwave-oven-repair-service' element={<DigitalMarketingCaseStudy heading="Microwave Oven Repair Service" link="microwave-oven-repair-service" />} />

              {/* <Route path='/digital-marketing-for/financial' element={<DigitalMarketingCaseStudy heading="Financial" link="financial" />} /> */}


              {/* Case Study for Design And Development */}
              <Route path='/design-development-for/middle-school' element={<DesignAndDevelopmentCaseStudy heading="Middle school" link="middle-school" />} />
              <Route path='/design-development-for/midwife' element={<DesignAndDevelopmentCaseStudy heading="Midwife" link="midwife" />} />
              <Route path='/design-development-for/military-archive' element={<DesignAndDevelopmentCaseStudy heading="Military Archive" link="military-archive" />} />
              <Route path='/design-development-for/military-barracks' element={<DesignAndDevelopmentCaseStudy heading="Military Barracks" link="military-barracks" />} />
              <Route path='/design-development-for/military-base' element={<DesignAndDevelopmentCaseStudy heading="Military Base" link="military-base" />} />
              <Route path='/design-development-for/military-board' element={<DesignAndDevelopmentCaseStudy heading="Military Board" link="military-board" />} />
              <Route path='/design-development-for/military-cemetery' element={<DesignAndDevelopmentCaseStudy heading="Military Cemetery" link="military-cemetery" />} />
              <Route path='/design-development-for/military-hospital' element={<DesignAndDevelopmentCaseStudy heading="Military Hospital" link="military-hospital" />} />
              <Route path='/design-development-for/military-recruiting-office' element={<DesignAndDevelopmentCaseStudy heading="Military Recruiting Office" link="military-recruiting-office" />} />
              <Route path='/design-development-for/military-residence' element={<DesignAndDevelopmentCaseStudy heading="Military Residence" link="military-residence" />} />
              <Route path='/design-development-for/military-school' element={<DesignAndDevelopmentCaseStudy heading="Military School" link="military-school" />} />
              <Route path='/design-development-for/military-town' element={<DesignAndDevelopmentCaseStudy heading="Military Town" link="military-town" />} />
              <Route path='/design-development-for/millwork-shop' element={<DesignAndDevelopmentCaseStudy heading="Millwork Shop" link="millwork-shop" />} />
              <Route path='/design-development-for/mine' element={<DesignAndDevelopmentCaseStudy heading="Mine" link="mine" />} />
              <Route path='/design-development-for/mineral-water-company' element={<DesignAndDevelopmentCaseStudy heading="Mineral Water Company" link="mineral-water-company" />} />
              <Route path='/design-development-for/mini-dealer' element={<DesignAndDevelopmentCaseStudy heading="MINI Dealer" link="mini-dealer" />} />
              <Route path='/design-development-for/miniature-golf-course' element={<DesignAndDevelopmentCaseStudy heading="Miniature Golf Course" link="miniature-golf-course" />} />
              <Route path='/design-development-for/minibus-taxi-service' element={<DesignAndDevelopmentCaseStudy heading="Minibus Taxi Service" link="minibus-taxi-service" />} />
              <Route path='/design-development-for/mining-company' element={<DesignAndDevelopmentCaseStudy heading="Mining Company" link="mining-company" />} />
              <Route path='/design-development-for/mining-consultant' element={<DesignAndDevelopmentCaseStudy heading="Mining Consultant" link="mining-consultant" />} />
              <Route path='/design-development-for/mining-equipment' element={<DesignAndDevelopmentCaseStudy heading="Mining Equipment" link="mining-equipment" />} />
              <Route path='/design-development-for/ministry-of-education' element={<DesignAndDevelopmentCaseStudy heading="Ministry of Education" link="ministry-of-education" />} />
              <Route path='/design-development-for/mission' element={<DesignAndDevelopmentCaseStudy heading="Mission" link="mission" />} />
              <Route path='/design-development-for/mitsubishi-dealer' element={<DesignAndDevelopmentCaseStudy heading="Mitsubishi Dealer" link="mitsubishi-dealer" />} />
              <Route path='/design-development-for/mobile-caterer' element={<DesignAndDevelopmentCaseStudy heading="Mobile Caterer" link="mobile-caterer" />} />
              <Route path='/design-development-for/mobile-hairdresser' element={<DesignAndDevelopmentCaseStudy heading="Mobile Hairdresser" link="mobile-hairdresser" />} />
              <Route path='/design-development-for/mobile-home-dealer' element={<DesignAndDevelopmentCaseStudy heading="Mobile Home Dealer" link="mobile-home-dealer" />} />
              <Route path='/design-development-for/mobile-home-park' element={<DesignAndDevelopmentCaseStudy heading="Mobile Home Park" link="mobile-home-park" />} />
              <Route path='/design-development-for/mobile-home-rental-agency' element={<DesignAndDevelopmentCaseStudy heading="Mobile Home Rental Agency" link="mobile-home-rental-agency" />} />
              <Route path='/design-development-for/mobile-money-agent' element={<DesignAndDevelopmentCaseStudy heading="Mobile Money Agent" link="mobile-money-agent" />} />
              <Route path='/design-development-for/mobile-network-operator' element={<DesignAndDevelopmentCaseStudy heading="Mobile Network Operator" link="mobile-network-operator" />} />
              <Route path='/design-development-for/mobile-phone-repair-shop' element={<DesignAndDevelopmentCaseStudy heading="Mobile Phone Repair Shop" link="mobile-phone-repair-shop" />} />
              <Route path='/design-development-for/mobility-equipment-supplier' element={<DesignAndDevelopmentCaseStudy heading="Mobility Equipment Supplier" link="mobility-equipment-supplier" />} />
              <Route path='/design-development-for/model-car-play-area' element={<DesignAndDevelopmentCaseStudy heading="Model Car Play Area" link="model-car-play-area" />} />
              <Route path='/design-development-for/model-design-company' element={<DesignAndDevelopmentCaseStudy heading="Model Design Company" link="model-design-company" />} />
              <Route path='/design-development-for/model-portfolio-studio' element={<DesignAndDevelopmentCaseStudy heading="Model Portfolio Studio" link="model-portfolio-studio" />} />
              <Route path='/design-development-for/model-shop' element={<DesignAndDevelopmentCaseStudy heading="Model Shop" link="model-shop" />} />
              <Route path='/design-development-for/model-train-store' element={<DesignAndDevelopmentCaseStudy heading="Model Train Store" link="model-train-store" />} />
              <Route path='/design-development-for/modeling-agency' element={<DesignAndDevelopmentCaseStudy heading="Modeling Agency" link="modeling-agency" />} />
              <Route path='/design-development-for/modeling-school' element={<DesignAndDevelopmentCaseStudy heading="Modeling School" link="modeling-school" />} />
              <Route path='/design-development-for/modern-art-museum' element={<DesignAndDevelopmentCaseStudy heading="Modern Art Museum" link="modern-art-museum" />} />
              <Route path='/design-development-for/modular-home-builder' element={<DesignAndDevelopmentCaseStudy heading="Modular Home Builder" link="modular-home-builder" />} />
              <Route path='/design-development-for/modular-home-dealer' element={<DesignAndDevelopmentCaseStudy heading="Modular Home Dealer" link="modular-home-dealer" />} />
              <Route path='/design-development-for/mohel' element={<DesignAndDevelopmentCaseStudy heading="Mohel" link="mohel" />} />
              <Route path='/design-development-for/mold-maker' element={<DesignAndDevelopmentCaseStudy heading="Mold Maker" link="mold-maker" />} />
              <Route path='/design-development-for/molding-supplier' element={<DesignAndDevelopmentCaseStudy heading="Molding Supplier" link="molding-supplier" />} />
              <Route path='/design-development-for/monastery' element={<DesignAndDevelopmentCaseStudy heading="Monastery" link="monastery" />} />
              <Route path='/design-development-for/money-order-service' element={<DesignAndDevelopmentCaseStudy heading="Money Order Service" link="money-order-service" />} />
              <Route path='/design-development-for/money-transfer-service' element={<DesignAndDevelopmentCaseStudy heading="Money Transfer Service" link="money-transfer-service" />} />
              <Route path='/design-development-for/monogramming-service' element={<DesignAndDevelopmentCaseStudy heading="Monogramming Service" link="monogramming-service" />} />
              <Route path='/design-development-for/montessori-school' element={<DesignAndDevelopmentCaseStudy heading="Montessori School" link="montessori-school" />} />
              <Route path='/design-development-for/moped-dealer' element={<DesignAndDevelopmentCaseStudy heading="Moped Dealer" link="moped-dealer" />} />
              <Route path='/design-development-for/moravian-church' element={<DesignAndDevelopmentCaseStudy heading="Moravian Church" link="moravian-church" />} />
              <Route path='/design-development-for/motel' element={<DesignAndDevelopmentCaseStudy heading="Motel" link="motel" />} />
              <Route path='/design-development-for/motor-scooter-dealer' element={<DesignAndDevelopmentCaseStudy heading="Motor Scooter Dealer" link="motor-scooter-dealer" />} />
              <Route path='/design-development-for/motor-scooter-repair-shop' element={<DesignAndDevelopmentCaseStudy heading="Motor Scooter Repair Shop" link="motor-scooter-repair-shop" />} />
              <Route path='/design-development-for/motor-vehicle-dealer' element={<DesignAndDevelopmentCaseStudy heading="Motor Vehicle Dealer" link="motor-vehicle-dealer" />} />
              <Route path='/design-development-for/motorcycle-dealer' element={<DesignAndDevelopmentCaseStudy heading="Motorcycle Dealer" link="motorcycle-dealer" />} />
              <Route path='/design-development-for/motorcycle-driving-school' element={<DesignAndDevelopmentCaseStudy heading="Motorcycle Driving School" link="motorcycle-driving-school" />} />
              <Route path='/design-development-for/motorcycle-insurance-agency' element={<DesignAndDevelopmentCaseStudy heading="Motorcycle Insurance Agency" link="motorcycle-insurance-agency" />} />
              <Route path='/design-development-for/motorcycle-rental-agency' element={<DesignAndDevelopmentCaseStudy heading="Motorcycle Rental Agency" link="motorcycle-rental-agency" />} />
              <Route path='/design-development-for/motorcycle-repair-shop' element={<DesignAndDevelopmentCaseStudy heading="Motorcycle Repair Shop" link="motorcycle-repair-shop" />} />
              <Route path='/design-development-for/motoring-club' element={<DesignAndDevelopmentCaseStudy heading="Motoring Club" link="motoring-club" />} />
              <Route path='/design-development-for/motorsports-store' element={<DesignAndDevelopmentCaseStudy heading="Motorsports Store" link="motorsports-store" />} />
              <Route path='/design-development-for/mountain-cabin' element={<DesignAndDevelopmentCaseStudy heading="Mountain Cabin" link="mountain-cabin" />} />
              <Route path='/design-development-for/mountaineering-class' element={<DesignAndDevelopmentCaseStudy heading="Mountaineering Class" link="mountaineering-class" />} />
              <Route path='/design-development-for/movie-rental-kiosk' element={<DesignAndDevelopmentCaseStudy heading="Movie Rental Kiosk" link="movie-rental-kiosk" />} />
              <Route path='/design-development-for/movie-studio' element={<DesignAndDevelopmentCaseStudy heading="Movie Studio" link="movie-studio" />} />
              <Route path='/design-development-for/movie-theater' element={<DesignAndDevelopmentCaseStudy heading="Movie Theater" link="movie-theater" />} />
              <Route path='/design-development-for/moving-and-storage-service' element={<DesignAndDevelopmentCaseStudy heading="Moving and Storage Service" link="moving-and-storage-service" />} />
              <Route path='/design-development-for/moving-company' element={<DesignAndDevelopmentCaseStudy heading="Moving Company" link="moving-company" />} />
              <Route path='/design-development-for/moving-supply-store' element={<DesignAndDevelopmentCaseStudy heading="Moving Supply Store" link="moving-supply-store" />} />
              <Route path='/design-development-for/mri-center' element={<DesignAndDevelopmentCaseStudy heading="MRI Center" link="mri-center" />} />
              <Route path='/design-development-for/muay-thai-boxing-gym' element={<DesignAndDevelopmentCaseStudy heading="Muay Thai Boxing Gym" link="muay-thai-boxing-gym" />} />
              <Route path='/design-development-for/municipal-department-agricultural-development' element={<DesignAndDevelopmentCaseStudy heading="Municipal Department Agricultural Development" link="municipal-department-agricultural-development" />} />
              <Route path='/design-development-for/municipal-department-civil-defense' element={<DesignAndDevelopmentCaseStudy heading="Municipal Department Civil Defense" link="municipal-department-civil-defense" />} />
              <Route path='/design-development-for/municipal-department-communication' element={<DesignAndDevelopmentCaseStudy heading="Municipal Department Communication" link="municipal-department-communication" />} />
              <Route path='/design-development-for/municipal-department-finance' element={<DesignAndDevelopmentCaseStudy heading="Municipal Department Finance" link="municipal-department-finance" />} />
              <Route path='/design-development-for/municipal-department-housing-and-urban-development' element={<DesignAndDevelopmentCaseStudy heading="Municipal Department Housing and Urban Development" link="municipal-department-housing-and-urban-development" />} />
              <Route path='/design-development-for/municipal-department-of-tourism' element={<DesignAndDevelopmentCaseStudy heading="Municipal Department of Tourism" link="municipal-department-of-tourism" />} />
              <Route path='/design-development-for/municipal-department-science-technology' element={<DesignAndDevelopmentCaseStudy heading="Municipal Department Science Technology" link="municipal-department-science-technology" />} />
              <Route path='/design-development-for/municipal-department-social-defense' element={<DesignAndDevelopmentCaseStudy heading="Municipal Department Social Defense" link="municipal-department-social-defense" />} />
              <Route path='/design-development-for/municipal-guard' element={<DesignAndDevelopmentCaseStudy heading="Municipal Guard" link="municipal-guard" />} />
              <Route path='/design-development-for/museum-of-zoology' element={<DesignAndDevelopmentCaseStudy heading="Museum of Zoology" link="museum-of-zoology" />} />
              <Route path='/design-development-for/music-box-store' element={<DesignAndDevelopmentCaseStudy heading="Music Box Store" link="music-box-store" />} />
              <Route path='/design-development-for/music-college' element={<DesignAndDevelopmentCaseStudy heading="Music College" link="music-college" />} />
              <Route path='/design-development-for/music-conservatory' element={<DesignAndDevelopmentCaseStudy heading="Music Conservatory" link="music-conservatory" />} />
              <Route path='/design-development-for/music-instructor' element={<DesignAndDevelopmentCaseStudy heading="Music Instructor" link="music-instructor" />} />
              <Route path='/design-development-for/music-management-and-promotion' element={<DesignAndDevelopmentCaseStudy heading="Music Management and Promotion" link="music-management-and-promotion" />} />
              <Route path='/design-development-for/music-producer' element={<DesignAndDevelopmentCaseStudy heading="Music Producer" link="music-producer" />} />
              <Route path='/design-development-for/music-publisher' element={<DesignAndDevelopmentCaseStudy heading="Music Publisher" link="music-publisher" />} />
              <Route path='/design-development-for/music-school' element={<DesignAndDevelopmentCaseStudy heading="Music School" link="music-school" />} />
              <Route path='/design-development-for/music-store' element={<DesignAndDevelopmentCaseStudy heading="Music Store" link="music-store" />} />
              <Route path='/design-development-for/musical-club' element={<DesignAndDevelopmentCaseStudy heading="Musical Club" link="musical-club" />} />
              <Route path='/design-development-for/musical-instrument-manufacturer' element={<DesignAndDevelopmentCaseStudy heading="Musical Instrument Manufacturer" link="musical-instrument-manufacturer" />} />
              <Route path='/design-development-for/musical-instrument-rental-service' element={<DesignAndDevelopmentCaseStudy heading="Musical Instrument Rental Service" link="musical-instrument-rental-service" />} />
              <Route path='/design-development-for/musical-instrument-repair-shop' element={<DesignAndDevelopmentCaseStudy heading="Musical Instrument Repair Shop" link="musical-instrument-repair-shop" />} />
              <Route path='/design-development-for/musical-instrument-store' element={<DesignAndDevelopmentCaseStudy heading="Musical Instrument Store" link="musical-instrument-store" />} />
              <Route path='/design-development-for/musician' element={<DesignAndDevelopmentCaseStudy heading="Musician" link="musician" />} />
              <Route path='/design-development-for/musician-and-composer' element={<DesignAndDevelopmentCaseStudy heading="Musician and Composer" link="musician-and-composer" />} />
              <Route path='/design-development-for/nail-salon' element={<DesignAndDevelopmentCaseStudy heading="Nail Salon" link="nail-salon" />} />
              <Route path='/design-development-for/nanotechnology-engineering-service' element={<DesignAndDevelopmentCaseStudy heading="Nanotechnology Engineering Service" link="nanotechnology-engineering-service" />} />
              <Route path='/design-development-for/national-forest' element={<DesignAndDevelopmentCaseStudy heading="National Forest" link="national-forest" />} />
              <Route path='/design-development-for/national-health-foundation' element={<DesignAndDevelopmentCaseStudy heading="National Health Foundation" link="national-health-foundation" />} />
              <Route path='/design-development-for/novelty-store' element={<DesignAndDevelopmentCaseStudy heading="Novelty Store" link="novelty-store" />} />
              <Route path='/design-development-for/nuclear-engineering-service' element={<DesignAndDevelopmentCaseStudy heading="Nuclear Engineering Service" link="nuclear-engineering-service" />} />
              <Route path='/design-development-for/nuclear-power-company' element={<DesignAndDevelopmentCaseStudy heading="Nuclear Power Company" link="nuclear-power-company" />} />
              <Route path='/design-development-for/nuclear-power-plant' element={<DesignAndDevelopmentCaseStudy heading="Nuclear Power Plant" link="nuclear-power-plant" />} />
              <Route path='/design-development-for/newspaper-publisher' element={<DesignAndDevelopmentCaseStudy heading="Newspaper Publisher" link="newspaper-publisher" />} />
              <Route path='/design-development-for/newsstand' element={<DesignAndDevelopmentCaseStudy heading="Newsstand" link="newsstand" />} />
              <Route path='/design-development-for/nissan-dealer' element={<DesignAndDevelopmentCaseStudy heading="Nissan Dealer" link="nissan-dealer" />} />
              <Route path='/design-development-for/non-smoking-holiday-home' element={<DesignAndDevelopmentCaseStudy heading="Non Smoking Holiday Home" link="non-smoking-holiday-home" />} />
              <Route path='/design-development-for/non-denominational-church' element={<DesignAndDevelopmentCaseStudy heading="Non-denominational Church" link="non-denominational-church" />} />
              <Route path='/design-development-for/non-governmental-organization' element={<DesignAndDevelopmentCaseStudy heading="Non-governmental Organization" link="non-governmental-organization" />} />
              <Route path='/design-development-for/non-profit-organization' element={<DesignAndDevelopmentCaseStudy heading="Non-profit Organization" link="non-profit-organization" />} />
              <Route path='/design-development-for/neurophysiologist' element={<DesignAndDevelopmentCaseStudy heading="Neurophysiologist" link="neurophysiologist" />} />
              <Route path='/design-development-for/neuropsychologist' element={<DesignAndDevelopmentCaseStudy heading="Neuropsychologist" link="neuropsychologist" />} />
              <Route path='/design-development-for/neurosurgeon' element={<DesignAndDevelopmentCaseStudy heading="Neurosurgeon" link="neurosurgeon" />} />
              <Route path='/design-development-for/new-age-church' element={<DesignAndDevelopmentCaseStudy heading="New Age Church" link="new-age-church" />} />
              <Route path='/design-development-for/news-service' element={<DesignAndDevelopmentCaseStudy heading="News Service" link="news-service" />} />
              <Route path='/design-development-for/newspaper-advertising-department' element={<DesignAndDevelopmentCaseStudy heading="Newspaper Advertising Department" link="newspaper-advertising-department" />} />
              <Route path='/design-development-for/natural-history-museum' element={<DesignAndDevelopmentCaseStudy heading="Natural History Museum" link="natural-history-museum" />} />
              <Route path='/design-development-for/natural-rock-climbing-area' element={<DesignAndDevelopmentCaseStudy heading="Natural Rock Climbing Area" link="natural-rock-climbing-area" />} />
              <Route path='/design-development-for/natural-stone-exporter' element={<DesignAndDevelopmentCaseStudy heading="Natural Stone Exporter" link="natural-stone-exporter" />} />
              <Route path='/design-development-for/natural-stone-supplier' element={<DesignAndDevelopmentCaseStudy heading="Natural Stone Supplier" link="natural-stone-supplier" />} />
              <Route path='/design-development-for/natural-stone-wholesaler' element={<DesignAndDevelopmentCaseStudy heading="Natural Stone Wholesaler" link="natural-stone-wholesaler" />} />
              <Route path='/design-development-for/nature-preserve' element={<DesignAndDevelopmentCaseStudy heading="Nature Preserve" link="nature-preserve" />} />
              <Route path='/design-development-for/naturopathic-practitioner' element={<DesignAndDevelopmentCaseStudy heading="Naturopathic Practitioner" link="naturopathic-practitioner" />} />
              <Route path='/design-development-for/naval-base' element={<DesignAndDevelopmentCaseStudy heading="Naval Base" link="naval-base" />} />
              <Route path='/design-development-for/needlework-shop' element={<DesignAndDevelopmentCaseStudy heading="Needlework Shop" link="needlework-shop" />} />
              <Route path='/design-development-for/neon-sign-shop' element={<DesignAndDevelopmentCaseStudy heading="Neon Sign Shop" link="neon-sign-shop" />} />
              <Route path='/design-development-for/neonatal-physician' element={<DesignAndDevelopmentCaseStudy heading="Neonatal Physician" link="neonatal-physician" />} />
              <Route path='/design-development-for/national-museum' element={<DesignAndDevelopmentCaseStudy heading="National Museum" link="national-museum" />} />
              <Route path='/design-development-for/national-park' element={<DesignAndDevelopmentCaseStudy heading="National Park" link="national-park" />} />
              <Route path='/design-development-for/national-reserve' element={<DesignAndDevelopmentCaseStudy heading="National Reserve" link="national-reserve" />} />
              <Route path='/design-development-for/native-american-goods-store' element={<DesignAndDevelopmentCaseStudy heading="Native American Goods Store" link="native-american-goods-store" />} />
              <Route path='/design-development-for/municipal-office-education' element={<DesignAndDevelopmentCaseStudy heading="Municipal Office Education" link="municipal-office-education" />} />
              <Route path='/design-development-for/municipal-social-development' element={<DesignAndDevelopmentCaseStudy heading="Municipal Social Development" link="municipal-social-development" />} />
              <Route path='/design-development-for/musalla' element={<DesignAndDevelopmentCaseStudy heading="Musalla" link="musalla" />} />
              <Route path='/design-development-for/museum' element={<DesignAndDevelopmentCaseStudy heading="Museum" link="museum" />} />
              <Route path='/design-development-for/nursing-agency' element={<DesignAndDevelopmentCaseStudy heading="Nursing Agency" link="nursing-agency" />} />
              <Route path='/design-development-for/nursing-association' element={<DesignAndDevelopmentCaseStudy heading="Nursing Association" link="nursing-association" />} />
              <Route path='/design-development-for/nursing-home' element={<DesignAndDevelopmentCaseStudy heading="Nursing Home" link="nursing-home" />} />
              <Route path='/design-development-for/nursing-school' element={<DesignAndDevelopmentCaseStudy heading="Nursing School" link="nursing-school" />} />
              <Route path='/design-development-for/nut-store' element={<DesignAndDevelopmentCaseStudy heading="Nut Store" link="nut-store" />} />
              <Route path='/design-development-for/nutritionist' element={<DesignAndDevelopmentCaseStudy heading="Nutritionist" link="nutritionist" />} />
              <Route path='/design-development-for/observation-deck' element={<DesignAndDevelopmentCaseStudy heading="Observation Deck" link="observation-deck" />} />
              <Route path='/design-development-for/obstetrician-gynecologist' element={<DesignAndDevelopmentCaseStudy heading="Obstetrician-Gynecologist" link="obstetrician-gynecologist" />} />
              <Route path='/design-development-for/occupational-health-service' element={<DesignAndDevelopmentCaseStudy heading="Occupational Health Service" link="occupational-health-service" />} />
              <Route path='/design-development-for/occupational-medical-physician' element={<DesignAndDevelopmentCaseStudy heading="Occupational Medical Physician" link="occupational-medical-physician" />} />
              <Route path='/design-development-for/office-accessories-wholesaler' element={<DesignAndDevelopmentCaseStudy heading="Office Accessories Wholesaler" link="office-accessories-wholesaler" />} />
              <Route path='/design-development-for/office-equipment-rental-service' element={<DesignAndDevelopmentCaseStudy heading="Office Equipment Rental Service" link="office-equipment-rental-service" />} />
              <Route path='/design-development-for/office-equipment-repair-service' element={<DesignAndDevelopmentCaseStudy heading="Office Equipment Repair Service" link="office-equipment-repair-service" />} />
              <Route path='/design-development-for/office-equipment-supplier' element={<DesignAndDevelopmentCaseStudy heading="Office Equipment Supplier" link="office-equipment-supplier" />} />
              <Route path='/design-development-for/office-furniture-store' element={<DesignAndDevelopmentCaseStudy heading="Office Furniture Store" link="office-furniture-store" />} />
              <Route path='/design-development-for/office-of-vital-records' element={<DesignAndDevelopmentCaseStudy heading="Office of Vital Records" link="office-of-vital-records" />} />
              <Route path='/design-development-for/office-refurbishment-service' element={<DesignAndDevelopmentCaseStudy heading="Office Refurbishment Service" link="office-refurbishment-service" />} />
              <Route path='/design-development-for/office-space-rental-agency' element={<DesignAndDevelopmentCaseStudy heading="Office Space Rental Agency" link="office-space-rental-agency" />} />
              <Route path='/design-development-for/office-supply-store' element={<DesignAndDevelopmentCaseStudy heading="Office Supply Store" link="office-supply-store" />} />
              <Route path='/design-development-for/office-supply-wholesaler' element={<DesignAndDevelopmentCaseStudy heading="Office Supply Wholesaler" link="office-supply-wholesaler" />} />
              <Route path='/design-development-for/oil-and-natural-gas-company' element={<DesignAndDevelopmentCaseStudy heading="Oil & Natural Gas Company" link="oil-and-natural-gas-company" />} />
              <Route path='/design-development-for/oil-and-gas-exploration-service' element={<DesignAndDevelopmentCaseStudy heading="Oil and Gas Exploration Service" link="oil-and-gas-exploration-service" />} />
              <Route path='/design-development-for/oil-change-service' element={<DesignAndDevelopmentCaseStudy heading="Oil Change Service" link="oil-change-service" />} />
              <Route path='/design-development-for/oil-field-equipment-supplier' element={<DesignAndDevelopmentCaseStudy heading="Oil Field Equipment Supplier" link="oil-field-equipment-supplier" />} />
              <Route path='/design-development-for/oil-refinery' element={<DesignAndDevelopmentCaseStudy heading="Oil Refinery" link="oil-refinery" />} />
              <Route path='/design-development-for/oil-store' element={<DesignAndDevelopmentCaseStudy heading="Oil Store" link="oil-store" />} />
              <Route path='/design-development-for/oil-wholesaler' element={<DesignAndDevelopmentCaseStudy heading="Oil Wholesaler" link="oil-wholesaler" />} />
              <Route path='/design-development-for/oilfield' element={<DesignAndDevelopmentCaseStudy heading="Oilfield" link="oilfield" />} />
              <Route path='/design-development-for/oldsmobile-dealer' element={<DesignAndDevelopmentCaseStudy heading="Oldsmobile Dealer" link="oldsmobile-dealer" />} />
              <Route path='/design-development-for/olive-oil-manufacturer' element={<DesignAndDevelopmentCaseStudy heading="Olive Oil Manufacturer" link="olive-oil-manufacturer" />} />
              <Route path='/design-development-for/oncologist' element={<DesignAndDevelopmentCaseStudy heading="Oncologist" link="oncologist" />} />
              <Route path='/design-development-for/onsen' element={<DesignAndDevelopmentCaseStudy heading="Onsen" link="onsen" />} />
              <Route path='/design-development-for/opel-dealer' element={<DesignAndDevelopmentCaseStudy heading="Opel Dealer" link="opel-dealer" />} />
              <Route path='/design-development-for/open-air-museum' element={<DesignAndDevelopmentCaseStudy heading="Open Air Museum" link="open-air-museum" />} />
              <Route path='/design-development-for/open-university' element={<DesignAndDevelopmentCaseStudy heading="Open University" link="open-university" />} />
              <Route path='/design-development-for/opera-company' element={<DesignAndDevelopmentCaseStudy heading="Opera Company" link="opera-company" />} />
              <Route path='/design-development-for/opera-house' element={<DesignAndDevelopmentCaseStudy heading="Opera House" link="opera-house" />} />
              <Route path='/design-development-for/ophthalmologist' element={<DesignAndDevelopmentCaseStudy heading="Ophthalmologist" link="ophthalmologist" />} />
              <Route path='/design-development-for/ophthalmology-clinic' element={<DesignAndDevelopmentCaseStudy heading="Ophthalmology Clinic" link="ophthalmology-clinic" />} />
              <Route path='/design-development-for/optical-instrument-repair-service' element={<DesignAndDevelopmentCaseStudy heading="Optical Instrument Repair Service" link="optical-instrument-repair-service" />} />
              <Route path='/design-development-for/optician' element={<DesignAndDevelopmentCaseStudy heading="Optician" link="optician" />} />
              <Route path='/design-development-for/optometrist' element={<DesignAndDevelopmentCaseStudy heading="Optometrist" link="optometrist" />} />
              <Route path='/design-development-for/oral-and-maxillofacial-surgeon' element={<DesignAndDevelopmentCaseStudy heading="Oral and Maxillofacial Surgeon" link="oral-and-maxillofacial-surgeon" />} />
              <Route path='/design-development-for/oral-surgeon' element={<DesignAndDevelopmentCaseStudy heading="Oral Surgeon" link="oral-surgeon" />} />
              <Route path='/design-development-for/orchard' element={<DesignAndDevelopmentCaseStudy heading="Orchard" link="orchard" />} />
              <Route path='/design-development-for/organic-drug-store' element={<DesignAndDevelopmentCaseStudy heading="Organic Drug Store" link="organic-drug-store" />} />
              <Route path='/design-development-for/organic-farm' element={<DesignAndDevelopmentCaseStudy heading="Organic Farm" link="organic-farm" />} />
              <Route path='/design-development-for/organic-food-store' element={<DesignAndDevelopmentCaseStudy heading="Organic Food Store" link="organic-food-store" />} />
              <Route path='/design-development-for/organic-shop' element={<DesignAndDevelopmentCaseStudy heading="Organic Shop" link="organic-shop" />} />
              <Route path='/design-development-for/oriental-goods-store' element={<DesignAndDevelopmentCaseStudy heading="Oriental Goods Store" link="oriental-goods-store" />} />
              <Route path='/design-development-for/oriental-medicine-clinic' element={<DesignAndDevelopmentCaseStudy heading="Oriental Medicine Clinic" link="oriental-medicine-clinic" />} />
              <Route path='/design-development-for/oriental-medicine-store' element={<DesignAndDevelopmentCaseStudy heading="Oriental Medicine Store" link="oriental-medicine-store" />} />
              <Route path='/design-development-for/oriental-rug-store' element={<DesignAndDevelopmentCaseStudy heading="Oriental Rug Store" link="oriental-rug-store" />} />
              <Route path='/design-development-for/orphanage' element={<DesignAndDevelopmentCaseStudy heading="Orphanage" link="orphanage" />} />
              <Route path='/design-development-for/orthodontist' element={<DesignAndDevelopmentCaseStudy heading="Orthodontist" link="orthodontist" />} />
              <Route path='/design-development-for/orthodox-church' element={<DesignAndDevelopmentCaseStudy heading="Orthodox Church" link="orthodox-church" />} />
              <Route path='/design-development-for/orthopedic-shoe-store' element={<DesignAndDevelopmentCaseStudy heading="Orthopedic Shoe Store" link="orthopedic-shoe-store" />} />
              <Route path='/design-development-for/orthopedic-supplies-store' element={<DesignAndDevelopmentCaseStudy heading="Orthopedic Supplies Store" link="orthopedic-supplies-store" />} />
              <Route path='/design-development-for/orthopedic-surgeon' element={<DesignAndDevelopmentCaseStudy heading="Orthopedic Surgeon" link="orthopedic-surgeon" />} />
              <Route path='/design-development-for/orthoptist' element={<DesignAndDevelopmentCaseStudy heading="Orthoptist" link="orthoptist" />} />
              <Route path='/design-development-for/orthotics-and-prosthetics-service' element={<DesignAndDevelopmentCaseStudy heading="Orthotics & Prosthetics Service" link="orthotics-and-prosthetics-service" />} />
              <Route path='/design-development-for/osteopath' element={<DesignAndDevelopmentCaseStudy heading="Osteopath" link="osteopath" />} />
              <Route path='/design-development-for/otolaryngologist' element={<DesignAndDevelopmentCaseStudy heading="Otolaryngologist" link="otolaryngologist" />} />
              <Route path='/design-development-for/otolaryngology-clinic' element={<DesignAndDevelopmentCaseStudy heading="Otolaryngology Clinic" link="otolaryngology-clinic" />} />
              <Route path='/design-development-for/outboard-motor-store' element={<DesignAndDevelopmentCaseStudy heading="Outboard Motor Store" link="outboard-motor-store" />} />
              <Route path='/design-development-for/outdoor-bath' element={<DesignAndDevelopmentCaseStudy heading="Outdoor Bath" link="outdoor-bath" />} />
              <Route path='/design-development-for/outdoor-clothing-and-equipment-shop' element={<DesignAndDevelopmentCaseStudy heading="Outdoor Clothing and Equipment Shop" link="outdoor-clothing-and-equipment-shop" />} />
              <Route path='/design-development-for/outdoor-equestrian-facility' element={<DesignAndDevelopmentCaseStudy heading="Outdoor Equestrian Facility" link="outdoor-equestrian-facility" />} />
              <Route path='/design-development-for/outdoor-furniture-store' element={<DesignAndDevelopmentCaseStudy heading="Outdoor Furniture Store" link="outdoor-furniture-store" />} />
              <Route path='/design-development-for/outdoor-movie-theater' element={<DesignAndDevelopmentCaseStudy heading="Outdoor Movie Theater" link="outdoor-movie-theater" />} />
              <Route path='/design-development-for/outdoor-sports-store' element={<DesignAndDevelopmentCaseStudy heading="Outdoor Sports Store" link="outdoor-sports-store" />} />
              <Route path='/design-development-for/outdoor-swimming-pool' element={<DesignAndDevelopmentCaseStudy heading="Outdoor Swimming Pool" link="outdoor-swimming-pool" />} />
              <Route path='/design-development-for/outerwear-store' element={<DesignAndDevelopmentCaseStudy heading="Outerwear Store" link="outerwear-store" />} />
              <Route path='/design-development-for/outlet-mall' element={<DesignAndDevelopmentCaseStudy heading="Outlet Mall" link="outlet-mall" />} />
              <Route path='/design-development-for/outlet-store' element={<DesignAndDevelopmentCaseStudy heading="Outlet Store" link="outlet-store" />} />
              <Route path='/design-development-for/packaging-company' element={<DesignAndDevelopmentCaseStudy heading="Packaging Company" link="packaging-company" />} />
              <Route path='/design-development-for/packaging-machinery' element={<DesignAndDevelopmentCaseStudy heading="Packaging Machinery" link="packaging-machinery" />} />
              <Route path='/design-development-for/packaging-supply-store' element={<DesignAndDevelopmentCaseStudy heading="Packaging Supply Store" link="packaging-supply-store" />} />
              <Route path='/design-development-for/padel-club' element={<DesignAndDevelopmentCaseStudy heading="Padel Club" link="padel-club" />} />
              <Route path='/design-development-for/pain-control-clinic' element={<DesignAndDevelopmentCaseStudy heading="Pain Control Clinic" link="pain-control-clinic" />} />
              <Route path='/design-development-for/pain-management-physician' element={<DesignAndDevelopmentCaseStudy heading="Pain Management Physician" link="pain-management-physician" />} />
              <Route path='/design-development-for/paint-manufacturer' element={<DesignAndDevelopmentCaseStudy heading="Paint Manufacturer" link="paint-manufacturer" />} />
              <Route path='/design-development-for/paint-store' element={<DesignAndDevelopmentCaseStudy heading="Paint Store" link="paint-store" />} />
              <Route path='/design-development-for/paint-stripping-service' element={<DesignAndDevelopmentCaseStudy heading="Paint Stripping Service" link="paint-stripping-service" />} />
              <Route path='/design-development-for/paintball-center' element={<DesignAndDevelopmentCaseStudy heading="Paintball Center" link="paintball-center" />} />
              <Route path='/design-development-for/paintball-store' element={<DesignAndDevelopmentCaseStudy heading="Paintball Store" link="paintball-store" />} />
              <Route path='/design-development-for/painter' element={<DesignAndDevelopmentCaseStudy heading="Painter" link="painter" />} />
              <Route path='/design-development-for/painting' element={<DesignAndDevelopmentCaseStudy heading="Painting" link="painting" />} />
              <Route path='/design-development-for/painting-lessons' element={<DesignAndDevelopmentCaseStudy heading="Painting Lessons" link="painting-lessons" />} />
              <Route path='/design-development-for/painting-studio' element={<DesignAndDevelopmentCaseStudy heading="Painting Studio" link="painting-studio" />} />
              <Route path='/design-development-for/paintings-store' element={<DesignAndDevelopmentCaseStudy heading="Paintings Store" link="paintings-store" />} />
              <Route path='/design-development-for/pallet-supplier' element={<DesignAndDevelopmentCaseStudy heading="Pallet Supplier" link="pallet-supplier" />} />
              <Route path='/design-development-for/panipuri-shop' element={<DesignAndDevelopmentCaseStudy heading="Panipuri Shop" link="panipuri-shop" />} />
              <Route path='/design-development-for/paper-distributor' element={<DesignAndDevelopmentCaseStudy heading="Paper Distributor" link="paper-distributor" />} />
              <Route path='/design-development-for/paper-exporter' element={<DesignAndDevelopmentCaseStudy heading="Paper Exporter" link="paper-exporter" />} />
              <Route path='/design-development-for/paper-mill' element={<DesignAndDevelopmentCaseStudy heading="Paper Mill" link="paper-mill" />} />
              <Route path='/design-development-for/paper-shredding-machine-supplier' element={<DesignAndDevelopmentCaseStudy heading="Paper Shredding Machine Supplier" link="paper-shredding-machine-supplier" />} />
              <Route path='/design-development-for/paper-store' element={<DesignAndDevelopmentCaseStudy heading="Paper Store" link="paper-store" />} />
              <Route path='/design-development-for/paralegal-services-provider' element={<DesignAndDevelopmentCaseStudy heading="Paralegal Services Provider" link="paralegal-services-provider" />} />
              <Route path='/design-development-for/parapharmacy' element={<DesignAndDevelopmentCaseStudy heading="Parapharmacy" link="parapharmacy" />} />
              <Route path='/design-development-for/parasailing-ride-operator' element={<DesignAndDevelopmentCaseStudy heading="Parasailing Ride Operator" link="parasailing-ride-operator" />} />
              <Route path='/design-development-for/parish' element={<DesignAndDevelopmentCaseStudy heading="Parish" link="parish" />} />
              <Route path='/design-development-for/park' element={<DesignAndDevelopmentCaseStudy heading="Park" link="park" />} />
              <Route path='/design-development-for/park-and-ride' element={<DesignAndDevelopmentCaseStudy heading="Park & Ride" link="park-and-ride" />} />
              <Route path='/design-development-for/parking-garage' element={<DesignAndDevelopmentCaseStudy heading="Parking Garage" link="parking-garage" />} />
              <Route path='/design-development-for/parking-lot' element={<DesignAndDevelopmentCaseStudy heading="Parking Lot" link="parking-lot" />} />
              <Route path='/design-development-for/part-time-daycare' element={<DesignAndDevelopmentCaseStudy heading="Part Time Daycare" link="part-time-daycare" />} />
              <Route path='/design-development-for/party-equipment-rental-service' element={<DesignAndDevelopmentCaseStudy heading="Party Equipment Rental Service" link="party-equipment-rental-service" />} />
              <Route path='/design-development-for/party-planner' element={<DesignAndDevelopmentCaseStudy heading="Party Planner" link="party-planner" />} />
              <Route path='/design-development-for/party-store' element={<DesignAndDevelopmentCaseStudy heading="Party Store" link="party-store" />} />
              <Route path='/design-development-for/passport-agent' element={<DesignAndDevelopmentCaseStudy heading="Passport Agent" link="passport-agent" />} />
              <Route path='/design-development-for/passport-office' element={<DesignAndDevelopmentCaseStudy heading="Passport Office" link="passport-office" />} />
              <Route path='/design-development-for/pinatas-supplier' element={<DesignAndDevelopmentCaseStudy heading="Piñatas Supplier" link="pinatas-supplier" />} />
              <Route path='/design-development-for/pinball-machine-supplier' element={<DesignAndDevelopmentCaseStudy heading="Pinball Machine Supplier" link="pinball-machine-supplier" />} />
              <Route path='/design-development-for/pine-furniture-shop' element={<DesignAndDevelopmentCaseStudy heading="Pine Furniture Shop" link="pine-furniture-shop" />} />
              <Route path='/design-development-for/pipe-supplier' element={<DesignAndDevelopmentCaseStudy heading="Pipe Supplier" link="pipe-supplier" />} />
              <Route path='/design-development-for/pizza-delivery' element={<DesignAndDevelopmentCaseStudy heading="Pizza Delivery" link="pizza-delivery" />} />
              <Route path='/design-development-for/pizza-takeaway' element={<DesignAndDevelopmentCaseStudy heading="Pizza Takeaway" link="pizza-takeaway" />} />
              <Route path='/design-development-for/place-of-worship' element={<DesignAndDevelopmentCaseStudy heading="Place of Worship" link="place-of-worship" />} />
              <Route path='/design-development-for/planetarium' element={<DesignAndDevelopmentCaseStudy heading="Planetarium" link="planetarium" />} />
              <Route path='/design-development-for/plant-and-machinery-hire' element={<DesignAndDevelopmentCaseStudy heading="Plant and Machinery Hire" link="plant-and-machinery-hire" />} />
              <Route path='/design-development-for/plant-nursery' element={<DesignAndDevelopmentCaseStudy heading="Plant Nursery" link="plant-nursery" />} />
              <Route path='/design-development-for/plast-window-store' element={<DesignAndDevelopmentCaseStudy heading="Plast Window Store" link="plast-window-store" />} />
              <Route path='/design-development-for/plasterer' element={<DesignAndDevelopmentCaseStudy heading="Plasterer" link="plasterer" />} />
              <Route path='/design-development-for/photo-shop' element={<DesignAndDevelopmentCaseStudy heading="Photo Shop" link="photo-shop" />} />
              <Route path='/design-development-for/photocopiers-supplier' element={<DesignAndDevelopmentCaseStudy heading="Photocopiers Supplier" link="photocopiers-supplier" />} />
              <Route path='/design-development-for/photographer' element={<DesignAndDevelopmentCaseStudy heading="Photographer" link="photographer" />} />
              <Route path='/design-development-for/photography-class' element={<DesignAndDevelopmentCaseStudy heading="Photography Class" link="photography-class" />} />
              <Route path='/design-development-for/photography-school' element={<DesignAndDevelopmentCaseStudy heading="Photography School" link="photography-school" />} />
              <Route path='/design-development-for/photography-service' element={<DesignAndDevelopmentCaseStudy heading="Photography Service" link="photography-service" />} />
              <Route path='/design-development-for/photography-studio' element={<DesignAndDevelopmentCaseStudy heading="Photography Studio" link="photography-studio" />} />
              <Route path='/design-development-for/physiatrist' element={<DesignAndDevelopmentCaseStudy heading="Physiatrist" link="physiatrist" />} />
              <Route path='/design-development-for/physical-examination-center' element={<DesignAndDevelopmentCaseStudy heading="Physical Examination Center" link="physical-examination-center" />} />
              <Route path='/design-development-for/physical-fitness-program' element={<DesignAndDevelopmentCaseStudy heading="Physical Fitness Program" link="physical-fitness-program" />} />
              <Route path='/design-development-for/physical-therapist' element={<DesignAndDevelopmentCaseStudy heading="Physical Therapist" link="physical-therapist" />} />
              <Route path='/design-development-for/physical-therapy-clinic' element={<DesignAndDevelopmentCaseStudy heading="Physical Therapy Clinic" link="physical-therapy-clinic" />} />
              <Route path='/design-development-for/physician-assistant' element={<DesignAndDevelopmentCaseStudy heading="Physician Assistant" link="physician-assistant" />} />
              <Route path='/design-development-for/physician-referral-service' element={<DesignAndDevelopmentCaseStudy heading="Physician Referral Service" link="physician-referral-service" />} />
              <Route path='/design-development-for/physiotherapy-equipment-supplier' element={<DesignAndDevelopmentCaseStudy heading="Physiotherapy Equipment Supplier" link="physiotherapy-equipment-supplier" />} />
              <Route path='/design-development-for/piano-bar' element={<DesignAndDevelopmentCaseStudy heading="Piano Bar" link="piano-bar" />} />
              <Route path='/design-development-for/piano-instructor' element={<DesignAndDevelopmentCaseStudy heading="Piano Instructor" link="piano-instructor" />} />
              <Route path='/design-development-for/piano-maker' element={<DesignAndDevelopmentCaseStudy heading="Piano Maker" link="piano-maker" />} />
              <Route path='/design-development-for/piano-moving-service' element={<DesignAndDevelopmentCaseStudy heading="Piano Moving Service" link="piano-moving-service" />} />
              <Route path='/design-development-for/piano-repair-service' element={<DesignAndDevelopmentCaseStudy heading="Piano Repair Service" link="piano-repair-service" />} />
              <Route path='/design-development-for/piano-store' element={<DesignAndDevelopmentCaseStudy heading="Piano Store" link="piano-store" />} />
              <Route path='/design-development-for/piano-tuning-service' element={<DesignAndDevelopmentCaseStudy heading="Piano Tuning Service" link="piano-tuning-service" />} />
              <Route path='/design-development-for/pick-your-own-farm-produce' element={<DesignAndDevelopmentCaseStudy heading="Pick Your Own Farm Produce" link="pick-your-own-farm-produce" />} />
              <Route path='/design-development-for/pickleball-court' element={<DesignAndDevelopmentCaseStudy heading="Pickleball Court" link="pickleball-court" />} />
              <Route path='/design-development-for/picnic-ground' element={<DesignAndDevelopmentCaseStudy heading="Picnic Ground" link="picnic-ground" />} />
              <Route path='/design-development-for/picture-frame-shop' element={<DesignAndDevelopmentCaseStudy heading="Picture Frame Shop" link="picture-frame-shop" />} />
              <Route path='/design-development-for/pie-shop' element={<DesignAndDevelopmentCaseStudy heading="Pie Shop" link="pie-shop" />} />
              <Route path='/design-development-for/pig-farm' element={<DesignAndDevelopmentCaseStudy heading="Pig Farm" link="pig-farm" />} />
              <Route path='/design-development-for/pilates-studio' element={<DesignAndDevelopmentCaseStudy heading="Pilates Studio" link="pilates-studio" />} />
              <Route path='/design-development-for/pile-driving-service' element={<DesignAndDevelopmentCaseStudy heading="Pile Driving Service" link="pile-driving-service" />} />
              <Route path='/design-development-for/pilgrim-hostel' element={<DesignAndDevelopmentCaseStudy heading="Pilgrim Hostel" link="pilgrim-hostel" />} />
              <Route path='/design-development-for/pet-sitter' element={<DesignAndDevelopmentCaseStudy heading="Pet Sitter" link="pet-sitter" />} />
              <Route path='/design-development-for/pet-store' element={<DesignAndDevelopmentCaseStudy heading="Pet Store" link="pet-store" />} />
              <Route path='/design-development-for/pet-supply-store' element={<DesignAndDevelopmentCaseStudy heading="Pet Supply Store" link="pet-supply-store" />} />
              <Route path='/design-development-for/pet-trainer' element={<DesignAndDevelopmentCaseStudy heading="Pet Trainer" link="pet-trainer" />} />
              <Route path='/design-development-for/petrochemical-engineering-service' element={<DesignAndDevelopmentCaseStudy heading="Petrochemical Engineering Service" link="petrochemical-engineering-service" />} />
              <Route path='/design-development-for/petroleum-products-company' element={<DesignAndDevelopmentCaseStudy heading="Petroleum Products Company" link="petroleum-products-company" />} />
              <Route path='/design-development-for/peugeot-dealer' element={<DesignAndDevelopmentCaseStudy heading="Peugeot Dealer" link="peugeot-dealer" />} />
              <Route path='/design-development-for/pharmaceutical-company' element={<DesignAndDevelopmentCaseStudy heading="Pharmaceutical Company" link="pharmaceutical-company" />} />
              <Route path='/design-development-for/pharmaceutical-lab' element={<DesignAndDevelopmentCaseStudy heading="Pharmaceutical Lab" link="pharmaceutical-lab" />} />
              <Route path='/design-development-for/pharmaceutical-products-wholesaler' element={<DesignAndDevelopmentCaseStudy heading="Pharmaceutical Products Wholesaler" link="pharmaceutical-products-wholesaler" />} />
              <Route path='/design-development-for/pharmacy' element={<DesignAndDevelopmentCaseStudy heading="Pharmacy" link="pharmacy" />} />
              <Route path='/design-development-for/philharmonic-hall' element={<DesignAndDevelopmentCaseStudy heading="Philharmonic Hall" link="philharmonic-hall" />} />
              <Route path='/design-development-for/phone-repair-service' element={<DesignAndDevelopmentCaseStudy heading="Phone Repair Service" link="phone-repair-service" />} />
              <Route path='/design-development-for/photo-agency' element={<DesignAndDevelopmentCaseStudy heading="Photo Agency" link="photo-agency" />} />
              <Route path='/design-development-for/photo-booth' element={<DesignAndDevelopmentCaseStudy heading="Photo Booth" link="photo-booth" />} />
              <Route path='/design-development-for/photo-lab' element={<DesignAndDevelopmentCaseStudy heading="Photo Lab" link="photo-lab" />} />
              <Route path='/design-development-for/patients-support-association' element={<DesignAndDevelopmentCaseStudy heading="Patients Support Association" link="patients-support-association" />} />
              <Route path='/design-development-for/patio-enclosure-supplier' element={<DesignAndDevelopmentCaseStudy heading="Patio Enclosure Supplier" link="patio-enclosure-supplier" />} />
              <Route path='/design-development-for/patisserie' element={<DesignAndDevelopmentCaseStudy heading="Patisserie" link="patisserie" />} />
              <Route path='/design-development-for/paving-contractor' element={<DesignAndDevelopmentCaseStudy heading="Paving Contractor" link="paving-contractor" />} />
              <Route path='/design-development-for/paving-materials-supplier' element={<DesignAndDevelopmentCaseStudy heading="Paving Materials Supplier" link="paving-materials-supplier" />} />
              <Route path='/design-development-for/pawn-shop' element={<DesignAndDevelopmentCaseStudy heading="Pawn Shop" link="pawn-shop" />} />
              <Route path='/design-development-for/payment-terminal' element={<DesignAndDevelopmentCaseStudy heading="Payment Terminal" link="payment-terminal" />} />
              <Route path='/design-development-for/payroll-service' element={<DesignAndDevelopmentCaseStudy heading="Payroll Service" link="payroll-service" />} />
              <Route path='/design-development-for/pedestrian-zone' element={<DesignAndDevelopmentCaseStudy heading="Pedestrian Zone" link="pedestrian-zone" />} />
              <Route path='/design-development-for/pediatric-cardiologist' element={<DesignAndDevelopmentCaseStudy heading="Pediatric Cardiologist" link="pediatric-cardiologist" />} />
              <Route path='/design-development-for/pediatric-clinic' element={<DesignAndDevelopmentCaseStudy heading="Pediatric Clinic" link="pediatric-clinic" />} />
              <Route path='/design-development-for/pediatric-dentist' element={<DesignAndDevelopmentCaseStudy heading="Pediatric Dentist" link="pediatric-dentist" />} />
              <Route path='/design-development-for/pediatric-dermatologist' element={<DesignAndDevelopmentCaseStudy heading="Pediatric Dermatologist" link="pediatric-dermatologist" />} />
              <Route path='/design-development-for/pediatric-endocrinologist' element={<DesignAndDevelopmentCaseStudy heading="Pediatric Endocrinologist" link="pediatric-endocrinologist" />} />
              <Route path='/design-development-for/pediatric-gastroenterologist' element={<DesignAndDevelopmentCaseStudy heading="Pediatric Gastroenterologist" link="pediatric-gastroenterologist" />} />
              <Route path='/design-development-for/pediatric-hematologist' element={<DesignAndDevelopmentCaseStudy heading="Pediatric Hematologist" link="pediatric-hematologist" />} />
              <Route path='/design-development-for/pediatric-nephrologist' element={<DesignAndDevelopmentCaseStudy heading="Pediatric Nephrologist" link="pediatric-nephrologist" />} />
              <Route path='/design-development-for/pediatric-neurologist' element={<DesignAndDevelopmentCaseStudy heading="Pediatric Neurologist" link="pediatric-neurologist" />} />
              <Route path='/design-development-for/pediatric-oncologist' element={<DesignAndDevelopmentCaseStudy heading="Pediatric Oncologist" link="pediatric-oncologist" />} />
              <Route path='/design-development-for/pediatric-ophthalmologist' element={<DesignAndDevelopmentCaseStudy heading="Pediatric Ophthalmologist" link="pediatric-ophthalmologist" />} />
              <Route path='/design-development-for/pediatric-orthopedic-surgeon' element={<DesignAndDevelopmentCaseStudy heading="Pediatric Orthopedic Surgeon" link="pediatric-orthopedic-surgeon" />} />
              <Route path='/design-development-for/pediatric-pulmonologist' element={<DesignAndDevelopmentCaseStudy heading="Pediatric Pulmonologist" link="pediatric-pulmonologist" />} />
              <Route path='/design-development-for/pediatric-rheumatologist' element={<DesignAndDevelopmentCaseStudy heading="Pediatric Rheumatologist" link="pediatric-rheumatologist" />} />
              <Route path='/design-development-for/pediatric-surgeon' element={<DesignAndDevelopmentCaseStudy heading="Pediatric Surgeon" link="pediatric-surgeon" />} />
              <Route path='/design-development-for/pediatric-urologist' element={<DesignAndDevelopmentCaseStudy heading="Pediatric Urologist" link="pediatric-urologist" />} />
              <Route path='/design-development-for/pediatrician' element={<DesignAndDevelopmentCaseStudy heading="Pediatrician" link="pediatrician" />} />
              <Route path='/design-development-for/pedorthist' element={<DesignAndDevelopmentCaseStudy heading="Pedorthist" link="pedorthist" />} />
              <Route path='/design-development-for/pen-store' element={<DesignAndDevelopmentCaseStudy heading="Pen Store" link="pen-store" />} />
              <Route path='/design-development-for/pension-office' element={<DesignAndDevelopmentCaseStudy heading="Pension Office" link="pension-office" />} />
              <Route path='/design-development-for/pentecostal-church' element={<DesignAndDevelopmentCaseStudy heading="Pentecostal Church" link="pentecostal-church" />} />
              <Route path='/design-development-for/performing-arts-group' element={<DesignAndDevelopmentCaseStudy heading="Performing Arts Group" link="performing-arts-group" />} />
              <Route path='/design-development-for/performing-arts-theater' element={<DesignAndDevelopmentCaseStudy heading="Performing Arts Theater" link="performing-arts-theater" />} />
              <Route path='/design-development-for/perfume-store' element={<DesignAndDevelopmentCaseStudy heading="Perfume Store" link="perfume-store" />} />
              <Route path='/design-development-for/perinatal-center' element={<DesignAndDevelopmentCaseStudy heading="Perinatal Center" link="perinatal-center" />} />
              <Route path='/design-development-for/periodontist' element={<DesignAndDevelopmentCaseStudy heading="Periodontist" link="periodontist" />} />
              <Route path='/design-development-for/permanent-make-up-clinic' element={<DesignAndDevelopmentCaseStudy heading="Permanent Make-up Clinic" link="permanent-make-up-clinic" />} />
              <Route path='/design-development-for/personal-chef-service' element={<DesignAndDevelopmentCaseStudy heading="Personal Chef Service" link="personal-chef-service" />} />
              <Route path='/design-development-for/personal-injury-attorney' element={<DesignAndDevelopmentCaseStudy heading="Personal Injury Attorney" link="personal-injury-attorney" />} />
              <Route path='/design-development-for/personal-trainer' element={<DesignAndDevelopmentCaseStudy heading="Personal Trainer" link="personal-trainer" />} />
              <Route path='/design-development-for/personal-watercraft-dealer' element={<DesignAndDevelopmentCaseStudy heading="Personal Watercraft Dealer" link="personal-watercraft-dealer" />} />
              <Route path='/design-development-for/pest-control-service' element={<DesignAndDevelopmentCaseStudy heading="Pest Control Service" link="pest-control-service" />} />
              <Route path='/design-development-for/pet-adoption-service' element={<DesignAndDevelopmentCaseStudy heading="Pet Adoption Service" link="pet-adoption-service" />} />
              <Route path='/design-development-for/pet-boarding-service' element={<DesignAndDevelopmentCaseStudy heading="Pet Boarding Service" link="pet-boarding-service" />} />
              <Route path='/design-development-for/pet-cemetery' element={<DesignAndDevelopmentCaseStudy heading="Pet Cemetery" link="pet-cemetery" />} />
              <Route path='/design-development-for/pet-funeral-service' element={<DesignAndDevelopmentCaseStudy heading="Pet Funeral Service" link="pet-funeral-service" />} />
              <Route path='/design-development-for/pet-groomer' element={<DesignAndDevelopmentCaseStudy heading="Pet Groomer" link="pet-groomer" />} />
              <Route path='/design-development-for/pasta-shop' element={<DesignAndDevelopmentCaseStudy heading="Pasta Shop" link="pasta-shop" />} />
              <Route path='/design-development-for/pastry-shop' element={<DesignAndDevelopmentCaseStudy heading="Pastry Shop" link="pastry-shop" />} />
              <Route path='/design-development-for/patent-attorney' element={<DesignAndDevelopmentCaseStudy heading="Patent Attorney" link="patent-attorney" />} />
              <Route path='/design-development-for/patent-office' element={<DesignAndDevelopmentCaseStudy heading="Patent Office" link="patent-office" />} />
              <Route path='/design-development-for/paternity-testing-service' element={<DesignAndDevelopmentCaseStudy heading="Paternity Testing Service" link="paternity-testing-service" />} />
              <Route path='/design-development-for/plastic-fabrication-company' element={<DesignAndDevelopmentCaseStudy heading="Plastic Fabrication Company" link="plastic-fabrication-company" />} />
              <Route path='/design-development-for/plastic-injection-molding-service' element={<DesignAndDevelopmentCaseStudy heading="Plastic Injection Molding Service" link="plastic-injection-molding-service" />} />
              <Route path='/design-development-for/plastic-products-supplier' element={<DesignAndDevelopmentCaseStudy heading="Plastic Products Supplier" link="plastic-products-supplier" />} />
              <Route path='/design-development-for/plastic-products-wholesaler' element={<DesignAndDevelopmentCaseStudy heading="Plastic Products Wholesaler" link="plastic-products-wholesaler" />} />
              <Route path='/design-development-for/plastic-resin-manufacturer' element={<DesignAndDevelopmentCaseStudy heading="Plastic Resin Manufacturer" link="plastic-resin-manufacturer" />} />
              <Route path='/design-development-for/plastic-surgeon' element={<DesignAndDevelopmentCaseStudy heading="Plastic Surgeon" link="plastic-surgeon" />} />
              <Route path='/design-development-for/plastic-surgery-clinic' element={<DesignAndDevelopmentCaseStudy heading="Plastic Surgery Clinic" link="plastic-surgery-clinic" />} />
              <Route path='/design-development-for/plating-service' element={<DesignAndDevelopmentCaseStudy heading="Plating Service" link="plating-service" />} />
              <Route path='/design-development-for/playground' element={<DesignAndDevelopmentCaseStudy heading="Playground" link="playground" />} />
              <Route path='/design-development-for/playground-equipment-supplier' element={<DesignAndDevelopmentCaseStudy heading="Playground Equipment Supplier" link="playground-equipment-supplier" />} />
              <Route path='/design-development-for/plumber' element={<DesignAndDevelopmentCaseStudy heading="Plumber" link="plumber" />} />
              <Route path='/design-development-for/plumbing-supply-store' element={<DesignAndDevelopmentCaseStudy heading="Plumbing Supply Store" link="plumbing-supply-store" />} />
              <Route path='/design-development-for/plus-size-clothing-store' element={<DesignAndDevelopmentCaseStudy heading="Plus Size Clothing Store" link="plus-size-clothing-store" />} />
              <Route path='/design-development-for/polytechnic-institute' element={<DesignAndDevelopmentCaseStudy heading="Polytechnic Institute" link="polytechnic-institute" />} />
              <Route path='/design-development-for/polythene-and-plastic-sheeting-supplier' element={<DesignAndDevelopmentCaseStudy heading="Polythene and Plastic Sheeting Supplier" link="polythene-and-plastic-sheeting-supplier" />} />
              <Route path='/design-development-for/pond-contractor' element={<DesignAndDevelopmentCaseStudy heading="Pond Contractor" link="pond-contractor" />} />
              <Route path='/design-development-for/pond-supply-store' element={<DesignAndDevelopmentCaseStudy heading="Pond Supply Store" link="pond-supply-store" />} />
              <Route path='/design-development-for/pontiac-dealer' element={<DesignAndDevelopmentCaseStudy heading="Pontiac Dealer" link="pontiac-dealer" />} />
              <Route path='/design-development-for/pony-club' element={<DesignAndDevelopmentCaseStudy heading="Pony Club" link="pony-club" />} />
              <Route path='/design-development-for/pony-ride-service' element={<DesignAndDevelopmentCaseStudy heading="Pony Ride Service" link="pony-ride-service" />} />
              <Route path='/design-development-for/port-authority' element={<DesignAndDevelopmentCaseStudy heading="Port Authority" link="port-authority" />} />
              <Route path='/design-development-for/port-operating-company' element={<DesignAndDevelopmentCaseStudy heading="Port Operating Company" link="port-operating-company" />} />
              <Route path='/design-development-for/portable-building-manufacturer' element={<DesignAndDevelopmentCaseStudy heading="Portable Building Manufacturer" link="portable-building-manufacturer" />} />
              <Route path='/design-development-for/portable-toilet-supplier' element={<DesignAndDevelopmentCaseStudy heading="Portable Toilet Supplier" link="portable-toilet-supplier" />} />
              <Route path='/design-development-for/portrait-studio' element={<DesignAndDevelopmentCaseStudy heading="Portrait Studio" link="portrait-studio" />} />
              <Route path='/design-development-for/post-office' element={<DesignAndDevelopmentCaseStudy heading="Post Office" link="post-office" />} />
              <Route path='/design-development-for/prawn-fishing' element={<DesignAndDevelopmentCaseStudy heading="Prawn Fishing" link="prawn-fishing" />} />
              <Route path='/design-development-for/pre-gymnasium-school' element={<DesignAndDevelopmentCaseStudy heading="Pre Gymnasium School" link="pre-gymnasium-school" />} />
              <Route path='/design-development-for/precision-engineer' element={<DesignAndDevelopmentCaseStudy heading="Precision Engineer" link="precision-engineer" />} />
              <Route path='/design-development-for/prefabricated-house-companies' element={<DesignAndDevelopmentCaseStudy heading="Prefabricated House Companies" link="prefabricated-house-companies" />} />
              <Route path='/design-development-for/pregnancy-care-center' element={<DesignAndDevelopmentCaseStudy heading="Pregnancy Care Center" link="pregnancy-care-center" />} />
              <Route path='/design-development-for/preparatory-school' element={<DesignAndDevelopmentCaseStudy heading="Preparatory School" link="preparatory-school" />} />
              <Route path='/design-development-for/presbyterian-church' element={<DesignAndDevelopmentCaseStudy heading="Presbyterian Church" link="presbyterian-church" />} />
              <Route path='/design-development-for/preschool' element={<DesignAndDevelopmentCaseStudy heading="Preschool" link="preschool" />} />
              <Route path='/design-development-for/press-advisory' element={<DesignAndDevelopmentCaseStudy heading="Press Advisory" link="press-advisory" />} />
              <Route path='/design-development-for/pressure-washing-service' element={<DesignAndDevelopmentCaseStudy heading="Pressure Washing Service" link="pressure-washing-service" />} />
              <Route path='/design-development-for/priest' element={<DesignAndDevelopmentCaseStudy heading="Priest" link="priest" />} />
              <Route path='/design-development-for/primary-school' element={<DesignAndDevelopmentCaseStudy heading="Primary School" link="primary-school" />} />
              <Route path='/design-development-for/print-shop' element={<DesignAndDevelopmentCaseStudy heading="Print Shop" link="print-shop" />} />
              <Route path='/design-development-for/printed-music-publisher' element={<DesignAndDevelopmentCaseStudy heading="Printed Music Publisher" link="printed-music-publisher" />} />
              <Route path='/design-development-for/printing-equipment-and-supplies' element={<DesignAndDevelopmentCaseStudy heading="Printing Equipment and Supplies" link="printing-equipment-and-supplies" />} />
              <Route path='/design-development-for/printing-equipment-supplier' element={<DesignAndDevelopmentCaseStudy heading="Printing Equipment Supplier" link="printing-equipment-supplier" />} />
              <Route path='/design-development-for/prison' element={<DesignAndDevelopmentCaseStudy heading="Prison" link="prison" />} />
              <Route path='/design-development-for/private-college' element={<DesignAndDevelopmentCaseStudy heading="Private College" link="private-college" />} />
              <Route path='/design-development-for/private-educational-institution' element={<DesignAndDevelopmentCaseStudy heading="Private Educational Institution" link="private-educational-institution" />} />
              <Route path='/design-development-for/private-equity-firm' element={<DesignAndDevelopmentCaseStudy heading="Private Equity Firm" link="private-equity-firm" />} />
              <Route path='/design-development-for/private-golf-course' element={<DesignAndDevelopmentCaseStudy heading="Private Golf Course" link="private-golf-course" />} />
              <Route path='/design-development-for/private-hospital' element={<DesignAndDevelopmentCaseStudy heading="Private Hospital" link="private-hospital" />} />
              <Route path='/design-development-for/private-investigator' element={<DesignAndDevelopmentCaseStudy heading="Private Investigator" link="private-investigator" />} />
              <Route path='/design-development-for/private-sector-bank' element={<DesignAndDevelopmentCaseStudy heading="Private Sector Bank" link="private-sector-bank" />} />
              <Route path='/design-development-for/private-tutor' element={<DesignAndDevelopmentCaseStudy heading="Private Tutor" link="private-tutor" />} />
              <Route path='/design-development-for/private-university' element={<DesignAndDevelopmentCaseStudy heading="Private University" link="private-university" />} />
              <Route path='/design-development-for/probation-office' element={<DesignAndDevelopmentCaseStudy heading="Probation Office" link="probation-office" />} />
              <Route path='/design-development-for/pump-supplier' element={<DesignAndDevelopmentCaseStudy heading="Pump Supplier" link="pump-supplier" />} />
              <Route path='/design-development-for/pumpkin-patch' element={<DesignAndDevelopmentCaseStudy heading="Pumpkin Patch" link="pumpkin-patch" />} />
              <Route path='/design-development-for/puppet-theater' element={<DesignAndDevelopmentCaseStudy heading="Puppet Theater" link="puppet-theater" />} />
              <Route path='/design-development-for/public-safety-office' element={<DesignAndDevelopmentCaseStudy heading="Public Safety Office" link="public-safety-office" />} />
              <Route path='/design-development-for/public-sauna' element={<DesignAndDevelopmentCaseStudy heading="Public Sauna" link="public-sauna" />} />
              <Route path='/design-development-for/public-sector-bank' element={<DesignAndDevelopmentCaseStudy heading="Public Sector Bank" link="public-sector-bank" />} />
              <Route path='/design-development-for/public-swimming-pool' element={<DesignAndDevelopmentCaseStudy heading="Public Swimming Pool" link="public-swimming-pool" />} />
              <Route path='/design-development-for/public-university' element={<DesignAndDevelopmentCaseStudy heading="Public University" link="public-university" />} />
              <Route path='/design-development-for/public-wheelchair-accessible-bathroom' element={<DesignAndDevelopmentCaseStudy heading="Public Wheelchair-accessible Bathroom" link="public-wheelchair-accessible-bathroom" />} />
              <Route path='/design-development-for/public-works-department' element={<DesignAndDevelopmentCaseStudy heading="Public Works Department" link="public-works-department" />} />
              <Route path='/design-development-for/publisher' element={<DesignAndDevelopmentCaseStudy heading="Publisher" link="publisher" />} />
              <Route path='/design-development-for/public-educational-institution' element={<DesignAndDevelopmentCaseStudy heading="Public Educational Institution" link="public-educational-institution" />} />
              <Route path='/design-development-for/public-female-bathroom' element={<DesignAndDevelopmentCaseStudy heading="Public Female Bathroom" link="public-female-bathroom" />} />
              <Route path='/design-development-for/public-golf-course' element={<DesignAndDevelopmentCaseStudy heading="Public Golf Course" link="public-golf-course" />} />
              <Route path='/design-development-for/public-health-department' element={<DesignAndDevelopmentCaseStudy heading="Public Health Department" link="public-health-department" />} />
              <Route path='/design-development-for/public-housing' element={<DesignAndDevelopmentCaseStudy heading="Public Housing" link="public-housing" />} />
              <Route path='/design-development-for/public-library' element={<DesignAndDevelopmentCaseStudy heading="Public Library" link="public-library" />} />
              <Route path='/design-development-for/public-medical-center' element={<DesignAndDevelopmentCaseStudy heading="Public Medical Center" link="public-medical-center" />} />
              <Route path='/design-development-for/public-parking-space' element={<DesignAndDevelopmentCaseStudy heading="Public Parking Space" link="public-parking-space" />} />
              <Route path='/design-development-for/public-prosecutors-office' element={<DesignAndDevelopmentCaseStudy heading="Public Prosecutors Office" link="public-prosecutors-office" />} />
              <Route path='/design-development-for/psychopedagogy-clinic' element={<DesignAndDevelopmentCaseStudy heading="Psychopedagogy Clinic" link="psychopedagogy-clinic" />} />
              <Route path='/design-development-for/psychosomatic-medical-practitioner' element={<DesignAndDevelopmentCaseStudy heading="Psychosomatic Medical Practitioner" link="psychosomatic-medical-practitioner" />} />
              <Route path='/design-development-for/psychotherapist' element={<DesignAndDevelopmentCaseStudy heading="Psychotherapist" link="psychotherapist" />} />
              <Route path='/design-development-for/pub' element={<DesignAndDevelopmentCaseStudy heading="Pub" link="pub" />} />
              <Route path='/design-development-for/psychiatric-hospital' element={<DesignAndDevelopmentCaseStudy heading="Psychiatric Hospital" link="psychiatric-hospital" />} />
              <Route path='/design-development-for/psychiatrist' element={<DesignAndDevelopmentCaseStudy heading="Psychiatrist" link="psychiatrist" />} />
              <Route path='/design-development-for/psychic' element={<DesignAndDevelopmentCaseStudy heading="Psychic" link="psychic" />} />
              <Route path='/design-development-for/psychoanalyst' element={<DesignAndDevelopmentCaseStudy heading="Psychoanalyst" link="psychoanalyst" />} />
              <Route path='/design-development-for/psychologist' element={<DesignAndDevelopmentCaseStudy heading="Psychologist" link="psychologist" />} />
              <Route path='/design-development-for/psychomotor-therapist' element={<DesignAndDevelopmentCaseStudy heading="Psychomotor Therapist" link="psychomotor-therapist" />} />
              <Route path='/design-development-for/prosthodontist' element={<DesignAndDevelopmentCaseStudy heading="Prosthodontist" link="prosthodontist" />} />
              <Route path='/design-development-for/protected-area' element={<DesignAndDevelopmentCaseStudy heading="Protected Area" link="protected-area" />} />
              <Route path='/design-development-for/protective-clothing-supplier' element={<DesignAndDevelopmentCaseStudy heading="Protective Clothing Supplier" link="protective-clothing-supplier" />} />
              <Route path='/design-development-for/protestant-church' element={<DesignAndDevelopmentCaseStudy heading="Protestant Church" link="protestant-church" />} />
              <Route path='/design-development-for/propeller-shop' element={<DesignAndDevelopmentCaseStudy heading="Propeller Shop" link="propeller-shop" />} />
              <Route path='/design-development-for/property-administration-service' element={<DesignAndDevelopmentCaseStudy heading="Property Administration Service" link="property-administration-service" />} />
              <Route path='/design-development-for/property-investment-company' element={<DesignAndDevelopmentCaseStudy heading="Property Investment Company" link="property-investment-company" />} />
              <Route path='/design-development-for/property-maintenance' element={<DesignAndDevelopmentCaseStudy heading="Property Maintenance" link="property-maintenance" />} />
              <Route path='/design-development-for/property-management-company' element={<DesignAndDevelopmentCaseStudy heading="Property Management Company" link="property-management-company" />} />
              <Route path='/design-development-for/produce-market' element={<DesignAndDevelopmentCaseStudy heading="Produce Market" link="produce-market" />} />
              <Route path='/design-development-for/produce-wholesaler' element={<DesignAndDevelopmentCaseStudy heading="Produce Wholesaler" link="produce-wholesaler" />} />
              <Route path='/design-development-for/professional-and-hobby-associations' element={<DesignAndDevelopmentCaseStudy heading="Professional and Hobby Associations" link="professional-and-hobby-associations" />} />
              <Route path='/design-development-for/professional-association' element={<DesignAndDevelopmentCaseStudy heading="Professional Association" link="professional-association" />} />
              <Route path='/design-development-for/professional-organizer' element={<DesignAndDevelopmentCaseStudy heading="Professional Organizer" link="professional-organizer" />} />
              <Route path='/design-development-for/race-car-dealer' element={<DesignAndDevelopmentCaseStudy heading="Race Car Dealer" link="race-car-dealer" />} />
              <Route path='/design-development-for/racecourse' element={<DesignAndDevelopmentCaseStudy heading="Racecourse" link="racecourse" />} />
              <Route path='/design-development-for/racing-car-parts-store' element={<DesignAndDevelopmentCaseStudy heading="Racing Car Parts Store" link="racing-car-parts-store" />} />
              <Route path='/design-development-for/racquetball-club' element={<DesignAndDevelopmentCaseStudy heading="Racquetball Club" link="racquetball-club" />} />
              <Route path='/design-development-for/radiator-repair-service' element={<DesignAndDevelopmentCaseStudy heading="Radiator Repair Service" link="radiator-repair-service" />} />
              <Route path='/design-development-for/radio-broadcaster' element={<DesignAndDevelopmentCaseStudy heading="Radio Broadcaster" link="radio-broadcaster" />} />
              <Route path='/design-development-for/radiologist' element={<DesignAndDevelopmentCaseStudy heading="Radiologist" link="radiologist" />} />
              <Route path='/design-development-for/radiotherapist' element={<DesignAndDevelopmentCaseStudy heading="Radiotherapist" link="radiotherapist" />} />
              <Route path='/design-development-for/railing-contractor' element={<DesignAndDevelopmentCaseStudy heading="Railing Contractor" link="railing-contractor" />} />
              <Route path='/design-development-for/railroad-company' element={<DesignAndDevelopmentCaseStudy heading="Railroad Company" link="railroad-company" />} />
              <Route path='/design-development-for/railroad-contractor' element={<DesignAndDevelopmentCaseStudy heading="Railroad Contractor" link="railroad-contractor" />} />
              <Route path='/design-development-for/railroad-equipment-supplier' element={<DesignAndDevelopmentCaseStudy heading="Railroad Equipment Supplier" link="railroad-equipment-supplier" />} />
              <Route path='/design-development-for/ram-dealer' element={<DesignAndDevelopmentCaseStudy heading="Ram Dealer" link="ram-dealer" />} />
              <Route path='/design-development-for/ranch' element={<DesignAndDevelopmentCaseStudy heading="Ranch" link="ranch" />} />
              <Route path='/design-development-for/rare-book-store' element={<DesignAndDevelopmentCaseStudy heading="Rare Book Store" link="rare-book-store" />} />
              <Route path='/design-development-for/real-estate-agency' element={<DesignAndDevelopmentCaseStudy heading="Real Estate Agency" link="real-estate-agency" />} />
              <Route path='/design-development-for/real-estate-agent' element={<DesignAndDevelopmentCaseStudy heading="Real Estate Agent" link="real-estate-agent" />} />
              <Route path='/design-development-for/real-estate-appraiser' element={<DesignAndDevelopmentCaseStudy heading="Real Estate Appraiser" link="real-estate-appraiser" />} />
              <Route path='/design-development-for/real-estate-attorney' element={<DesignAndDevelopmentCaseStudy heading="Real Estate Attorney" link="real-estate-attorney" />} />
              <Route path='/design-development-for/real-estate-auctioneer' element={<DesignAndDevelopmentCaseStudy heading="Real Estate Auctioneer" link="real-estate-auctioneer" />} />
              <Route path='/design-development-for/real-estate-consultant' element={<DesignAndDevelopmentCaseStudy heading="Real Estate Consultant" link="real-estate-consultant" />} />
              <Route path='/design-development-for/real-estate-developer' element={<DesignAndDevelopmentCaseStudy heading="Real Estate Developer" link="real-estate-developer" />} />
              <Route path='/design-development-for/real-estate-rental-agency' element={<DesignAndDevelopmentCaseStudy heading="Real Estate Rental Agency" link="real-estate-rental-agency" />} />
              <Route path='/design-development-for/real-estate-school' element={<DesignAndDevelopmentCaseStudy heading="Real Estate School" link="real-estate-school" />} />
              <Route path='/design-development-for/real-estate-surveyor' element={<DesignAndDevelopmentCaseStudy heading="Real Estate Surveyor" link="real-estate-surveyor" />} />
              <Route path='/design-development-for/realschule' element={<DesignAndDevelopmentCaseStudy heading="Realschule" link="realschule" />} />
              <Route path='/design-development-for/reclamation-centre' element={<DesignAndDevelopmentCaseStudy heading="Reclamation Centre" link="reclamation-centre" />} />
              <Route path='/design-development-for/record-company' element={<DesignAndDevelopmentCaseStudy heading="Record Company" link="record-company" />} />
              <Route path='/design-development-for/record-store' element={<DesignAndDevelopmentCaseStudy heading="Record Store" link="record-store" />} />
              <Route path='/design-development-for/recording-studio' element={<DesignAndDevelopmentCaseStudy heading="Recording Studio" link="recording-studio" />} />
              <Route path='/design-development-for/records-storage-facility' element={<DesignAndDevelopmentCaseStudy heading="Records Storage Facility" link="records-storage-facility" />} />
              <Route path='/design-development-for/recreation-center' element={<DesignAndDevelopmentCaseStudy heading="Recreation Center" link="recreation-center" />} />
              <Route path='/design-development-for/recruiter' element={<DesignAndDevelopmentCaseStudy heading="Recruiter" link="recruiter" />} />
              <Route path='/design-development-for/rectory' element={<DesignAndDevelopmentCaseStudy heading="Rectory" link="rectory" />} />
              <Route path='/design-development-for/recycling-center' element={<DesignAndDevelopmentCaseStudy heading="Recycling Center" link="recycling-center" />} />
              <Route path='/design-development-for/recycling-drop-off-location' element={<DesignAndDevelopmentCaseStudy heading="Recycling Drop-off Location" link="recycling-drop-off-location" />} />
              <Route path='/design-development-for/reenactment-site' element={<DesignAndDevelopmentCaseStudy heading="Reenactment Site" link="reenactment-site" />} />
              <Route path='/design-development-for/reformed-church' element={<DesignAndDevelopmentCaseStudy heading="Reformed Church" link="reformed-church" />} />
              <Route path='/design-development-for/refrigerated-transport-service' element={<DesignAndDevelopmentCaseStudy heading="Refrigerated Transport Service" link="refrigerated-transport-service" />} />
              <Route path='/design-development-for/refrigerator-store' element={<DesignAndDevelopmentCaseStudy heading="Refrigerator Store" link="refrigerator-store" />} />
              <Route path='/design-development-for/refugee-camp' element={<DesignAndDevelopmentCaseStudy heading="Refugee Camp" link="refugee-camp" />} />
              <Route path='/design-development-for/regional-council' element={<DesignAndDevelopmentCaseStudy heading="Regional Council" link="regional-council" />} />
              <Route path='/design-development-for/regional-government-office' element={<DesignAndDevelopmentCaseStudy heading="Regional Government Office" link="regional-government-office" />} />
              <Route path='/design-development-for/registered-general-nurse' element={<DesignAndDevelopmentCaseStudy heading="Registered General Nurse" link="registered-general-nurse" />} />
              <Route path='/design-development-for/registration-office' element={<DesignAndDevelopmentCaseStudy heading="Registration Office" link="registration-office" />} />
              <Route path='/design-development-for/rehabilitation-center' element={<DesignAndDevelopmentCaseStudy heading="Rehabilitation Center" link="rehabilitation-center" />} />
              <Route path='/design-development-for/rehearsal-studio' element={<DesignAndDevelopmentCaseStudy heading="Rehearsal Studio" link="rehearsal-studio" />} />
              <Route path='/design-development-for/reiki-therapist' element={<DesignAndDevelopmentCaseStudy heading="Reiki Therapist" link="reiki-therapist" />} />
              <Route path='/design-development-for/renters-insurance-agency' element={<DesignAndDevelopmentCaseStudy heading="Renter’s Insurance Agency" link="renters-insurance-agency" />} />
              <Route path='/design-development-for/repair-service' element={<DesignAndDevelopmentCaseStudy heading="Repair Service" link="repair-service" />} />
              <Route path='/design-development-for/reproductive-health-clinic' element={<DesignAndDevelopmentCaseStudy heading="Reproductive Health Clinic" link="reproductive-health-clinic" />} />
              <Route path='/design-development-for/reptile-store' element={<DesignAndDevelopmentCaseStudy heading="Reptile Store" link="reptile-store" />} />
              <Route path='/design-development-for/research-and-product-development' element={<DesignAndDevelopmentCaseStudy heading="Research and Product Development" link="research-and-product-development" />} />
              <Route path='/design-development-for/research-engineer' element={<DesignAndDevelopmentCaseStudy heading="Research Engineer" link="research-engineer" />} />
              <Route path='/design-development-for/research-foundation' element={<DesignAndDevelopmentCaseStudy heading="Research Foundation" link="research-foundation" />} />
              <Route path='/design-development-for/research-institute' element={<DesignAndDevelopmentCaseStudy heading="Research Institute" link="research-institute" />} />
              <Route path='/design-development-for/resident-registration-office' element={<DesignAndDevelopmentCaseStudy heading="Resident Registration Office" link="resident-registration-office" />} />
              <Route path='/design-development-for/residents-association' element={<DesignAndDevelopmentCaseStudy heading="Residents Association" link="residents-association" />} />
              <Route path='/design-development-for/resort-hotel' element={<DesignAndDevelopmentCaseStudy heading="Resort Hotel" link="resort-hotel" />} />
              <Route path='/design-development-for/rest-stop' element={<DesignAndDevelopmentCaseStudy heading="Rest Stop" link="rest-stop" />} />
              <Route path='/design-development-for/retail-space-rental-agency' element={<DesignAndDevelopmentCaseStudy heading="Retail Space Rental Agency" link="retail-space-rental-agency" />} />
              <Route path='/design-development-for/retaining-wall-supplier' element={<DesignAndDevelopmentCaseStudy heading="Retaining Wall Supplier" link="retaining-wall-supplier" />} />
              <Route path='/design-development-for/retirement-community' element={<DesignAndDevelopmentCaseStudy heading="Retirement Community" link="retirement-community" />} />
              <Route path='/design-development-for/retirement-home' element={<DesignAndDevelopmentCaseStudy heading="Retirement Home" link="retirement-home" />} />
              <Route path='/design-development-for/retreat-center' element={<DesignAndDevelopmentCaseStudy heading="Retreat Center" link="retreat-center" />} />
              <Route path='/design-development-for/rock-climbing' element={<DesignAndDevelopmentCaseStudy heading="Rock Climbing" link="rock-climbing" />} />
              <Route path='/design-development-for/rock-climbing-gym' element={<DesignAndDevelopmentCaseStudy heading="Rock Climbing Gym" link="rock-climbing-gym" />} />
              <Route path='/design-development-for/rock-climbing-instructor' element={<DesignAndDevelopmentCaseStudy heading="Rock Climbing Instructor" link="rock-climbing-instructor" />} />
              <Route path='/design-development-for/rock-music-club' element={<DesignAndDevelopmentCaseStudy heading="Rock Music Club" link="rock-music-club" />} />
              <Route path='/design-development-for/rock-shop' element={<DesignAndDevelopmentCaseStudy heading="Rock Shop" link="rock-shop" />} />
              <Route path='/design-development-for/roller-coaster' element={<DesignAndDevelopmentCaseStudy heading="Roller Coaster" link="roller-coaster" />} />
              <Route path='/design-development-for/roller-skating-club' element={<DesignAndDevelopmentCaseStudy heading="Roller Skating Club" link="roller-skating-club" />} />
              <Route path='/design-development-for/roller-skating-rink' element={<DesignAndDevelopmentCaseStudy heading="Roller Skating Rink" link="roller-skating-rink" />} />
              <Route path='/design-development-for/rolls-royce-dealer' element={<DesignAndDevelopmentCaseStudy heading="Rolls-Royce Dealer" link="rolls-royce-dealer" />} />
              <Route path='/design-development-for/roofing-contractor' element={<DesignAndDevelopmentCaseStudy heading="Roofing Contractor" link="roofing-contractor" />} />
              <Route path='/design-development-for/roofing-supply-store' element={<DesignAndDevelopmentCaseStudy heading="Roofing Supply Store" link="roofing-supply-store" />} />
              <Route path='/design-development-for/roommate-referral-service' element={<DesignAndDevelopmentCaseStudy heading="Roommate Referral Service" link="roommate-referral-service" />} />
              <Route path='/design-development-for/rowing-area' element={<DesignAndDevelopmentCaseStudy heading="Rowing Area" link="rowing-area" />} />
              <Route path='/design-development-for/rowing-club' element={<DesignAndDevelopmentCaseStudy heading="Rowing Club" link="rowing-club" />} />
              <Route path='/design-development-for/rugby-store' element={<DesignAndDevelopmentCaseStudy heading="Rugby Store" link="rugby-store" />} />
              <Route path='/design-development-for/running-store' element={<DesignAndDevelopmentCaseStudy heading="Running Store" link="running-store" />} />
              <Route path='/design-development-for/russian-grocery-store' element={<DesignAndDevelopmentCaseStudy heading="Russian Grocery Store" link="russian-grocery-store" />} />
              <Route path='/design-development-for/russian-orthodox-church' element={<DesignAndDevelopmentCaseStudy heading="Russian Orthodox Church" link="russian-orthodox-church" />} />
              <Route path='/design-development-for/rustic-furniture-store' element={<DesignAndDevelopmentCaseStudy heading="Rustic Furniture Store" link="rustic-furniture-store" />} />
              <Route path='/design-development-for/rv-dealer' element={<DesignAndDevelopmentCaseStudy heading="RV Dealer" link="rv-dealer" />} />
              <Route path='/design-development-for/rv-detailing-service' element={<DesignAndDevelopmentCaseStudy heading="RV Detailing Service" link="rv-detailing-service" />} />
              <Route path='/design-development-for/rv-park' element={<DesignAndDevelopmentCaseStudy heading="RV Park" link="rv-park" />} />
              <Route path='/design-development-for/rv-repair-shop' element={<DesignAndDevelopmentCaseStudy heading="RV Repair Shop" link="rv-repair-shop" />} />
              <Route path='/design-development-for/rv-storage-facility' element={<DesignAndDevelopmentCaseStudy heading="RV Storage Facility" link="rv-storage-facility" />} />
              <Route path='/design-development-for/rv-supply-store' element={<DesignAndDevelopmentCaseStudy heading="RV Supply Store" link="rv-supply-store" />} />
              <Route path='/design-development-for/safety-equipment-supplier' element={<DesignAndDevelopmentCaseStudy heading="Safety Equipment Supplier" link="safety-equipment-supplier" />} />
              <Route path='/design-development-for/sailing-club' element={<DesignAndDevelopmentCaseStudy heading="Sailing Club" link="sailing-club" />} />
              <Route path='/design-development-for/sailing-event-area' element={<DesignAndDevelopmentCaseStudy heading="Sailing Event Area" link="sailing-event-area" />} />
              <Route path='/design-development-for/salsa-classes' element={<DesignAndDevelopmentCaseStudy heading="Salsa Classes" link="salsa-classes" />} />
              <Route path='/design-development-for/salvage-dealer' element={<DesignAndDevelopmentCaseStudy heading="Salvage Dealer" link="salvage-dealer" />} />
              <Route path='/design-development-for/salvage-yard' element={<DesignAndDevelopmentCaseStudy heading="Salvage Yard" link="salvage-yard" />} />
              <Route path='/design-development-for/samba-school' element={<DesignAndDevelopmentCaseStudy heading="Samba School" link="samba-school" />} />
              <Route path='/design-development-for/sambo-school' element={<DesignAndDevelopmentCaseStudy heading="Sambo School" link="sambo-school" />} />
              <Route path='/design-development-for/sand-and-gravel-supplier' element={<DesignAndDevelopmentCaseStudy heading="Sand & Gravel Supplier" link="sand-and-gravel-supplier" />} />
              <Route path='/design-development-for/sand-plant' element={<DesignAndDevelopmentCaseStudy heading="Sand Plant" link="sand-plant" />} />
              <Route path='/design-development-for/sandblasting-service' element={<DesignAndDevelopmentCaseStudy heading="Sandblasting Service" link="sandblasting-service" />} />
              <Route path='/design-development-for/sandwich-shop' element={<DesignAndDevelopmentCaseStudy heading="Sandwich Shop" link="sandwich-shop" />} />
              <Route path='/design-development-for/scuba-instructor' element={<DesignAndDevelopmentCaseStudy heading="SCUBA Instructor" link="scuba-instructor" />} />
              <Route path='/design-development-for/scuba-tour-agency' element={<DesignAndDevelopmentCaseStudy heading="SCUBA Tour Agency" link="scuba-tour-agency" />} />
              <Route path='/design-development-for/sculptor' element={<DesignAndDevelopmentCaseStudy heading="Sculptor" link="sculptor" />} />
              <Route path='/design-development-for/sculpture' element={<DesignAndDevelopmentCaseStudy heading="Sculpture" link="sculpture" />} />
              <Route path='/design-development-for/sculpture-museum' element={<DesignAndDevelopmentCaseStudy heading="Sculpture Museum" link="sculpture-museum" />} />
              <Route path='/design-development-for/seafood-farm' element={<DesignAndDevelopmentCaseStudy heading="Seafood Farm" link="seafood-farm" />} />
              <Route path='/design-development-for/seafood-market' element={<DesignAndDevelopmentCaseStudy heading="Seafood Market" link="seafood-market" />} />
              <Route path='/design-development-for/seafood-wholesaler' element={<DesignAndDevelopmentCaseStudy heading="Seafood Wholesaler" link="seafood-wholesaler" />} />
              <Route path='/design-development-for/seal-shop' element={<DesignAndDevelopmentCaseStudy heading="Seal Shop" link="seal-shop" />} />
              <Route path='/design-development-for/seaplane-base' element={<DesignAndDevelopmentCaseStudy heading="Seaplane Base" link="seaplane-base" />} />
              <Route path='/design-development-for/seasonal-goods-store' element={<DesignAndDevelopmentCaseStudy heading="Seasonal Goods Store" link="seasonal-goods-store" />} />
              <Route path='/design-development-for/seat-dealer' element={<DesignAndDevelopmentCaseStudy heading="Seat Dealer" link="seat-dealer" />} />
              <Route path='/design-development-for/second-hand-store' element={<DesignAndDevelopmentCaseStudy heading="Second Hand Store" link="second-hand-store" />} />
              <Route path='/design-development-for/secondary-school' element={<DesignAndDevelopmentCaseStudy heading="Secondary School" link="secondary-school" />} />
              <Route path='/design-development-for/secondary-school-germany' element={<DesignAndDevelopmentCaseStudy heading="Secondary School (Germany)" link="secondary-school-germany" />} />
              <Route path='/design-development-for/security-guard-service' element={<DesignAndDevelopmentCaseStudy heading="Security Guard Service" link="security-guard-service" />} />
              <Route path='/design-development-for/security-service' element={<DesignAndDevelopmentCaseStudy heading="Security Service" link="security-service" />} />
              <Route path='/design-development-for/security-system-installation-service' element={<DesignAndDevelopmentCaseStudy heading="Security System Installation Service" link="security-system-installation-service" />} />
              <Route path='/design-development-for/security-system-supplier' element={<DesignAndDevelopmentCaseStudy heading="Security System Supplier" link="security-system-supplier" />} />
              <Route path='/design-development-for/seed-supplier' element={<DesignAndDevelopmentCaseStudy heading="Seed Supplier" link="seed-supplier" />} />
              <Route path='/design-development-for/scooter-repair-shop' element={<DesignAndDevelopmentCaseStudy heading="Scooter Repair Shop" link="scooter-repair-shop" />} />
              <Route path='/design-development-for/scout-hall' element={<DesignAndDevelopmentCaseStudy heading="Scout Hall" link="scout-hall" />} />
              <Route path='/design-development-for/scout-home' element={<DesignAndDevelopmentCaseStudy heading="Scout Home" link="scout-home" />} />
              <Route path='/design-development-for/scouting' element={<DesignAndDevelopmentCaseStudy heading="Scouting" link="scouting" />} />
              <Route path='/design-development-for/screen-printer' element={<DesignAndDevelopmentCaseStudy heading="Screen Printer" link="screen-printer" />} />
              <Route path='/design-development-for/screen-printing-shop' element={<DesignAndDevelopmentCaseStudy heading="Screen Printing Shop" link="screen-printing-shop" />} />
              <Route path='/design-development-for/screen-printing-supply-store' element={<DesignAndDevelopmentCaseStudy heading="Screen Printing Supply Store" link="screen-printing-supply-store" />} />
              <Route path='/design-development-for/screen-repair-service' element={<DesignAndDevelopmentCaseStudy heading="Screen Repair Service" link="screen-repair-service" />} />
              <Route path='/design-development-for/screen-store' element={<DesignAndDevelopmentCaseStudy heading="Screen Store" link="screen-store" />} />
              <Route path='/design-development-for/school-house' element={<DesignAndDevelopmentCaseStudy heading="School House" link="school-house" />} />
              <Route path='/design-development-for/school-supply-store' element={<DesignAndDevelopmentCaseStudy heading="School Supply Store" link="school-supply-store" />} />
              <Route path='/design-development-for/science-academy' element={<DesignAndDevelopmentCaseStudy heading="Science Academy" link="science-academy" />} />
              <Route path='/design-development-for/science-museum' element={<DesignAndDevelopmentCaseStudy heading="Science Museum" link="science-museum" />} />
              <Route path='/design-development-for/scientific-equipment-supplier' element={<DesignAndDevelopmentCaseStudy heading="Scientific Equipment Supplier" link="scientific-equipment-supplier" />} />
              <Route path='/design-development-for/school-cafeteria' element={<DesignAndDevelopmentCaseStudy heading="School Cafeteria" link="school-cafeteria" />} />
              <Route path='/design-development-for/school-center' element={<DesignAndDevelopmentCaseStudy heading="School Center" link="school-center" />} />
              <Route path='/design-development-for/school-district-office' element={<DesignAndDevelopmentCaseStudy heading="School District Office" link="school-district-office" />} />
              <Route path='/design-development-for/sauna-store' element={<DesignAndDevelopmentCaseStudy heading="Sauna Store" link="sauna-store" />} />
              <Route path='/design-development-for/savings-bank' element={<DesignAndDevelopmentCaseStudy heading="Savings Bank" link="savings-bank" />} />
              <Route path='/design-development-for/saw-mill' element={<DesignAndDevelopmentCaseStudy heading="Saw Mill" link="saw-mill" />} />
              <Route path='/design-development-for/saw-sharpening-service' element={<DesignAndDevelopmentCaseStudy heading="Saw Sharpening Service" link="saw-sharpening-service" />} />
              <Route path='/design-development-for/scaffolding-rental-service' element={<DesignAndDevelopmentCaseStudy heading="Scaffolding Rental Service" link="scaffolding-rental-service" />} />
              <Route path='/design-development-for/scaffolding-service' element={<DesignAndDevelopmentCaseStudy heading="Scaffolding Service" link="scaffolding-service" />} />
              <Route path='/design-development-for/scale-model-club' element={<DesignAndDevelopmentCaseStudy heading="Scale Model Club" link="scale-model-club" />} />
              <Route path='/design-development-for/scale-repair-service' element={<DesignAndDevelopmentCaseStudy heading="Scale Repair Service" link="scale-repair-service" />} />
              <Route path='/design-development-for/scale-supplier' element={<DesignAndDevelopmentCaseStudy heading="Scale Supplier" link="scale-supplier" />} />
              <Route path='/design-development-for/scenic-spot' element={<DesignAndDevelopmentCaseStudy heading="Scenic Spot" link="scenic-spot" />} />
              <Route path='/design-development-for/scenography-company' element={<DesignAndDevelopmentCaseStudy heading="Scenography Company" link="scenography-company" />} />
              <Route path='/design-development-for/school' element={<DesignAndDevelopmentCaseStudy heading="School" link="school" />} />
              <Route path='/design-development-for/school-administrator' element={<DesignAndDevelopmentCaseStudy heading="School Administrator" link="school-administrator" />} />
              <Route path='/design-development-for/sanitation-service' element={<DesignAndDevelopmentCaseStudy heading="Sanitation Service" link="sanitation-service" />} />
              <Route path='/design-development-for/saree-shop' element={<DesignAndDevelopmentCaseStudy heading="Saree Shop" link="saree-shop" />} />
              <Route path='/design-development-for/satellite-communication-service' element={<DesignAndDevelopmentCaseStudy heading="Satellite Communication Service" link="satellite-communication-service" />} />
              <Route path='/design-development-for/saturn-dealer' element={<DesignAndDevelopmentCaseStudy heading="Saturn Dealer" link="saturn-dealer" />} />
              <Route path='/design-development-for/sod-supplier' element={<DesignAndDevelopmentCaseStudy heading="Sod Supplier" link="sod-supplier" />} />
              <Route path='/design-development-for/sofa-store' element={<DesignAndDevelopmentCaseStudy heading="Sofa Store" link="sofa-store" />} />
              <Route path='/design-development-for/soft-drinks-shop' element={<DesignAndDevelopmentCaseStudy heading="Soft Drinks Shop" link="soft-drinks-shop" />} />
              <Route path='/design-development-for/softball-club' element={<DesignAndDevelopmentCaseStudy heading="Softball Club" link="softball-club" />} />
              <Route path='/design-development-for/softball-field' element={<DesignAndDevelopmentCaseStudy heading="Softball Field" link="softball-field" />} />
              <Route path='/design-development-for/software-company' element={<DesignAndDevelopmentCaseStudy heading="Software Company" link="software-company" />} />
              <Route path='/design-development-for/software-training-institute' element={<DesignAndDevelopmentCaseStudy heading="Software Training Institute" link="software-training-institute" />} />
              <Route path='/design-development-for/soil-testing-service' element={<DesignAndDevelopmentCaseStudy heading="Soil Testing Service" link="soil-testing-service" />} />
              <Route path='/design-development-for/soccer-practice' element={<DesignAndDevelopmentCaseStudy heading="Soccer Practice" link="soccer-practice" />} />
              <Route path='/design-development-for/soccer-store' element={<DesignAndDevelopmentCaseStudy heading="Soccer Store" link="soccer-store" />} />
              <Route path='/design-development-for/social-club' element={<DesignAndDevelopmentCaseStudy heading="Social Club" link="social-club" />} />
              <Route path='/design-development-for/social-security-attorney' element={<DesignAndDevelopmentCaseStudy heading="Social Security Attorney" link="social-security-attorney" />} />
              <Route path='/design-development-for/social-security-financial-department' element={<DesignAndDevelopmentCaseStudy heading="Social Security Financial Department" link="social-security-financial-department" />} />
              <Route path='/design-development-for/social-security-office' element={<DesignAndDevelopmentCaseStudy heading="Social Security Office" link="social-security-office" />} />
              <Route path='/design-development-for/social-services-organization' element={<DesignAndDevelopmentCaseStudy heading="Social Services Organization" link="social-services-organization" />} />
              <Route path='/design-development-for/sleep-clinic' element={<DesignAndDevelopmentCaseStudy heading="Sleep Clinic" link="sleep-clinic" />} />
              <Route path='/design-development-for/small-appliance-repair-service' element={<DesignAndDevelopmentCaseStudy heading="Small Appliance Repair Service" link="small-appliance-repair-service" />} />
              <Route path='/design-development-for/small-claims-assistance-service' element={<DesignAndDevelopmentCaseStudy heading="Small Claims Assistance Service" link="small-claims-assistance-service" />} />
              <Route path='/design-development-for/smart-car-dealer' element={<DesignAndDevelopmentCaseStudy heading="Smart Car Dealer" link="smart-car-dealer" />} />
              <Route path='/design-development-for/smart-dealer' element={<DesignAndDevelopmentCaseStudy heading="Smart Dealer" link="smart-dealer" />} />
              <Route path='/design-development-for/smart-shop' element={<DesignAndDevelopmentCaseStudy heading="Smart Shop" link="smart-shop" />} />
              <Route path='/design-development-for/skeet-shooting-range' element={<DesignAndDevelopmentCaseStudy heading="Skeet Shooting Range" link="skeet-shooting-range" />} />
              <Route path='/design-development-for/ski-club' element={<DesignAndDevelopmentCaseStudy heading="Ski Club" link="ski-club" />} />
              <Route path='/design-development-for/ski-rental-service' element={<DesignAndDevelopmentCaseStudy heading="Ski Rental Service" link="ski-rental-service" />} />
              <Route path='/design-development-for/ski-repair-service' element={<DesignAndDevelopmentCaseStudy heading="Ski Repair Service" link="ski-repair-service" />} />
              <Route path='/design-development-for/ski-school' element={<DesignAndDevelopmentCaseStudy heading="Ski School" link="ski-school" />} />
              <Route path='/design-development-for/ski-shop' element={<DesignAndDevelopmentCaseStudy heading="Ski Shop" link="ski-shop" />} />
              <Route path='/design-development-for/skin-care-clinic' element={<DesignAndDevelopmentCaseStudy heading="Skin Care Clinic" link="skin-care-clinic" />} />
              <Route path='/design-development-for/skin-care-products-vending-machine' element={<DesignAndDevelopmentCaseStudy heading="Skin Care Products Vending Machine" link="skin-care-products-vending-machine" />} />
              <Route path='/design-development-for/shrine' element={<DesignAndDevelopmentCaseStudy heading="Shrine" link="shrine" />} />
              <Route path='/design-development-for/siding-contractor' element={<DesignAndDevelopmentCaseStudy heading="Siding Contractor" link="siding-contractor" />} />
              <Route path='/design-development-for/sightseeing-tour-agency' element={<DesignAndDevelopmentCaseStudy heading="Sightseeing Tour Agency" link="sightseeing-tour-agency" />} />
              <Route path='/design-development-for/sign-shop' element={<DesignAndDevelopmentCaseStudy heading="Sign Shop" link="sign-shop" />} />
              <Route path='/design-development-for/silk-plant-shop' element={<DesignAndDevelopmentCaseStudy heading="Silk Plant Shop" link="silk-plant-shop" />} />
              <Route path='/design-development-for/silk-store' element={<DesignAndDevelopmentCaseStudy heading="Silk Store" link="silk-store" />} />
              <Route path='/design-development-for/silversmith' element={<DesignAndDevelopmentCaseStudy heading="Silversmith" link="silversmith" />} />
              <Route path='/design-development-for/singing-telegram-service' element={<DesignAndDevelopmentCaseStudy heading="Singing Telegram Service" link="singing-telegram-service" />} />
              <Route path='/design-development-for/single-sex-secondary-school' element={<DesignAndDevelopmentCaseStudy heading="Single Sex Secondary School" link="single-sex-secondary-school" />} />
              <Route path='/design-development-for/shooting-event-area' element={<DesignAndDevelopmentCaseStudy heading="Shooting Event Area" link="shooting-event-area" />} />
              <Route path='/design-development-for/shooting-range' element={<DesignAndDevelopmentCaseStudy heading="Shooting Range" link="shooting-range" />} />
              <Route path='/design-development-for/shop-supermarket-furniture-store' element={<DesignAndDevelopmentCaseStudy heading="Shop Supermarket Furniture Store" link="shop-supermarket-furniture-store" />} />
              <Route path='/design-development-for/shipping-and-mailing-service' element={<DesignAndDevelopmentCaseStudy heading="Shipping and Mailing Service" link="shipping-and-mailing-service" />} />
              <Route path='/design-development-for/shipping-company' element={<DesignAndDevelopmentCaseStudy heading="Shipping Company" link="shipping-company" />} />
              <Route path='/design-development-for/shipping-equipment-industry' element={<DesignAndDevelopmentCaseStudy heading="Shipping Equipment Industry" link="shipping-equipment-industry" />} />
              <Route path='/design-development-for/shipping-service' element={<DesignAndDevelopmentCaseStudy heading="Shipping Service" link="shipping-service" />} />
              <Route path='/design-development-for/shochu-brewery' element={<DesignAndDevelopmentCaseStudy heading="Shochu Brewery" link="shochu-brewery" />} />
              <Route path='/design-development-for/shoe-factory' element={<DesignAndDevelopmentCaseStudy heading="Shoe Factory" link="shoe-factory" />} />
              <Route path='/design-development-for/shoe-repair-shop' element={<DesignAndDevelopmentCaseStudy heading="Shoe Repair Shop" link="shoe-repair-shop" />} />
              <Route path='/design-development-for/shoe-shining-service' element={<DesignAndDevelopmentCaseStudy heading="Shoe Shining Service" link="shoe-shining-service" />} />
              <Route path='/design-development-for/shoe-store' element={<DesignAndDevelopmentCaseStudy heading="Shoe Store" link="shoe-store" />} />
              <Route path='/design-development-for/sheepskin-coat-store' element={<DesignAndDevelopmentCaseStudy heading="Sheepskin Coat Store" link="sheepskin-coat-store" />} />
              <Route path='/design-development-for/sheet-metal-contractor' element={<DesignAndDevelopmentCaseStudy heading="Sheet Metal Contractor" link="sheet-metal-contractor" />} />
              <Route path='/design-development-for/sheet-music-store' element={<DesignAndDevelopmentCaseStudy heading="Sheet Music Store" link="sheet-music-store" />} />
              <Route path='/design-development-for/shelter' element={<DesignAndDevelopmentCaseStudy heading="Shelter" link="shelter" />} />
              <Route path='/design-development-for/sheltered-housing' element={<DesignAndDevelopmentCaseStudy heading="Sheltered Housing" link="sheltered-housing" />} />
              <Route path='/design-development-for/shelving-store' element={<DesignAndDevelopmentCaseStudy heading="Shelving Store" link="shelving-store" />} />
              <Route path='/design-development-for/sheriffs-department' element={<DesignAndDevelopmentCaseStudy heading="Sheriff’s Department" link="sheriffs-department" />} />
              <Route path='/design-development-for/shinkin-bank' element={<DesignAndDevelopmentCaseStudy heading="Shinkin Bank" link="shinkin-bank" />} />
              <Route path='/design-development-for/shinto-shrine' element={<DesignAndDevelopmentCaseStudy heading="Shinto Shrine" link="shinto-shrine" />} />
              <Route path='/design-development-for/senior-citizen-center' element={<DesignAndDevelopmentCaseStudy heading="Senior Citizen Center" link="senior-citizen-center" />} />
              <Route path='/design-development-for/senior-high-school' element={<DesignAndDevelopmentCaseStudy heading="Senior High School" link="senior-high-school" />} />
              <Route path='/design-development-for/septic-system-service' element={<DesignAndDevelopmentCaseStudy heading="Septic System Service" link="septic-system-service" />} />
              <Route path='/design-development-for/serviced-accommodation' element={<DesignAndDevelopmentCaseStudy heading="Serviced Accommodation" link="serviced-accommodation" />} />
              <Route path='/design-development-for/serviced-apartment' element={<DesignAndDevelopmentCaseStudy heading="Serviced Apartment" link="serviced-apartment" />} />
              <Route path='/design-development-for/seventh-day-adventist-church' element={<DesignAndDevelopmentCaseStudy heading="Seventh-day Adventist Church" link="seventh-day-adventist-church" />} />
              <Route path='/design-development-for/sewage-disposal-service' element={<DesignAndDevelopmentCaseStudy heading="Sewage Disposal Service" link="sewage-disposal-service" />} />
              <Route path='/design-development-for/sewage-treatment-plant' element={<DesignAndDevelopmentCaseStudy heading="Sewage Treatment Plant" link="sewage-treatment-plant" />} />
              <Route path='/design-development-for/sewing-company' element={<DesignAndDevelopmentCaseStudy heading="Sewing Company" link="sewing-company" />} />
              <Route path='/design-development-for/self-defense-school' element={<DesignAndDevelopmentCaseStudy heading="Self Defense School" link="self-defense-school" />} />
              <Route path='/design-development-for/self-service-car-wash' element={<DesignAndDevelopmentCaseStudy heading="Self Service Car Wash" link="self-service-car-wash" />} />
              <Route path='/design-development-for/self-service-health-station' element={<DesignAndDevelopmentCaseStudy heading="Self Service Health Station" link="self-service-health-station" />} />
              <Route path='/design-development-for/self-storage-facility' element={<DesignAndDevelopmentCaseStudy heading="Self-storage Facility" link="self-storage-facility" />} />
              <Route path='/design-development-for/stained-glass-studio' element={<DesignAndDevelopmentCaseStudy heading="Stained Glass Studio" link="stained-glass-studio" />} />
              <Route path='/design-development-for/stainless-steel-plant' element={<DesignAndDevelopmentCaseStudy heading="Stainless Steel Plant" link="stainless-steel-plant" />} />
              <Route path='/design-development-for/stair-contractor' element={<DesignAndDevelopmentCaseStudy heading="Stair Contractor" link="stair-contractor" />} />
              <Route path='/design-development-for/stall-installation-service' element={<DesignAndDevelopmentCaseStudy heading="Stall Installation Service" link="stall-installation-service" />} />
              <Route path='/design-development-for/stable' element={<DesignAndDevelopmentCaseStudy heading="Stable" link="stable" />} />
              <Route path='/design-development-for/stadium' element={<DesignAndDevelopmentCaseStudy heading="Stadium" link="stadium" />} />
              <Route path='/design-development-for/stage' element={<DesignAndDevelopmentCaseStudy heading="Stage" link="stage" />} />
              <Route path='/design-development-for/sports-nutrition-store' element={<DesignAndDevelopmentCaseStudy heading="Sports Nutrition Store" link="sports-nutrition-store" />} />
              <Route path='/design-development-for/sports-school' element={<DesignAndDevelopmentCaseStudy heading="Sports School" link="sports-school" />} />
              <Route path='/design-development-for/sportswear-store' element={<DesignAndDevelopmentCaseStudy heading="Sportswear Store" link="sportswear-store" />} />
              <Route path='/design-development-for/sportwear-manufacturer' element={<DesignAndDevelopmentCaseStudy heading="Sportwear Manufacturer" link="sportwear-manufacturer" />} />
              <Route path='/design-development-for/spring-supplier' element={<DesignAndDevelopmentCaseStudy heading="Spring Supplier" link="spring-supplier" />} />
              <Route path='/design-development-for/spice-exporter' element={<DesignAndDevelopmentCaseStudy heading="Spice Exporter" link="spice-exporter" />} />
              <Route path='/design-development-for/spice-store' element={<DesignAndDevelopmentCaseStudy heading="Spice Store" link="spice-store" />} />
              <Route path='/design-development-for/spice-wholesaler' element={<DesignAndDevelopmentCaseStudy heading="Spice Wholesaler" link="spice-wholesaler" />} />
              <Route path='/design-development-for/spiritist-center' element={<DesignAndDevelopmentCaseStudy heading="Spiritist Center" link="spiritist-center" />} />
              <Route path='/design-development-for/sport-tour-agency' element={<DesignAndDevelopmentCaseStudy heading="Sport Tour Agency" link="sport-tour-agency" />} />
              <Route path='/design-development-for/sporting-goods-store' element={<DesignAndDevelopmentCaseStudy heading="Sporting Goods Store" link="sporting-goods-store" />} />
              <Route path='/design-development-for/sports-accessories-wholesaler' element={<DesignAndDevelopmentCaseStudy heading="Sports Accessories Wholesaler" link="sports-accessories-wholesaler" />} />
              <Route path='/design-development-for/sports-bar' element={<DesignAndDevelopmentCaseStudy heading="Sports Bar" link="sports-bar" />} />
              <Route path='/design-development-for/sports-club' element={<DesignAndDevelopmentCaseStudy heading="Sports Club" link="sports-club" />} />
              <Route path='/design-development-for/sports-complex' element={<DesignAndDevelopmentCaseStudy heading="Sports Complex" link="sports-complex" />} />
              <Route path='/design-development-for/sports-equipment-rental-service' element={<DesignAndDevelopmentCaseStudy heading="Sports Equipment Rental Service" link="sports-equipment-rental-service" />} />
              <Route path='/design-development-for/sports-massage-therapist' element={<DesignAndDevelopmentCaseStudy heading="Sports Massage Therapist" link="sports-massage-therapist" />} />
              <Route path='/design-development-for/sports-medicine-clinic' element={<DesignAndDevelopmentCaseStudy heading="Sports Medicine Clinic" link="sports-medicine-clinic" />} />
              <Route path='/design-development-for/sports-medicine-physician' element={<DesignAndDevelopmentCaseStudy heading="Sports Medicine Physician" link="sports-medicine-physician" />} />
              <Route path='/design-development-for/special-educator' element={<DesignAndDevelopmentCaseStudy heading="Special Educator" link="special-educator" />} />
              <Route path='/design-development-for/specialized-clinic' element={<DesignAndDevelopmentCaseStudy heading="Specialized Clinic" link="specialized-clinic" />} />
              <Route path='/design-development-for/specialized-hospital' element={<DesignAndDevelopmentCaseStudy heading="Specialized Hospital" link="specialized-hospital" />} />
              <Route path='/design-development-for/speech-pathologist' element={<DesignAndDevelopmentCaseStudy heading="Speech Pathologist" link="speech-pathologist" />} />
              <Route path='/design-development-for/spa' element={<DesignAndDevelopmentCaseStudy heading="Spa" link="spa" />} />
              <Route path='/design-development-for/spa-and-health-club' element={<DesignAndDevelopmentCaseStudy heading="Spa and Health Club" link="spa-and-health-club" />} />
              <Route path='/design-development-for/spa-garden' element={<DesignAndDevelopmentCaseStudy heading="Spa Garden" link="spa-garden" />} />
              <Route path='/design-development-for/space-of-remembrance' element={<DesignAndDevelopmentCaseStudy heading="Space of Remembrance" link="space-of-remembrance" />} />
              <Route path='/design-development-for/soup-shop' element={<DesignAndDevelopmentCaseStudy heading="Soup Shop" link="soup-shop" />} />
              <Route path='/design-development-for/souvenir-manufacturer' element={<DesignAndDevelopmentCaseStudy heading="Souvenir Manufacturer" link="souvenir-manufacturer" />} />
              <Route path='/design-development-for/souvenir-store' element={<DesignAndDevelopmentCaseStudy heading="Souvenir Store" link="souvenir-store" />} />
              <Route path='/design-development-for/solar-energy-company' element={<DesignAndDevelopmentCaseStudy heading="Solar Energy Company" link="solar-energy-company" />} />
              <Route path='/design-development-for/solar-energy-equipment-supplier' element={<DesignAndDevelopmentCaseStudy heading="Solar Energy Equipment Supplier" link="solar-energy-equipment-supplier" />} />
              <Route path='/design-development-for/solar-energy-system-service' element={<DesignAndDevelopmentCaseStudy heading="Solar Energy System Service" link="solar-energy-system-service" />} />
              <Route path='/design-development-for/solar-hot-water-system-supplier' element={<DesignAndDevelopmentCaseStudy heading="Solar Hot Water System Supplier" link="solar-hot-water-system-supplier" />} />
              <Route path='/design-development-for/solar-panel-maintenance-service' element={<DesignAndDevelopmentCaseStudy heading="Solar Panel Maintenance Service" link="solar-panel-maintenance-service" />} />
              <Route path='/design-development-for/solar-photovoltaic-power-plant' element={<DesignAndDevelopmentCaseStudy heading="Solar Photovoltaic Power Plant" link="solar-photovoltaic-power-plant" />} />
              <Route path='/design-development-for/solid-fuel-company' element={<DesignAndDevelopmentCaseStudy heading="Solid Fuel Company" link="solid-fuel-company" />} />
              <Route path='/design-development-for/solid-waste-engineer' element={<DesignAndDevelopmentCaseStudy heading="Solid Waste Engineer" link="solid-waste-engineer" />} />
              <Route path='/design-development-for/student-union' element={<DesignAndDevelopmentCaseStudy heading="Student Union" link="student-union" />} />
              <Route path='/design-development-for/students-parents-association' element={<DesignAndDevelopmentCaseStudy heading="Students Parents Association" link="students-parents-association" />} />
              <Route path='/design-development-for/students-support-association' element={<DesignAndDevelopmentCaseStudy heading="Students Support Association" link="students-support-association" />} />
              <Route path='/design-development-for/study-at-home-school' element={<DesignAndDevelopmentCaseStudy heading="Study at Home School" link="study-at-home-school" />} />
              <Route path='/design-development-for/studying-center' element={<DesignAndDevelopmentCaseStudy heading="Studying Center" link="studying-center" />} />
              <Route path='/design-development-for/stylist' element={<DesignAndDevelopmentCaseStudy heading="Stylist" link="stylist" />} />
              <Route path='/design-development-for/stone-supplier' element={<DesignAndDevelopmentCaseStudy heading="Stone Supplier" link="stone-supplier" />} />
              <Route path='/design-development-for/storage-facility' element={<DesignAndDevelopmentCaseStudy heading="Storage Facility" link="storage-facility" />} />
              <Route path='/design-development-for/store' element={<DesignAndDevelopmentCaseStudy heading="Store" link="store" />} />
              <Route path='/design-development-for/store-equipment-supplier' element={<DesignAndDevelopmentCaseStudy heading="Store Equipment Supplier" link="store-equipment-supplier" />} />
              <Route path='/design-development-for/stove-builder' element={<DesignAndDevelopmentCaseStudy heading="Stove Builder" link="stove-builder" />} />
              <Route path='/design-development-for/stringed-instrument-maker' element={<DesignAndDevelopmentCaseStudy heading="Stringed Instrument Maker" link="stringed-instrument-maker" />} />
              <Route path='/design-development-for/stock-broker' element={<DesignAndDevelopmentCaseStudy heading="Stock Broker" link="stock-broker" />} />
              <Route path='/design-development-for/stock-exchange-building' element={<DesignAndDevelopmentCaseStudy heading="Stock Exchange Building" link="stock-exchange-building" />} />
              <Route path='/design-development-for/stone-carving' element={<DesignAndDevelopmentCaseStudy heading="Stone Carving" link="stone-carving" />} />
              <Route path='/design-development-for/steel-fabricator' element={<DesignAndDevelopmentCaseStudy heading="Steel Fabricator" link="steel-fabricator" />} />
              <Route path='/design-development-for/steel-framework-contractor' element={<DesignAndDevelopmentCaseStudy heading="Steel Framework Contractor" link="steel-framework-contractor" />} />
              <Route path='/design-development-for/stereo-rental-store' element={<DesignAndDevelopmentCaseStudy heading="Stereo Rental Store" link="stereo-rental-store" />} />
              <Route path='/design-development-for/stereo-repair-service' element={<DesignAndDevelopmentCaseStudy heading="Stereo Repair Service" link="stereo-repair-service" />} />
              <Route path='/design-development-for/sticker-manufacturer' element={<DesignAndDevelopmentCaseStudy heading="Sticker Manufacturer" link="sticker-manufacturer" />} />
              <Route path='/design-development-for/steak-house' element={<DesignAndDevelopmentCaseStudy heading="Steak House" link="steak-house" />} />
              <Route path='/design-development-for/steamed-bun-shop' element={<DesignAndDevelopmentCaseStudy heading="Steamed Bun Shop" link="steamed-bun-shop" />} />
              <Route path='/design-development-for/steel-construction-company' element={<DesignAndDevelopmentCaseStudy heading="Steel Construction Company" link="steel-construction-company" />} />
              <Route path='/design-development-for/steel-distributor' element={<DesignAndDevelopmentCaseStudy heading="Steel Distributor" link="steel-distributor" />} />
              <Route path='/design-development-for/steel-drum-supplier' element={<DesignAndDevelopmentCaseStudy heading="Steel Drum Supplier" link="steel-drum-supplier" />} />
              <Route path='/design-development-for/stationery-manufacturer' element={<DesignAndDevelopmentCaseStudy heading="Stationery Manufacturer" link="stationery-manufacturer" />} />
              <Route path='/design-development-for/stationery-store' element={<DesignAndDevelopmentCaseStudy heading="Stationery Store" link="stationery-store" />} />
              <Route path='/design-development-for/stationery-wholesaler' element={<DesignAndDevelopmentCaseStudy heading="Stationery Wholesaler" link="stationery-wholesaler" />} />
              <Route path='/design-development-for/statuary' element={<DesignAndDevelopmentCaseStudy heading="Statuary" link="statuary" />} />
              <Route path='/design-development-for/staple-food-package' element={<DesignAndDevelopmentCaseStudy heading="Staple Food Package" link="staple-food-package" />} />
              <Route path='/design-development-for/state-archive' element={<DesignAndDevelopmentCaseStudy heading="State Archive" link="state-archive" />} />
              <Route path='/design-development-for/state-department-agriculture-food-supply' element={<DesignAndDevelopmentCaseStudy heading="State Department Agriculture Food Supply" link="state-department-agriculture-food-supply" />} />
              <Route path='/design-development-for/state-department-communication' element={<DesignAndDevelopmentCaseStudy heading="State Department Communication" link="state-department-communication" />} />
              <Route path='/design-development-for/state-department-finance' element={<DesignAndDevelopmentCaseStudy heading="State Department Finance" link="state-department-finance" />} />
              <Route path='/design-development-for/state-department-for-social-development' element={<DesignAndDevelopmentCaseStudy heading="State Department for Social Development" link="state-department-for-social-development" />} />
              <Route path='/design-development-for/state-department-housing-and-urban-development' element={<DesignAndDevelopmentCaseStudy heading="State Department Housing and Urban Development" link="state-department-housing-and-urban-development" />} />
              <Route path='/design-development-for/state-department-of-environment' element={<DesignAndDevelopmentCaseStudy heading="State Department of Environment" link="state-department-of-environment" />} />
              <Route path='/design-development-for/state-department-of-social-defense' element={<DesignAndDevelopmentCaseStudy heading="State department of social defense" link="state-department-of-social-defense" />} />
              <Route path='/design-development-for/state-department-of-tourism' element={<DesignAndDevelopmentCaseStudy heading="State Department of Tourism" link="state-department-of-tourism" />} />
              <Route path='/design-development-for/state-department-of-transportation' element={<DesignAndDevelopmentCaseStudy heading="State Department of Transportation" link="state-department-of-transportation" />} />
              <Route path='/design-development-for/state-department-science-technology' element={<DesignAndDevelopmentCaseStudy heading="State Department Science Technology" link="state-department-science-technology" />} />
              <Route path='/design-development-for/state-dept-of-sports' element={<DesignAndDevelopmentCaseStudy heading="State Dept of Sports" link="state-dept-of-sports" />} />
              <Route path='/design-development-for/state-government-office' element={<DesignAndDevelopmentCaseStudy heading="State Government Office" link="state-government-office" />} />
              <Route path='/design-development-for/state-liquor-store' element={<DesignAndDevelopmentCaseStudy heading="State Liquor Store" link="state-liquor-store" />} />
              <Route path='/design-development-for/state-office-of-education' element={<DesignAndDevelopmentCaseStudy heading="State Office of Education" link="state-office-of-education" />} />
              <Route path='/design-development-for/state-owned-farm' element={<DesignAndDevelopmentCaseStudy heading="State Owned Farm" link="state-owned-farm" />} />
              <Route path='/design-development-for/state-park' element={<DesignAndDevelopmentCaseStudy heading="State Park" link="state-park" />} />
              <Route path='/design-development-for/state-parliament' element={<DesignAndDevelopmentCaseStudy heading="State Parliament" link="state-parliament" />} />
              <Route path='/design-development-for/textile-exporter' element={<DesignAndDevelopmentCaseStudy heading="Textile Exporter" link="textile-exporter" />} />
              <Route path='/design-development-for/textike-mass' element={<DesignAndDevelopmentCaseStudy heading="Textike Mass" link="textike-mass" />} />
              <Route path='/design-development-for/thai-massage-therapist' element={<DesignAndDevelopmentCaseStudy heading="Thai Massage Therapist" link="thai-massage-therapist" />} />
              <Route path='/design-development-for/theater-company' element={<DesignAndDevelopmentCaseStudy heading="Theater Company" link="theater-company" />} />
              <Route path='/design-development-for/theater-production' element={<DesignAndDevelopmentCaseStudy heading="Theater Production" link="theater-production" />} />
              <Route path='/design-development-for/theater-supply-store' element={<DesignAndDevelopmentCaseStudy heading="Theater Supply Store" link="theater-supply-store" />} />
              <Route path='/design-development-for/theatrical-costume-supplier' element={<DesignAndDevelopmentCaseStudy heading="Theatrical Costume Supplier" link="theatrical-costume-supplier" />} />
              <Route path='/design-development-for/theme-park' element={<DesignAndDevelopmentCaseStudy heading="Theme Park" link="theme-park" />} />
              <Route path='/design-development-for/thermal-baths' element={<DesignAndDevelopmentCaseStudy heading="Thermal Baths" link="thermal-baths" />} />
              <Route path='/design-development-for/thermal-power-plant' element={<DesignAndDevelopmentCaseStudy heading="Thermal Power Plant" link="thermal-power-plant" />} />
              <Route path='/design-development-for/thread-supplier' element={<DesignAndDevelopmentCaseStudy heading="Thread Supplier" link="thread-supplier" />} />
              <Route path='/design-development-for/threads-and-yarns-wholesaler' element={<DesignAndDevelopmentCaseStudy heading="Threads and Yarns Wholesaler" link="threads-and-yarns-wholesaler" />} />
              <Route path='/design-development-for/thrift-store' element={<DesignAndDevelopmentCaseStudy heading="Thrift Store" link="thrift-store" />} />
              <Route path='/design-development-for/tennis-court-construction-company' element={<DesignAndDevelopmentCaseStudy heading="Tennis Court Construction Company" link="tennis-court-construction-company" />} />
              <Route path='/design-development-for/tennis-instructor' element={<DesignAndDevelopmentCaseStudy heading="Tennis Instructor" link="tennis-instructor" />} />
              <Route path='/design-development-for/tennis-store' element={<DesignAndDevelopmentCaseStudy heading="Tennis Store" link="tennis-store" />} />
              <Route path='/design-development-for/tent-rental-service' element={<DesignAndDevelopmentCaseStudy heading="Tent Rental Service" link="tent-rental-service" />} />
              <Route path='/design-development-for/tax-department' element={<DesignAndDevelopmentCaseStudy heading="Tax Department" link="tax-department" />} />
              <Route path='/design-development-for/tax-preparation' element={<DesignAndDevelopmentCaseStudy heading="Tax Preparation" link="tax-preparation" />} />
              <Route path='/design-development-for/tax-preparation-service' element={<DesignAndDevelopmentCaseStudy heading="Tax Preparation Service" link="tax-preparation-service" />} />
              <Route path='/design-development-for/taxi-service' element={<DesignAndDevelopmentCaseStudy heading="Taxi Service" link="taxi-service" />} />
              <Route path='/design-development-for/taxi-stand' element={<DesignAndDevelopmentCaseStudy heading="Taxi Stand" link="taxi-stand" />} />
              <Route path='/design-development-for/taxidermist' element={<DesignAndDevelopmentCaseStudy heading="Taxidermist" link="taxidermist" />} />
              <Route path='/design-development-for/tb-clinic' element={<DesignAndDevelopmentCaseStudy heading="TB Clinic" link="tb-clinic" />} />
              <Route path='/design-development-for/tea-exporter' element={<DesignAndDevelopmentCaseStudy heading="Tea Exporter" link="tea-exporter" />} />
              <Route path='/design-development-for/tea-house' element={<DesignAndDevelopmentCaseStudy heading="Tea House" link="tea-house" />} />
              <Route path='/design-development-for/tea-manufacturer' element={<DesignAndDevelopmentCaseStudy heading="Tea Manufacturer" link="tea-manufacturer" />} />
              <Route path='/design-development-for/tea-market-place' element={<DesignAndDevelopmentCaseStudy heading="Tea Market Place" link="tea-market-place" />} />
              <Route path='/design-development-for/tea-store' element={<DesignAndDevelopmentCaseStudy heading="Tea Store" link="tea-store" />} />
              <Route path='/design-development-for/tea-wholesaler' element={<DesignAndDevelopmentCaseStudy heading="Tea Wholesaler" link="tea-wholesaler" />} />
              <Route path='/design-development-for/teachers-college' element={<DesignAndDevelopmentCaseStudy heading="Teachers College" link="teachers-college" />} />
              <Route path='/design-development-for/teachers-housing' element={<DesignAndDevelopmentCaseStudy heading="Teachers’ Housing" link="teachers-housing" />} />
              <Route path='/design-development-for/technical-school' element={<DesignAndDevelopmentCaseStudy heading="Technical School" link="technical-school" />} />
              <Route path='/design-development-for/technical-university' element={<DesignAndDevelopmentCaseStudy heading="Technical University" link="technical-university" />} />
              <Route path='/design-development-for/technology-museum' element={<DesignAndDevelopmentCaseStudy heading="Technology Museum" link="technology-museum" />} />
              <Route path='/design-development-for/technology-park' element={<DesignAndDevelopmentCaseStudy heading="Technology Park" link="technology-park" />} />
              <Route path='/design-development-for/teeth-whitening-service' element={<DesignAndDevelopmentCaseStudy heading="Teeth Whitening Service" link="teeth-whitening-service" />} />
              <Route path='/design-development-for/telecommunication-school' element={<DesignAndDevelopmentCaseStudy heading="Telecommunication School" link="telecommunication-school" />} />
              <Route path='/design-development-for/telecommunications-contractor' element={<DesignAndDevelopmentCaseStudy heading="Telecommunications Contractor" link="telecommunications-contractor" />} />
              <Route path='/design-development-for/telecommunications-engineer' element={<DesignAndDevelopmentCaseStudy heading="Telecommunications Engineer" link="telecommunications-engineer" />} />
              <Route path='/design-development-for/telecommunications-equipment-supplier' element={<DesignAndDevelopmentCaseStudy heading="Telecommunications Equipment Supplier" link="telecommunications-equipment-supplier" />} />
              <Route path='/design-development-for/telecommunications-service-provider' element={<DesignAndDevelopmentCaseStudy heading="Telecommunications Service Provider" link="telecommunications-service-provider" />} />
              <Route path='/design-development-for/telemarketing-service' element={<DesignAndDevelopmentCaseStudy heading="Telemarketing Service" link="telemarketing-service" />} />
              <Route path='/design-development-for/telephone-answering-service' element={<DesignAndDevelopmentCaseStudy heading="Telephone Answering Service" link="telephone-answering-service" />} />
              <Route path='/design-development-for/telephone-company' element={<DesignAndDevelopmentCaseStudy heading="Telephone Company" link="telephone-company" />} />
              <Route path='/design-development-for/telephone-exchange' element={<DesignAndDevelopmentCaseStudy heading="Telephone Exchange" link="telephone-exchange" />} />
              <Route path='/design-development-for/telescope-store' element={<DesignAndDevelopmentCaseStudy heading="Telescope Store" link="telescope-store" />} />
              <Route path='/design-development-for/television-station' element={<DesignAndDevelopmentCaseStudy heading="Television Station" link="television-station" />} />
              <Route path='/design-development-for/temp-agency' element={<DesignAndDevelopmentCaseStudy heading="Temp Agency" link="temp-agency" />} />
              <Route path='/design-development-for/tenant-ownership' element={<DesignAndDevelopmentCaseStudy heading="Tenant Ownership" link="tenant-ownership" />} />
              <Route path='/design-development-for/tenants-union' element={<DesignAndDevelopmentCaseStudy heading="Tenant’s Union" link="tenants-union" />} />
              <Route path='/design-development-for/tennis-club' element={<DesignAndDevelopmentCaseStudy heading="Tennis Club" link="tennis-club" />} />
              <Route path='/design-development-for/tattoo-removal-service' element={<DesignAndDevelopmentCaseStudy heading="Tattoo Removal Service" link="tattoo-removal-service" />} />
              <Route path='/design-development-for/tattoo-shop' element={<DesignAndDevelopmentCaseStudy heading="Tattoo Shop" link="tattoo-shop" />} />
              <Route path='/design-development-for/tax-assessor' element={<DesignAndDevelopmentCaseStudy heading="Tax Assessor" link="tax-assessor" />} />
              <Route path='/design-development-for/tax-attorney' element={<DesignAndDevelopmentCaseStudy heading="Tax Attorney" link="tax-attorney" />} />
              <Route path='/design-development-for/tax-collectors-office' element={<DesignAndDevelopmentCaseStudy heading="Tax Collector’s Office" link="tax-collectors-office" />} />
              <Route path='/design-development-for/taoist-temple' element={<DesignAndDevelopmentCaseStudy heading="Taoist Temple" link="taoist-temple" />} />
              <Route path='/design-development-for/taekwondo-competition-area' element={<DesignAndDevelopmentCaseStudy heading="Taekwondo Competition Area" link="taekwondo-competition-area" />} />
              <Route path='/design-development-for/taekwondo-school' element={<DesignAndDevelopmentCaseStudy heading="Taekwondo School" link="taekwondo-school" />} />
              <Route path='/design-development-for/tai-chi-school' element={<DesignAndDevelopmentCaseStudy heading="Tai Chi School" link="tai-chi-school" />} />
              <Route path='/design-development-for/tailor' element={<DesignAndDevelopmentCaseStudy heading="Tailor" link="tailor" />} />
              <Route path='/design-development-for/tamale-agency' element={<DesignAndDevelopmentCaseStudy heading="Tamale Agency" link="tamale-agency" />} />
              <Route path='/design-development-for/swimming-pool' element={<DesignAndDevelopmentCaseStudy heading="Swimming Pool" link="swimming-pool" />} />
              <Route path='/design-development-for/swimming-pool-contractor' element={<DesignAndDevelopmentCaseStudy heading="Swimming Pool Contractor" link="swimming-pool-contractor" />} />
              <Route path='/design-development-for/swimming-pool-repair-service' element={<DesignAndDevelopmentCaseStudy heading="Swimming Pool Repair Service" link="swimming-pool-repair-service" />} />
              <Route path='/design-development-for/swimming-pool-supply-store' element={<DesignAndDevelopmentCaseStudy heading="Swimming Pool Supply Store" link="swimming-pool-supply-store" />} />
              <Route path='/design-development-for/swimming-school' element={<DesignAndDevelopmentCaseStudy heading="Swimming School" link="swimming-school" />} />
              <Route path='/design-development-for/swimwear-store' element={<DesignAndDevelopmentCaseStudy heading="Swimwear Store" link="swimwear-store" />} />
              <Route path='/design-development-for/synagogue' element={<DesignAndDevelopmentCaseStudy heading="Synagogue" link="synagogue" />} />
              <Route path='/design-development-for/t-shirt-company' element={<DesignAndDevelopmentCaseStudy heading="T-shirt Company" link="t-shirt-company" />} />
              <Route path='/design-development-for/t-shirt-store' element={<DesignAndDevelopmentCaseStudy heading="T-shirt Store" link="t-shirt-store" />} />
              <Route path='/design-development-for/table-tennis-facility' element={<DesignAndDevelopmentCaseStudy heading="Table Tennis Facility" link="table-tennis-facility" />} />
              <Route path='/design-development-for/table-tennis-supply-store' element={<DesignAndDevelopmentCaseStudy heading="Table Tennis Supply Store" link="table-tennis-supply-store" />} />
              <Route path='/design-development-for/sweets-and-dessert-buffet' element={<DesignAndDevelopmentCaseStudy heading="Sweets and Dessert Buffet" link="sweets-and-dessert-buffet" />} />
              <Route path='/design-development-for/swim-club' element={<DesignAndDevelopmentCaseStudy heading="Swim Club" link="swim-club" />} />
              <Route path='/design-development-for/swimming-basin' element={<DesignAndDevelopmentCaseStudy heading="Swimming Basin" link="swimming-basin" />} />
              <Route path='/design-development-for/swimming-competition' element={<DesignAndDevelopmentCaseStudy heading="Swimming Competition" link="swimming-competition" />} />
              <Route path='/design-development-for/swimming-facility' element={<DesignAndDevelopmentCaseStudy heading="Swimming Facility" link="swimming-facility" />} />
              <Route path='/design-development-for/surgical-products-wholesaler' element={<DesignAndDevelopmentCaseStudy heading="Surgical Products Wholesaler" link="surgical-products-wholesaler" />} />
              <Route path='/design-development-for/surgical-supply-store' element={<DesignAndDevelopmentCaseStudy heading="Surgical Supply Store" link="surgical-supply-store" />} />
              <Route path='/design-development-for/surplus-store' element={<DesignAndDevelopmentCaseStudy heading="Surplus Store" link="surplus-store" />} />
              <Route path='/design-development-for/sushi-takeaway' element={<DesignAndDevelopmentCaseStudy heading="Sushi Takeaway" link="sushi-takeaway" />} />
              <Route path='/design-development-for/surf-school' element={<DesignAndDevelopmentCaseStudy heading="Surf School" link="surf-school" />} />
              <Route path='/design-development-for/surf-shop' element={<DesignAndDevelopmentCaseStudy heading="Surf Shop" link="surf-shop" />} />
              <Route path='/design-development-for/surgeon' element={<DesignAndDevelopmentCaseStudy heading="Surgeon" link="surgeon" />} />
              <Route path='/design-development-for/truck-accessories-store' element={<DesignAndDevelopmentCaseStudy heading="Truck Accessories Store" link="truck-accessories-store" />} />
              <Route path='/design-development-for/truck-dealer' element={<DesignAndDevelopmentCaseStudy heading="Truck Dealer" link="truck-dealer" />} />
              <Route path='/design-development-for/truck-driving-school' element={<DesignAndDevelopmentCaseStudy heading="Truck Driving School" link="truck-driving-school" />} />
              <Route path='/design-development-for/truck-farmer' element={<DesignAndDevelopmentCaseStudy heading="Truck Farmer" link="truck-farmer" />} />
              <Route path='/design-development-for/truck-parts-supplier' element={<DesignAndDevelopmentCaseStudy heading="Truck Parts Supplier" link="truck-parts-supplier" />} />
              <Route path='/design-development-for/truck-rental-agency' element={<DesignAndDevelopmentCaseStudy heading="Truck Rental Agency" link="truck-rental-agency" />} />
              <Route path='/design-development-for/truck-repair-shop' element={<DesignAndDevelopmentCaseStudy heading="Truck Repair Shop" link="truck-repair-shop" />} />
              <Route path='/design-development-for/truck-stop' element={<DesignAndDevelopmentCaseStudy heading="Truck Stop" link="truck-stop" />} />
              <Route path='/design-development-for/triumph-motorcycle-dealer' element={<DesignAndDevelopmentCaseStudy heading="Triumph Motorcycle Dealer" link="triumph-motorcycle-dealer" />} />
              <Route path='/design-development-for/transportation-service' element={<DesignAndDevelopmentCaseStudy heading="Transportation Service" link="transportation-service" />} />
              <Route path='/design-development-for/travel-agency' element={<DesignAndDevelopmentCaseStudy heading="Travel Agency" link="travel-agency" />} />
              <Route path='/design-development-for/travel-clinic' element={<DesignAndDevelopmentCaseStudy heading="Travel Clinic" link="travel-clinic" />} />
              <Route path='/design-development-for/travel-lounge' element={<DesignAndDevelopmentCaseStudy heading="Travel Lounge" link="travel-lounge" />} />
              <Route path='/design-development-for/tree-farm' element={<DesignAndDevelopmentCaseStudy heading="Tree Farm" link="tree-farm" />} />
              <Route path='/design-development-for/tree-service' element={<DesignAndDevelopmentCaseStudy heading="Tree Service" link="tree-service" />} />
              <Route path='/design-development-for/trailer-manufacturer' element={<DesignAndDevelopmentCaseStudy heading="Trailer Manufacturer" link="trailer-manufacturer" />} />
              <Route path='/design-development-for/trailer-rental-service' element={<DesignAndDevelopmentCaseStudy heading="Trailer Rental Service" link="trailer-rental-service" />} />
              <Route path='/design-development-for/trailer-repair-shop' element={<DesignAndDevelopmentCaseStudy heading="Trailer Repair Shop" link="trailer-repair-shop" />} />
              <Route path='/design-development-for/trailer-supply-store' element={<DesignAndDevelopmentCaseStudy heading="Trailer Supply Store" link="trailer-supply-store" />} />
              <Route path='/design-development-for/train-depot' element={<DesignAndDevelopmentCaseStudy heading="Train Depot" link="train-depot" />} />
              <Route path='/design-development-for/train-ticket-agency' element={<DesignAndDevelopmentCaseStudy heading="Train Ticket Agency" link="train-ticket-agency" />} />
              <Route path='/design-development-for/train-repairing-center' element={<DesignAndDevelopmentCaseStudy heading="Train Repairing Center" link="train-repairing-center" />} />
              <Route path='/design-development-for/train-yard' element={<DesignAndDevelopmentCaseStudy heading="Train Yard" link="train-yard" />} />
              <Route path='/design-development-for/training-centre' element={<DesignAndDevelopmentCaseStudy heading="Training Centre" link="training-centre" />} />
              <Route path='/design-development-for/training-school' element={<DesignAndDevelopmentCaseStudy heading="Training School" link="training-school" />} />
              <Route path='/design-development-for/transcription-service' element={<DesignAndDevelopmentCaseStudy heading="Transcription Service" link="transcription-service" />} />
              <Route path='/design-development-for/transit-depot' element={<DesignAndDevelopmentCaseStudy heading="Transit Depot" link="transit-depot" />} />
              <Route path='/design-development-for/translator' element={<DesignAndDevelopmentCaseStudy heading="Translator" link="translator" />} />
              <Route path='/design-development-for/transmission-shop' element={<DesignAndDevelopmentCaseStudy heading="Transmission Shop" link="transmission-shop" />} />
              <Route path='/design-development-for/transplant-surgeon' element={<DesignAndDevelopmentCaseStudy heading="Transplant Surgeon" link="transplant-surgeon" />} />
              <Route path='/design-development-for/trade-fair-construction-company' element={<DesignAndDevelopmentCaseStudy heading="Trade Fair Construction Company" link="trade-fair-construction-company" />} />
              <Route path='/design-development-for/trade-school' element={<DesignAndDevelopmentCaseStudy heading="Trade School" link="trade-school" />} />
              <Route path='/design-development-for/trading-card-store' element={<DesignAndDevelopmentCaseStudy heading="Trading Card Store" link="trading-card-store" />} />
              <Route path='/design-development-for/traditional-costume-club' element={<DesignAndDevelopmentCaseStudy heading="Traditional Costume Club" link="traditional-costume-club" />} />
              <Route path='/design-development-for/traditional-kostume-store' element={<DesignAndDevelopmentCaseStudy heading="Traditional Kostume Store" link="traditional-kostume-store" />} />
              <Route path='/design-development-for/traditional-market' element={<DesignAndDevelopmentCaseStudy heading="Traditional Market" link="traditional-market" />} />
              <Route path='/design-development-for/topsoil-supplier' element={<DesignAndDevelopmentCaseStudy heading="Topsoil Supplier" link="topsoil-supplier" />} />
              <Route path='/design-development-for/tortilla-shop' element={<DesignAndDevelopmentCaseStudy heading="Tortilla Shop" link="tortilla-shop" />} />
              <Route path='/design-development-for/tour-agency' element={<DesignAndDevelopmentCaseStudy heading="Tour Agency" link="tour-agency" />} />
              <Route path='/design-development-for/tour-operator' element={<DesignAndDevelopmentCaseStudy heading="Tour Operator" link="tour-operator" />} />
              <Route path='/design-development-for/tourist-attraction' element={<DesignAndDevelopmentCaseStudy heading="Tourist Attraction" link="tourist-attraction" />} />
              <Route path='/design-development-for/tourist-information-center' element={<DesignAndDevelopmentCaseStudy heading="Tourist Information Center" link="tourist-information-center" />} />
              <Route path='/design-development-for/towing-equipment-provider' element={<DesignAndDevelopmentCaseStudy heading="Towing Equipment Provider" link="towing-equipment-provider" />} />
              <Route path='/design-development-for/townhouse-complex' element={<DesignAndDevelopmentCaseStudy heading="Townhouse Complex" link="townhouse-complex" />} />
              <Route path='/design-development-for/toy-and-game-manufacturer' element={<DesignAndDevelopmentCaseStudy heading="Toy and Game Manufacturer" link="toy-and-game-manufacturer" />} />
              <Route path='/design-development-for/toll-road-rest-stop' element={<DesignAndDevelopmentCaseStudy heading="Toll Road Rest Stop" link="toll-road-rest-stop" />} />
              <Route path='/design-development-for/toner-cartridge-supplier' element={<DesignAndDevelopmentCaseStudy heading="Toner Cartridge Supplier" link="toner-cartridge-supplier" />} />
              <Route path='/design-development-for/tool-and-die-shop' element={<DesignAndDevelopmentCaseStudy heading="Tool & Die Shop" link="tool-and-die-shop" />} />
              <Route path='/design-development-for/tool-grinding-service' element={<DesignAndDevelopmentCaseStudy heading="Tool Grinding Service" link="tool-grinding-service" />} />
              <Route path='/design-development-for/tool-manufacturer' element={<DesignAndDevelopmentCaseStudy heading="Tool Manufacturer" link="tool-manufacturer" />} />
              <Route path='/design-development-for/tool-rental-service' element={<DesignAndDevelopmentCaseStudy heading="Tool Rental Service" link="tool-rental-service" />} />
              <Route path='/design-development-for/tool-repair-shop' element={<DesignAndDevelopmentCaseStudy heading="Tool Repair Shop" link="tool-repair-shop" />} />
              <Route path='/design-development-for/tool-store' element={<DesignAndDevelopmentCaseStudy heading="Tool Store" link="tool-store" />} />
              <Route path='/design-development-for/tool-wholesaler' element={<DesignAndDevelopmentCaseStudy heading="Tool Wholesaler" link="tool-wholesaler" />} />
              <Route path='/design-development-for/toolroom' element={<DesignAndDevelopmentCaseStudy heading="Toolroom" link="toolroom" />} />
              <Route path='/design-development-for/tiki-bar' element={<DesignAndDevelopmentCaseStudy heading="Tiki Bar" link="tiki-bar" />} />
              <Route path='/design-development-for/tile-cleaning-service' element={<DesignAndDevelopmentCaseStudy heading="Tile Cleaning Service" link="tile-cleaning-service" />} />
              <Route path='/design-development-for/tile-contractor' element={<DesignAndDevelopmentCaseStudy heading="Tile Contractor" link="tile-contractor" />} />
              <Route path='/design-development-for/tile-manufacturer' element={<DesignAndDevelopmentCaseStudy heading="Tile Manufacturer" link="tile-manufacturer" />} />
              <Route path='/design-development-for/tile-store' element={<DesignAndDevelopmentCaseStudy heading="Tile Store" link="tile-store" />} />
              <Route path='/design-development-for/timeshare-agency' element={<DesignAndDevelopmentCaseStudy heading="Timeshare Agency" link="timeshare-agency" />} />
              <Route path='/design-development-for/tire-repair-shop' element={<DesignAndDevelopmentCaseStudy heading="Tire Repair Shop" link="tire-repair-shop" />} />
              <Route path='/design-development-for/tire-shop' element={<DesignAndDevelopmentCaseStudy heading="Tire Shop" link="tire-shop" />} />
              <Route path='/design-development-for/title-company' element={<DesignAndDevelopmentCaseStudy heading="Title Company" link="title-company" />} />
              <Route path='/design-development-for/tobacco-exporter' element={<DesignAndDevelopmentCaseStudy heading="Tobacco Exporter" link="tobacco-exporter" />} />
              <Route path='/design-development-for/tobacco-shop' element={<DesignAndDevelopmentCaseStudy heading="Tobacco Shop" link="tobacco-shop" />} />
              <Route path='/design-development-for/tobacco-supplier' element={<DesignAndDevelopmentCaseStudy heading="Tobacco Supplier" link="tobacco-supplier" />} />
              <Route path='/design-development-for/tofu-shop' element={<DesignAndDevelopmentCaseStudy heading="Tofu Shop" link="tofu-shop" />} />
              <Route path='/design-development-for/toiletries-store' element={<DesignAndDevelopmentCaseStudy heading="Toiletries Store" link="toiletries-store" />} />
              <Route path='/design-development-for/trial-attorney' element={<DesignAndDevelopmentCaseStudy heading="Trial Attorney" link="trial-attorney" />} />
              <Route path='/design-development-for/urgent-care-center' element={<DesignAndDevelopmentCaseStudy heading="Urgent Care Center" link="urgent-care-center" />} />
              <Route path='/design-development-for/urologist' element={<DesignAndDevelopmentCaseStudy heading="Urologist" link="urologist" />} />
              <Route path='/design-development-for/urology-clinic' element={<DesignAndDevelopmentCaseStudy heading="Urology Clinic" link="urology-clinic" />} />
              <Route path='/design-development-for/used-appliance-store' element={<DesignAndDevelopmentCaseStudy heading="Used Appliance Store" link="used-appliance-store" />} />
              <Route path='/design-development-for/used-auto-parts-store' element={<DesignAndDevelopmentCaseStudy heading="Used Auto Parts Store" link="used-auto-parts-store" />} />
              <Route path='/design-development-for/used-bicycle-shop' element={<DesignAndDevelopmentCaseStudy heading="Used Bicycle Shop" link="used-bicycle-shop" />} />
              <Route path='/design-development-for/used-book-store' element={<DesignAndDevelopmentCaseStudy heading="Used Book Store" link="used-book-store" />} />
              <Route path='/design-development-for/used-car-dealer' element={<DesignAndDevelopmentCaseStudy heading="Used Car Dealer" link="used-car-dealer" />} />
              <Route path='/design-development-for/used-cd-store' element={<DesignAndDevelopmentCaseStudy heading="Used CD Store" link="used-cd-store" />} />
              <Route path='/design-development-for/used-clothing-store' element={<DesignAndDevelopmentCaseStudy heading="Used Clothing Store" link="used-clothing-store" />} />
              <Route path='/design-development-for/used-computer-store' element={<DesignAndDevelopmentCaseStudy heading="Used Computer Store" link="used-computer-store" />} />
              <Route path='/design-development-for/used-furniture-store' element={<DesignAndDevelopmentCaseStudy heading="Used Furniture Store" link="used-furniture-store" />} />
              <Route path='/design-development-for/used-game-store' element={<DesignAndDevelopmentCaseStudy heading="Used Game Store" link="used-game-store" />} />
              <Route path='/design-development-for/used-motorcycle-dealer' element={<DesignAndDevelopmentCaseStudy heading="Used Motorcycle Dealer" link="used-motorcycle-dealer" />} />
              <Route path='/design-development-for/used-musical-instrument-store' element={<DesignAndDevelopmentCaseStudy heading="Used Musical Instrument Store" link="used-musical-instrument-store" />} />
              <Route path='/design-development-for/used-office-furniture-store' element={<DesignAndDevelopmentCaseStudy heading="Used Office Furniture Store" link="used-office-furniture-store" />} />
              <Route path='/design-development-for/used-store-fixture-supplier' element={<DesignAndDevelopmentCaseStudy heading="Used Store Fixture Supplier" link="used-store-fixture-supplier" />} />
              <Route path='/design-development-for/used-tire-shop' element={<DesignAndDevelopmentCaseStudy heading="Used Tire Shop" link="used-tire-shop" />} />
              <Route path='/design-development-for/used-truck-dealer' element={<DesignAndDevelopmentCaseStudy heading="Used Truck Dealer" link="used-truck-dealer" />} />
              <Route path='/design-development-for/utility-contractor' element={<DesignAndDevelopmentCaseStudy heading="Utility Contractor" link="utility-contractor" />} />
              <Route path='/design-development-for/underwear-store' element={<DesignAndDevelopmentCaseStudy heading="Underwear Store" link="underwear-store" />} />
              <Route path='/design-development-for/unemployment-office' element={<DesignAndDevelopmentCaseStudy heading="Unemployment Office" link="unemployment-office" />} />
              <Route path='/design-development-for/unfinished-furniture-store' element={<DesignAndDevelopmentCaseStudy heading="Unfinished Furniture Store" link="unfinished-furniture-store" />} />
              <Route path='/design-development-for/uniform-store' element={<DesignAndDevelopmentCaseStudy heading="Uniform Store" link="uniform-store" />} />
              <Route path='/design-development-for/unitarian-universalist-church' element={<DesignAndDevelopmentCaseStudy heading="Unitarian Universalist Church" link="unitarian-universalist-church" />} />
              <Route path='/design-development-for/trucking-company' element={<DesignAndDevelopmentCaseStudy heading="Trucking Company" link="trucking-company" />} />
              <Route path='/design-development-for/truss-manufacturer' element={<DesignAndDevelopmentCaseStudy heading="Truss Manufacturer" link="truss-manufacturer" />} />
              <Route path='/design-development-for/trust-bank' element={<DesignAndDevelopmentCaseStudy heading="Trust Bank" link="trust-bank" />} />
              <Route path='/design-development-for/tsukigime-parking-lot' element={<DesignAndDevelopmentCaseStudy heading="Tsukigime Parking Lot" link="tsukigime-parking-lot" />} />
              <Route path='/design-development-for/tune-up-supplier' element={<DesignAndDevelopmentCaseStudy heading="Tune up Supplier" link="tune-up-supplier" />} />
              <Route path='/design-development-for/warehouse' element={<DesignAndDevelopmentCaseStudy heading="Warehouse" link="warehouse" />} />
              <Route path='/design-development-for/warehouse-club' element={<DesignAndDevelopmentCaseStudy heading="Warehouse Club" link="warehouse-club" />} />
              <Route path='/design-development-for/warehouse-store' element={<DesignAndDevelopmentCaseStudy heading="Warehouse Store" link="warehouse-store" />} />
              <Route path='/design-development-for/washer-and-dryer-repair-service' element={<DesignAndDevelopmentCaseStudy heading="Washer & Dryer Repair Service" link="washer-and-dryer-repair-service" />} />
              <Route path='/design-development-for/washer-and-dryer-store' element={<DesignAndDevelopmentCaseStudy heading="Washer & Dryer Store" link="washer-and-dryer-store" />} />
              <Route path='/design-development-for/waste-management-service' element={<DesignAndDevelopmentCaseStudy heading="Waste Management Service" link="waste-management-service" />} />
              <Route path='/design-development-for/waste-transfer-station' element={<DesignAndDevelopmentCaseStudy heading="Waste Transfer Station" link="waste-transfer-station" />} />
              <Route path='/design-development-for/watch-manufacturer' element={<DesignAndDevelopmentCaseStudy heading="Watch Manufacturer" link="watch-manufacturer" />} />
              <Route path='/design-development-for/watch-repair-service' element={<DesignAndDevelopmentCaseStudy heading="Watch Repair Service" link="watch-repair-service" />} />
              <Route path='/design-development-for/watch-store' element={<DesignAndDevelopmentCaseStudy heading="Watch Store" link="watch-store" />} />
              <Route path='/design-development-for/vocational-college' element={<DesignAndDevelopmentCaseStudy heading="Vocational College" link="vocational-college" />} />
              <Route path='/design-development-for/vocational-gymnasium-school' element={<DesignAndDevelopmentCaseStudy heading="Vocational Gymnasium School" link="vocational-gymnasium-school" />} />
              <Route path='/design-development-for/vocational-school' element={<DesignAndDevelopmentCaseStudy heading="Vocational School" link="vocational-school" />} />
              <Route path='/design-development-for/vocational-school-one' element={<DesignAndDevelopmentCaseStudy heading="Vocational School One" link="vocational-school-one" />} />
              <Route path='/design-development-for/vocational-secondary-school' element={<DesignAndDevelopmentCaseStudy heading="Vocational Secondary School" link="vocational-secondary-school" />} />
              <Route path='/design-development-for/volkswagen-dealer' element={<DesignAndDevelopmentCaseStudy heading="Volkswagen Dealer" link="volkswagen-dealer" />} />
              <Route path='/design-development-for/volleyball-club' element={<DesignAndDevelopmentCaseStudy heading="Volleyball Club" link="volleyball-club" />} />
              <Route path='/design-development-for/video-game-rental-service' element={<DesignAndDevelopmentCaseStudy heading="Video Game Rental Service" link="video-game-rental-service" />} />
              <Route path='/design-development-for/video-game-rental-store' element={<DesignAndDevelopmentCaseStudy heading="Video Game Rental Store" link="video-game-rental-store" />} />
              <Route path='/design-development-for/video-game-store' element={<DesignAndDevelopmentCaseStudy heading="Video Game Store" link="video-game-store" />} />
              <Route path='/design-development-for/video-karaoke' element={<DesignAndDevelopmentCaseStudy heading="Video Karaoke" link="video-karaoke" />} />
              <Route path='/design-development-for/video-production-service' element={<DesignAndDevelopmentCaseStudy heading="Video Production Service" link="video-production-service" />} />
              <Route path='/design-development-for/video-store' element={<DesignAndDevelopmentCaseStudy heading="Video Store" link="video-store" />} />
              <Route path='/design-development-for/villa' element={<DesignAndDevelopmentCaseStudy heading="Villa" link="villa" />} />
              <Route path='/design-development-for/village-hall' element={<DesignAndDevelopmentCaseStudy heading="Village Hall" link="village-hall" />} />
              <Route path='/design-development-for/vineyard' element={<DesignAndDevelopmentCaseStudy heading="Vineyard" link="vineyard" />} />
              <Route path='/design-development-for/vineyard-church' element={<DesignAndDevelopmentCaseStudy heading="Vineyard Church" link="vineyard-church" />} />
              <Route path='/design-development-for/vegetarian-cafe-and-deli' element={<DesignAndDevelopmentCaseStudy heading="Vegetarian Cafe and Deli" link="vegetarian-cafe-and-deli" />} />
              <Route path='/design-development-for/vehicle-inspection-service' element={<DesignAndDevelopmentCaseStudy heading="Vehicle Inspection Service" link="vehicle-inspection-service" />} />
              <Route path='/design-development-for/vehicle-shipping-agent' element={<DesignAndDevelopmentCaseStudy heading="Vehicle Shipping Agent" link="vehicle-shipping-agent" />} />
              <Route path='/design-development-for/vehicle-wrapping-service' element={<DesignAndDevelopmentCaseStudy heading="Vehicle Wrapping Service" link="vehicle-wrapping-service" />} />
              <Route path='/design-development-for/velodrome' element={<DesignAndDevelopmentCaseStudy heading="Velodrome" link="velodrome" />} />
              <Route path='/design-development-for/vehicle-exporter' element={<DesignAndDevelopmentCaseStudy heading="Vehicle Exporter" link="vehicle-exporter" />} />
              <Route path='/design-development-for/venereologist' element={<DesignAndDevelopmentCaseStudy heading="Venereologist" link="venereologist" />} />
              <Route path='/design-development-for/ventilating-equipment-manufacturer' element={<DesignAndDevelopmentCaseStudy heading="Ventilating Equipment Manufacturer" link="ventilating-equipment-manufacturer" />} />
              <Route path='/design-development-for/venture-capital-company' element={<DesignAndDevelopmentCaseStudy heading="Venture Capital Company" link="venture-capital-company" />} />
              <Route path='/design-development-for/veterans-affairs-department' element={<DesignAndDevelopmentCaseStudy heading="Veterans Affairs Department" link="veterans-affairs-department" />} />
              <Route path='/design-development-for/veterans-center' element={<DesignAndDevelopmentCaseStudy heading="Veterans Center" link="veterans-center" />} />
              <Route path='/design-development-for/veterans-hospital' element={<DesignAndDevelopmentCaseStudy heading="Veterans Hospital" link="veterans-hospital" />} />
              <Route path='/design-development-for/veterans-organization' element={<DesignAndDevelopmentCaseStudy heading="Veterans Organization" link="veterans-organization" />} />
              <Route path='/design-development-for/veterinarian' element={<DesignAndDevelopmentCaseStudy heading="Veterinarian" link="veterinarian" />} />
              <Route path='/design-development-for/veterinary-pharmacy' element={<DesignAndDevelopmentCaseStudy heading="Veterinary Pharmacy" link="veterinary-pharmacy" />} />
              <Route path='/design-development-for/video-arcade' element={<DesignAndDevelopmentCaseStudy heading="Video Arcade" link="video-arcade" />} />


              {/* Paid Media Case Study */}
              <Route path='/ppc-for-american-grocery-store' element={<PaidMediaCaseStudy heading="American grocery store" link="/ppc-for-american-grocery-store" />} />
              <Route path='/ppc-for-amusement-park-ride' element={<PaidMediaCaseStudy heading="Amusement park ride" link="/ppc-for-amusement-park-ride" />} />
              <Route path='/ppc-for-water-purification-company' element={<PaidMediaCaseStudy heading="Water purification company" link="/ppc-for-water-purification-company" />} />
              <Route path='/ppc-for-water-ski-shop' element={<PaidMediaCaseStudy heading="Water ski shop" link="/ppc-for-water-ski-shop" />} />
              <Route path='/ppc-for-water-skiing-club' element={<PaidMediaCaseStudy heading="Water skiing club" link="/ppc-for-water-skiing-club" />} />
              <Route path='/ppc-for-water-skiing-instructor' element={<PaidMediaCaseStudy heading="Water skiing instructor" link="/ppc-for-water-skiing-instructor" />} />
              <Route path='/ppc-for-water-skiing-service' element={<PaidMediaCaseStudy heading="Water skiing service" link="/ppc-for-water-skiing-service" />} />
              <Route path='/ppc-for-water-softening-equipment-supplier' element={<PaidMediaCaseStudy heading="Water softening equipment supplier" link="/ppc-for-water-softening-equipment-supplier" />} />
              <Route path='/ppc-for-water-testing-service' element={<PaidMediaCaseStudy heading="Water testing service" link="/ppc-for-water-testing-service" />} />
              <Route path='/ppc-for-water-treatment-plant' element={<PaidMediaCaseStudy heading="Water treatment plant" link="/ppc-for-water-treatment-plant" />} />
              <Route path='/ppc-for-water-treatment-supplier' element={<PaidMediaCaseStudy heading="Water treatment supplier" link="/ppc-for-water-treatment-supplier" />} />
              <Route path='/ppc-for-water-utility-company' element={<PaidMediaCaseStudy heading="Water utility company" link="/ppc-for-water-utility-company" />} />
              <Route path='/ppc-for-water-works' element={<PaidMediaCaseStudy heading="Water works" link="/ppc-for-water-works" />} />
              <Route path='/ppc-for-water-works-equipment-supplier' element={<PaidMediaCaseStudy heading="Water works equipment supplier" link="/ppc-for-water-works-equipment-supplier" />} />
              <Route path='/ppc-for-web-hosting-company' element={<PaidMediaCaseStudy heading="Web hosting company" link="/ppc-for-web-hosting-company" />} />
              <Route path='/ppc-for-website-designer' element={<PaidMediaCaseStudy heading="Website designer" link="/ppc-for-website-designer" />} />
              <Route path='/ppc-for-wedding-bakery' element={<PaidMediaCaseStudy heading="Wedding bakery" link="/ppc-for-wedding-bakery" />} />
              <Route path='/ppc-for-wedding-buffet' element={<PaidMediaCaseStudy heading="Wedding buffet" link="/ppc-for-wedding-buffet" />} />
              <Route path='/ppc-for-wedding-chapel' element={<PaidMediaCaseStudy heading="Wedding chapel" link="/ppc-for-wedding-chapel" />} />
              <Route path='/ppc-for-wedding-dress-rental-service' element={<PaidMediaCaseStudy heading="Wedding dress rental service" link="/ppc-for-wedding-dress-rental-service" />} />
              <Route path='/ppc-for-wedding-photographer' element={<PaidMediaCaseStudy heading="Wedding photographer" link="/ppc-for-wedding-photographer" />} />
              <Route path='/ppc-for-wedding-planner' element={<PaidMediaCaseStudy heading="Wedding planner" link="/ppc-for-wedding-planner" />} />
              <Route path='/ppc-for-wedding-service' element={<PaidMediaCaseStudy heading="Wedding service" link="/ppc-for-wedding-service" />} />
              <Route path='/ppc-for-wedding-souvenir-shop' element={<PaidMediaCaseStudy heading="Wedding souvenir shop" link="/ppc-for-wedding-souvenir-shop" />} />
              <Route path='/ppc-for-wedding-store' element={<PaidMediaCaseStudy heading="Wedding store" link="/ppc-for-wedding-store" />} />
              <Route path='/ppc-for-wedding-venue' element={<PaidMediaCaseStudy heading="Wedding venue" link="/ppc-for-wedding-venue" />} />
              <Route path='/ppc-for-weigh-station' element={<PaidMediaCaseStudy heading="Weigh station" link="/ppc-for-weigh-station" />} />
              <Route path='/ppc-for-weight-loss-service' element={<PaidMediaCaseStudy heading="Weight loss service" link="/ppc-for-weight-loss-service" />} />
              <Route path='/ppc-for-weightlifting-area' element={<PaidMediaCaseStudy heading="Weightlifting area" link="/ppc-for-weightlifting-area" />} />
              <Route path='/ppc-for-well-drilling-contractor' element={<PaidMediaCaseStudy heading="Well drilling contractor" link="/ppc-for-well-drilling-contractor" />} />
              <Route path='/ppc-for-wellness-center' element={<PaidMediaCaseStudy heading="Wellness center" link="/ppc-for-wellness-center" />} />
              <Route path='/ppc-for-wellness-hotel' element={<PaidMediaCaseStudy heading="Wellness hotel" link="/ppc-for-wellness-hotel" />} />
              <Route path='/ppc-for-wellness-program' element={<PaidMediaCaseStudy heading="Wellness program" link="/ppc-for-wellness-program" />} />
              <Route path='/ppc-for-wesleyan-church' element={<PaidMediaCaseStudy heading="Wesleyan church" link="/ppc-for-wesleyan-church" />} />
              <Route path='/ppc-for-western-apparel-store' element={<PaidMediaCaseStudy heading="Western apparel store" link="/ppc-for-western-apparel-store" />} />
              <Route path='/ppc-for-whale-watching-tour-agency' element={<PaidMediaCaseStudy heading="Whale watching tour agency" link="/ppc-for-whale-watching-tour-agency" />} />
              <Route path='/ppc-for-wholesale-bakery' element={<PaidMediaCaseStudy heading="Wholesale bakery" link="/ppc-for-wholesale-bakery" />} />
              <Route path='/ppc-for-wholesale-drugstore' element={<PaidMediaCaseStudy heading="Wholesale drugstore" link="/ppc-for-wholesale-drugstore" />} />
              <Route path='/ppc-for-wholesale-florist' element={<PaidMediaCaseStudy heading="Wholesale florist" link="/ppc-for-wholesale-florist" />} />
              <Route path='/ppc-for-wholesale-food-store' element={<PaidMediaCaseStudy heading="Wholesale food store" link="/ppc-for-wholesale-food-store" />} />
              <Route path='/ppc-for-wholesale-grocer' element={<PaidMediaCaseStudy heading="Wholesale grocer" link="/ppc-for-wholesale-grocer" />} />
              <Route path='/ppc-for-wholesale-jeweler' element={<PaidMediaCaseStudy heading="Wholesale jeweler" link="/ppc-for-wholesale-jeweler" />} />
              <Route path='/ppc-for-wholesale-market' element={<PaidMediaCaseStudy heading="Wholesale market" link="/ppc-for-wholesale-market" />} />
              <Route path='/ppc-for-wholesale-plant-nursery' element={<PaidMediaCaseStudy heading="Wholesale plant nursery" link="/ppc-for-wholesale-plant-nursery" />} />
              <Route path='/ppc-for-wholesaler' element={<PaidMediaCaseStudy heading="Wholesaler" link="/ppc-for-wholesaler" />} />
              <Route path='/ppc-for-wi-fi-spot' element={<PaidMediaCaseStudy heading="Wi-Fi spot" link="/ppc-for-wi-fi-spot" />} />
              <Route path='/ppc-for-wildlife-park' element={<PaidMediaCaseStudy heading="Wildlife park" link="/ppc-for-wildlife-park" />} />
              <Route path='/ppc-for-wildlife-refuge' element={<PaidMediaCaseStudy heading="Wildlife refuge" link="/ppc-for-wildlife-refuge" />} />
              <Route path='/ppc-for-wildlife-rescue-service' element={<PaidMediaCaseStudy heading="Wildlife rescue service" link="/ppc-for-wildlife-rescue-service" />} />
              <Route path='/ppc-for-willow-basket-manufacturer' element={<PaidMediaCaseStudy heading="Willow basket manufacturer" link="/ppc-for-willow-basket-manufacturer" />} />
              <Route path='/ppc-for-wind-turbine-builder' element={<PaidMediaCaseStudy heading="Wind turbine builder" link="/ppc-for-wind-turbine-builder" />} />
              <Route path='/ppc-for-window-cleaning-service' element={<PaidMediaCaseStudy heading="Window cleaning service" link="/ppc-for-window-cleaning-service" />} />
              <Route path='/ppc-for-window-installation-service' element={<PaidMediaCaseStudy heading="Window installation service" link="/ppc-for-window-installation-service" />} />
              <Route path='/ppc-for-window-supplier' element={<PaidMediaCaseStudy heading="Window supplier" link="/ppc-for-window-supplier" />} />
              <Route path='/ppc-for-window-tinting-service' element={<PaidMediaCaseStudy heading="Window tinting service" link="/ppc-for-window-tinting-service" />} />
              <Route path='/ppc-for-window-treatment-store' element={<PaidMediaCaseStudy heading="Window treatment store" link="/ppc-for-window-treatment-store" />} />
              <Route path='/ppc-for-wine-store' element={<PaidMediaCaseStudy heading="Wine store" link="/ppc-for-wine-store" />} />
              <Route path='/ppc-for-wine-wholesaler-and-importer' element={<PaidMediaCaseStudy heading="Wine wholesaler and importer" link="/ppc-for-wine-wholesaler-and-importer" />} />
              <Route path='/ppc-for-winemaking-supply-store' element={<PaidMediaCaseStudy heading="Winemaking supply store" link="/ppc-for-winemaking-supply-store" />} />
              <Route path='/ppc-for-winery' element={<PaidMediaCaseStudy heading="Winery" link="/ppc-for-winery" />} />
              <Route path='/ppc-for-wing-chun-school' element={<PaidMediaCaseStudy heading="Wing chun school" link="/ppc-for-wing-chun-school" />} />
              <Route path='/ppc-for-womens-clothing-store' element={<PaidMediaCaseStudy heading="Women’s clothing store" link="/ppc-for-womens-clothing-store" />} />
              <Route path='/ppc-for-womens-college' element={<PaidMediaCaseStudy heading="Women’s college" link="/ppc-for-womens-college" />} />
              <Route path='/ppc-for-womens-health-clinic' element={<PaidMediaCaseStudy heading="Women’s health clinic" link="/ppc-for-womens-health-clinic" />} />
              <Route path='/ppc-for-womens-organization' element={<PaidMediaCaseStudy heading="Women’s organization" link="/ppc-for-womens-organization" />} />
              <Route path='/ppc-for-womens-personal-trainer' element={<PaidMediaCaseStudy heading="Women’s personal trainer" link="/ppc-for-womens-personal-trainer" />} />
              <Route path='/ppc-for-womens-shelter' element={<PaidMediaCaseStudy heading="Women’s shelter" link="/ppc-for-womens-shelter" />} />
              <Route path='/ppc-for-womens-protection-service' element={<PaidMediaCaseStudy heading="Women’s protection service" link="/ppc-for-womens-protection-service" />} />
              <Route path='/ppc-for-wood-and-laminate-flooring-supplier' element={<PaidMediaCaseStudy heading="Wood and laminate flooring supplier" link="/ppc-for-wood-and-laminate-flooring-supplier" />} />
              <Route path='/ppc-for-wood-floor-installation-service' element={<PaidMediaCaseStudy heading="Wood floor installation service" link="/ppc-for-wood-floor-installation-service" />} />
              <Route path='/ppc-for-wood-floor-refinishing-service' element={<PaidMediaCaseStudy heading="Wood floor refinishing service" link="/ppc-for-wood-floor-refinishing-service" />} />
              <Route path='/ppc-for-wood-frame-supplier' element={<PaidMediaCaseStudy heading="Wood frame supplier" link="/ppc-for-wood-frame-supplier" />} />
              <Route path='/ppc-for-wood-stove-shop' element={<PaidMediaCaseStudy heading="Wood stove shop" link="/ppc-for-wood-stove-shop" />} />
              <Route path='/ppc-for-wood-supplier' element={<PaidMediaCaseStudy heading="Wood supplier" link="/ppc-for-wood-supplier" />} />
              <Route path='/ppc-for-wood-working-class' element={<PaidMediaCaseStudy heading="Wood working class" link="/ppc-for-wood-working-class" />} />
              <Route path='/ppc-for-woodworker' element={<PaidMediaCaseStudy heading="Woodworker" link="/ppc-for-woodworker" />} />
              <Route path='/ppc-for-woodworking-supply-store' element={<PaidMediaCaseStudy heading="Woodworking supply store" link="/ppc-for-woodworking-supply-store" />} />
              <Route path='/ppc-for-wool-store' element={<PaidMediaCaseStudy heading="Wool store" link="/ppc-for-wool-store" />} />
              <Route path='/ppc-for-work-clothes-store' element={<PaidMediaCaseStudy heading="Work clothes store" link="/ppc-for-work-clothes-store" />} />
              <Route path='/ppc-for-workers-club' element={<PaidMediaCaseStudy heading="Workers’ club" link="/ppc-for-workers-club" />} />
              <Route path='/ppc-for-working-womens-hostel' element={<PaidMediaCaseStudy heading="Working womens hostel" link="/ppc-for-working-womens-hostel" />} />
              <Route path='/ppc-for-shared-use-commercial-kitchen' element={<PaidMediaCaseStudy heading="Shared-use commercial kitchen" link="/ppc-for-shared-use-commercial-kitchen" />} />
              <Route path='/ppc-for-sharpening-service' element={<PaidMediaCaseStudy heading="Sharpening service" link="/ppc-for-sharpening-service" />} />
              <Route path='/ppc-for-shed-builder' element={<PaidMediaCaseStudy heading="Shed builder" link="/ppc-for-shed-builder" />} />
              <Route path='/ppc-for-smoke-shop' element={<PaidMediaCaseStudy heading="Smoke shop" link="/ppc-for-smoke-shop" />} />
              <Route path='/ppc-for-snack-bar' element={<PaidMediaCaseStudy heading="Snack bar" link="/ppc-for-snack-bar" />} />
              <Route path='/ppc-for-snow-removal-service' element={<PaidMediaCaseStudy heading="Snow removal service" link="/ppc-for-snow-removal-service" />} />
              <Route path='/ppc-for-toy-store' element={<PaidMediaCaseStudy heading="Toy store" link="/ppc-for-toy-store" />} />
              <Route path='/ppc-for-toyota-dealer' element={<PaidMediaCaseStudy heading="Toyota dealer" link="/ppc-for-toyota-dealer" />} />
              <Route path='/ppc-for-tractor-dealer' element={<PaidMediaCaseStudy heading="Tractor dealer" link="/ppc-for-tractor-dealer" />} />
              <Route path='/ppc-for-toy-manufacturer' element={<PaidMediaCaseStudy heading="Toy manufacturer" link="/ppc-for-toy-manufacturer" />} />
              <Route path='/ppc-for-waterproofing-service' element={<PaidMediaCaseStudy heading="Waterproofing service" link="/ppc-for-waterproofing-service" />} />
              <Route path='/ppc-for-wax-museum' element={<PaidMediaCaseStudy heading="Wax museum" link="/ppc-for-wax-museum" />} />
              <Route path='/ppc-for-wax-supplier' element={<PaidMediaCaseStudy heading="Wax supplier" link="/ppc-for-wax-supplier" />} />
              <Route path='/ppc-for-waxing-hair-removal-service' element={<PaidMediaCaseStudy heading="Waxing hair removal service" link="/ppc-for-waxing-hair-removal-service" />} />
              <Route path='/ppc-for-youth-club' element={<PaidMediaCaseStudy heading="Youth club" link="/ppc-for-youth-club" />} />
              <Route path='/ppc-for-youth-group' element={<PaidMediaCaseStudy heading="Youth group" link="/ppc-for-youth-group" />} />
              <Route path='/ppc-for-youth-hostel' element={<PaidMediaCaseStudy heading="Youth hostel" link="/ppc-for-youth-hostel" />} />
              <Route path='/ppc-for-youth-organization' element={<PaidMediaCaseStudy heading="Youth organization" link="/ppc-for-youth-organization" />} />
              <Route path='/ppc-for-youth-social-services-organization' element={<PaidMediaCaseStudy heading="Youth social services organization" link="/ppc-for-youth-social-services-organization" />} />
              <Route path='/ppc-for-zhejiang-restaurant' element={<PaidMediaCaseStudy heading="Zhejiang restaurant" link="/ppc-for-zhejiang-restaurant" />} />
              <Route path='/ppc-for-zoo' element={<PaidMediaCaseStudy heading="Zoo" link="/ppc-for-zoo" />} />
              <Route path='/ppc-agency-for-law-firms' element={<PaidMediaCaseStudy heading="Law Firms" link="/ppc-agency-for-law-firms" />} />
              <Route path='/ppc-agency-for-dentists' element={<PaidMediaCaseStudy heading="Dentists" link="/ppc-agency-for-dentists" />} />
              <Route path='/ppc-agency-for-financial-services' element={<PaidMediaCaseStudy heading="Financial Services" link="/ppc-agency-for-financial-services" />} />
              <Route path='/ppc-agency-for-higher-education' element={<PaidMediaCaseStudy heading="Higher Education" link="/ppc-agency-for-higher-education" />} />
              <Route path='/ppc-agency-for-startups' element={<PaidMediaCaseStudy heading="Startups" link="/ppc-agency-for-startups" />} />
              <Route path='/best-ppc-agency-for-chiropractic' element={<PaidMediaCaseStudy heading="Chiropractic" link="/best-ppc-agency-for-chiropractic" />} />
              <Route path='/ppc-agency-hornchurch' element={<PaidMediaCaseStudy heading="Hornchurch" link="/ppc-agency-hornchurch" />} />
              <Route path='/ppc-agency-for-accountants' element={<PaidMediaCaseStudy heading="Accountants" link="/ppc-agency-for-accountants" />} />
              <Route path='/ppc-agency-for-charities' element={<PaidMediaCaseStudy heading="Charities" link="/ppc-agency-for-charities" />} />
              <Route path='/ppc-agency-for-distributors' element={<PaidMediaCaseStudy heading="Distributors" link="/ppc-agency-for-distributors" />} />
              <Route path='/ppc-agency-for-events' element={<PaidMediaCaseStudy heading="Events" link="/ppc-agency-for-events" />} />
              <Route path='/ppc-agency-for-fashion' element={<PaidMediaCaseStudy heading="Fashion" link="/ppc-agency-for-fashion" />} />
              <Route path='/ppc-agency-for-food-industry' element={<PaidMediaCaseStudy heading="Food Industry" link="/ppc-agency-for-food-industry" />} />
              <Route path='/ppc-agency-for-healthcare' element={<PaidMediaCaseStudy heading="Healthcare" link="/ppc-agency-for-healthcare" />} />
              <Route path='/ppc-agency-for-local-businesses' element={<PaidMediaCaseStudy heading="Local Businesses" link="/ppc-agency-for-local-businesses" />} />
              <Route path='/ppc-agency-for-manufacturers' element={<PaidMediaCaseStudy heading="Manufacturers" link="/ppc-agency-for-manufacturers" />} />
              <Route path='/ppc-agency-for-non-profit' element={<PaidMediaCaseStudy heading="Non Profit" link="/ppc-agency-for-non-profit" />} />
              <Route path='/ppc-agency-for-outdoor-companies' element={<PaidMediaCaseStudy heading="Outdoor Companies" link="/ppc-agency-for-outdoor-companies" />} />
              <Route path='/ppc-agency-for-suppliers' element={<PaidMediaCaseStudy heading="Suppliers" link="/ppc-agency-for-suppliers" />} />
              <Route path='/ppc-agency-for-travel' element={<PaidMediaCaseStudy heading="Travel" link="/ppc-agency-for-travel" />} />
              <Route path='/ppc-for-insurance-agency' element={<PaidMediaCaseStudy heading="Insurance Agency" link="/ppc-for-insurance-agency" />} />
              <Route path='/ppc-for-lawyers' element={<PaidMediaCaseStudy heading="Lawyers" link="/ppc-for-lawyers" />} />
              <Route path='/ppc-for-law-firms' element={<PaidMediaCaseStudy heading="Law Firms" link="/ppc-for-law-firms" />} />
              <Route path='/ppc-services-for-lawyers' element={<PaidMediaCaseStudy heading="Lawyers" link="/ppc-services-for-lawyers" />} />
              <Route path='/ppc-for-attorneys' element={<PaidMediaCaseStudy heading="Attorneys" link="/ppc-for-attorneys" />} />
              <Route path='/ppc-for-ecommerce' element={<PaidMediaCaseStudy heading="Ecommerce" link="/ppc-for-ecommerce" />} />
              <Route path='/ppc-for-manufacturers' element={<PaidMediaCaseStudy heading="Manufacturers" link="/ppc-for-manufacturers" />} />
              <Route path='/ppc-for-seo' element={<PaidMediaCaseStudy heading="SEO" link="/ppc-for-seo" />} />
              <Route path='/ppc-for-small-business' element={<PaidMediaCaseStudy heading="Small Business" link="/ppc-for-small-business" />} />
              <Route path='/ppc-for-apartments' element={<PaidMediaCaseStudy heading="Apartments" link="/ppc-for-apartments" />} />
              <Route path='/ppc-for-doctors' element={<PaidMediaCaseStudy heading="Doctors" link="/ppc-for-doctors" />} />
              <Route path='/ppc-for-hvac' element={<PaidMediaCaseStudy heading="HVAC" link="/ppc-for-hvac" />} />
              <Route path='/ppc-for-realtors' element={<PaidMediaCaseStudy heading="Realtors" link="/ppc-for-realtors" />} />
              <Route path='/ppc-for-financial-services' element={<PaidMediaCaseStudy heading="Financial Services" link="/ppc-for-financial-services" />} />
              <Route path='/ppc-for-hotels' element={<PaidMediaCaseStudy heading="Hotels" link="/ppc-for-hotels" />} />
              <Route path='/ppc-for-real-estate-investors' element={<PaidMediaCaseStudy heading="Real Estate Investors" link="/ppc-for-real-estate-investors" />} />
              <Route path='/ppc-for-roofers' element={<PaidMediaCaseStudy heading="Roofers" link="/ppc-for-roofers" />} />
              <Route path='/ppc-marketing-for-dentists' element={<PaidMediaCaseStudy heading="Dentists" link="/ppc-marketing-for-dentists" />} />
              <Route path='/ppc-ads-for-doctors' element={<PaidMediaCaseStudy heading="Doctors" link="/ppc-ads-for-doctors" />} />
              <Route path='/ppc-for-contractors' element={<PaidMediaCaseStudy heading="Contractors" link="/ppc-for-contractors" />} />
              <Route path='/ppc-for-manufacturing' element={<PaidMediaCaseStudy heading="Manufacturing" link="/ppc-for-manufacturing" />} />
              <Route path='/ppc-for-plumbers' element={<PaidMediaCaseStudy heading="Plumbers" link="/ppc-for-plumbers" />} />
              <Route path='/ppc-for-real-estate' element={<PaidMediaCaseStudy heading="Real Estate" link="/ppc-for-real-estate" />} />
              <Route path='/ppc-marketing-for-attorneys' element={<PaidMediaCaseStudy heading="Attorney's" link="/ppc-marketing-for-attorneys" />} />
              <Route path='/ppc-marketing-for-law-firms' element={<PaidMediaCaseStudy heading="Law Firms" link="/ppc-marketing-for-law-firms" />} />
              <Route path='/ppc-marketing-for-lawyers' element={<PaidMediaCaseStudy heading="Lawyers" link="/ppc-marketing-for-lawyers" />} />
              <Route path='/ppc-for-industrial-companies' element={<PaidMediaCaseStudy heading="Industrial Companies" link="/ppc-for-industrial-companies" />} />
              <Route path='/ppc-for-plastic-surgeons' element={<PaidMediaCaseStudy heading="Plastic Surgeons" link="/ppc-for-plastic-surgeons" />} />
              <Route path='/google-ppc-for-realtors' element={<PaidMediaCaseStudy heading="Google PPC For Realtors" link="/google-ppc-for-realtors" />} />
              <Route path='/ppc-ad-network-for-publishers' element={<PaidMediaCaseStudy heading="Ad Network For Publishers" link="/ppc-ad-network-for-publishers" />} />
              <Route path='/ppc-ads-for-dentists' element={<PaidMediaCaseStudy heading="Ads For Dentists" link="/ppc-ads-for-dentists" />} />
              <Route path='/ppc-ads-for-healthcare' element={<PaidMediaCaseStudy heading="Ads For Healthcare" link="/ppc-ads-for-healthcare" />} />
              <Route path='/ppc-advertising-for-healthcare' element={<PaidMediaCaseStudy heading="Advertising For Healthcare" link="/ppc-advertising-for-healthcare" />} />
              <Route path='/ppc-agency-for-attorneys' element={<PaidMediaCaseStudy heading="Agency For Attorneys" link="/ppc-agency-for-attorneys" />} />
              <Route path='/ppc-agency-for-dentists' element={<PaidMediaCaseStudy heading="Agency For Dentists" link="/ppc-agency-for-dentists" />} />
              <Route path='/ppc-agency-for-financial-services' element={<PaidMediaCaseStudy heading="Agency For Financial Services" link="/ppc-agency-for-financial-services" />} />
              <Route path='/ppc-agency-for-higher-education' element={<PaidMediaCaseStudy heading="Agency For Higher Education" link="/ppc-agency-for-higher-education" />} />
              <Route path='/ppc-agency-for-startups' element={<PaidMediaCaseStudy heading="Agency For Startups" link="/ppc-agency-for-startups" />} />
              <Route path='/ppc-campaigns-for-lawyers' element={<PaidMediaCaseStudy heading="Campaigns For Lawyers" link="/ppc-campaigns-for-lawyers" />} />
              <Route path='/ppc-company-for-movers' element={<PaidMediaCaseStudy heading="Company For Movers" link="/ppc-company-for-movers" />} />
              <Route path='/ppc-for-accountants' element={<PaidMediaCaseStudy heading="Accountants" link="/ppc-for-accountants" />} />
              <Route path='/ppc-for-addiction-treatment-centers' element={<PaidMediaCaseStudy heading="Addiction Treatment Centers" link="/ppc-for-addiction-treatment-centers" />} />
              <Route path='/ppc-for-automotive' element={<PaidMediaCaseStudy heading="Automotive" link="/ppc-for-automotive" />} />
              <Route path='/ppc-for-banks' element={<PaidMediaCaseStudy heading="Banks" link="/ppc-for-banks" />} />
              <Route path='/ppc-for-brand-awareness' element={<PaidMediaCaseStudy heading="Brand Awareness" link="/ppc-for-brand-awareness" />} />
              <Route path='/ppc-for-divorce-attorney' element={<PaidMediaCaseStudy heading="Divorce Attorney" link="/ppc-for-divorce-attorney" />} />
              <Route path='/ppc-for-divorce-lawyers' element={<PaidMediaCaseStudy heading="Divorce Lawyers" link="/ppc-for-divorce-lawyers" />} />
              <Route path='/ppc-for-drug-rehabs' element={<PaidMediaCaseStudy heading="Drug Rehabs" link="/ppc-for-drug-rehabs" />} />
              <Route path='/ppc-for-ecommerce-sites' element={<PaidMediaCaseStudy heading="Ecommerce Sites" link="/ppc-for-ecommerce-sites" />} />
              <Route path='/ppc-for-education' element={<PaidMediaCaseStudy heading="Education" link="/ppc-for-education" />} />
              <Route path='/ppc-for-estate-agents' element={<PaidMediaCaseStudy heading="Estate Agents" link="/ppc-for-estate-agents" />} />
              <Route path='/ppc-for-financial-advisors' element={<PaidMediaCaseStudy heading="Financial Advisors" link="/ppc-for-financial-advisors" />} />
              <Route path='/ppc-for-flooring-companies' element={<PaidMediaCaseStudy heading="Flooring Companies" link="/ppc-for-flooring-companies" />} />
              <Route path='/ppc-for-fmcg-brands' element={<PaidMediaCaseStudy heading="FMCG Brands" link="/ppc-for-fmcg-brands" />} />
              <Route path='/ppc-for-funeral-homes' element={<PaidMediaCaseStudy heading="Funeral Homes" link="/ppc-for-funeral-homes" />} />
              <Route path='/ppc-for-higher-education' element={<PaidMediaCaseStudy heading="Higher Education" link="/ppc-for-higher-education" />} />
              <Route path='/ppc-for-home-services' element={<PaidMediaCaseStudy heading="Home Services" link="/ppc-for-home-services" />} />
              <Route path='/ppc-for-hvac-companies' element={<PaidMediaCaseStudy heading="HVAC Companies" link="/ppc-for-hvac-companies" />} />
              <Route path='/ppc-for-insurance-brokers' element={<PaidMediaCaseStudy heading="Insurance Brokers" link="/ppc-for-insurance-brokers" />} />
              <Route path='/ppc-for-lead-generation' element={<PaidMediaCaseStudy heading="Lead Generation" link="/ppc-for-lead-generation" />} />
              <Route path='/ppc-for-local-business' element={<PaidMediaCaseStudy heading="Local Business" link="/ppc-for-local-business" />} />
              <Route path='/ppc-for-luxury-hotels' element={<PaidMediaCaseStudy heading="Luxury Hotels" link="/ppc-for-luxury-hotels" />} />
              <Route path='/ppc-for-moving-companies' element={<PaidMediaCaseStudy heading="Moving Companies" link="/ppc-for-moving-companies" />} />
              <Route path='/ppc-for-packaging' element={<PaidMediaCaseStudy heading="Packaging" link="/ppc-for-packaging" />} />
              <Route path='/ppc-for-plumbing-companies' element={<PaidMediaCaseStudy heading="Plumbing Companies" link="/ppc-for-plumbing-companies" />} />
              <Route path='/ppc-for-real-estate-agents' element={<PaidMediaCaseStudy heading="Real Estate Agents" link="/ppc-for-real-estate-agents" />} />
              <Route path='/ppc-for-recruiters' element={<PaidMediaCaseStudy heading="Recruiters" link="/ppc-for-recruiters" />} />
              <Route path='/ppc-for-rehab-centers' element={<PaidMediaCaseStudy heading="Rehab Centers" link="/ppc-for-rehab-centers" />} />
              <Route path='/ppc-for-restaurants' element={<PaidMediaCaseStudy heading="Restaurants" link="/ppc-for-restaurants" />} />
              <Route path='/ppc-for-retail' element={<PaidMediaCaseStudy heading="Retail" link="/ppc-for-retail" />} />
              <Route path='/ppc-for-self-storage' element={<PaidMediaCaseStudy heading="Self Storage" link="/ppc-for-self-storage" />} />
              <Route path='/ppc-keywords-for-real-estate-investors' element={<PaidMediaCaseStudy heading="Real Estate Investors" link="/ppc-keywords-for-real-estate-investors" />} />
              <Route path='/ppc-management-for-animal-removal' element={<PaidMediaCaseStudy heading="Animal Removal" link="/ppc-management-for-animal-removal" />} />
              <Route path='/ppc-management-for-automotive-industry' element={<PaidMediaCaseStudy heading="Automotive Industry" link="/ppc-management-for-automotive-industry" />} />
              <Route path='/ppc-management-for-pest-control-companies' element={<PaidMediaCaseStudy heading="Pest Control Companies" link="/ppc-management-for-pest-control-companies" />} />
              <Route path='/ppc-management-for-real-estate-investors' element={<PaidMediaCaseStudy heading="Real Estate Investors" link="/ppc-management-for-real-estate-investors" />} />
              <Route path='/ppc-management-for-wildlife-removal' element={<PaidMediaCaseStudy heading="Wildlife Removal" link="/ppc-management-for-wildlife-removal" />} />
              <Route path='/ppc-management-software-for-agencies' element={<PaidMediaCaseStudy heading="Management Software" link="/ppc-management-software-for-agencies" />} />
              <Route path='/ppc-marketing-for-contractors' element={<PaidMediaCaseStudy heading="Contractors" link="/ppc-marketing-for-contractors" />} />
              <Route path='/ppc-marketing-for-doctors' element={<PaidMediaCaseStudy heading="Doctors" link="/ppc-marketing-for-doctors" />} />
              <Route path='/ppc-marketing-for-higher-education' element={<PaidMediaCaseStudy heading="Higher Education" link="/ppc-marketing-for-higher-education" />} />
              <Route path='/ppc-marketing-for-insurance-agents' element={<PaidMediaCaseStudy heading="Insurance Agents" link="/ppc-marketing-for-insurance-agents" />} />
              <Route path='/ppc-marketing-for-pest-control-companies' element={<PaidMediaCaseStudy heading="Pest Control Companies" link="/ppc-marketing-for-pest-control-companies" />} />
              <Route path='/ppc-networks-for-publishers' element={<PaidMediaCaseStudy heading="Publishers" link="/ppc-networks-for-publishers" />} />
              <Route path='/ppc-services-for-law-firms' element={<PaidMediaCaseStudy heading="Law Firms" link="/ppc-services-for-law-firms" />} />
              <Route path='/ppc-services-for-pest-control-companies' element={<PaidMediaCaseStudy heading="Pest Control Companies" link="/ppc-services-for-pest-control-companies" />} />
              <Route path='/ppc-services-for-therapists' element={<PaidMediaCaseStudy heading="Therapists" link="/ppc-services-for-therapists" />} />
              <Route path='/ppc-software-for-agencies' element={<PaidMediaCaseStudy heading="Software" link="/ppc-software-for-agencies" />} />
              <Route path='/google-ppc-for-aviation' element={<PaidMediaCaseStudy heading="Google Aviation" link="/google-ppc-for-aviation" />} />
              <Route path='/ppc-ads-for-portable-storage' element={<PaidMediaCaseStudy heading="Portable Storage" link="/ppc-ads-for-portable-storage" />} />
              <Route path='/ppc-advertising-for-auto-shop' element={<PaidMediaCaseStudy heading="Auto Shop" link="/ppc-advertising-for-auto-shop" />} />
              <Route path='/ppc-advertising-for-contractors' element={<PaidMediaCaseStudy heading="Contractors" link="/ppc-advertising-for-contractors" />} />
              <Route path='/ppc-for-appliance-repair' element={<PaidMediaCaseStudy heading="Appliance Repair" link="/ppc-for-appliance-repair" />} />
              <Route path='/ppc-for-auto-dealers' element={<PaidMediaCaseStudy heading="Auto Dealers" link="/ppc-for-auto-dealers" />} />
              <Route path='/ppc-for-auto-dealerships' element={<PaidMediaCaseStudy heading="Auto Dealerships" link="/ppc-for-auto-dealerships" />} />
              <Route path='/ppc-for-banking' element={<PaidMediaCaseStudy heading="Banking" link="/ppc-for-banking" />} />
              <Route path='/ppc-for-business-consultants' element={<PaidMediaCaseStudy heading="Business Consultants" link="/ppc-for-business-consultants" />} />
              <Route path='/ppc-for-charities' element={<PaidMediaCaseStudy heading="Charities" link="/ppc-for-charities" />} />
              <Route path='/ppc-for-construction-companies' element={<PaidMediaCaseStudy heading="Construction Companies" link="/ppc-for-construction-companies" />} />
              <Route path='/ppc-for-dealerships' element={<PaidMediaCaseStudy heading="Dealerships" link="/ppc-for-dealerships" />} />
              <Route path='/ppc-for-distribution-companies' element={<PaidMediaCaseStudy heading="Distribution Companies" link="/ppc-for-distribution-companies" />} />
              <Route path='/ppc-for-financial-planners' element={<PaidMediaCaseStudy heading="Financial Planners" link="/ppc-for-financial-planners" />} />
              <Route path='/ppc-for-gyms' element={<PaidMediaCaseStudy heading="Gyms" link="/ppc-for-gyms" />} />
              <Route path='/ppc-for-home-builders' element={<PaidMediaCaseStudy heading="Home Builders" link="/ppc-for-home-builders" />} />
              <Route path='/ppc-for-hvac-contractor' element={<PaidMediaCaseStudy heading="HVAC Contractor" link="/ppc-for-hvac-contractor" />} />
              <Route path='/ppc-for-locksmiths' element={<PaidMediaCaseStudy heading="Locksmiths" link="/ppc-for-locksmiths" />} />
              <Route path='/ppc-for-mattress-company' element={<PaidMediaCaseStudy heading="Mattress Company" link="/ppc-for-mattress-company" />} />
              <Route path='/ppc-for-painters' element={<PaidMediaCaseStudy heading="Painters" link="/ppc-for-painters" />} />
              <Route path='/ppc-for-pest-control' element={<PaidMediaCaseStudy heading="Pest Control" link="/ppc-for-pest-control" />} />
              <Route path='/ppc-for-plumbing-company' element={<PaidMediaCaseStudy heading="Plumbing Company" link="/ppc-for-plumbing-company" />} />
              <Route path='/ppc-for-roofer' element={<PaidMediaCaseStudy heading="Roofer" link="/ppc-for-roofer" />} />
              <Route path='/ppc-for-schools' element={<PaidMediaCaseStudy heading="Schools" link="/ppc-for-schools" />} />
              <Route path='/ppc-for-senior-living' element={<PaidMediaCaseStudy heading="Senior Living" link="/ppc-for-senior-living" />} />
              <Route path='/ppc-for-small-businesses' element={<PaidMediaCaseStudy heading="Small Businesses" link="/ppc-for-small-businesses" />} />
              <Route path='/ppc-for-therapists' element={<PaidMediaCaseStudy heading="Therapists" link="/ppc-for-therapists" />} />
              <Route path='/ppc-for-tile-service' element={<PaidMediaCaseStudy heading="Tile Service" link="/ppc-for-tile-service" />} />
              <Route path='/ppc-for-travel-industry' element={<PaidMediaCaseStudy heading="Travel Industry" link="/ppc-for-travel-industry" />} />
              <Route path='/ppc-for-universities' element={<PaidMediaCaseStudy heading="Universities" link="/ppc-for-universities" />} />
              <Route path='/ppc-for-veterinarians' element={<PaidMediaCaseStudy heading="Veterinarians" link="/ppc-for-veterinarians" />} />
              <Route path='/ppc-for-wedding-venues' element={<PaidMediaCaseStudy heading="Wedding Venues" link="/ppc-for-wedding-venues" />} />
              <Route path='/ppc-for-weight-loss' element={<PaidMediaCaseStudy heading="Weight Loss" link="/ppc-for-weight-loss" />} />
              <Route path='/ppc-for-window-contractors' element={<PaidMediaCaseStudy heading="Window Contractors" link="/ppc-for-window-contractors" />} />
              <Route path='/ppc-management-for-insurance-brokers' element={<PaidMediaCaseStudy heading="Insurance Brokers" link="/ppc-management-for-insurance-brokers" />} />
              <Route path='/ppc-marketing-for-airlines' element={<PaidMediaCaseStudy heading="Airlines" link="/ppc-marketing-for-airlines" />} />
              <Route path='/ppc-marketing-for-landscaping-companies' element={<PaidMediaCaseStudy heading="Landscaping Companies" link="/ppc-marketing-for-landscaping-companies" />} />
              <Route path='/ppc-marketing-for-medical-clinics' element={<PaidMediaCaseStudy heading="Medical Clinics" link="/ppc-marketing-for-medical-clinics" />} />
              <Route path='/ppc-marketing-for-medical-professionals' element={<PaidMediaCaseStudy heading="Medical Professionals" link="/ppc-marketing-for-medical-professionals" />} />
              <Route path='/ppc-marketing-for-moving-companies' element={<PaidMediaCaseStudy heading="Moving Companies" link="/ppc-marketing-for-moving-companies" />} />
              <Route path='/ppc-marketing-for-painting-companies' element={<PaidMediaCaseStudy heading="Painting Companies" link="/ppc-marketing-for-painting-companies" />} />
              <Route path='/ppc-services-for-counselors' element={<PaidMediaCaseStudy heading="Counselors" link="/ppc-services-for-counselors" />} />



              {/* Location */}
              <Route path='/locations' element={<Locations />} />

              {/* Locations pages   */}
              <Route path='/locations/leeds-seo-agency' element={<Location heading="SEO Agency Leeds" type="one" link="leeds-seo-agency" subtitle="Leeds" />} />
              <Route path='/locations/newcastle-seo-agency' element={<Location heading="SEO Agency Newcastle" type="one" link="newcastle-seo-agency" subtitle="Newcastle" />} />
              <Route path='/locations/bath-seo-agency' element={<Location heading="SEO Agency Bath" type="one" link="bath-seo-agency" subtitle="Bath" />} />
              <Route path='/locations/bend-oregon-seo-agency' element={<Location heading="SEO Agency Bend Oregon" type="one" link="bend-oregon-seo-agency" subtitle="Bend Oregon" />} />
              <Route path='/locations/blackpool-seo-agency' element={<Location heading="SEO Agency Blackpool" type="one" link="blackpool-seo-agency" subtitle="Blackpool" />} />
              <Route path='/locations/darwin-seo-agency' element={<Location heading="SEO Agency Darwin" type="one" link="darwin-seo-agency" subtitle="Darwin" />} />
              <Route path='/locations/fort-worth-seo-agency' element={<Location heading="SEO Agency Fort Worth" type="one" link="fort-worth-seo-agency" subtitle="Fort Worth" />} />
              <Route path='/locations/gold-coast-seo-agency' element={<Location heading="SEO Agency Gold Coast" type="one" link="gold-coast-seo-agency" subtitle="Gold Coast" />} />
              <Route path='/locations/hertfordshire-seo-agency' element={<Location heading="SEO Agency Hertfordshire" type="one" link="hertfordshire-seo-agency" subtitle="Hertfordshire" />} />
              <Route path='/locations/hobart-seo-agency' element={<Location heading="SEO Agency Hobart" type="one" link="hobart-seo-agency" subtitle="Hobart" />} />
              <Route path='/locations/huddersfield-seo-agency' element={<Location heading="SEO Agency Huddersfield" type="one" link="huddersfield-seo-agency" subtitle="Huddersfield" />} />
              <Route path='/locations/northampton-seo-agency' element={<Location heading="SEO Agency Northampton" type="one" link="northampton-seo-agency" subtitle="Northampton" />} />
              <Route path='/locations/peterborough-seo-agency' element={<Location heading="SEO Agency Peterborough" type="one" link="peterborough-seo-agency" subtitle="Peterborough" />} />
              <Route path='/locations/preston-seo-agency' element={<Location heading="SEO Agency Preston" type="one" link="preston-seo-agency" subtitle="Preston" />} />
              <Route path='/locations/geelong-seo-agency' element={<Location heading="SEO Agency Geelong" type="one" link="geelong-seo-agency" subtitle="Geelong" />} />
              <Route path='/locations/guildford-seo-agency' element={<Location heading="SEO Agency Guildford" type="one" link="guildford-seo-agency" subtitle="Guildford" />} />
              <Route path='/locations/ottawa-seo-agency' element={<Location heading="SEO Agency Ottawa" type="one" link="ottawa-seo-agency" subtitle="Ottawa" />} />
              <Route path='/locations/kl-seo-agency' element={<Location heading="SEO Agency KL" type="one" link="kl-seo-agency" subtitle="KL" />} />
              <Route path='/locations/pasadena-seo-agency' element={<Location heading="SEO Agency Pasadena" type="one" link="pasadena-seo-agency" subtitle="Pasadena" />} />
              <Route path='/locations/chelmsford-seo-agency' element={<Location heading="SEO Agency Chelmsford" type="one" link="chelmsford-seo-agency" subtitle="Chelmsford" />} />
              {/* Type two */}
              <Route path='/locations/exeter-seo-agency' element={<Location heading="SEO Agency Exeter" type="one" link="exeter-seo-agency" subtitle="Exeter" />} />
              <Route path='/locations/doncaster-seo-agency' element={<Location heading="SEO Agency Doncaster" type="one" link="doncaster-seo-agency" subtitle="Doncaster" />} />
              <Route path='/locations/leicester-seo-agency' element={<Location heading="SEO Agency Leicester" type="one" link="leicester-seo-agency" subtitle="Leicester" />} />
              <Route path='/locations/memphis-seo-agency' element={<Location heading="SEO Agency Memphis" type="one" link="memphis-seo-agency" subtitle="Memphis" />} />
              <Route path='/locations/mesa-az-seo-agency' element={<Location heading="SEO Agency Mesa AZ" type="one" link="mesa-az-seo-agency" subtitle="Mesa AZ" />} />
              <Route path='/locations/mississauga-seo-agency' element={<Location heading="SEO Agency Mississauga" type="one" link="mississauga-seo-agency" subtitle="Mississauga" />} />
              <Route path='/locations/newcastle-upon-tyne-seo-agency' element={<Location heading="SEO Agency Newcastle upon Tyne" type="one" link="newcastle-upon-tyne-seo-agency" subtitle="Newcastle upon Tyne" />} />
              <Route path='/locations/oxford-seo-agency' element={<Location heading="SEO Agency Oxford" type="one" link="oxford-seo-agency" subtitle="Oxford" />} />
              <Route path='/locations/reading-seo-agency' element={<Location heading="SEO Agency Reading" type="one" link="reading-seo-agency" subtitle="Reading" />} />
              <Route path='/locations/california-seo-agency' element={<Location heading="SEO Agency California" type="one" link="california-seo-agency" subtitle="California" />} />
              <Route path='/locations/florida-seo-agency' element={<Location heading="SEO Agency Florida" type="one" link="florida-seo-agency" subtitle="Florida" />} />
              <Route path='/locations/yorkshire-seo-agency' element={<Location heading="SEO Agency Yorkshire" type="one" link="yorkshire-seo-agency" subtitle="Yorkshire" />} />
              <Route path='/locations/texas-seo-agency' element={<Location heading="SEO Agency Texas" type="one" link="texas-seo-agency" subtitle="Texas" />} />
              <Route path='/locations/thailand-seo-agency' element={<Location heading="SEO Agency Thailand" type="one" link="thailand-seo-agency" subtitle="Thailand" />} />
              <Route path='/locations/west-sussex-seo-agency' element={<Location heading="SEO Agency West Sussex" type="one" link="west-sussex-seo-agency" subtitle="West Sussex" />} />
              <Route path='/locations/singapore-seo-agency' element={<Location heading="Best SEO Agency Singapore" type="one" link="singapore-seo-agency" subtitle="Singapore" />} />
              <Route path='/locations/sydney-seo-agency' element={<Location heading="Best SEO Agency Sydney" type="one" link="sydney-seo-agency" subtitle="Sydney" />} />
              <Route path='/locations/wiltshire-seo-agency' element={<Location heading="SEO Agency Wiltshire" type="one" link="wiltshire-seo-agency" subtitle="Wiltshire" />} />
              <Route path='/locations/worthing-seo-agency' element={<Location heading="SEO Agency Worthing" type="one" link="worthing-seo-agency" subtitle="Worthing" />} />
              {/* type three no location to be added in links */}
              <Route path='/dubai-seo-agency' element={<Location heading="SEO Agency Dubai" type="two" link="dubai-seo-agency" subtitle="Dubai" />} />
              <Route path='/french-seo-agency' element={<Location heading="French SEO Agency " type="two" link="french-seo-agency" subtitle="French" />} />
              <Route path='/los-angeles-seo-agency' element={<Location heading="SEO Agency Los Angeles" type="two" link="los-angeles-seo-agency" subtitle="Los Angeles" />} />
              <Route path='/birmingham-seo-agency' element={<Location heading="SEO Agency Birmingham" type="two" link="birmingham-seo-agency" subtitle="Birmingham" />} />
              <Route path='/chicago-seo-agency' element={<Location heading="SEO Agency Chicago" type="two" link="chicago-seo-agency" subtitle="Chicago" />} />
              <Route path='/liverpool-seo-agency' element={<Location heading="SEO Agency Liverpool" type="two" link="liverpool-seo-agency" subtitle="Liverpool" />} />
              <Route path='/houston-seo-agency' element={<Location heading="SEO Agency Houston" type="two" link="houston-seo-agency" subtitle="Houston" />} />
              <Route path='/miami-seo-agency' element={<Location heading="SEO Agency Miami" type="two" link="miami-seo-agency" subtitle="Miami" />} />
              <Route path='/UK-seo-agency' element={<Location heading="SEO Agency UK" type="two" link="UK-seo-agency" subtitle="UK" />} />
              <Route path='/san-francisco-seo-agency' element={<Location heading="SEO Agency San Francisco" type="two" link="san-francisco-seo-agency" subtitle="San Francisco" />} />
              <Route path='/colchester-seo-agency' element={<Location heading="SEO Agency Colchester Essex" type="two" link="colchester-seo-agency" subtitle="Colchester Essex" />} />
              <Route path='/vancouver-seo-agency' element={<Location heading="SEO Agency Vancouver" type="two" link="vancouver-seo-agency" subtitle="Vancouver" />} />
              <Route path='/melbourne-seo-agency' element={<Location heading="SEO Agency Melbourne" type="two" link="melbourne-seo-agency" subtitle="Melbourne" />} />
              <Route path='/seattle-seo-agency' element={<Location heading="SEO Agency Seattle" type="two" link="seattle-seo-agency" subtitle="Seattle" />} />
              <Route path='/boston-seo-agency' element={<Location heading="SEO Agency Boston" type="two" link="boston-seo-agency" subtitle="Boston" />} />
              <Route path='/australia-seo-agency' element={<Location heading="SEO Agency Australia" type="two" link="australia-seo-agency" subtitle="Australia" />} />
              <Route path='/canada-seo-agency' element={<Location heading="SEO Agency Canada" type="two" link="canada-seo-agency" subtitle="Canada" />} />
              <Route path='/virginia-seo-agency' element={<Location heading="SEO Agency Virginia" type="two" link="virginia-seo-agency" subtitle="Virginia" />} />
              <Route path='/london-seo-agency' element={<Location heading="Best SEO Agency London" type="two" link="london-seo-agency" subtitle="London" />} />
              <Route path='/washington-DC-seo-agency' element={<Location heading="SEO Agency Washington DC" type="two" link="washington-DC-seo-agency" subtitle="Washington DC" />} />
              <Route path='/new-york-seo-agency' element={<Location heading="Best SEO Agency New York" type="two" link="new-york-seo-agency" subtitle="New York" />} />
              <Route path='/bristol-seo-agency' element={<Location heading="Best SEO Agency Bristol" type="two" link="bristol-seo-agency" subtitle="Bristol" />} />
              <Route path='/edinburgh-seo-agency' element={<Location heading="SEO Agency Edinburgh" type="two" link="edinburgh-seo-agency" subtitle="Edinburgh" />} />
              <Route path='/lancashire-seo-agency' element={<Location heading="SEO Agency Lancashire" type="two" link="lancashire-seo-agency" subtitle="Lancashire" />} />
              <Route path='/brighton-seo-agency' element={<Location heading="Top SEO Agency Brighton" type="two" link="brighton-seo-agency" subtitle="Brighton" />} />
              <Route path='/USA-seo-agency' element={<Location heading="Best SEO Agency USA" type="two" link="best-seo-agency-usa" subtitle="USA" />} />
              <Route path='/calgary-seo-agency' element={<Location heading="SEO Agency Calgary" type="two" link="calgary-seo-agency" subtitle="Calgary" />} />
              <Route path='/jacksonville-seo-agency' element={<Location heading="SEO Agency Jacksonville" type="two" link="jacksonville-seo-agency" subtitle="Jacksonville" />} />
              <Route path='/nottingham-seo-agency' element={<Location heading="SEO Agency Nottingham" type="two" link="nottingham-seo-agency" subtitle="Nottingham" />} />
              <Route path='/hornchurch-seo-agency' element={<Location heading="SEO Agency Hornchurch" type="two" link="hornchurch-seo-agency" subtitle="Hornchurch" />} />
              {/*  */}
              <Route path='/locations/philadelphia-seo-agency' element={<Location heading="SEO Agency Philadelphia" type="one" link="philadelphia-seo-agency" subtitle="Philadelphia" />} />
              <Route path='/locations/austin-seo-agency' element={<Location heading="SEO Agency Austin" type="one" link="austin-seo-agency" subtitle="Austin" />} />
              <Route path='/locations/denver-seo-agency' element={<Location heading="SEO Agency Denver" type="one" link="denver-seo-agency" subtitle="Denver" />} />
              <Route path='/locations/abu-dhabi-seo-agency' element={<Location heading="SEO Agency Abu Dhabi" type="one" link="abu-dhabi-seo-agency" subtitle="Abu Dhabi" />} />
              <Route path='/locations/adelaide-seo-agency' element={<Location heading="SEO Agency Adelaide" type="one" link="adelaide-seo-agency" subtitle="Adelaide" />} />
              <Route path='/locations/edmonton-seo-agency' element={<Location heading="SEO Agency Edmonton" type="one" link="edmonton-seo-agency" subtitle="Edmonton" />} />
              <Route path='/locations/perth-seo-agency' element={<Location heading="SEO Agency In Perth" type="one" link="perth-seo-agency" subtitle="Perth" />} />
              <Route path='/locations/toronto-seo-agency' element={<Location heading="SEO Agency In Toronto" type="one" link="toronto-seo-agency" subtitle="Toronto" />} />
              <Route path='/locations/auckland-seo-agency' element={<Location heading="SEO Agency Auckland" type="one" link="auckland-seo-agency" subtitle="Auckland" />} />
              <Route path='/locations/san-jose-seo-agency' element={<Location heading="SEO Agency In San Jose" type="one" link="san-jose-seo-agency" subtitle="San Jose" />} />
              <Route path='/locations/las-vegas-seo-agency' element={<Location heading="SEO Agency In Las Vegas" type="one" link="las-vegas-seo-agency" subtitle="Las Vegas" />} />
              <Route path='/locations/new-zealand-seo-agency' element={<Location heading="SEO Agency In New Zealand" type="one" link="new-zealand-seo-agency" subtitle="New Zealand" />} />
              <Route path='/locations/phoenix-seo-agency' element={<Location heading="SEO Agency Phoenix" type="one" link="phoenix-seo-agency" subtitle="Phoenix" />} />
              <Route path='/locations/utah-seo-agency' element={<Location heading="SEO Agency Utah" type="one" link="utah-seo-agency" subtitle="Utah" />} />
              <Route path='/locations/brooklyn-seo-agency' element={<Location heading="Brooklyn SEO Agency" type="one" link="brooklyn-seo-agency" subtitle="Brooklyn" />} />
              <Route path='/locations/buffalo-seo-agency' element={<Location heading="Buffalo SEO Agency" type="one" link="buffalo-seo-agency" subtitle="Buffalo" />} />
              <Route path='/locations/online-seo-agency' element={<Location heading="Online SEO Agency" type="one" link="online-seo-agency" subtitle="Online" />} />
              <Route path='/locations/outsourcing-seo-agency' element={<Location heading="Outsourcing SEO Agency" type="one" link="outsourcing-seo-agency" subtitle="Outsourcing" />} />
              <Route path='/locations/bolton-seo-agency' element={<Location heading="SEO Agency Bolton" type="one" link="bolton-seo-agency" subtitle="Bolton" />} />
              <Route path='/locations/charleston-sc-seo-agency' element={<Location heading="SEO Agency Charleston SC" type="one" link="charleston-sc-seo-agency" subtitle="Charleston SC" />} />
              <Route path='/locations/devon-seo-agency' element={<Location heading="SEO Agency Devon" type="one" link="devon-seo-agency" subtitle="Devon" />} />
              <Route path='/locations/bognor-regis-seo-agency' element={<Location heading="SEO Agency In Bognor Regis" type="one" link="bognor-regis-seo-agency" subtitle="Bognor Regis" />} />
              <Route path='/locations/geelong-seo-agency' element={<Location heading="SEO Agency In Geelong" type="one" link="geelong-seo-agency" subtitle="Geelong" />} />
              <Route path='/locations/hampshire-seo-agency' element={<Location heading="SEO Agency In Hampshire" type="one" link="hampshire-seo-agency" subtitle="Hampshire" />} />
              <Route path='/locations/san-diego-seo-agency' element={<Location heading="SEO Agency San Diego" type="one" link="san-diego-seo-agency" subtitle="San Diego" />} />
              <Route path='/locations/swindon-seo-agency' element={<Location heading="SEO Agency Swindon" type="one" link="swindon-seo-agency" subtitle="Swindon" />} />
              <Route path='/locations/switzerland-seo-agency' element={<Location heading="SEO Agency Switzerland" type="one" link="switzerland-seo-agency" subtitle="Switzerland" />} />
              <Route path='/locations/scotland-seo-agency' element={<Location heading="SEO Agency Scotland" type="one" link="scotland-seo-agency" subtitle="Scotland" />} />
              <Route path='/locations/sheffield-seo-agency' element={<Location heading="SEO Agency Sheffield" type="one" link="sheffield-seo-agency" subtitle="Sheffield" />} />
              <Route path='/locations/shoreditch-seo-agency' element={<Location heading="SEO Agency Shoreditch" type="one" link="shoreditch-seo-agency" subtitle="Shoreditch" />} />
              <Route path='/locations/slough-seo-agency' element={<Location heading="SEO Agency Slough" type="one" link="slough-seo-agency" subtitle="Slough" />} />
              <Route path='/locations/swansea-seo-agency' element={<Location heading="SEO Agency In Swansea" type="one" link="swansea-seo-agency" subtitle="Swansea" />} />
              <Route path='/locations/manchester-seo-agency' element={<Location heading="SEO Agency In Manchester" type="one" link="manchester-seo-agency" subtitle="Manchester" />} />
              <Route path='/locations/cambridge-seo-agency' element={<Location heading="SEO Agency In Cambridge" type="one" link="cambridge-seo-agency" subtitle="Cambridge" />} />
              <Route path='/locations/portsmouth-seo-agency' element={<Location heading="SEO Agency In Portsmouth" type="one" link="portsmouth-seo-agency" subtitle="Portsmouth" />} />
              <Route path='/locations/norwich-seo-agency' element={<Location heading="SEO Agency In Norwich" type="one" link="norwich-seo-agency" subtitle="Norwich" />} />
              <Route path='/locations/cheltenham-seo-agency' element={<Location heading="SEO Agency In Cheltenham" type="one" link="cheltenham-seo-agency" subtitle="Cheltenham" />} />
              <Route path='/locations/berkshire-seo-agency' element={<Location heading="SEO Agency In Berkshire" type="one" link="berkshire-seo-agency" subtitle="Berkshire" />} />
              <Route path='/locations/maidstone-seo-agency' element={<Location heading="SEO Agency In Maidstone" type="one" link="maidstone-seo-agency" subtitle="Maidstone" />} />
              <Route path='/locations/aylesbury-seo-agency' element={<Location heading="SEO Agency In Aylesbury" type="one" link="aylesbury-seo-agency" subtitle="Aylesbury" />} />
              <Route path='/locations/worcester-seo-agency' element={<Location heading="SEO Agency In Worcester" type="one" link="worcester-seo-agency" subtitle="Worcester" />} />
              <Route path='/locations/belfast-seo-agency' element={<Location heading="SEO Agency In Belfast" type="one" link="belfast-seo-agency" subtitle="Belfast" />} />
              <Route path='/locations/cardiff-seo-agency' element={<Location heading="SEO Agency In Cardiff" type="one" link="cardiff-seo-agency" subtitle="Cardiff" />} />
              <Route path='/locations/bradford-seo-agency' element={<Location heading="SEO Agency In Bradford" type="one" link="bradford-seo-agency" subtitle="Bradford" />} />
              <Route path='/locations/glasgow-seo-agency' element={<Location heading="SEO Agency In Glasgow" type="one" link="glasgow-seo-agency" subtitle="Glasgow" />} />
              <Route path='/locations/aberdeen-seo-agency' element={<Location heading="SEO Agency In Aberdeen" type="one" link="aberdeen-seo-agency" subtitle="Aberdeen" />} />
              <Route path='/locations/luton-seo-agency' element={<Location heading="SEO Agency In Luton" type="one" link="luton-seo-agency" subtitle="Luton" />} />
              <Route path='/locations/southampton-seo-agency' element={<Location heading="SEO Agency In Southampton" type="one" link="southampton-seo-agency" subtitle="Southampton" />} />
              <Route path='/locations/st-albans-seo-agency' element={<Location heading="SEO Agency In St Albans" type="one" link="st-albans-seo-agency" subtitle="St Albans" />} />
              <Route path='/locations/bedford-seo-agency' element={<Location heading="SEO Agency In Bedford" type="one" link="bedford-seo-agency" subtitle="Bedford" />} />
              <Route path='/locations/coventry-seo-agency' element={<Location heading="SEO Agency In Coventry" type="one" link="coventry-seo-agency" subtitle="Coventry" />} />
              <Route path='/locations/buckinghamshire-seo-agency' element={<Location heading="SEO Agency In Buckinghamshire" type="one" link="buckinghamshire-seo-agency" subtitle="Buckinghamshire" />} />
              <Route path='/locations/milton-keynes-seo-agency' element={<Location heading="SEO Agency In Milton Keynes" type="one" link="milton-keynes-seo-agency" subtitle="Milton Keynes" />} />
              <Route path='/locations/wokingham-seo-agency' element={<Location heading="SEO Agency In Wokingham" type="one" link="wokingham-seo-agency" subtitle="Wokingham" />} />
              <Route path='/locations/basingstoke-seo-agency' element={<Location heading="SEO Agency In Basingstoke" type="one" link="basingstoke-seo-agency" subtitle="Basingstoke" />} />
              <Route path='/locations/high-wycombe-seo-agency' element={<Location heading="SEO Agency In High Wycombe" type="one" link="high-wycombe-seo-agency" subtitle="High Wycombe" />} />

              {/* Rediection of pages */}
              {/* Redirect from /old-page to /new-page */}
              <Route path="/locations/dubai-seo-agency" element={<Navigate to="/dubai-seo-agency" />} />
              {/* <Route path="/industries/fitness/" element={<Navigate to="/industries/fitness" />} />
              <Route path="/industries/insurance/" element={<Navigate to="/industries/insurance" />} />
              <Route path="/industries/government-agency/" element={<Navigate to="/industries/government-agency" />} />
              <Route path="/seo-services/technical-seo/" element={<Navigate to="/seo-services/technical-seo" />} />
              <Route path="/design-development/b2b-web-design/" element={<Navigate to="/design-development/b2b-web-design" />} />
              <Route path="/industries/finance/" element={<Navigate to="/industries/finance" />} />
              <Route path="/seo-services/youtube-seo/" element={<Navigate to="/seo-services/youtube-seo" />} />
              <Route path="/industries/recreation/" element={<Navigate to="/industries/recreation" />} />
              <Route path="/industries/medical-health/" element={<Navigate to="/industries/medical-health" />} />
              <Route path="/seo-services/seo-consultancy/" element={<Navigate to="/seo-services/seo-consultancy" />} />
              <Route path="/ppc-services/ppc-management-services/" element={<Navigate to="/ppc-services/ppc-management-services" />} />
              <Route path="/seo-services/nationwide-seo/" element={<Navigate to="/seo-services/nationwide-seo" />} />
              <Route path="/seo-services/small-business-seo/" element={<Navigate to="/seo-services/small-business-seo" />} />
              <Route path="/seo-for/window-cleaning-services/" element={<Navigate to="/seo-for/window-cleaning-services" />} />
              <Route path="/seo-services/luxury-seo/" element={<Navigate to="/seo-services/luxury-seo" />} />
              <Route path="/seo-services/link-building-services/" element={<Navigate to="/seo-services/link-building-services" />} />
              <Route path="/design-development/ecommerce-web-design/" element={<Navigate to="/design-development/ecommerce-web-design" />} />
              <Route path="/industries/heavy-equipment/" element={<Navigate to="/industries/heavy-equipment" />} />
              <Route path="/seo-services/" element={<Navigate to="/seo-services" />} />
              <Route path="/seo-services/ecommerce-seo/" element={<Navigate to="/seo-services/ecommerce-seo" />} />
              <Route path="/services/" element={<Navigate to="/services" />} />
              <Route path="/ppc-services/youtube-advertising/" element={<Navigate to="/ppc-services/youtube-advertising" />} />
              <Route path="/design-development/responsive-web-design/" element={<Navigate to="/design-development/responsive-web-design" />} />
              <Route path="/ppc-services/linkedin-marketing/" element={<Navigate to="/ppc-services/linkedin-marketing" />} />
              <Route path="/seo-services/shopify-seo/" element={<Navigate to="/seo-services/shopify-seo" />} />
              <Route path="/design-development/website-hosting/" element={<Navigate to="/design-development/website-hosting" />} />
              <Route path="/industries/retail-ecommerce/" element={<Navigate to="/industries/retail-ecommerce" />} />
              <Route path="/content-marketing-services/" element={<Navigate to="/content-marketing-services" />} />
              <Route path="/industries/industrial/" element={<Navigate to="/industries/industrial" />} />
              <Route path="/industries/food-beverage/" element={<Navigate to="/industries/food-beverage" />} />
              <Route path="/ppc-services/pinterest-marketing/" element={<Navigate to="/ppc-services/pinterest-marketing" />} />
              <Route path="/industries/logistics/" element={<Navigate to="/industries/logistics" />} />
              <Route path="/ppc-services/twitter-marketing/" element={<Navigate to="/ppc-services/twitter-marketing" />} />
              <Route path="/design-development/website-redesign/" element={<Navigate to="/design-development/website-redesign" />} />
              <Route path="/ppc-services/google-ads-marketing/" element={<Navigate to="/ppc-services/google-ads-marketing" />} />
              <Route path="/seo-services/backlink-audit/" element={<Navigate to="/seo-services/backlink-audit" />} />
              <Route path="/content-marketing-services/product-descriptions/" element={<Navigate to="/content-marketing-services/product-descriptions" />} />
              <Route path="/design-development/website-optimization-services/" element={<Navigate to="/design-development/website-optimization-services" />} />
              <Route path="/seo-services/celebrity-seo/" element={<Navigate to="/seo-services/celebrity-seo" />} />
              <Route path="/industries/education/" element={<Navigate to="/industries/education" />} />
              <Route path="/ppc-services/quora-marketing/" element={<Navigate to="/ppc-services/quora-marketing" />} />
              <Route path="/seo-services/on-page-seo/" element={<Navigate to="/seo-services/on-page-seo" />} />
              <Route path="/design-development/landing-pages-design/" element={<Navigate to="/design-development/landing-pages-design" />} />
              <Route path="/ppc-services/facebook-advertising/" element={<Navigate to="/ppc-services/facebook-advertising" />} />
              <Route path="/industries/real-estate/" element={<Navigate to="/industries/real-estate" />} />
              <Route path="/design-development/shopify-web-design/" element={<Navigate to="/design-development/shopify-web-design" />} />
              <Route path="/industries/" element={<Navigate to="/industries" />} />
              <Route path="/digital-marketing-for-grand-rapids/" element={<Navigate to="/digital-marketing-for-grand-rapids" />} />
              <Route path="/seo-services/seo-keywords-research/" element={<Navigate to="/seo-services/seo-keywords-research" />} />
              <Route path="/industries/tourism-hospitality/" element={<Navigate to="/industries/tourism-hospitality" />} />
              <Route path="/seo-services/mobile-seo/" element={<Navigate to="/seo-services/mobile-seo" />} />
              <Route path="/seo-services/local-seo/" element={<Navigate to="/seo-services/local-seo" />} />
              <Route path="/ppc-services/instagram-advertising/" element={<Navigate to="/ppc-services/instagram-advertising" />} />
              <Route path="/design-development/" element={<Navigate to="/design-development" />} />
              <Route path="/seo-services/seo-audits/" element={<Navigate to="/seo-services/seo-audits" />} />
              <Route path="/industries/franchises/" element={<Navigate to="/industries/franchises" />} />
              <Route path="/design-development/website-maintenance/" element={<Navigate to="/design-development/website-maintenance" />} />
              <Route path="/industries/fashion/" element={<Navigate to="/industries/fashion" />} />
              <Route path="/content-marketing-services/web-copy-writing/" element={<Navigate to="/content-marketing-services/web-copy-writing" />} />
              <Route path="/content-marketing-services/seo-content-writing/" element={<Navigate to="/content-marketing-services/seo-content-writing" />} />
              <Route path="/content-marketing-services/blog-writing/" element={<Navigate to="/content-marketing-services/blog-writing" />} />
              <Route path="/seo-services/google-analytics-services/" element={<Navigate to="/seo-services/google-analytics-services" />} />
              <Route path="/industries/automotive/" element={<Navigate to="/industries/automotive" />} />
              <Route path="/ppc-services/whatsapp-marketing/" element={<Navigate to="/ppc-services/whatsapp-marketing" />} />
              <Route path="/industries/manufacturing/" element={<Navigate to="/industries/manufacturing" />} />
              <Route path="/about-us/" element={<Navigate to="/about-us" />} />
              <Route path="/about" element={<Navigate to="/about-us" />} />
              <Route path="/seo-services/international-seo/" element={<Navigate to="/seo-services/international-seo" />} />
              <Route path="/industries/local-consumer/" element={<Navigate to="/industries/local-consumer" />} />
              <Route path="/design-development/wordpress-web-design/" element={<Navigate to="/design-development/wordpress-web-design" />} />
              <Route path="/seo-services/franchise-seo/" element={<Navigate to="/seo-services/franchise-seo" />} />
              <Route path="/industries/home-repair/" element={<Navigate to="/industries/home-repair" />} />
              <Route path="/design-development/b2c-web-design/" element={<Navigate to="/design-development/b2c-web-design" />} />
              <Route path="/contact-us/" element={<Navigate to="/contact-us" />} />
              <Route path="/industries/technology/" element={<Navigate to="/industries/technology" />} />
              <Route path="/content-marketing-services/guest-blogging/" element={<Navigate to="/content-marketing-services/guest-blogging" />} /> */}


              <Route path="/service" element={<Navigate to="/services" />} />
              <Route path="/industries/foods-beverage" element={<Navigate to="/industries/food-beverage" />} />
              <Route path="/industries/legal/familiy-law" element={<Navigate to="/industries/legal/family-lawyers/seo" />} />
              <Route path="/aerospace-company" element={<Navigate to="/seo-for/aerospace-company" />} />
              <Route path="/alcohol-manufacturer" element={<Navigate to="/seo-for/alcohol-manufacturer" />} />
              <Route path="/industries/insurance/disability-insurance" element={<Navigate to="/industries/insurance/disability-insurance/seo" />} />
              <Route path="/cbd-services" element={<Navigate to="/seo-for/cbd-services" />} />
              <Route path="/electricians-services" element={<Navigate to="/seo-for/electricians-services" />} />
              <Route path="/abortion-clinic" element={<Navigate to="/seo-for/abortion-clinic" />} />
              <Route path="/aluminum-frames-supplier" element={<Navigate to="/seo-for/aluminum-frames-supplier" />} />
              <Route path="/seo-for/" element={<Navigate to="/seo-services" />} />
              <Route path="/virtual-assistant-services" element={<Navigate to="/seo-for/virtual-assistant-services" />} />
              <Route path="/airplane" element={<Navigate to="/seo-for/airplane" />} />
              <Route path="/airport" element={<Navigate to="/seo-for/airport" />} />
              <Route path="/wedding-photographers-services" element={<Navigate to="/seo-for/wedding-photographers-services" />} />
              <Route path="/architects-services" element={<Navigate to="/seo-for/architects-services" />} />
              <Route path="/dispensary-services" element={<Navigate to="/seo-for/dispensary-services" />} />
              <Route path="/air-force-base" element={<Navigate to="/seo-for/air-force-base" />} />
              <Route path="/professional-firm-services" element={<Navigate to="/seo-for/professional-firm-services" />} />
              <Route path="/cosmetics-services" element={<Navigate to="/seo-for/cosmetics-services" />} />
              <Route path="/ppc-servicesage" element={<Navigate to="/ppc-services" />} />
              <Route path="/agricultural-service" element={<Navigate to="/seo-for/agricultural-service" />} />
              <Route path="/industries/franchises/cleaning" element={<Navigate to="/industries/franchises/cleaning-franchise/seo" />} />
              <Route path="/airport-shuttle-service" element={<Navigate to="/seo-for/airport-shuttle-service" />} />
              <Route path="/air-filter-supplier" element={<Navigate to="/seo-for/air-filter-supplier" />} />
              <Route path="/agistment-service" element={<Navigate to="/seo-for/agistment-service" />} />
              <Route path="/adult-entertainment-store" element={<Navigate to="/seo-for/adult-entertainment-store" />} />
              <Route path="/hotel-services" element={<Navigate to="/seo-for/hotel-services" />} />
              <Route path="/airline-ticket-agency" element={<Navigate to="/seo-for/airline-ticket-agency" />} />
              <Route path="/aged-care" element={<Navigate to="/seo-for/aged-care" />} />
              <Route path="/security-guard-services" element={<Navigate to="/seo-for/security-guard-services" />} />
              <Route path="/agricultural-production" element={<Navigate to="/seo-for/agricultural-production" />} />
              <Route path="/translation-services" element={<Navigate to="/seo-for/translation-services" />} />
              <Route path="/alcoholic-beverage-wholesaler" element={<Navigate to="/seo-for/alcoholic-beverage-wholesaler" />} />
              <Route path="/adult-foster-care-service" element={<Navigate to="/seo-for/adult-foster-care-service" />} />
              <Route path="/aluminum-supplier" element={<Navigate to="/seo-for/aluminum-supplier" />} />
              <Route path="/fintech-services" element={<Navigate to="/seo-for/fintech-services" />} />
              <Route path="/agricultural-cooperative" element={<Navigate to="/seo-for/agricultural-cooperative" />} />
              <Route path="/air-taxi" element={<Navigate to="/seo-for/air-taxi" />} />
              <Route path="/agricultural-machinery-manufacturer" element={<Navigate to="/seo-for/agricultural-machinery-manufacturer" />} />
              <Route path="/aircraft-rental-service" element={<Navigate to="/seo-for/aircraft-rental-service" />} />
              <Route path="/air-compressor-supplier" element={<Navigate to="/seo-for/air-compressor-supplier" />} />
              <Route path="/crypto-services" element={<Navigate to="/seo-for/crypto-services" />} />
              <Route path="/spanish-services" element={<Navigate to="/seo-for/spanish-services" />} />
              <Route path="/home-services" element={<Navigate to="/seo-for/home-services" />} />
              <Route path="/aircraft-maintenance-company" element={<Navigate to="/seo-for/aircraft-maintenance-company" />} />
              <Route path="/locksmith-services" element={<Navigate to="/seo-for/locksmith-services" />} />
              <Route path="/air-duct-cleaning-service" element={<Navigate to="/seo-for/air-duct-cleaning-service" />} />
              <Route path="/concierge-services" element={<Navigate to="/seo-for/concierge-services" />} />
              <Route path="/grand-rapids-services" element={<Navigate to="/seo-for/grand-rapids-services" />} />
              <Route path="/german-services" element={<Navigate to="/seo-for/german-services" />} />
              <Route path="/adult-entertainment-club" element={<Navigate to="/seo-for/adult-entertainment-club" />} />
              <Route path="/french-services" element={<Navigate to="/seo-for/french-services" />} />
              <Route path="/air-conditioning-store" element={<Navigate to="/seo-for/air-conditioning-store" />} />
              <Route path="/industries/recration" element={<Navigate to="/industries/recreation" />} />
              <Route path="/academic-department" element={<Navigate to="/seo-for/academic-department" />} />
              <Route path="/fitness" element={<Navigate to="/industries/fitness" />} />
              <Route path="/abrasives-supplier" element={<Navigate to="/seo-for/abrasives-supplier" />} />
              <Route path="/agrochemicals-supplier" element={<Navigate to="/seo-for/agrochemicals-supplier" />} />
              <Route path="/aircraft-supply-store" element={<Navigate to="/seo-for/aircraft-supply-store" />} />
              <Route path="/appliance-repair-services" element={<Navigate to="/seo-for/appliance-repair-service" />} />
              <Route path="/chiropractor-services" element={<Navigate to="/seo-for/chiropractor-services" />} />
              <Route path="/air-conditioning-contractor" element={<Navigate to="/seo-for/air-conditioning-contractor" />} />
              <Route path="/aikido-school" element={<Navigate to="/seo-for/aikido-school" />} />
              <Route path="/3d-printing-services" element={<Navigate to="/seo-for/3d-printing-services" />} />
              <Route path="/aerial-photographer" element={<Navigate to="/seo-for/aerial-photographer" />} />
              <Route path="/aerobics-instructor" element={<Navigate to="/seo-for/aerobics-instructor" />} />
              <Route path="/aero-dance-class" element={<Navigate to="/seo-for/aero-dance-class" />} />
              <Route path="/industries/heavy-equipment/industrial-machinery" element={<Navigate to="/industries/heavy-equipment/industrial-machinery/seo" />} />
              <Route path="/industries/automotive/auto-parts" element={<Navigate to="/industries/automotive/auto-parts-retailers/seo" />} />
              <Route path="/industries/automotive/automotive-dealers" element={<Navigate to="/industries/automotive/automotive-dealers/seo" />} />
              <Route path="/industries/education/arts-schools" element={<Navigate to="/industries/education/arts-schools/seo" />} />
              <Route path="/industries/legal/estate-planning" element={<Navigate to="/industries/legal/estate-planning-lawyers/seo" />} />
              <Route path="/industries/Logistics-transportation/logistics-consultin-firm" element={<Navigate to="/industries/logistics/logistics-consulting-firm" />} />
              <Route path="/industries/franchises/trevel-franchise" element={<Navigate to="/industries/franchises/hospitality-franchise/seo" />} />
              <Route path="/industries/finance/accounting" element={<Navigate to="/industries/finance/accountants/seo" />} />
              <Route path="/design-development/custom-logo-design/" element={<Navigate to="/design-development/custom-logo-design" />} />
              <Route path="/industries/professional-services/" element={<Navigate to="/industries/professional-services" />} />
              <Route path="/ppc-services/" element={<Navigate to="/ppc-services" />} />
              <Route path="/industries/legal/" element={<Navigate to="/industries/legal" />} />
              <Route path="/seo-services/ga4-migration/" element={<Navigate to="/seo-services/ga4-migration" />} />
              <Route path="/ppc-services/tiktok-advertising/" element={<Navigate to="/ppc-services/tiktok-advertising" />} />
              <Route path="/seo-services/wordpress-seo/" element={<Navigate to="/seo-services/wordpress-seo" />} />
              <Route path="/seo-services-blog" element={<Navigate to="/seo-services" />} />
              <Route path="/ppc-services-blog" element={<Navigate to="/ppc-services" />} />
              <Route path="/design-development-services-blog" element={<Navigate to="/design-development" />} />
              <Route path="/content-marketing-blog" element={<Navigate to="/content-marketing-services" />} />
              <Route path="/seo-for/beauty-salon" element={<Navigate to="/industries/fashion/beauty-salons/seo" />} />
              {/* <Route path="/about" element={<Navigate to="/about-us" />} /> */}




              {/* Redirect 404 errors to homepage or any other page */}
              <Route path="*" element={<Navigate to="/" />} />



            </Routes>
            <Footer />
            <BackToTop />
            <Whatsappchat />
          </Suspense>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;