import React from 'react';
import { Link } from 'react-router-dom';
import style from './locations.module.css';
import IndiaMap from '../Pages/IndiaMap';
import LocationCard from '../Pages/LocationCard';
import { Helmet } from 'react-helmet';
import Location from '../assets/Location/locations.png'

function Locations() {
    const paths = [
        '/locations/leeds-seo-agency',
        '/locations/newcastle-seo-agency',
        '/locations/bath-seo-agency',
        '/locations/bend-oregon-seo-agency',
        '/locations/blackpool-seo-agency',
        '/locations/darwin-seo-agency',
        '/locations/fort-worth-seo-agency',
        '/locations/gold-coast-seo-agency',
        '/locations/hertfordshire-seo-agency',
        '/locations/hobart-seo-agency',
        '/locations/huddersfield-seo-agency',
        '/locations/northampton-seo-agency',
        '/locations/peterborough-seo-agency',
        '/locations/preston-seo-agency',
        '/locations/geelong-seo-agency',
        '/locations/guildford-seo-agency',
        '/locations/ottawa-seo-agency',
        '/locations/KL-seo-agency',
        '/locations/pasadena-seo-agency',
        '/locations/chelmsford-seo-agency',
        '/locations/exeter-seo-agency',
        '/locations/doncaster-seo-agency',
        '/locations/leicester-seo-agency',
        '/locations/memphis-seo-agency',
        '/locations/mesa-az-seo-agency',
        '/locations/mississauga-seo-agency',
        '/locations/newcastle-upon-tyne-seo-agency',
        '/locations/oxford-seo-agency',
        '/locations/reading-seo-agency',
        '/locations/california-seo-agency',
        '/locations/florida-seo-agency',
        '/locations/yorkshire-seo-agency',
        '/locations/texas-seo-agency',
        '/locations/thailand-seo-agency',
        '/locations/west-sussex-seo-agency',
        '/locations/singapore-seo-agency',
        '/locations/sydney-seo-agency',
        '/locations/wiltshire-seo-agency',
        '/locations/worthing-seo-agency',
        '/locations/london-seo-agency',
        '/los-angeles-seo-agency',
        '/birmingham-seo-agency',
        '/chicago-seo-agency',
        '/liverpool-seo-agency',
        '/houston-seo-agency',
        '/miami-seo-agency',
        '/UK-seo-agency',
        '/san-francisco-seo-agency',
        '/colchester-seo-agency',
        '/vancouver-seo-agency',
        '/melbourne-seo-agency',
        '/seattle-seo-agency',
        '/boston-seo-agency',
        '/australia-seo-agency',
        '/canada-seo-agency',
        '/virginia-seo-agency',
        '/london-seo-agency-london',
        '/washington-DC-seo-agency',
        '/new-york-seo-agency',
        '/bristol-seo-agency',
        '/edinburgh-seo-agency',
        '/lancashire-seo-agency',
        '/brighton-seo-agency',
        '/USA-seo-agency-',
        '/calgary-seo-agency',
        '/jacksonville-seo-agency',
        '/nottingham-seo-agency',
        '/hornchurch-seo-agency',
        '/locations/philadelphia-seo-agency',
        '/locations/austin-seo-agency',
        '/locations/denver-seo-agency',
        '/locations/abu-dhabi-seo-agency',
        '/locations/adelaide-seo-agency',
        '/locations/edmonton-seo-agency',
        '/locations/perth-seo-agency',
        '/locations/toronto-seo-agency',
        '/locations/auckland-seo-agency',
        '/locations/san-jose-seo-agency',
        '/locations/las-vegas-seo-agency',
        '/locations/new-zealand-seo-agency',
        '/locations/phoenix-seo-agency',
        '/locations/utah-seo-agency',
        '/locations/brooklyn-seo-agency',
        '/locations/buffalo-seo-agency',
        '/locations/online-seo-agency',
        '/locations/outsourcing-seo-agency',
        '/locations/bolton-seo-agency',
        '/locations/charleston-sc-seo-agency',
        '/locations/devon-seo-agency',
        '/locations/bognor-regis-seo-agency',
        '/locations/geelong-seo-agency',
        '/locations/hampshire-seo-agency',
        '/locations/san-diego-seo-agency',
        '/locations/swindon-seo-agency',
        '/locations/switzerland-seo-agency',
        '/locations/scotland-seo-agency',
        '/locations/sheffield-seo-agency',
        '/locations/shoreditch-seo-agency',
        '/locations/slough-seo-agency',
        '/locations/swansea-seo-agency',
        '/locations/manchester-seo-agency',
        '/locations/cambridge-seo-agency',
        '/locations/portsmouth-seo-agency',
        '/locations/norwich-seo-agency',
        '/locations/cheltenham-seo-agency',
        '/locations/berkshire-seo-agency',
        '/locations/maidstone-seo-agency',
        '/locations/aylesbury-seo-agency',
        '/locations/worcester-seo-agency',
        '/locations/belfast-seo-agency',
        '/locations/cardiff-seo-agency',
        '/locations/bradford-seo-agency',
        '/locations/glasgow-seo-agency',
        '/locations/aberdeen-seo-agency',
        '/locations/luton-seo-agency',
        '/locations/southampton-seo-agency',
        '/locations/st-albans-seo-agency',
        '/locations/bedford-seo-agency',
        '/locations/coventry-seo-agency',
        '/locations/buckinghamshire-seo-agency',
        '/locations/milton-keynes-seo-agency',
        '/locations/wokingham-seo-agency',
        '/locations/basingstoke-seo-agency',
        '/locations/high-wycombe-seo-agency',
    ];


    return (
        <>
            <Helmet>
                <title>Digital Marketing Agency Locations | NSP Global Services</title>
                <meta name="description" content="Explore NSP Global Services' worldwide presence and find our strategic locations to better serve your business needs. Visit our website to discover more about our global reach." />
            </Helmet>
            <div className={style.mapAndheading}>
                <div className={style.heading}>
                    <h1 >Locations</h1>
                </div>
                <div className={style.Map} >
                    {/* <IndiaMap /> */}
                    <img src={Location} className={style.Location} alt='Location' />
                </div>
            </div>


            <div className={style.locationlinks}>
                {paths.map((path, index) => (
                    <Link className={style.links} key={index} to={path}> <LocationCard paths={path.includes('/locations/')
                        ? path.replace('/locations/', '')
                        : path.replace('/', '')} /></Link>
                ))}
            </div>
        </>
    );
}

export default Locations;
