import React from 'react'
import style from '../BlogPages/BlogSubPages/Alteration.module.css'
import { GoPlus } from 'react-icons/go'
import FAQ from '../assets/FAQ_1_-removebg.png'
import { useState } from 'react'



const data = [
    {
        id: 0,
        icon: <GoPlus style={{ marginTop: '28px' }} size={25} />,
        statement: 'What are some sweet birthday wishes for my love?',
        paragraph: 'Some sweet birthday wishes include: “To the love of my life, happy birthday! You fill my days with joy and my life with love,” and “Each moment with you is a gem. Happy birthday, my darling!”'
    },
    {
        id: 1,
        icon: <GoPlus style={{ marginTop: '28px' }} size={25} />,
        statement: 'What are the best birthday wishes for your wife?',
        paragraph: `The best wishes for your wife could be: “Happy birthday to the light of my life, my amazing wife,” or “Your love makes every day special. I hope today is as special for you.”`
    },
    {
        id: 2,
        icon: <GoPlus style={{ marginTop: '28px' }} size={25} />,
        statement: 'What are some birthday wishes for a wife?',
        paragraph: `For a wife, you might say: “To my incredible wife, happy birthday! You are my rock and my passion,” or “I’m forever grateful for your love. Happy birthday, sweetheart!`
    },
    {
        id: 3,
        icon: <GoPlus style={{ marginTop: '28px' }} size={25} />,
        statement: 'What is the best birthday quote for a boy who loves you?',
        paragraph: `A great birthday quote for a boy could be: “You make the world a better place just by being in it. Happy birthday to the boy who means everything to me.”With these birthday wishes, quotes, and messages, you can truly express your love and appreciation for your loved ones on their special day.We also offer SEO, Website development, and PPC Services for Small Businesses.`
    }
]

export default function BirthDaywishes() {
    const [selection, setSelection] = useState(null)
    const toggle = (i) => {
        if (selection === i) {
            return setSelection(null)
        }
        setSelection(i)
    }
    return (
        <>
            <div className={style.header}>
                <div className={style.container1_div}>
                    <h1 className={style.container1_div_hone}>100+ Birthday Wishes for Your Loved Ones</h1>
                    {/* <p className={style.container1_div_ptag}>Grow Your Accountancy Firm With Organic SEO Services</p> */}
                </div>
                <div className={style.container2_div}>
                    {/* <TextSphare texts={[
              "Consultation",
              "Keyword Research",
              "On-Page",
              "Off-Page",
              "SEO",
              "Reputation Management",
              "Google Ads",
              "Facebook Ads",
              "Lead Generation",
              "Shopping Ads",
              "Maximize Conversions",
              "Accountancy Firm SEO"
            ]} /> */}
                </div>
            </div>
            <div className={style.container2}>
                <p className={style.container2_ptag}>Birthdays are a special occasion to celebrate the joy and happiness that your loved one brings into your life. Finding the perfect birthday wishes can make their day even more memorable. Whether it’s your friend, wife, or any special person in your life, here are more than 100 heartfelt birthday wishes to inspire you.</p>
                <div className={style.container2_div1}>
                    <h2>Birthday Wishes for a Friend</h2>
                    <p>“Happy birthday to an amazing friend! May this year bring you endless joy and laughter.”</p>
                    <p>“Wishing you a fantastic birthday filled with great adventures and cherished moments.”</p>
                    <p>“Happy birthday wishes for you, my friend! May your day be as wonderful as you are.”</p>
                    <p>“Happy birthday! May your day be filled with love, laughter, and all your favorite things.”</p>
                    <p>“Wishing you a birthday that’s as special as you are, full of moments you’ll cherish forever.”</p>
                </div>
                <div className={style.container2_div1}>
                    <h2>Teasing Happy Birthday Wishes for Friend</h2>
                    <p>“Happy birthday! Here’s to a friend who’s forever young at heart—and slightly older in other places.”</p>
                    <p>“Another year, another gray hair—just kidding! Happy birthday, my ever-youthful friend!”</p>
                    <p>“Happy birthday to the friend who asked if anyone remembered their age—it’s a secret well kept!”</p>
                    <p>“You’re not getting older, just more distinguished. Happy birthday, my ancient friend!”</p>
                    <p>“They say wise people are a bit more eccentric… Happy birthday to the quirkiest friend I know!”</p>
                    <p>“Happy birthday! Remember, you’re not over the hill yet, but you can definitely see it now!”</p>
                    <p>“To my friend on their birthday: here’s to another year of laughing until we forget what we were laughing about.”</p>
                    <p>“Happy birthday! Let’s celebrate like we’re 21—after a long nap.”</p>
                    <p>“Happy birthday! Here’s to a year of mischief and mayhem… just kidding, we’re old and need sleep.”</p>
                    <p>“I was going to bring you candles, but they were ‘out of stock’ for your age group—happy birthday!”</p>
                    <p>“Cheers to a friend who has been 29 for years! Stay forever young, my ageless wonder.”</p>
                    <p>“Happy birthday! Just remember, age gets better with wine—it helps you forget it.”</p>
                    <p>“Here’s to pretending we’re still young enough to stay out past midnight—happy birthday!”</p>
                    <p>“Happy birthday! I won’t make any old jokes, because they’re all worn out just like us.”</p>
                    <p>“Happy birthday to a classic friend. Like all classics, you just keep getting better with age.”</p>
                    <p>“Congrats on reaching an age where you laugh at your own jokes before you tell them—happy birthday!”</p>
                    <p>“Happy birthday! Just remember, gray hair is not a sign of age, it’s a sign of wisdom…or not enough dye.”</p>
                    <p>“Happy birthday! You’re not old; you’re just vintage!”</p>
                    <p>“Happy birthday! The good news is, we’re too old to remember how many candles there should be.”</p>
                    <p>“To my friend who’s gracefully aging—like fine cheese! Happy birthday!”</p>
                    <p>“Happy birthday! They say age is just a number—yours just happens to be really, really big!”</p>
                    <p>“Here’s to a friend who always makes an entrance at a party—mostly because they forgot the doors are there! Happy birthday!</p>
                    <p>“Happy birthday! Age might have slowed you down, but time has just made you more sophisticated—like a busted antique.”</p>
                    <p>“Today we celebrate one of the few things we have in common—our inability to act our age! Happy birthday!”</p>
                    <p>“Happy birthday! Let’s raise a toast to a friend who’s been bringing joy for decades now with that ‘almost there’ memory.”</p>
                </div>
                <div className={style.container2_div1}>
                    <h2>Birthday wishes for husband</h2>
                    <p>Happy birthday to my dear friend! May your special day be filled with love and unforgettable memories.</p>
                    <p>Cheers to you on your birthday! Wishing you another fabulous year of friendship and joy.</p>
                    <p>On your birthday, my friend, I wish you abundant happiness and love.</p>
                    <p>Happy birthday to the friend who knows me inside out! Here’s to more adventures together.</p>
                    <p>Your friendship is a cherished gift. Happy birthday, and may your day be as awesome as you are!</p>
                    <p>Sending you lots of love and laughter on your birthday, my dear friend.</p>
                    <p>Wishing you a day filled with sunshine, smiles, and sweet surprises, my friend. Happy birthday!</p>
                    <p>Happy birthday to the most wonderful friend! Your presence lights up my life.</p>
                    <p>On your birthday, I hope you are surrounded by laughter, love, and cake!</p>
                    <p>Happy birthday to the most inspiring friend I know! Wishing you a day as remarkable as you are.</p>
                    <p>To my incredible friend on your birthday: May your life be filled with endless joy and celebrations.</p>
                    <p>Have a fantastic birthday, my friend! Here’s to more beautiful adventures.</p>
                    <p>Happy birthday to a truly awesome friend—enjoy every moment of your day.</p>
                    <p>May every dream you’ve dreamt come true on your birthday, my dear friend.</p>
                    <p>Happy birthday! I’m so grateful to have you as my friend. You deserve all the best!</p>
                    <p>Wishing you all the laughter and joy your heart can hold on your special day.</p>
                    <p>Here’s to a fabulous birthday for a fantastic friend. Let the celebrations begin!</p>
                    <p>Happy birthday to a friend who makes every day sparkle!</p>
                    <p>To my always-loving and caring friend: Have a delightful birthday filled with good times.</p>
                    <p>Wishing you peace, love, and joy on your special day, my friend.</p>
                    <p>Happy birthday! May this day bring you endless happiness and wonderful memories.</p>
                    <p>You’re not just a friend; you’re family. Happy birthday, and enjoy every minute!</p>
                    <p>Sending the happiest birthday wishes to my irreplaceable friend! Celebrate big!</p>
                    <p>Hope your birthday is full of your favorite things, my friend!</p>
                    <p>Let’s make this day unforgettable. Happy birthday to my one-of-a-kind friend!</p>

                </div>
                <div className={style.container2_div1}>
                    <h2>Happy Birthday Wishes for Your Wife</h2>
                    <p>To my dearest wife, happy birthday! You make every day brighter with your love and laughter.</p>
                    <p>Happy birthday to the love of my life! You are my everything, and I am grateful for you each day.</p>
                    <p>Wishing the happiest of birthdays to my beautiful wife, who never fails to inspire me.</p>

                </div>
                <div className={style.container2_div1}>
                    <h2>More Romantic Birthday Wishes for Wife</h2>
                    <p>On your birthday, I just want to remind you how deeply I love you. Thank you for being my forever love.</p>
                    <p>Happy birthday, my darling wife. May your day be as beautiful and radiant as your smile.</p>
                    <p>To the woman who stole my heart, happy birthday! My love for you grows stronger with each day.</p>
                    <p>Every moment with you is a gift. Wishing you endless love and joy on your birthday, my sweet wife.</p>
                    <p>Happy birthday to the love of my life. Your presence makes my world so much brighter.</p>
                    <p>My love for you knows no bounds. Happy birthday, my precious wife, and thank you for being my rock.</p>
                    <p>To my wonderful wife: Happy birthday! You are the reason for every joy and every success in my life.</p>
                    <p>May your birthday be filled with all the love and happiness you bring to my life every day.</p>
                    <p>Happy birthday to my beautiful wife. I am so grateful for you and the love we share.</p>
                    <p>On your special day, I want to remind you of the incredible love we have. Happy birthday, darling!</p>
                    <p>To my soulmate and best friend—happy birthday. My love for you is as deep as the ocean.</p>
                    <p>The day you came into the world was the day my life changed for the better. Happy birthday, my love.</p>
                    <p>Happy birthday to the most amazing woman I know. May our love continue to blossom.</p>
                    <p>You are the love of my life, and I cherish every moment with you. Happy birthday, sweetheart!</p>
                    <p>To my dearest wife: Happy birthday. May every dream you have come true, today and always.</p>
                    <p>Happy birthday to my forever love. You are the melody in my life, and I love every note.</p>
                    <p>Having you in my life is a gift I cherish every day. Happy birthday to my beloved wife.</p>
                    <p>Each day with you is a beautiful addition to my life’s journey. Happy birthday, dear wife.</p>
                    <p>Happy birthday to the woman who completes me. You are my everything.</p>
                    <p>To my one and only: Happy birthday. May your day be as lovely and wonderful as you are.</p>
                    <p>Your beauty, grace, and love fill my life with happiness. Happy birthday, my cherished wife.</p>
                    <p>With you, every day is magical. Happy birthday to the woman who makes my life extraordinary.</p>
                    <p>May this birthday be as memorable and amazing as you are, my love. Happy birthday!</p>
                    <p>Happy birthday to the woman who brings light to my darkness and joy to my heart.</p>
                    <p>On your birthday, I celebrate you and the amazing love we share. Happy birthday, my darling wife.</p>
                </div>
                <div className={style.container2_div1}>
                    <h2>Heartfelt Happy Birthday Messages to Mom</h2>
                    <p>Happy Birthday to the most amazing mom! Thank you for your endless love and support. Enjoy your special day!</p>
                    <p>Wishing my beautiful mom a birthday filled with laughter, joy, and all the love in the world. Happy Birthday!</p>
                    <p>Happy Birthday, Mom! Your love and wisdom guide us every day. Here’s to an extraordinary year ahead!</p>
                    <p>To my wonderful mother, happy birthday! May your day be as incredible as you are.</p>
                    <p>Happy Birthday to the heart of our family! Thank you for all that you do. We love you immensely!</p>
                    <p>Mom, your kindness and love make the world a better place. Have a fantastic birthday!</p>
                    <p>Cheers to another year of amazing adventures with you, Mom. Happy Birthday!</p>
                    <p>May your birthday be filled with all the love and happiness you bring into our lives. Happy Birthday, Mom!</p>
                    <p>Happy Birthday to the person who always knows how to brighten my day! I love you, Mom!</p>
                    <p>Mom, thank you for being our guiding star. Wishing you an unforgettable birthday!</p>
                    <p>Happy Birthday, Mom! Your strength and love inspire us all. May this year bring you immense joy!</p>
                    <p>To my caring and loving mom, happy birthday! May your day be filled with unforgettable moments.</p>
                    <p>Mom, you are truly one of a kind. Happy Birthday to the most special person in my life!</p>
                    <p>Happy Birthday to the sweetest mom! May your day be filled with everything and everyone you love.</p>
                    <p>Wishing my wonderful mom a fabulous birthday! Here’s to more love, hugs, and cherished memories.</p>
                    <p>Happy Birthday, dearest Mom! Thank you for making the world a brighter place.</p>
                    <p>May your birthday be as exquisite as you are, Mom. Happy Birthday to the queen of our hearts!</p>
                    <p>To the best mom in the world, happy birthday! May you always be surrounded by love and happiness.</p>
                    <p>Happy Birthday, Mom! Your love is my strength and guidance. Celebrate big!</p>
                    <p>Wishing my amazing mom a birthday full of love, laughter, and endless joy. Happy Birthday!</p>
                </div>
                <div className={style.container2_div1}>
                    <h2>Happy Birthday Messages for Father</h2>
                    <p>Happy Birthday, Dad! Your wisdom, kindness, and sense of humor have always inspired me. I’m grateful for every moment spent with you. Wishing you a fantastic day filled with love and laughter!</p>
                    <p>To the World’s Best Father: On your special day, I want you to know how much you mean to me. Thank you for your endless support and guidance. May this year bring you as much happiness as you’ve brought into my life. Happy Birthday!</p>
                    <p>Dear Dad, Happy Birthday! Your strength and resilience are unmatched, and I’m so proud to call you my father. May your day be filled with joy, surrounded by those you love the most. Cheers to wonderful memories and new adventures!</p>
                    <p>Happy Birthday, Daddy! You’re my hero and my mentor. I hope this birthday is as incredible as you are and that the joy you bring to others comes back to you tenfold. Enjoy your day!</p>
                </div>
                <div className={style.container2_div1}>
                    <h2>Birthday Quotes for Loved Ones</h2>
                    <p>“The more you praise and celebrate your life, the more there is in life to celebrate.” – Oprah Winfrey</p>
                    <p>“Count your life by smiles, not tears. Count your age by friends, not years. Happy birthday!” — John Lennon</p>
                    <p>“A birthday is like a new year and my wish for you is a great year full of happiness and sunshine!” — Catherine Pulsifer</p>
                    <p>“You are never too old to set another goal or to dream a new dream.” — C.S. Lewis</p>
                    <p>“Let us celebrate the occasion with wine and sweet words.” — Plautus</p>
                    <p>“Whatever with the past has gone, the best is always yet to come.” — Lucy Larcom</p>
                </div>
                {/* FAQ */}
                <div className={style.FAQS}>
                    <div >
                        <img className={style.faq} src={FAQ} alt="FAQ" />
                    </div>
                    <div className={style.wrapper}>
                        <h1 className={style.accordion_h1}>Frequently Asked Question</h1>
                        <div className={style.accordion}>
                            {data.map((element, i) => (
                                <div key={element.id}>
                                    <div className={style.title_1} style={{ backgroundColor: 'white', height: '90px' }} onClick={() => { toggle(i) }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', gridGap: '8px' }}>{element.icon}<h2 className={style.accodion_h2}>{element.statement}</h2></div>
                                    </div>
                                    <div className={selection === i ? `${style.contain} ${style.show}` : `${style.contain}`}>
                                        <p className={style.container_accordion_ptag}>  {element.paragraph}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
