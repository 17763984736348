import React, { useRef, useEffect } from 'react'
import emailjs from '@emailjs/browser';
import styles from '../IndustryServices/IndustryServices.module.css'
import TextSphare from '../BlogPages/BlogSubPages/TextSphare'
import { Link, useNavigate } from 'react-router-dom'
import Image18 from '../assets/Industry Services/SEO Services/New/20240424_234143_0004.png'
// import Image19 from '../assets/Industry Services/SEO Services/New/20240424_234143_0005.png'

// import Video from '../assets/Industry Services/Video/marketing.mp4'

import bannerImage from '../assets/Digital Marketing/IMG-20231116-WA0001.jpg'
import BannerImage from '../assets/Digital Marketing//NspGlobalServices.png'
// import Icons1 from '../assets/Home Two/IMG-20231207-WA0009.jpg'
// import Icons2 from '../assets/Home Two/IMG-20231207-WA0010.jpg'
// import Icons3 from '../assets/Home Two/IMG-20231207-WA0011.jpg'
// import Icons4 from '../assets/Home Two/IMG-20231207-WA0012.jpg'
import Toolsnames from '../Pages/Toolsnames'
import Carousal from '../Carousal'
import { Helmet } from 'react-helmet';

import Keyword from '../assets/Industry Services/Services Images/keyword search.png'
import onPageSEO from '../assets/Industry Services/Services Images/on page.png'
import offPageSEO from '../assets/Industry Services/Services Images/20240426_190746_0000.png'
import technical from '../assets/Industry Services/Services Images/technical seo.png'
import mobileSEO from '../assets/Industry Services/Services Images/mobile seo.png'
import localSEO from '../assets/Industry Services/Services Images/localized seo.png'
import auditingSEO from '../assets/Industry Services/Services Images/audit seo.png'
import analitics from '../assets/Industry Services/Services Images/analyst service.png'
import multiLingual from '../assets/Industry Services/Services Images/20240426_190746_0000.png'
import contentCreation from '../assets/Industry Services/Services Images/seo content.png'
import linkBuilding from '../assets/Industry Services/Services Images/linkbuilding.png'

import Arrow from '../assets/right-arrow.png'



function SEOCaseStudy(props) {
    const texts = ["Tags", "Meta", "SEO", "Audit", "Local", "Maps", "Links", "Schema", "Content", "Keywords"];

    const navigate = useNavigate()
    const form = useRef();
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    }, []);

    const sendEmail = (e) => {
        e.preventDefault();
        navigate("/thank-you")
        emailjs
            .sendForm('service_xeikd6k', 'template_184dkpm', form.current, {
                publicKey: 'n--h-ZLHb25m5t0A1',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    };
    return (
        <>
            <Helmet>
                <title>SEO Services for {props.heading}</title>
                <meta property="og:title" content={`${props.heading}`} />
                <meta property="og:description" content={`Drive organic growth with our expert ${props.heading} SEO Service. Boost rankings, increase leads, and maximize ROI. Contact us today`} />
                <meta name="description" content={`Drive organic growth in the cleaning industry with our expert ${props.heading}. Boost rankings, increase leads, and maximize ROI. Contact us today`} />
                <link rel="canonical" href={`https://www.nspglobalservices.com/seo-for/${props.link}`} />
            </Helmet>
            <div className={styles.container}>
                <div className={styles.content} >
                    <h1 className={styles.title} >SEO For {props.heading}</h1>
                    <p className={styles.subtitle}>Let’s talk about how we can maximise your ROI from Premium SEO Services</p>
                    <div className={styles.buttons} >
                        {/* <Link className={styles.btns} to='/contact-us'>Call us now</Link> */}
                        <Link className={styles.btns} to='/contact-us'>Contact us now</Link>
                    </div>
                </div>
                <div className={styles.bannerImage}>
                    <TextSphare texts={texts} />
                </div>
            </div>
            <div className={styles.contents} >
                <h2 className={styles.heading}>Drive Your Business Growth with {props.heading}</h2>
                {/* <div className={styles.alternativeContent} >
                    <div className={styles.contentImages} >
                        <img style={{ height: '100%', width: '70%' }} src={Images1} alt="" />
                        <video ref={videoRef} style={{ height: '68%' }} loop autoplay muted>
                            <source src={Video} type="video/mp4" />
                        </video>
                    </div>
                    <div className={styles.contentTexts}>
                        <h2>SEO Keyword Research Services</h2>
                        <p>Our<Link to={'/seo-services/seo-keywords-research'}> Keyword Research Services</Link> form the foundation of our SEO strategy, designed to propel your website to the top ranks of search engine results. We employ advanced tools and techniques to identify the keywords and phrases most relevant to your business, ensuring they align with the queries your target audience is using to search for your products or services. This critical insight allows us to optimize your site's content and metadata with precision, driving targeted traffic and increasing your site’s visibility. Through our meticulous research process, we not only target high-volume keywords but also uncover long-tail opportunities that offer a higher conversion rate, setting the stage for your business's online growth and success.</p>
                    </div>
                </div> */}
                <div className={styles.alternativeContent}>
                    <div className={styles.contentImages}>
                        <img style={{ width: '70%' }} src={Keyword} alt="" />
                        {/* <MyWordCloud /> */}
                    </div>
                    <div className={styles.contentTexts}>
                        {/* <h2>On Page SEO Services</h2> */}
                        <h2 className={styles.heading} >Dominate search rankings with SEO keyword research services.</h2>
                        {/* <p>Optimize your website's content and structure for top performance in search engines. Boost your online visibility and attract the right audience with our meticulous on-page SEO strategies, including keyword targeting, content optimization, and meta-tag enhancement.Our on page SEO specialists are dedicated to optimizing every aspect of your website to ensure it performs exceptionally in search engine rankings.For businesses seeking a more bespoke approach without the overhead of an in-house team, our white label <Link to={'/seo-services/on-page-seo'}>on-page SEO services</Link> offer a seamless solution, allowing you to provide top-tier SEO expertise under your own brand name.</p> */}
                        <p className={styles.paragraph}>Stay ahead of the competition and increase your website's visibility and traffic.</p>
                    </div>

                </div>
                {/* <div className={styles.alternativeContent}>
                    <div className={styles.contentTexts}>
                        <h2 className={styles.heading}>SEO Keyword Research Services</h2>
                        <p className={styles.paragraph}>Explore our  <Link to={'/seo-services/seo-keywords-research'}>Keyword Research Services</Link>, the foundation of our SEO strategy, aimed at boosting your website's search engine rankings by identifying precise keywords for optimum content visibility and conversion rates.</p>
                    </div>
                    <div className={styles.contentImages}>
                        <img style={{ width: '70%', mixBlendMode: 'darken' }} src={Image15} alt="" />
                    </div>
                </div> */}
                {/* <div className={styles.alternativeContent}>
                    <div className={styles.contentTexts}>
                        <h2>Technical SEO Services</h2>
                        <p>Ensure your website operates at lightning-fast speed with seamless navigation by tackling the complex elements of <Link to={'/seo-services/technical-seo'}>technical SEO</Link>, from site speed optimizations to structured data implementation, ensuring search engines can crawl and index your website smoothly. Improve your website's performance with our comprehensive technical SEO audit consultancy, identifying and fixing potential pitfalls to boost your search rankings. Gain a competitive edge by hiring our technical SEO experts, skilled in refining your website's backend operations for optimal search engine visibility. Benefit from the expertise of a dedicated technical SEO consultants who can provide customized strategies to enhance your website's search engine visibility, or leverage our specialized technical SEO services designed to skyrocket your ecommerce platform's visibility and drive sales. Our team of technical SEO audit consultants is ready to provide targeted strategies that ensure your website exceeds search engine standards for maximum visibility.</p>
                    </div>
                    <div className={styles.contentImages}>
                        <img style={{ width: '70%', mixBlendMode: 'darken' }} src={Images4} alt="" />
                    </div>
                </div> */}
                <div className={styles.alternativeContent}>
                    <div className={styles.contentTexts}>
                        {/* <h2>SEO Content Creation Services</h2> */}
                        <h2 className={styles.heading} >Boost your website ranking with our On Page SEO services. </h2>
                        {/* <p>In the digital era, content reigns supreme. Our digital <Link to={'/content-marketing-services'}>content creation services</Link> are tailored to enhance our SEO strategies, delivering engaging, informative, and value-driven content that not only secures a top spot in search engine rankings but also positions your brand as an industry leader. We offer the best content creation services, specializing in high-quality, SEO-optimized blog posts, articles, product descriptions, and multimedia content, including video content creation services, that truly connect with your target audience. Further, our website content creation services ensure your online presence is powerful and persuasive. Coupled with social media management and content creation services, we craft content that not only aligns with your brand's voice and messaging but also meets your business objectives and satisfies your audience's needs. </p> */}
                        <p className={styles.paragraph}>Maximize your online presence with optimized keywords, enhanced content, and more!</p>
                    </div>
                    <div className={styles.contentImages}>
                        <img style={{ width: '70%', mixBlendMode: 'darken' }} src={onPageSEO} alt="" />
                    </div>
                </div>
                {/* <div className={styles.alternativeContent}>
                    <div className={styles.contentTexts}>
                        <h2 className={styles.heading}>On Page SEO Services</h2>
                        <p className={styles.paragraph}>Boost your site's search engine performance and online visibility with our expert <Link to={'/seo-services/on-page-seo'}>on-page SEO</Link> strategies. From keyword targeting to content and meta-tag optimization, our specialists fine-tune every detail of your site for peak search rankings. Want the expertise without the in-house team hassle? Our white label on-page SEO services let you offer premium SEO under your brand.</p>
                    </div>
                    <div className={styles.contentImages}>
                        <img style={{ width: '70%', mixBlendMode: 'darken' }} src={Image17} alt="" />
                    </div>
                </div> */}
                <div className={styles.alternativeContent}>
                    <div className={styles.contentImages}>
                        <img style={{ width: '70%', mixBlendMode: 'darken' }} src={linkBuilding} alt="" />
                    </div>
                    <div className={styles.contentTexts}>
                        {/* <h2>International SEO Services</h2> */}
                        <h2 className={styles.heading}>Expand your digital footprint with off-page SEO services.</h2>
                        <p className={styles.paragraph}>Drive engagement and broaden your reach with our tailored strategies.  <Link to={'/seo-services/backlink-audit'}><img src={Arrow} className={styles.arrow} alt="" /> </Link></p>
                    </div>
                </div>
                <div className={styles.alternativeContent}>
                    <div className={styles.contentTexts}>
                        <h2 className={styles.heading}>Take your website to the top of SERP with advanced technical SEO.</h2>
                        <p className={styles.paragraph}>Optimize your speed, navigation, and structured data for maximum success online.  <Link to={'/seo-services/technical-seo'}><img src={Arrow} className={styles.arrow} alt="" /> </Link></p>
                    </div>
                    <div className={styles.contentImages}>
                        <img style={{ width: '70%', mixBlendMode: 'darken' }} src={technical} alt="" />
                    </div>
                </div>
                <div className={styles.alternativeContent}>
                    <div className={styles.contentImages}>
                        <img style={{ width: '70%', mixBlendMode: 'darken' }} src={mobileSEO} alt="" />
                    </div>
                    <div className={styles.contentTexts}>
                        <h2 className={styles.heading}>Engage with users on all devices with expert mobile SEO services.</h2>
                        <p className={styles.paragraph}>Don't miss out on potential customers from the growing mobile market.  <Link to={'/seo-services/mobile-seo'}><img src={Arrow} className={styles.arrow} alt="" /> </Link></p>
                    </div>
                </div>
                <div className={styles.alternativeContent}>
                    <div className={styles.contentTexts}>
                        <h2 className={styles.heading}>Dominate your local market with top localization SEO services.</h2>
                        <p className={styles.paragraph}>Boost your visibility on Google Maps and local directories to connect with nearby customers and grow your business.  <Link to={'/seo-services/local-seo'}><img src={Arrow} className={styles.arrow} alt="" /> </Link></p>
                    </div>
                    <div className={styles.contentImages}>
                        <img style={{ width: '70%', mixBlendMode: 'darken' }} src={localSEO} alt="" />
                    </div>
                </div>
                <div className={styles.alternativeContent}>
                    <div className={styles.contentImages}>
                        <img style={{ width: '70%', mixBlendMode: 'darken' }} src={auditingSEO} alt="" />
                    </div>
                    <div className={styles.contentTexts}>
                        <h2 className={styles.heading}>Maximize your online presence with SEO Auditing Services.</h2>
                        <p className={styles.paragraph}> Uncover hidden opportunities for improvement and increase your online visibility today!   <Link to={'/seo-services/seo-audits'}><img src={Arrow} className={styles.arrow} alt="" /> </Link></p>
                    </div>
                </div>
                <div className={styles.alternativeContent}>
                    <div className={styles.contentTexts}>
                        <h2 className={styles.heading}>Take control of your online presence with powerful Analytics Services. </h2>
                        <p className={styles.paragraph}>Get actionable insights and track your site's performance for better online success.  <Link to={'/seo-services/google-analytics-services'}><img src={Arrow} className={styles.arrow} alt="" /> </Link></p>
                    </div>
                    <div className={styles.contentImages}>
                        <img style={{ width: '70%', mixBlendMode: 'darken' }} src={analitics} alt="" />
                    </div>
                </div>
                <div className={styles.alternativeContent}>
                    <div className={styles.contentImages}>
                        <img style={{ width: '70%', mixBlendMode: 'darken' }} src={multiLingual} alt="" />
                    </div>
                    <div className={styles.contentTexts}>
                        <h2 className={styles.heading}>Reach a global audience with our multilingual SEO services. </h2>
                        <p className={styles.paragraph}>Expand your online presence across languages and cultures for maximum impact. <Link to={'/seo-services/'}><img src={Arrow} className={styles.arrow} alt="" /> </Link></p>
                    </div>
                </div>
                <div className={styles.alternativeContent}>
                    <div className={styles.contentTexts}>
                        <h2 className={styles.heading}>Transform online presence with SEO content creation services.</h2>
                        <p className={styles.paragraph}>Reign over the digital world with our expertly crafted blogs, articles, product descriptions, and engaging video content.  <Link to={'/content-marketing-services'}><img src={Arrow} className={styles.arrow} alt="" /> </Link></p>
                    </div>
                    <div className={styles.contentImages}>
                        <img style={{ width: '70%', mixBlendMode: 'darken' }} src={contentCreation} alt="" />
                    </div>
                </div>
                {/* <div className={styles.alternativeContent}>
                    <div className={styles.contentImages}>
                        <img style={{ width: '70%', mixBlendMode: 'darken' }} src={Images13} alt="" />
                    </div>
                    <div className={styles.contentTexts}>
                        <h2>Maximize Your ROI with Our SEO Services</h2>
                        <p>Our comprehensive SEO services are designed to give your website a competitive advantage in the ever-evolving digital landscape. With our expert team, up-to-date strategies, and data-driven approach, we ensure maximum return on investment for your business. Don't miss out on the opportunity to drive your business growth with advanced SEO services today.</p>
                    </div>
                </div> */}
            </div>
            {/* container 3 */}
            <div className={styles.container_3}>
                <div className={styles.container_3_div1}>
                    <h3 className={styles.container_3_H2}>Why Choose Us?</h3>
                    <p className={styles.container_3_para}>Increase your online presence and drive success with NSP's comprehensive digital marketing services. Benefit from increased visibility, lead generation, and revenue growth.</p>
                </div>
                <div className={styles.cardContainer}>
                    <div className={styles.card} >
                        {/* <img loading="lazy" src={Icons1} alt='Icons1' className={styles.cardimg} /> */}
                        <h3 className={styles.cardTitle}>Best SEO Services Near Me</h3>
                        <p className={styles.cardPara}>NSP Global Services We are just a Click Away for all your SEO needs.</p>
                    </div>
                    <div className={styles.card} >
                        {/* <img loading="lazy" src={Icons2} alt='Icons2' className={styles.cardimg} /> */}
                        <h3 className={styles.cardTitle}>SEO Optimization</h3>
                        <p className={styles.cardPara}> Stay ahead with the latest SEO techniques tailored to your specific industry needs.</p>
                    </div>
                    <div className={styles.card} >
                        {/* <img loading="lazy" src={Icons3} alt='Icons3' className={styles.cardimg} /> */}
                        <h3 className={styles.cardTitle}>Improve Search Rankings</h3>
                        <p className={styles.cardPara}>Climb to the top of search results with our proven track record of success.</p>
                    </div>
                    <div className={styles.card} >
                        {/* <img loading="lazy" src={Icons4} alt='Icons4' className={styles.cardimg} /> */}
                        <h3 className={styles.cardTitle}>Experience and Expertise</h3>
                        <p className={styles.cardPara}> A team of SEO experts dedicated to keeping you ahead of the competition.</p>
                    </div>
                </div>
            </div>
            {/* toolsname */}
            <div className={styles.tools}>
                <h2 className={styles.toolweuse} >Tools that we use</h2>
                <div className={styles.toolsname}> <Toolsnames /></div>
            </div>
            {/* testimonials  */}
            <div className={styles.container5}>
                <div className={styles.container5_div}>
                    <p className={styles.container4_para1}>Testimonials</p>
                    <h2 className={styles.container4_hone}>What People Say</h2>
                    {/* <p className={styles.container4_para2}>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. when the musics over turnoff the light</p> */}
                </div>
            </div>
            <div className={styles.carousel}>
                <Carousal num={3} />
            </div>
            {/*  */}
            <div className={styles.container4}>
                <div >
                    <img loading="lazy" src={BannerImage} alt='bannerImage' className={styles.bannerimage} />
                </div>
                <div className={styles.bannerForm}>
                    <h2>Get Your Free Quote</h2>
                    <form className={styles.Form} ref={form} onSubmit={sendEmail} >
                        <input className={styles.inputs} placeholder='Your Name' type="text" name="user_name" />
                        <input className={styles.inputs} placeholder='Your email' type="email" name="user_email" />
                        <input className={styles.inputs} name="message" placeholder='Enter your message' />
                        {/* <input type="hidden" name="_template" value="table"></input> */}
                        <input type="submit" className={styles.FormButton} value="Send" />
                        {/* <button className={style.FormButton} type='submit'>Submit</button> */}
                    </form>
                </div>
            </div>
        </>
    )
}

export default SEOCaseStudy
