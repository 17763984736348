import React from 'react';

function EmbeddedMap() {
    // Directly use the iframe HTML code copied from Google Maps
    const iframeHtml = `<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d16540590.466578657!2d79.39566441081209!3d22.83923693759985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1720286085966!5m2!1sen!2sin" width="800" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`;

    return (
        <div dangerouslySetInnerHTML={{ __html: iframeHtml }} style={{ width: '100%', height: '100%' }} />
    );
}

export default EmbeddedMap;
