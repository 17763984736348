import React from 'react'
import style from '../BlogPages/BlogSubPages/Alteration.module.css'
import { GoPlus } from 'react-icons/go'
import FAQ from '../assets/FAQ_1_-removebg.png'
import { useState } from 'react'



const data = [
    {
        id: 0,
        icon: <GoPlus style={{ marginTop: '28px' }} size={25} />,
        statement: 'Why is a professional website important for my business?',
        paragraph: 'A professional website is essential for any business. A high-quality website demonstrates you take your company’s presence seriously and can also increase customer confidence and build trust in your brand. An effective website needs to have a modern design that reflects your company’s identity, be easy to navigate and use, load quickly, and offer engaging content. Additionally, an eCommerce website development should provide customers with the necessary tools to purchase goods or services from you online. Investing in web development services ensures your site is aesthetically appealing, responsive, safe from cyber security threats, and optimized for search engines and user experience – all of which are critical aspects of success for any digital business strategy.'
    },
    {
        id: 1,
        icon: <GoPlus style={{ marginTop: '28px' }} size={25} />,
        statement: 'What are the benefits of hiring a professional website development company?',
        paragraph: `Hiring a professional website development company offers numerous benefits. From web design to website performance, e-commerce websites, and web development services, their expertise in these areas will allow you to create a compelling and user-friendly site that meets your goals and objectives. Professional website developers can help you create effective content for your e-commerce website development needs and optimize the navigation to make it easier for customers to interact with your business. They can also ensure maximum performance of the site by implementing best practices in coding technologies so that it runs quickly without glitches or crashes. Hiring a professional web developer will save you time and money while giving your project the highest quality results possible.`
    },
    {
        id: 2,
        icon: <GoPlus style={{ marginTop: '28px' }} size={25} />,
        statement: 'What factors should I consider when choosing a website development company for my business?',
        paragraph: `When selecting a web development company for your business, it’s essential to evaluate how well it can meet the specific needs of your project. Consider their experience in designing and developing websites and their expertise with e-commerce solutions and website performance metrics. Be sure to ask about the quality of their services, what kind of warranty they offer, and if they can provide references from past clients. A reliable web development service should also have experience with the most modern technology available to create the best possible product for you.`
    },
    {
        id: 3,
        icon: <GoPlus style={{ marginTop: '28px' }} size={25} />,
        statement: 'How long does it typically take to develop a website, and what is involved in the process?',
        paragraph: `The time it takes to develop a website varies depending on the project’s complexity. Still, generally speaking, a professional web development team will require anywhere from 4-6 weeks for basic designs and functionality and up to 8-12 weeks for more complex e-commerce website projects. This also depends on various factors, such as website design, performance requirements, content creation, and integration. Using an experienced web development service provider can streamline the whole process by providing specialist knowledge in each area of expertise necessary to bring your online presence alive.`
    },
    {
        id: 4,
        icon: <GoPlus style={{ marginTop: '28px' }} size={25} />,
        statement: 'What kind of website maintenance and support can I expect after my website is launched?',
        paragraph: `With the right website development services, you can expect comprehensive support and maintenance after successfully launching your website. Our web development experts are highly proficient in website design, e-commerce websites, and performance analysis. We pay extra attention to the latest trends and technologies so that our client’s websites stay ahead of the competition all year round. A satisfaction guarantee and timely customer service for urgent requests or queries back all our work.`
    }
]

export default function WebDevelopmentCompany() {
    const [selection, setSelection] = useState(null)
    const toggle = (i) => {
        if (selection === i) {
            return setSelection(null)
        }
        setSelection(i)
    }
    return (
        <>
            <div className={style.header}>
                <div className={style.container1_div}>
                    <h1 className={style.container1_div_hone}>The Benefits of Hiring a Professional Website Development Company for Your Business</h1>
                    {/* <p className={style.container1_div_ptag}>Grow Your Accountancy Firm With Organic SEO Services</p> */}
                </div>
                <div className={style.container2_div}>
                    {/* <TextSphare texts={[
              "Consultation",
              "Keyword Research",
              "On-Page",
              "Off-Page",
              "SEO",
              "Reputation Management",
              "Google Ads",
              "Facebook Ads",
              "Lead Generation",
              "Shopping Ads",
              "Maximize Conversions",
              "Accountancy Firm SEO"
            ]} /> */}
                </div>
            </div>
            <div className={style.container2}>
                <p className={style.container2_ptag}>In today’s digital age, having a professional and efficient website is crucial for the success of any business. Your website is the online representation of your brand, and it needs to be developed to meet your unique needs and requirements. Hiring a professional website development company can help you achieve this goal, ensuring the best possible results for your company’s online presence. In this blog post, we will explore the benefits of hiring a professional website development company for your business, focusing on key factors like the website’s quality, the developers’ expertise, and the efficient use of time and resources in the development process.</p>
                <div className={style.container2_div1}>
                    <h2>Website Design & Development</h2>
                    <p>One of the most significant advantages of hiring a professional web development company is its high-quality design and development services. A well-designed website is essential for attracting and retaining visitors, and a professional team will ensure that your site is visually appealing, user-friendly, and optimized for search engines. They will also provide ongoing support and maintenance to keep your website up to date and running smoothly, as well as assist with any necessary updates or improvements in the future.</p>
                </div>
                <div className={style.container2_div1}>
                    <h2>Website Performance</h2>
                    <p>A professional website development company understands the importance of website performance, ensuring that your site loads quickly and seamlessly across all devices and browsers. This improves user experience and affects search engine rankings, as Google rewards faster-loading sites with higher placement in search results. By investing in a professional website, you can guarantee optimal performance, which will ultimately result in increased traffic and more potential clients.</p>
                </div>
                <div className={style.container2_div1}>
                    <h2>E-commerce Website Development</h2>
                    <p>A professional website development company can be invaluable for businesses that operate in the e-commerce space. These companies have the expertise and experience to build a fully functional, responsive, secure e-commerce website to meet your unique needs. They will also advise on the best e-commerce solutions and tools available, helping you streamline the shopping experience for your customers and maximize sales.</p>
                </div>
                <div className={style.container2_div1}>
                    <h2>Factors to Consider When Choosing a Website Development Company</h2>
                    <p>When selecting a responsive website development company for your business, there are several factors to consider, such as the company’s portfolio, customer reviews, and communication skills. It’s crucial to choose a company with experience working within your industry and a proven track record of delivering successful projects on time and within a budget.</p>
                </div>
                <div className={style.container2_div1}>
                    <h2>Time and Process</h2>
                    <p>The time it takes to develop a website varies based on several factors, including the project’s complexity, the team’s size, and the client’s requirements. Typically, it can take anywhere from a few weeks to several months to complete a website project. The process usually starts with a consultation, design, and development, testing, and launch.</p>
                </div>
                <div className={style.container2_div1}>
                    <h2>Maintenance and Support</h2>
                    <p>After your website is launched, it is essential to maintain and update it to ensure it continues optimally functioning. Most professional website development companies offer ongoing maintenance and support services to address issues, make necessary updates, and keep your site running smoothly.</p>
                </div>
                <div className={style.container2_div1}>
                    <h2>Conclusion</h2>
                    <p>In conclusion, having a best website design company is essential for the success of your business in today’s digital age. Hiring a website development company ensures your website is built to meet your unique needs, adhering to the best design and development practices. The specific benefits of hiring a professional website development company include higher design quality, expert development services, improved performance, tailored e-commerce solutions, and ongoing maintenance and support. To choose the right company for your needs, be sure to consider factors like these.</p>
                </div>
                {/* FAQ */}
                <div className={style.FAQS}>
                    <div >
                        <img className={style.faq} src={FAQ} alt="FAQ" />
                    </div>
                    <div className={style.wrapper}>
                        <h1 className={style.accordion_h1}>Frequently Asked Question</h1>
                        <div className={style.accordion}>
                            {data.map((element, i) => (
                                <div key={element.id}>
                                    <div className={style.title_1} style={{ backgroundColor: 'white', height: '90px' }} onClick={() => { toggle(i) }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', gridGap: '8px' }}>{element.icon}<h2 className={style.accodion_h2}>{element.statement}</h2></div>
                                    </div>
                                    <div className={selection === i ? `${style.contain} ${style.show}` : `${style.contain}`}>
                                        <p className={style.container_accordion_ptag}>  {element.paragraph}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
